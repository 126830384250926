const state = () => ({
    cards: [],
});

const getters = {
    getCards(state) {
        return state.cards;
    },
};

const mutations = {
    setCards(state, cards) {
        state.bluePrints = cards;
    },
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}