<template>
    <div id="register-main" class="auth-container">
        <div class="auth-container__form-wrap">
            
            <AnimatedLogoPreloader> </AnimatedLogoPreloader>

            <p class="auth-container__form-wrap__form-description">
                Tell us about yourself, so we can <br/> connect to you to your gym</p>

            <form class="auth-form auth-form--with-subhead" v-on:keyup.enter="logAsGuest()">
                <AppInputComponent
                        class="auth-form__field"
                        required
                        placeholder="First Name"
                        v-model="$v.fName.$model"
                        :error="$v.fName.$error && !$v.fName.required"
                        errorText="First Name is required"
                />
                <AppInputComponent
                        class="auth-form__field"
                        required
                        placeholder="Last Name"
                        v-model="$v.lName.$model"
                        :error="$v.lName.$error && !$v.lName.required"
                        errorText="Last Name is required"
                />
                <AppInputComponent
                        class="auth-form__field"
                        required
                        placeholder="Email"
                        v-model="$v.email.$model"
                        :error="$v.email.$invalid && $v.email.$dirty"
                        errorText="Valid email is required"
                />
                <AppInputComponent
                        class="auth-form__field"
                        required
                        placeholder="Phone"
                        v-model="$v.phone.$model"
                        :error="$v.phone.$error && !$v.phone.required"
                        errorText="Phone is required"
                />

                <md-button class="md-primary md-raised auth-form__submit-btn md-theme-light"
                           :disabled="$v.$invalid"
                           @click="logAsGuest()">SEND
                </md-button>
            </form>
        </div>

    </div>
</template>

<script>
    import {required, email} from 'vuelidate/lib/validators'
    import AnimatedLogoPreloader from "@/components/Common/AnimatedLogoPreloader";

    export default {
        name: 'AuthGuestScreenLogin',
        data: () => ({
            fName: '',
            lName: '',
            email: '',
            phone: ''
        }),
        components: {
            AnimatedLogoPreloader
        },
        validations: {
            fName: {required},
            lName: {required},
            email: {required, email},
            phone: {required}
        },
        methods: {
            logAsGuest(){

                let guest = {
                    first_name: this.fName,
                    last_name: this.lName,
                    email: this.email,
                    phone: this.phone,
                    home_store_id: 1
                };

                this.$axios.post('/guests', guest)
                    .then(res => {
                        this.$router.replace('/blueprints');

                        this.$store.dispatch('showSnackbarMessage', {message: 'You have logged as a guest.', duration: 4000, mode: 'success'});

                        console.log(res)
                    })
                    .catch((error) => {

                    })
            }
        }

    }
</script>

<style lang="scss" scoped>

    @include authPage;
    .logo-image{
        margin-bottom: 2.3%;
    }
</style>