import VueCookies from 'vue-cookies';
import AuthService from '@/services/auth.service';

// import Cookie from 'js-cookie'

const setUpCookie = (token) => {
	
	VueCookies.set('current_user', token, '1Y', null, null, false, 'Lax');
};

const state = () => ({
	user: null,
	sudoMode: false,
	selectedStore:null
});

const getters = {
	
	isAuthenticated: (state) => () => {
		
		let cookie = VueCookies.get('current_user');
		
		return cookie != null;
	},
	
	isSudoMode(state) {
		return state.sudoMode;
	},
	
	getCurrentUser(state) {
		return state.user;
	},
	
	currentUserRole(state) {
		return state.user ? state.user.role_id: null;
	},
	getSelectedStore(state) {
		return state.selectedStore;
	},
};

const mutations = {
	
	setUser(state, user) {
		state.user = user;
	},
	
	logout(state) {
		state.user = null;
		state.selectedStore = null;
	},
	
	toggleSudoMode(state, sudoModeState) {
		state.sudoMode = sudoModeState;
	},

    signOut(state) {
        state.user = null;
		state.selectedStore = null;
        VueCookies.remove('current_user');
        VueCookies.remove('user_data');
    },
	setSelectedStore(state,store) {
		state.selectedStore = store;
	},

};

const actions = {

    impersonateUser(vuexContext, userID) {
    	
        const token = VueCookies.get('current_user');
        
        VueCookies.set('real_user', token);
	
		return AuthService.impersonate(userID).then(res => {
						
			setUpCookie(res.auth_token);
			
			vuexContext.commit('setUser', res.user);
			vuexContext.commit('toggleSudoMode', true);
		});
    },

	signOut(vuexContext) {
        const oldToken = VueCookies.get('real_user');

        if (oldToken) {
            VueCookies.remove('real_user');
            vuexContext.commit('toggleSudoMode', false);
            return vuexContext.dispatch('getUserFromToken', oldToken);
        } else {
            vuexContext.commit('signOut');
            return Promise.resolve(null);
        }
	},


    getUserFromToken(vuexContext, token) {

        setUpCookie(token);
        window.location.replace("/login");


        // return $axios.get('/me')
        //     .then(res => {
        //         setUpCookie(token, res.data.user);
        //         vuexContext.commit('setUser', res.data);
        //         return res.data;
        //     });
    },
	
	// impersonateUser(vuexContext, userID) {
	//     const token = Cookie.get('current_user');
	//     Cookie.set('real_user', token);
	//
	//
	//     return $axios.post(`users/${userID}/impersonate`)
	//         .then(res => {
	//             setUpAuth(res.data.users_bearer_token, res.data);
	//             vuexContext.commit('setUser', res.data);
	//             vuexContext.commit('toggleSudoMode', true);
	//             return res.data;
	//         });
	// },
	
	signUp(vuexContext, user) {
		
		return AuthService.signUp(user).then(res => {
			
			vuexContext.commit('setToken', res.token);
		});
	},
	
	getMe(vuexContext) {
		
		return AuthService.getMe().then(data => {

			vuexContext.commit('setSelectedStore', data.store_id);
			vuexContext.commit('setUser', data);
		}).catch((error) => {
			
			console.log(error);
			return new Promise((resolve, reject) => {
				return reject(error);
			});
		});
	},
	
	login(vuexContext, data) {
		
		let payload = {
			email: data.email,
			password: data.password,
		};
		
		return AuthService.login(payload).then(data => {
			
			setUpCookie(data.auth_token, data.user);
			vuexContext.commit('setUser', data.user);
			
			return data;
		}).catch((error) => {
			
			let errorMessage = 'Failed. Check your credentials, please.';

			if (403 === error.response.status){

				errorMessage = error.response.data;
			}

			vuexContext.dispatch('showSnackbarMessage', {
				message: errorMessage,
				duration: 8000,
				mode: 'fail',
			}, {root: true});
			
			return new Promise((resolve, reject) => {
				return reject(error);
			});
		});
	},

	loginAfterRegistration(vuexContext, data) {

		let payload = {
			email: data.email,
			password: data.password,
			after_registration:true
		};

		return AuthService.loginAfterRegistration(payload).then(data => {

			setUpCookie(data.auth_token, data.user);
			vuexContext.commit('setUser', data.user);

			return data;
		}).catch((error) => {

			let errorMessage = 'Failed. Check your credentials, please.';

			if (403 === error.response.status){

				errorMessage = error.response.data;
			}

			vuexContext.dispatch('showSnackbarMessage', {
				message: errorMessage,
				duration: 8000,
				mode: 'fail',
			}, {root: true});

			return new Promise((resolve, reject) => {
				return reject(error);
			});
		});
	},
	setSelectedStore(vuexContext, data){

        vuexContext.commit('setSelectedStore', data);
    },
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};