import $axios from '../plugins/axios'

class SearchService {

    getSearchResults(searchInput) {
        return $axios.get('/search', {
            params: {
                q: searchInput.data
            }
        })
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

}

export default new SearchService();