const state = () => ({
    accounts: []
});

const getters = {
    getAccounts(state) {
        return state.accounts;
    }
};

const mutations = {
    setAccounts(state, accounts) {
        state.accounts = accounts;
    }
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}