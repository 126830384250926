const state = () => ({
    stores: []
});

const getters = {
    getStores(state) {
        return state.stores;
    }
};

const mutations = {
    setStores(state, stores) {
        state.stores = stores;
    }
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}