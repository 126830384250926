import $axios from '../plugins/axios'

class SectionsService {
    createSection(data) {

        return $axios.post(`/sections`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {
                let errMsg = error.response.data;
                this.$store.dispatch('showSnackbarMessage',
                    { message: errMsg, duration: 6000, mode: 'fail' })
            });
    
    }
   
    UpdateSection(id,data) {

        return $axios.put(`/sections/${id}`, data)
            .then(res => {

                return res.data;
            })
            
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }
    UpdateSectionSummary(id,data) {

        return $axios.put(`/section-summary/${id}`, data)
            .then(res => {

                return res.data;
            })
            
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getSingleSection(id) {

        return $axios.get(`/sections/${id}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }
    deleteSingleSection(id){

        return $axios.delete(`/sections/${id}`)
            .then(res => {
                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    reorderSections({ data, id }) {

        return $axios.put(`/sections/${id}/order`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

}

export default new SectionsService();
