import $axios from '../plugins/axios'

class CardService {

    contactUs(contactObject) {

        return $axios.post('/contact', contactObject)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    sendFeedback(feedbackObject) {

        return $axios.post('/feedback', feedbackObject)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }
}

export default new CardService();
