<template>
    <div class="component-wrap">

        <AppHeaderComponent headline="Classes" class="content-header__classes">
            <div class="content-header__actions">

                <md-button
                        v-if="userRole < 5"
                        id="create-single-class"
                        class="md-raised md-primary md-raised md-theme-light"
                        @click="openCreateRecurringClassesModal(false)">Create Single Class</md-button>

                <md-button
                        v-if="userRole < 5"
                        id="create-recurring-class"
                        class="md-raised md-primary md-raised md-theme-light"
                        @click="openCreateRecurringClassesModal(true)">Create Recurring Class</md-button>

            </div>
        </AppHeaderComponent>

		<AppSpinnerComponent v-if="loadingSpinner" :loading="loadingSpinner"/>

		<AppEmptyStateComponent message="There are no classes added yet."
								v-if="!classes.length && !loadingSpinner"/>

        <div class="classes" v-if="classes.length && !loadingSpinner">

            <div class="singleClass__header md-layout md-gutter md-raised">
                <h2 class="md-layout-item md-medium-size-100">Name</h2>
                <h2 class="md-layout-item md-medium-size-100">Repeat</h2>
                <h2 class="md-layout-item md-medium-size-100">Start</h2>
				<h2 class="md-layout-item md-medium-size-100">End</h2>
				<h2 class="md-layout-item md-medium-size-100">Room</h2>
				<h2 class="md-layout-item md-medium-size-100"></h2>
			</div>

            <div class="singleClass md-raised md-layout"
			 	 v-for="(singleClass, index) in classes"
				 :key="index + '_singleClass'"
				 @click="goToSeeDetails(singleClass.id)">

                <p class="singleClass__data special-labels md-layout-item md-medium-size-100">
                    {{singleClass.name}}
                </p>

                <p class="singleClass__data md-layout-item md-medium-size-100">
                    {{singleClass.repeat_label}}
                </p>

                <p class="singleClass__data md-layout-item md-medium-size-100">
                    <span class="singleClass__data__room-label">Start: </span>{{singleClass.start_time}}
                </p>

                <p class="singleClass__data md-layout-item md-medium-size-100">
                    <span class="singleClass__data__room-label">End: </span>{{singleClass.end_time}}
                </p>

                <p class="singleClass__data  special-labels md-layout-item md-medium-size-100">
                    {{singleClass.room.name}}
                </p>

                <div class="md-layout-item md-medium-size-100 md-xsmall-size-100">
                    <div class="md-layout md-layout-item">
                        <div class="singleClass__remove-btn edit-btn"
                             v-if="userRole < 5"
                             @click.stop="openEditSingleClassesModal(singleClass)">
                            <md-icon>edit</md-icon>
                        </div>

                        <div class="singleClass__remove-btn"
                             v-if="userRole < 5"
                             @click.stop="removeSingleClass(singleClass.id)">
                            <md-icon>delete_forever</md-icon>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <EditSingleClassDialog v-if="singleClassForEdit"
            :showDialogEditClass.sync="showDialogEditClass"
            :isReccuringClassProp="openReccuringClassEdit"
            @refreshAllClasses="getAllClasses"
            :key="dialogKeyEditClass"
            :rooms-prop="rooms"
            :single-class-prop.sync="singleClassForEdit"/>

        <CreateSingleClassDialog
            @refreshAllClasses="getAllClasses"
            :isReccuringClass="openReccuringClass"
            :showDialogRecurringClass.sync="showDialogRecurringClass"
            :key="dialogKeyRecurringClass"/>

    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import {repeatClassName} from '@/mixins/repeatClassName.mixin.js'
    import EditSingleClassDialog from "../../components/Classes/EditSingleClassDialog.vue"
    import CreateSingleClassDialog from "../../components/Classes/CreateSingleClassDialog.vue"
    import {DeepFilterMixin} from '@/mixins/DeepFilterMixin';
    import RoomService from "../../services/room.service";
    import ClassService from '../../services/class.service';

    export default {
        name: 'ClassesDashboard',
        mixins: [repeatClassName, DeepFilterMixin],

        data: () => ({
            classes: [],
            loadingSpinner: true,
            showDialogSingleClass: false,
            dialogKeySingleClass: 0,
            showDialogRecurringClass: false,
            dialogKeyRecurringClass: 50,
            showDialogEditClass: false,
            dialogKeyEditClass: 100,
            singleClassForEdit: null,
            rooms: [],
            openReccuringClass: false,
            openReccuringClassEdit: false,
        }),

        components: {
            EditSingleClassDialog,
            CreateSingleClassDialog,
        },

        methods: {

            goNextStepIfTourGuideActive() {

                this.$nextTick(() => {

                    this.$tours['myTour'].nextStep();
                })
            },

            getAllRooms(){

                RoomService.getRooms()
                    .then((res) => {

                        this.rooms = res;
                    })
                    .catch(() => {

                        this.$store.dispatch('showSnackbarMessage', {message: 'Failed. Please, try again.', duration: 8000, mode: 'fail'});
                    });
            },

            setPageName() {

                this.$store.dispatch('changePageName', { pageNameString: 'Classes'});
            },

            goToSeeDetails(id) {

                this.$router.push('/classes/'  + id);
            },

            openEditSingleClassesModal(singleClass) {

                this.openReccuringClassEdit = false;
                this.getSingleClass(singleClass.id)
                this.dialogKeyEditClass++;
                this.showDialogEditClass = true;

                if (singleClass.class_type_id == 2) {

                    this.openReccuringClassEdit = true;
                }
            },

            openCreateRecurringClassesModal(isReccuringClass) {

                this.openReccuringClass = false;

                this.dialogKeyRecurringClass++;
                this.showDialogRecurringClass = true;
                this.openReccuringClass = isReccuringClass;

                if (this.showTourGuide) {

                    this.goNextStepIfTourGuideActive();
                }
            },

            removeSingleClass(classId) {

                ClassService.deleteSingleClass(classId)
                    .then(() => {

                        this.getAllClasses();

                        this.$store.dispatch('showSnackbarMessage', {
                            message: 'The class was successfully deleted.',
                            duration: 4000,
                            mode: 'success'
                        });
                    })
                    .catch((err) => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: `${err.message}. Please, try again.`,
                            duration: 4000,
                            mode: 'fail'
                        });
                    });
            },

            getSingleClass(id){

                ClassService.getSingleClass(id)
                    .then(res => {

                        this.singleClassForEdit = res;

                        // if (!this.singleClassForEdit.repeat_patterns_id) {
						//
                        //     this.singleClassForEdit.repeat_patterns_id = 0;
                        // }

                        this.singleClassForEdit['repeat_replacements'] = [];
                        this.singleClassForEdit.start_time = this.convertTimeFormatEdit(this.singleClassForEdit.start_time);
                        this.singleClassForEdit.end_time = this.convertTimeFormatEdit(this.singleClassForEdit.end_time);

                    })
                    .catch(() => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: 'Failed. Please, try again.',
                            duration: 8000,
                            mode: 'fail'
                        });
                    });
            },

            getAllClasses() {

                ClassService.getClasses()
                    .then(res => {

                        this.classes = res;

                        for (let i = 0; i < this.classes.length; i++) {

                            let repPattern = this.classes[i].repeat_pattern_id;

                            this.classes[i]['repeat_label'] = this.getRepeatClassName(repPattern);

                            this.classes[i].start_time = this.convertTimeFormat(this.classes[i].start_time);
                            this.classes[i].end_time = this.convertTimeFormat(this.classes[i].end_time);
                        }
                        this.loadingSpinner = false;
                    })
                    .catch(() => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: 'Failed. Please, try again.',
                            duration: 8000,
                            mode: 'fail'
                        });
                    });
            },
        },

        computed: {
            ...mapGetters(
                {
                    userRole: 'auth/currentUserRole',
                    showTourGuide: 'getIsTourGuideActive'
                },
            ),
        },

        created() {

            this.setPageName();
            this.getAllClasses();
            this.getAllRooms();
        },

        mounted() {

            if (this.showTourGuide) {

                this.goNextStepIfTourGuideActive();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .content-header.content-header__stores {
        margin-bottom: 40px;
    }

    .input-field.input-field__stores-filter {
        padding-bottom: 0;
    }

    @include tableStyle(classes, singleClass);

	.singleClass {

		&__header {
			text-align: left;
			padding-bottom: 15px;


			@include respond(small) {
				flex-direction: column;
				padding: 16px 24px;
				line-height: normal;
			}
		}

		.classes {

			.edit-btn{
				margin-right: 20px;
			}

			.special-labels{
				line-height: 30px;
			}
		}
	}
</style>