<template>
    <div class="component-wrap">
        <AppHeaderComponent headline="Movement Type" class="types-cont-filter">
            <div class="md-layout content-header__actions">
                <AppInputComponent
                        class="md-layout-item input-field input-field--filter md-xlarge-size-45 md-large-size-45 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
                        required
                        placeholder="Filter Types By Name"
                        v-model="filterUserString"/>

                <md-button
                        class="md-layout-item md-raised md-primary md-raised md-theme-light addBtn md-xlarge-size-45 md-large-size-45 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
                        @click="openAddTypeModal()">New Type</md-button>
            </div>
        </AppHeaderComponent>

        <AppSpinnerComponent v-if="loadingSpinner" :loading="loadingSpinner"/>

        <AppEmptyStateComponent message="There are no results."
								v-if="!types.length && !loadingSpinner"/>

        <div class="types">
            <div
                class="type md-raised md-layout"
                v-for="(type, index) in filteredTypes"
                :key="index + '_intervalType'">

                <p class="type__data md-layout-item md-size-90">
                    {{type.name}}
                </p>

                <div class="md-layout-item md-size-10 md-xsmall-size-100 ">
                    <div class="md-layout md-layout-item">
                        <div class="type__remove-btn"
                             id="edit-button"
                             @click.stop="openEditTypeDialog(type.id)">
                            <md-icon>edit</md-icon>
                        </div>

                        <div class="type__remove-btn"
                             id="delete-button"
                             @click.stop="removeInterval(type.id)">
                            <md-icon>delete_forever</md-icon>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <IntervalsTypeDialog
                :showDialog.sync="showDialog"
                :key="dialogKey"
                :type-prop="type"
                :allTypes="types"
                @refreshAllIntervalTypes="getAllIntervalTypes"/>

    </div>
</template>

<script>
    import IntervalsTypeDialog from "@/components/IntervalTypes/IntervalsTypeDialog.vue"
    import {DeepFilterMixin} from "@/mixins/DeepFilterMixin"
    import IntervalService from "../../services/interval.service"

    export default {
        name: 'IntervalTypesDashboard',
        mixins: [DeepFilterMixin],

        data: () => ({
            types: [],
            type: {},
            showDialog: false,
            dialogKey: 0,
            filterUserString: '',
            loadingSpinner: true
        }),

        components: {
            IntervalsTypeDialog
        },

        computed: {

            filteredTypes() {

                return this.types.filter(x => this.filterFunction(x, this.filterUserString));
            }
        },

        methods: {

            setPageName(){

                this.$store.dispatch('changePageName', { pageNameString: 'Movement Type'});
            },

            removeInterval(id){

                IntervalService.deleteSingleIntervalTypes(id)
                    .then(() => {

                        this.getAllIntervalTypes();

                        this.$store.dispatch('showSnackbarMessage', {
                            message: 'The type was successfully deleted.',
                            duration: 4000,
                            mode: 'success'
                        });
                    })
                    .catch((err) => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: `This movement type cannot be deleted because its already in use.`,
                            duration: 4000,
                            mode: 'fail'
                        });
                    });
            },

            getCurrentType(id) {

                let type = this.types.find(x => x.id === id)
                this.type = Object.assign({}, type);
            },

            openAddTypeModal(){

                this.type = null;
                this.dialogKey++;
                this.showDialog = true;
            },

            openEditTypeDialog(id) {

                this.getCurrentType(id);
                this.dialogKey++;
                this.showDialog = true;
            },

            getAllIntervalTypes(){

                IntervalService.getAllIntervalTypes()
                    .then((res) => {

                        this.types = res;
                        this.loadingSpinner = false;
                    })
                    .catch(() => {

                        this.loadingSpinner = false;
                        this.$store.dispatch('showSnackbarMessage', {message: 'Failed. Please, try again.', duration: 8000, mode: 'fail'});
                    });
            }
        },

        created() {

            this.setPageName();
            this.getAllIntervalTypes();
        }
    }
</script>

<style lang="scss" scoped>

    .content-header__actions {
        width: 38%;
    }

    @media screen and (min-width: 640px) {
        .input-field.input-field--filter {
            margin-right: 2.4rem;
        }
    }
    .types-cont-filter{
        margin-bottom: 80px!important;
    }

    .admins-label{
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    @include tableStyle(types, type);

    .types {

        .type {

            &__data {
                min-width: 20%;
                padding-left: 3.2rem;
                line-height: 57px;
            }

            .rmv {
                margin-left: 20px;
            }
        }
    }
</style>