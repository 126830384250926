import $axios from "@/plugins/axios";
import SongService from '@/services/song.service'

export const AppleSearchMixin = {

    methods: {
        async appleSong(song, intervalSongId, flag = false, index = -1) {
            let target;
            if (flag) {
                target = document.getElementById("interval_" + Number(index));
            }
            else {
                target = document.getElementById("inner-interval_" + index);
            }
            if (index !== -1) target.classList.add('replaced-item');
            setTimeout(async () => {
                await this.$axios.post(`/songs-add`, song)
                    .then(res => {
                        SongService.changeSongData({ song: res.data }, intervalSongId)
                            .then(async (res) => {

                                this.$emit('refreshAllIntervals', res.id);
                                this.closeAppleAudioPlayer();

                                if (flag) {
                                    this.getIntervalsByPlaylist();
                                    this.clearAppleSearch();
                                }
                                else
                                    this.$emit('clearAppleSearch');

                                if (index !== -1) target.classList.remove('replaced-item');
                                document.querySelectorAll('[id^="interval_"]').forEach(element => {
                                    element.style.opacity='1';
                                });
                                document.querySelectorAll('[id^="inner-interval_"]').forEach(element => {
                                    element.style.opacity='1';
                                });
                                this.$store.dispatch('showSnackbarMessage', {
                                    message: 'Song successfully changed!',
                                    duration: 4000,
                                    mode: 'success'
                                });
                            })
                            .catch((err) => {
                                console.log('error', err)
                                let errorMessage = err.response.data ? err.response.data : `${err.message}. Please, try again.`;

                                this.$store.dispatch('showSnackbarMessage', {
                                    message: errorMessage,
                                    duration: 4000,
                                    mode: 'fail'
                                });
                            });
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                this.searchTerm = ''
            }, 500);
        },
    },
}