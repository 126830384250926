<template>
    <div class="component-wrap">
        <AppHeaderComponent
                class="about-page"
                headline="Reflex Radio - Contact">
        </AppHeaderComponent>

        <form class="contact-form">
            <div class="md-layout md-gutter">

                <div class="md-layout-item md-xlarge-size-45 md-large-size-45 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
                    <AppInputComponent
                        class="contact-form__field input-field"
                        required
                        placeholder="First name"
                        v-model="$v.contactForm.first_name.$model"
                        :error="$v.contactForm.first_name.$invalid && $v.contactForm.first_name.$dirty"
                        errorText="First name is required"
                    />

                    <AppInputComponent
                        class="contact-form__field input-field"
                        required
                        placeholder="Last name"
                        v-model="$v.contactForm.last_name.$model"
                        :error="$v.contactForm.last_name.$invalid && $v.contactForm.last_name.$dirty"
                        errorText="Last name is required"
                    />

                    <AppInputComponent
                        class="contact-form__field input-field"
                        required
                        placeholder="Enter Email"
                        v-model="$v.contactForm.email.$model"
                        :error="$v.contactForm.email.$invalid && $v.contactForm.email.$dirty"
                        errorText="Valid email is required"
                    />
                </div>

                <div class="md-layout-item md-xlarge-size-45 md-large-size-45 md-medium-size-100 md-small-size-100 md-xsmall-size-100">

                    <AppInputComponent
                            class="contact-form__field input-field"
                            required
                            placeholder="Subject"
                            v-model="$v.contactForm.subject.$model"
                            :error="$v.contactForm.subject.$invalid && $v.contactForm.subject.$dirty"
                            errorText="Last name is required"
                    />

                    <AppInputComponent
                         class="contact-form__field input-field"
                         :controlType="'textarea'"
                         required
                         placeholder="Your message"
                         v-model="$v.contactForm.message.$model"
                         :error="$v.contactForm.message.$invalid && $v.contactForm.message.$dirty"
                         errorText="First name is required"
                    />

                    <md-button  class="md-primary md-raised contact-form__submit-btn md-theme-light"
                                :disabled="$v.contactForm.$invalid || sendClicked"
                                @click="sendInquiry()">SEND
                    </md-button>
                 </div>



            </div>
        </form>
    </div>
</template>

<script>
    import {required, email} from 'vuelidate/lib/validators';
    import ContactService from "../../services/ContactService";
    
    export default {
        name: 'ContactDashboard',
      
        data: () => ({
            sendClicked: false,
            contactForm: {
                first_name: '',
                last_name: '',
                subject: '',
                message: '',
                email: '',
              
            },
        }),
      
        validations: {
          contactForm: {
                first_name: {required},
                last_name: {required},
                subject: {required},
                message: {required},
                email: {required, email},
            }
        },
      
        methods: {
            setPageName() {

                this.$store.dispatch('changePageName', {pageNameString: 'Contact'});
            },

            sendInquiry() {
                this.sendClicked = true;
              const singleMessageObj = {
                name: this.contactForm.first_name + ' ' + this.contactForm.last_name,
                subject: this.contactForm.subject,
                message: this.contactForm.message,
                email: this.contactForm.email
              };


                ContactService.contactUs(singleMessageObj)
                    .then(() => {
                        this.$store.dispatch('showSnackbarMessage',{ message: 'Message was send successfully!',
                            duration: 6000,
                            mode: 'success'
                        });
                      this.sendClicked =false;

                      this.contactForm.first_name = '';
                      this.contactForm.last_name = '';
                      this.contactForm.subject = '';
                      this.contactForm.message = '';
                      this.contactForm.email = '';
                      this.$v.$reset();
                    })
                    .catch((error) => {
                        const errMsg = error.response.data;

                        this.$store.dispatch('showSnackbarMessage',
                            {message: errMsg, duration: 6000,  mode: 'fail'})
                        this.sendClicked =false;
                    });
            }
        },

        created() {
            this.setPageName();
        }

    }
</script>

<style lang="scss" scoped>

    .content-header.about-page {
        margin-bottom: 40px;
    }
    


</style>
