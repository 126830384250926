const state = () => ({
    bluePrints: [],
    isUndoActive: false,
    tempBluePrints: []
});

const getters = {
    getBluePrints(state) {
        return state.bluePrints;
    },

    getIsUndoActive(state) {
        return state.isUndoActive;
    },

    getTempBluePrints(state) {
        return state.tempBluePrints;
    },
};

const mutations = {
    setBluePrints(state, bluePrints) {
        state.bluePrints = bluePrints.sort((p1, p2) => p2.id - p1.id);
    },

    setIsUndoActive(state, data) {
        state.isUndoActive = data;
    },

    setTempBluePrints(state, bluePrints) {

        if (bluePrints){

            state.tempBluePrints = bluePrints.sort((p1, p2) => p2.id - p1.id);
        } else {

            state.tempBluePrints = '';
        }
    },
}

const actions = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}