<template>
    <md-dialog :md-active.sync="showModal" :md-click-outside-to-close="false">
        <md-dialog-title v-if="isEditFormModal == false">Create a new Genre</md-dialog-title>
        <md-dialog-title v-if="isEditFormModal == true">Edit a Genre</md-dialog-title>

        <form class="md-layout md-gutter create-form" @keydown.enter.prevent="">
            <div class="md-layout-item md-size-100">
                <AppInputComponent
                    class="create-form__field"
                    required
                    :disabled="isEditFormModal"
                    placeholder="Genre Name"
                    v-model="$v.genre.name.$model"
                    :error="$v.genre.name.$invalid && $v.genre.name.$dirty"
                    errorText="Genre Name is required"
                    @keydown.enter.prevent="saveGenre()"
                />
                
            </div>
           
                <div  v-if="!commonGenre" class="md-layout-item md-size-30 md-xsmall-size-100" style="margin: auto;text-align-last: start;">
                    <label for="parentGenre">
                         Genre Parent
                    </label>
                </div>
                <div class="md-layout-item md-size-70 md-xsmall-size-100" v-if="!commonGenre">
                        <md-field class="parent-genre-selct">
                            <md-select   v-model="parentGenre"  name="parentGenre"  placeholder="Parent Genre">
                                <md-option :value="0">
                                    NONE
                                </md-option>
                                <md-option  :disapled="selectedVisibility" v-for="(genre, index) in wholeGenres.filter(genre=>genre.visibility==1||genre.visibility==2)" :key="index" :value="genre.id">
                                    {{ genre.name }}
                                </md-option>
            
                            </md-select>
                        </md-field>
                  </div>
                  <div   class="md-layout-item md-size-30 md-xsmall-size-100" style="margin: auto;text-align-last: start;"> <!--v-if="isEditFormModal == true"-->
                    <label for="parentGenre">
                          Genre Visibilty
                    </label>
                </div>
                <div class="md-layout-item md-size-70 md-xsmall-size-100" >
                        <md-field class="parent-genre-selct">
                            <md-select   v-model="selectedVisibility"  name="genreVisibility" placeholder=" Genre Visibility"> <!--v-if="isEditFormModal == true"-->
                                <md-option :value="1" v-if="!parentGenre">Must Display</md-option>
                                <md-option :value="2" v-if="!parentGenre">Display On Show More</md-option>
                                <md-option :value="0" >Hidden</md-option>
            
                            </md-select>
                        </md-field>
                  </div>
            <div class="md-layout-item md-size-100">
                <md-dialog-actions class="form-buttons">
                    <md-button
                        class="dialog__btn dialog__btn--danger"
                        id="closeBtn"
                        @click="closeDialog()"
                    >
                        Cancel
                    </md-button>
                    <md-button
                        class="md-primary dialog__btn md-raised md-theme-light"
                        v-if="isEditFormModal == true && !commonGenre"
                        id="editGenreBtn"
                        :disabled="selectedVisibility==this.genre.visibility && parentGenre==this.genre.parent_id"
                        @click="editGenreParent()"
                    > <!--
                        :disabled="$v.genre.$invalid"
                    -->
                        SAVE
                    </md-button>
                    <md-button
                        class="md-primary dialog__btn md-raised md-theme-light"
                        v-if="commonGenre && isEditFormModal == true "
                        id="editGenreBtn"
                        :disabled="selectedVisibility==this.genre.visibility"
                        @click="editGenreParentVisibility()"
                    >
                        SAVE 
                    </md-button>
                    <md-button
                        class="md-primary dialog__btn md-raised md-theme-light"
                        v-if="isEditFormModal == false"
                        id="saveGenreBtn"
                        :disabled="$v.genre.$invalid || (parentGenre==null&&selectedVisibility==null)"
                        @click="saveGenre()"
                    >
                        SUBMIT
                    </md-button>
                   

                </md-dialog-actions>
            </div>
        </form>
    </md-dialog>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
import { array } from "yargs";
    import GenreService from "../../services/genre.service";

    export default {
        name: "GenresFormModal",

        props: {

            showModal: {
                type: Boolean,
                default: false
            },
            genreProp: {
                type: Object,
                default: {
                    name: ''
                }
            },
            allGenre:{
                type: [Array, Object],
            }
        },

        data: () => ({
            genre: {
                name: ''
            },

            isEditFormModal: false,
            parentGenre: null,
            selectedVisibility :null,
            wholeGenres:[],
            commonGenre:false
        }),

        validations: {
            genre: {
                name: {required},
            }
        },

        methods: {

            closeDialog() {

                this.$emit('update:showModal', false);
            },

            async saveGenre() {
                this.genre.parent_id =  this.parentGenre
                this.genre.visibility = this.selectedVisibility;
                await GenreService.createGenre(this.genre)
                    .then((res) => {
                        this.$emit('refreshAllGenres');
                        this.$store.dispatch('showSnackbarMessage', {message: 'The genre was successfully created.'});
                        this.closeDialog();
                    })
                    .catch((err) => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: `${err.message}. Please, try again.`,
                            mode: 'fail'
                        });
                    });
            },

            async saveParentGenre() {
                this.genre.parent_id =this.parentGenre;
                (this.isVisible)? this.genre.visibility  = 1:this.genre.visibility  = 2;
                GenreService.createGenre(this.genre)
                    .then((res) => {
                        this.$emit('refreshAllGenres');
                        this.$store.dispatch('showSnackbarMessage', {message: 'The genre was successfully created.'});
                        this.closeDialog();
                    })
                    .catch((err) => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: `${err.message}. Please, try again.`,
                            mode: 'fail'
                        });
                    });
            },

            editGenre() {

                GenreService.editGenre(this.genre)
                    .then((res) => {

                        this.$emit('refreshAllGenres');
                        this.$store.dispatch('showSnackbarMessage', {message: 'The genre was successfully created.'});
                        this.closeDialog();
                    })
                    .catch((err) => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: `${err.message}. Please, try again.`,
                            mode: 'fail'
                        });
                    });
            },
            async editGenreParent() {
            if (this.parentGenre !=null && this.parentGenre !=0) {
                let parentGenreData = this.filter()[0];
                parentGenreData.updateParent=0;
                await GenreService.editGenreParent(this.genre,parentGenreData)
                    .then((res) => {
                        this.$emit('refreshAllGenres');
                        this.$store.dispatch('showSnackbarMessage', {message: 'The genre was successfully Updated.'});
                        this.closeDialog();
                    })
                    .catch((err) => {
                        this.$store.dispatch('showSnackbarMessage', {
                            message: `${err.message}. Please, try again.`,
                            mode: 'fail'
                        });
                    });
                  
                }
                else{
                    this.editGenreParentVisibility();
                }

            },
            async editGenreParentVisibility() {
               
                this.genre.visibility = this.selectedVisibility;
                this.genre.updateParent=1;

                await GenreService.editGenreParent(this.genre,this.genre)
                .then((res) => {
                    this.$emit('refreshAllGenres');
                    this.$store.dispatch('showSnackbarMessage', {message: 'The genre was successfully Updated.'});
                    this.closeDialog();
                })
                .catch((err) => {
                    this.$store.dispatch('showSnackbarMessage', {
                        message: `${err.message}. Please, try again.`,
                        mode: 'fail'
                    });
                });
                if (this.parentGenre ==0) {
                    let genreData
                    this.genre.updateParent=0;
                    this.genre.fromChildToparent=1;
                await GenreService.editGenreParent(this.genre,this.genre)
                .then((res) => {
                    this.$emit('refreshAllGenres');
                    this.$store.dispatch('showSnackbarMessage', {message: 'The genre was successfully Updated.'});
                    this.closeDialog();
                })
                .catch((err) => {
                    this.$store.dispatch('showSnackbarMessage', {
                        message: `${err.message}. Please, try again.`,
                        mode: 'fail'
                    });
                });
                }
            },
            filter(){
               return this.wholeGenres.filter(parent => parent.id === this.parentGenre)
            },
        },

        created() {

            if (this.genreProp) {

                this.isEditFormModal = true;
                this.genre = this.genreProp;
                this.selectedVisibility=this.genre.visibility;

                let genreparent = this.allGenre.find(genre => genre.id === this.genre.parent_id)
                this.parentGenre = (genreparent!=undefined)?genreparent.id:null;

                if(this.genre.visibility == 1 || this.genre.visibility == 2){
                    this.commonGenre = true;
                }


            }

            if (this.allGenre) {
                this.wholeGenres = this.allGenre;
            }
        },
        watch:{
            parentGenre(newValue, oldValue) {
                if (newValue!=null) {
                    this.selectedVisibility=this.genre.visibility?this.genre.visibility:0;
                }
        },
        selectedVisibility(newValue, oldValue) {
                if (newValue!=0) {
                    this.parentGenre=null;
                }
        }
    }

    }
</script>

<style lang="scss" scoped>
    @include dialogSettings();

    .dialog__hint {
        margin-bottom: 16px;
    }

    .md-dialog {

        .choose-file-btn {
            width: 30%;
        }

        .upload-cont {
            text-align: center;
        }

        .file-upload-label {
            font-size: 2rem;
        }
    }

    .form-buttons {
        margin-top: 20px;
        justify-content: space-around;
    }
    .select-centered {
        margin: 0 auto;
        width: 250px;
        height: 35px;
    }

</style>