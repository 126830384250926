<template>
<div class="request md-raised">
    
    <div class="request__label">
        <span class="request__name">{{request.userName}}</span> has requested to link their phone to this gym. Allow ?
    </div>
    
    <div class="request__actions">
        <md-button class="request__btn request__btn--reject" @click="$emit('rejected')">Reject</md-button>
        <md-button class="md-accent md-raised request__btn request__btn--accept" @click="$emit('accepted')">Accept</md-button>
    </div>
</div>
</template>

<script>
export default {
    name: 'SingleStoreAccessRequestBlock',
    props: {
        requestData: {
            type: Object,
            required: true
        }
    },
    computed: {
        request() {
            return this.requestData;
        }
    }
}
</script>

<style scoped lang="scss">
.request {
    background-color: #212121;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 5px 24px;
    margin-bottom: 3px;
    cursor: pointer;

    @include respond(small) {
        flex-direction: column;
    }

    &__label {
        @include respond(small) {
            text-align: left;
            margin: 8px;
            width: 100%;
        }
    }

    &__actions {
        margin-left: auto;
    
        @include respond(small) {
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
    }

    &__btn {
        &:not(:last-child) {
            margin-right: 24px;
        }
    }

    .reject{
        color: white!important;
    }

    .accept {
        background-color: #ef476f!important;
    }

    .question-label{
        margin-top: auto;
        margin-bottom: auto;
    }
}
</style>