<template>
    <div class="component-wrap">
        <AppHeaderComponent headline="Search results"/>

         <div v-if="allSearchResults" class="search-results">
            <md-tabs>
                <template slot="md-tab" slot-scope="{ tab }">
                    {{ tab.label }}
                    <p v-if="tab.data.results"> ( {{ tab.data.results }} )</p>

                </template>

                <md-tab id="tab-all"
                        md-label="All"
                        :md-template-data="{ results: allSearchResults.users.length + allSearchResults.accounts.length +
                allSearchResults.templates.length + allSearchResults.interval_types.length}">

                    <div v-if="allSearchResults.users.length != 0">
                        <h2 class="md-headline"> Users results </h2>
                        <div class="md-layout result-card">

                            <div class="md-layout-item user-result-info-card md-raised user-result-card md-xlarge-size-25 md-large-size-25 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
                                 v-for="(user, index) in allSearchResults.users" :key="index" :value="user.id"
                                 @click="goToUser(user.id)">
                                <md-card class="md-primary" md-with-hover>
                                    <md-ripple>
                                        <md-card-header>
                                            <div class="md-title user-name">{{ user.name }}</div>

                                        </md-card-header>

                                        <md-card-content class="user-result-info">
                                            {{ user | fullName }}  <br>
                                            {{ user.email }}
                                        </md-card-content>
                                    </md-ripple>
                                </md-card>
                            </div>
                        </div>
                    </div>

                    <div v-if="userRole == 1">
                        <div v-if="allSearchResults.accounts.length != 0 ">
                            <h2 class="md-headline"> Accounts results </h2>
                            <div class="md-layout result-card">

                                <div class="md-layout-item account-result-card md-xlarge-size-25 md-large-size-25 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
                                     v-for="(account, index) in allSearchResults.accounts" :key="index" :value="account.id"
                                     @click="goToAccount(account.id)">
                                    <md-card class="md-primary" md-with-hover>
                                        <md-ripple>
                                            <md-card-header>
                                                <div class="md-title">{{ account.name }}</div>

                                            </md-card-header>

                                            <md-card-content class="account-result-info">
                                                <br>
                                                {{ account | address }}
                                            </md-card-content>
                                        </md-ripple>
                                    </md-card>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="allSearchResults.templates.length != 0">
                        <h2 class="md-headline"> Templates results </h2>
                        <div class="md-layout result-card">

                            <div class="md-layout-item md-xlarge-size-25 md-large-size-25 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
                                 v-for="(template, index) in allSearchResults.templates" :key="index" :value="template.id">
                                <md-card class="md-primary" md-with-hover>
                                    <md-ripple>
                                        <md-card-header>
                                            <div class="md-title">{{ template.name }}</div>

                                        </md-card-header>

                                        <md-card-content>
                                            <br>
                                        </md-card-content>
                                    </md-ripple>
                                </md-card>
                            </div>
                        </div>
                    </div>

                    <div v-if="allSearchResults.interval_types.length != 0">
                        <h2 class="md-headline"> Interval Types results </h2>
                        <div class="md-layout result-card">

                            <div class="md-layout-item md-xlarge-size-25 md-large-size-25 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
                                 v-for="(interval_type, index) in allSearchResults.interval_types" :key="index" :value="interval_type.id">
                                <md-card class="md-primary" md-with-hover>
                                    <md-ripple>
                                        <md-card-header>
                                            <div class="md-title">{{ interval_type.name }}</div>

                                        </md-card-header>

                                        <md-card-content>
                                            <br>

                                        </md-card-content>
                                    </md-ripple>
                                </md-card>
                            </div>
                        </div>
                    </div>

                    <div v-if="Object.values(allSearchResults).every(x => !x.length)">
		                <AppEmptyStateComponent message="No results"/>
	                </div>
                </md-tab>

                <md-tab id="tab-users"
                        md-label="Users"
                        :md-template-data="{ results: allSearchResults.users.length }">

                    <div v-if="allSearchResults.users.length != 0">
                        <h2 class="md-headline" > Users results </h2>
                        <div class="md-layout result-card">

                            <div class="md-layout-item md-raised md-xlarge-size-25 md-large-size-25 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
                                 v-for="(user, index) in allSearchResults.users" :key="index" :value="user.id"
                                 @click="goToUser(user.id)">
                                <md-card class="md-primary" md-with-hover>
                                    <md-ripple>
                                        <md-card-header>
                                            <div class="md-title">{{ user.name }}</div>

                                        </md-card-header>

                                        <md-card-content>
                                            {{ user | fullName  }}  <br>
                                            {{ user.email }}
                                        </md-card-content>
                                    </md-ripple>
                                </md-card>
                            </div>
                        </div>
                    </div>

                    <div v-else>
                        <AppEmptyStateComponent message="No results for users"/>
                    </div>
                </md-tab>

                <md-tab id="tab-accounts"
                        v-if="userRole == 1"
                        md-label="Accounts"
                        :md-template-data="{ results: allSearchResults.accounts.length } "
                >

                    <div v-if="allSearchResults.accounts.length != 0">
                        <h2 class="md-headline"> Accounts results </h2>
                        <div class="md-layout result-card">

                            <div class="md-layout-item md-xlarge-size-25 md-large-size-25 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
                                 v-for="(account, index) in allSearchResults.accounts" :key="index" :value="account.id"
                                 @click="goToAccount(account.id)">
                                <md-card class="md-primary" md-with-hover>
                                    <md-ripple>
                                        <md-card-header>
                                            <div class="md-title">{{ account.name }}</div>

                                        </md-card-header>

                                        <md-card-content>
                                            <br>
                                            {{ account | address }}
                                        </md-card-content>
                                    </md-ripple>
                                </md-card>
                            </div>
                        </div>
                    </div>

                    <div v-else>
                        <AppEmptyStateComponent message="No results for accounts"/>
                    </div>
                </md-tab>

                <md-tab id="tab-templates"
                        md-label="Templates"
                        :md-template-data="{ results: allSearchResults.templates.length } "
                >

                    <div v-if="allSearchResults.templates.length != 0">
                        <h2 class="md-headline"> Templates results </h2>
                        <div class="md-layout result-card">

                            <div class="md-layout-item md-xlarge-size-25 md-large-size-25 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
                                 v-for="(template, index) in allSearchResults.templates" :key="index" :value="template.id">
                                <md-card class="md-primary" md-with-hover>
                                    <md-ripple>
                                        <md-card-header>
                                            <div class="md-title">{{ template.name }}</div>
                                        </md-card-header>

                                        <md-card-content>
                                            <br>
                                        </md-card-content>
                                    </md-ripple>
                                </md-card>
                            </div>
                        </div>
                    </div>

                    <div v-else>
                        <AppEmptyStateComponent message="No results for templates"/>
                    </div>
                </md-tab>
                
                <md-tab id="tab-interval_types"
                        md-label="Interval Types"
                        :md-template-data="{ results: allSearchResults.interval_types.length } "
                >

                    <div v-if="allSearchResults.interval_types.length != 0">
                        <h2 class="md-headline"> Interval Types results </h2>
                        <div class="md-layout result-card">

                            <div class="md-layout-item md-xlarge-size-25 md-large-size-25 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
                                 v-for="(interval_type, index) in allSearchResults.interval_types" :key="index" :value="interval_type.id">
                                <md-card class="md-primary" md-with-hover>
                                    <md-ripple>
                                        <md-card-header>
                                            <div class="md-title">{{ interval_type.name }}</div>
                                        </md-card-header>

                                        <md-card-content>
                                            <br>
                                        </md-card-content>
                                    </md-ripple>
                                </md-card>
                            </div>
                        </div>
                    </div>

                    <div v-else>
                        <AppEmptyStateComponent message="No results for interval types"/>
                    </div>
                </md-tab>
            </md-tabs>
        </div>

    </div>
</template>

<script>

    import {mapGetters} from "vuex";

    export default {
        name: 'SearchesDashboard',

        data: () => ({
        }),
        components: {

        },
        computed: {

            ...mapGetters(
                {
                  userRole: 'auth/currentUserRole',
                },
            ),

            allSearchResults() {

                return this.$store.getters['searches/getSearchResults'];
            }
        },
        methods: {

            goToUser(userId) {

                this.$router.push('/users/' + userId);
            },

            goToAccount(accountId) {

                this.$router.push('/account/' + accountId);
            },

            setPageName() {
                this.$store.dispatch('changePageName', {pageNameString: 'Search'});
            },
        },

        filters: {

            fullName(value) {

                return value.first_name.charAt(0).toUpperCase() + value.first_name.slice(1) + ' '
                    + value.last_name.charAt(0).toUpperCase() + value.last_name.slice(1);
            },

            address(value) {

                return value.state + " " + value.city + " " + value.street + " " +  value.zip;
            }
        },

        created() {

            this.setPageName();
        }
    }
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 768px) {
        .content-header {
            margin-bottom: 0 !important;
        }
    }
    
    h2 {
        font-size: xx-large;
        margin-bottom: 5px;
    }

    .md-card.md-primary.md-theme-default.md-with-hover {
        height: 200px;
    }

    .md-card.md-primary {
        color: #ffffff!important;
        background: #212121 !important;;
    }

    .result-card .md-card.md-primary.md-theme-default.md-with-hover {
        margin-bottom: 20px;
    }
    
    ::v-deep .md-tabs.md-theme-default .md-tabs-navigation {
        background: unset !important;
        margin-bottom: 30px;
        color: var(--lavender) !important;
        font-family: var(--font-family-figtree) !important;
        font-size: var(--font-size-l) !important;
        font-weight: 500 !important;
        font-style: normal !important;
    }
</style>