<template>
	<div id="register-main" class="auth-container">
		<div class="auth-container__user-select-wrapper">
			<AnimatedLogoPreloader :style="{marginTop: isMobile ? '10px' : '40px'}"></AnimatedLogoPreloader>

			<div class="register-options" :style="{marginTop: isMobile ? '10px' : '20px'}"
				 v-if="!isTrainerRegister && !isOwnerRegister && !isMemberWithoutReflexRegister && !isMemberWithReflexRegister && !isInvite">

				<div class="registration-cards"  id="feature-cards">
					<div
						v-for="(plan, index) in pricePlans"
						:key="index"
						class="card-container "
					>
						<div class="card-top">
							<div class="figtree-bold-white-32px smaller-font" :style="{fontSize: isMobile ? '25px': ''}" style="flex:15;margin-top: 10px;"> {{ plan.title }}</div>
							<div class="figtree-medium-bombay-14px smaller-font" :style="{fontSize: isMobile ? '12px': ''}" style="flex:30; min-height: 54px;">{{ plan.description }}</div>

						</div>

						<div class="card-center">
							<div class="price" v-if="plan.price != 0"> 
								<h1 style="flex:20;" class="figtree-semi-bold-white-48px" :style="{fontSize: isMobile ? '38px': ''}">$ {{ plan.price }}.00</h1>	 
								<div style="flex:20;" class="figtree-medium-bombay-16px" :style="{fontSize: isMobile ? '14	px': ''}">/per month</div>
								
							</div>
							<div class="price figtree-semi-bold-white-48px" v-if="plan.price == 0"> FREE</div>
						</div>

						<div class="card-bottom">
							<div v-for="(feature, index) in plan.infoFeatures" :key="index" class="info" style="flex:10">
								<span  class="figtree-medium-bombay-16px">
									<img style="width:24px;height: 24px;" src="@/assets/images/check-circle-fill.png" alt="info"> {{ feature.text }} 
								</span>
							</div>
							<div v-if="!isMobile" style="flex:50;opacity: 0;">1</div>

							<div style="flex:20;display: flex;flex-direction: column;gap: 10px;">
								<div style="flex:40">
									<md-button v-if="plan.title==='Trainer/Coach'" 
										class="md-primary md-raised form-select-btn md-theme-light try-btn figtree-medium-white-20px" :style="{fontSize: isMobile ? '15px': '' , height: '40px'}"
										@click="isTrainerRegister = true"
									>
										Try for Free
									</md-button>
									<md-button v-if="plan.title==='Facility'"
										class="md-primary md-raised form-select-btn md-theme-light try-btn figtree-medium-white-20px" :style="{fontSize: isMobile ? '15px': '',height: '40px'}"
										@click="isOwnerRegister = true"
									>
										Try for Free
									</md-button>
								</div>
								<div style="flex:40;text-align: center;" class="figtree-normal-white-16px">
									First 2 Weeks Free
								</div>
							</div>
						</div>
					</div>
				</div>
				<p class="auth-container__form-wrap__form-description">
    				<span>Already have an Account? </span>
					<span class="back-to-login-page" @click="$router.push('/login');">Sign In</span>
  				</p>

<!--				<p class="auth-container__form-wrap__form-description">-->
<!--					Are you a gym member looking to connect with your gym that already subscribes to Reflex Radio?-->
<!--				</p>-->
<!--				<md-button-->
<!--					class="md-primary md-raised form-select-btn md-theme-light"-->
<!--					@click="isMemberWithReflexRegister = true"-->
<!--				>-->
<!--					CLICK HERE TO CONNECT-->
<!--				</md-button>-->

<!--				<p class="auth-container__form-wrap__form-description">-->
<!--					Want Reflex Radio for your workout? Let us know if you want us to contact your gym!-->
<!--				</p>-->
<!--				<md-button-->
<!--					class="md-primary md-raised form-select-btn md-theme-light"-->
<!--					@click="isMemberWithoutReflexRegister = true"-->
<!--				>-->
<!--					CLICK HERE TO PROVIDE DETAILS-->
<!--				</md-button>-->
			</div>

			<AuthRegisterFormTrainer
				:is-register-prop.sync="isTrainerRegister"
				@register="register"
			/>

			<AuthRegisterOwner
				:is-register-prop.sync="isOwnerRegister"
				@register="register"
			/>

			<AuthRegisterFormMember
				v-if="isInvite || isMemberWithReflexRegister"
				:is-register-prop.sync="isMemberWithReflexRegister"
				@register="register"
				:reg-form-prop="regForm"
				:is-invite-prop="isInvite"
			/>

			<AuthRegisterFormMemberWithoutStore
				:is-register-prop.sync="isMemberWithoutReflexRegister"
				@register="registerMemberWithoutStore"
			/>
		</div>

	</div>
</template>

<script>
	import AnimatedLogoPreloader from '@/components/Common/AnimatedLogoPreloader';
	import {statesConstData} from '@/constants'
	import AuthRegisterFormTrainer from "@/components/Auth/AuthRegisterFormTrainer";
	import AuthRegisterOwner from "@/components/Auth/AuthRegisterFormOwner";
	import AuthRegisterFormMember from "@/components/Auth/AuthRegisterFormMember";
	import AuthRegisterFormMemberWithoutStore from "@/components/Auth/AuthRegisterFormMemberWithoutStore";
	import {CheckWindowWidthMixin} from '../../mixins/CheckWindowWidthMixin';

	export default {
		name: 'AuthRegister',
		mixins: [CheckWindowWidthMixin],
		data: () => ({

			regForm: {
				first_name: null,
				last_name: null,
				email: null,
				password: null
			},
			showPhotoError: false,
			isInvite: false,
			previewUrl: '',
			states: statesConstData,
			isTrainerRegister: false,
			isOwnerRegister: false,
			isMemberWithReflexRegister: false,
			isMemberWithoutReflexRegister: false,
			pricePlans: [
				{
					title: 'Trainer/Coach', price: 49, infoFeatures: [
						{text: '1 Trainer Account'},
						{text: 'Unlimited Blueprint Creation'},
						// {text: 'Unlimited Member/Follower Accounts'}
					],
					description: 'Perfect for individual trainers'
				},
				{
					title: 'Facility', price: 149, infoFeatures: [
						{text: 'Unlimited Trainer Accounts'},
						{text: 'Unlimited Blueprint Creation'},
						// {text: 'Unlimited Member/Follower Accounts'}
					],
					description: 'Your entire team in one place'
				},
				// {
				// 	title: 'Gym Members', price: 0, infoFeatures: [
				// 		{text: 'Follow Blueprints from Gym/Trainer'},
				// 		{text: 'Music available anywhere/anytime'},
				// 	]
				// }
			]
		}),

		components: {
			AnimatedLogoPreloader,
			AuthRegisterFormMemberWithoutStore,
			AuthRegisterFormMember,
			AuthRegisterOwner,
			AuthRegisterFormTrainer,
		},

		methods: {

			login(user) {

				this.$store.dispatch('auth/loginAfterRegistration', {
					email: user.email,
					password: user.password,
				})
					.then((res) => {

						//Set axios Authorization header
						this.$axios.defaults.headers.common['Authorization'] = `Bearer ${res.auth_token}`;
						this.$router.push('/dashboard');
					})
					.catch((error) => {

						console.log(error);
						this.$store.dispatch('showSnackbarMessage',
							{message: error.response.data, duration: 4000, mode: 'fail'});
					});
			},

			registerMemberWithoutStore(user) {

				const path = 'store-requests';

				this.$axios.post(path, user)
					.then(res => {

						this.isMemberWithoutReflexRegister = false;
						this.$store.dispatch('showSnackbarMessage',
							{message: 'Your information was sent successfully.', duration: 4000, mode: 'success'});
					})
					.catch(error => {

						this.$store.dispatch('showSnackbarMessage',
							{message: error.response.data, duration: 4000, mode: 'fail'});
					});
			},

			register(user) {

				// let user = this.setUserForRegister();

				let path = 'register';
				if (this.token) path += '/' + this.token;

				this.$axios.post(path, user)
					.then(res => {
						// console.log(res);
						// this.$router.replace('/login');

						this.$store.dispatch('showSnackbarMessage',
							{message: 'Registered successfully.', duration: 4000, mode: 'success'});
						this.login(user);
					})
					.catch(error => {

						this.$store.dispatch('showSnackbarMessage',
							{message: error.response.data, duration: 4000, mode: 'fail'});
					});
			},

			getTokenInvitation(token) {

				this.$axios.get(`/invites/${token}`).then(res => {

					this.isInvite = true;
					this.regForm.first_name = res.data.first_name;
					this.regForm.last_name = res.data.last_name;
					this.regForm.email = res.data.email;
				}).catch(error => {

					this.$store.dispatch('showSnackbarMessage',
						{message: error.response.data, duration: 4000, mode: 'fail'});
				});
			},
		},

		computed: {},

		created() {

			if (this.$route.params.token) {

				this.isCreateAccount = false;
				this.token = this.$route.params.token;

				this.getTokenInvitation(this.token);
			}
		},
	};
</script>

<style lang="scss" scoped>

	@include authPage;

	.logo-image {
		margin-bottom: 2.3%;
	}

	.single-trainer-checkbox {
		color: #FFFFFF;
	}
	.registration-cards{
		display: flex;
		justify-content: center;
		gap:40px;
		flex-wrap: wrap;
		@media only screen and (max-width: 481px) {
			gap: 10px;
		};
	}
	.card-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 30px 40px;
		gap: 10px;
		background: #28292E;
		border: 1px solid #3C3C3C;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
		border-radius: 5px;
		height: 457px;
	    width: 317px;
		@media only screen and (max-width: 834px) {
			height: 420px;
		};
		@media only screen and (max-width: 481px) {
			height: 380px;
			gap: 5px;

		};

		.card-top {
			flex: 15;
			display: flex;
			flex-direction: column;
			gap:10px;
			align-items: center;
			justify-content: center;
			width: 100%;
		}

		.card-center {
			flex:15;
			display: flex;
			justify-content: flex-start;
			flex-direction: column;
			align-items: flex-start;
		}
		.price{
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			gap: 15px;
		}
		.card-bottom {
			flex:20;
			gap: 10px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			text-align: left;
			padding-bottom: 5px;

			@include respond(small) {
				font-size: 1.2rem;
			}
		}
	}
	.try-btn{
		width: 237px;
		height: 60px;
		border-radius: 5px;
	}
	.back-to-login-page{
		color: #BB6BD9;;
		cursor: pointer;
	}
	.back-to-login-page:hover{
		color: #FFFFFF;;
	}
</style>
