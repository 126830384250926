<template>
    <div class="component-wrap">
        <AppHeaderComponent headline="Accounts" class="content-header__stores">
            <div class="content-header__actions">
                <md-button
                        v-if="userRole === 1"
                        class="md-raised md-primary md-raised md-theme-light"
                        @click="openAccountModal()">Create Account</md-button>

                <div class="filter-input">
                    <AppInputComponent
                            class="input-field input-field__stores-filter"
                            required
                            placeholder="Filter By Name"
                            v-model="filterUserString"/>
                </div>
            </div>
        </AppHeaderComponent>

        <div class="content-header__actions-2">

            <md-button
                    v-if="userRole === 1"
                    class="md-raised md-primary md-raised md-theme-light"
                    id="set-wtf-member"
                    @click="setWTF">Set as WTF gym member</md-button>
            <md-button
                    v-if="userRole === 1"
                    class="md-raised md-primary md-raised md-theme-light"
                    id="unset-wtf-member"
                    @click="unSetWTF">Unset as WTF gym member</md-button>
        </div>

        <AppSpinnerComponent v-if="loadingSpinner" :loading="loadingSpinner"/>

		<AppEmptyStateComponent message="There are no accounts added yet."
								v-if="!accounts.length && !loadingSpinner"/>

        <div class="accounts">

            <div
                    class="account md-raised account-records"
                    v-for="(account, index) in filteredAccounts"
                    :key="index + '_store'">


                <p class="account__data checkbox-wtf">
                    <md-checkbox class="md-layout-item md-size-10" v-model="account.is_checked"/>
                </p>

                <div class="account__image">
                    <img :src="account.image_url" alt="image"/>
                </div>

                <p class="account__data">
                    {{account.name}}
                </p>

                <p class="account__data store__data--grey">
                    {{account.street}}
                </p>

                <p class="account__data store__data--grey">
                    {{account.city}}
                </p>

                <p class="account__data store__data--grey">
                    {{account.state}}
                </p>

                <p class="account__data store__data--grey">
                    {{account.zip}}
                </p>


                <div class="account__remove-btn"
                     v-if="userRole < 2"
                     @click.stop="openEditAccountModal(account.id)">
                    <md-icon>edit</md-icon>
                </div>

            </div>

        </div>

        <AccountDialog
                :showDialog.sync="showDialog"
                :key="dialogKey"
                @addNewAccount="getAllAccounts"
                :account-prop.sync="account"/>

    </div>
</template>

<script>
    import AccountService from "../../services/account.service"
    import AccountDialog from "../../components/Accounts/AccountDialog.vue"
    import {DeepFilterMixin} from '@/mixins/DeepFilterMixin';
    import {mapGetters} from "vuex";

    export default {
        name: 'AccountsDashboard',
        mixins: [DeepFilterMixin],

        data: () => ({
            accounts: [],
            loadingSpinner: true,
            filterUserString: '',
            showDialog: false,
            dialogKey: 0,
            account: {
                name: null,
                street: null,
                logo: null,
                suite: null,
                state: null,
                city: null,
                zip: null,
                email: null,
                active: null
            },
        }),

        components: {
            AccountDialog
        },

        computed: {

            filteredAccounts() {

                return this.accounts.filter(x => this.filterFunction(x, this.filterUserString));
            },

            ...mapGetters(
                {
                  userRole: 'auth/currentUserRole',
                },
            ),
        },
        methods: {

            setWTF(){
                this.updateAccounts(1);
            },

            unSetWTF(){
                this.updateAccounts(0)

            },

            updateAccounts(is_set){
                for(let i = 0; i < this.accounts.length; i++){

                    if(this.accounts[i].is_checked){

                        this.accounts[i].is_wtf_member = is_set;

                        AccountService.updateAccount(this.accounts[i])
                            .then((res) => {
                            })
                            .catch(() => {
                                this.$store.dispatch('showSnackbarMessage', {message: 'Failed Please, try again.', duration: 8000, mode: 'fail'});
                            });
                    }
                }

                this.$store.dispatch('showSnackbarMessage', {message: 'All Account are updated.', duration: 8000, mode: 'success'});
            },

            setPageName() {

                this.$store.dispatch('changePageName', { pageNameString: 'Accounts'});
            },

            goToSeeDetails(id) {

                this.$router.push('/accounts/'  + id);
            },

            openAccountModal() {

                this.account =  {
                        name: null,
                        street: null,
                        logo: null,
                        suite: null,
                        state: null,
                        city: null,
                        zip: null,
                        email: null,
                        active: null
                };
                this.dialogKey++;
                this.showDialog = true;
            },

            openEditAccountModal(id) {

                this.getCurrentAccount(id).then(res => {

                    this.dialogKey++;
                    this.showDialog = true;
                })
            },
//            removeAccount(accountId) {
//                this.$store.dispatch('accounts/deleteAccount', {accId: accountId})
//                    .then(() => {
//                        this.getAllAccounts();
//
//                        this.$store.dispatch('showSnackbarMessage', {
//                            message: 'The store was successfully deleted.',
//                            duration: 4000,
//                            mode: 'success'
//                        });
//                    })
//                    .catch((err) => {
//                        this.$store.dispatch('showSnackbarMessage', {
//                            message: `${err.message}. Please, try again.`,
//                            duration: 4000,
//                            mode: 'fail'
//                        });
//                    });
//            },
            getCurrentAccount(id){

                return AccountService.getOne(id)
                    .then((res) => {

                        this.account = res;
                    })
                    .catch(() => {
                        this.$store.dispatch('showSnackbarMessage', {message: 'Failed Please, try again.', duration: 8000, mode: 'fail'});
                    });
            },

            getAllAccounts() {

                return AccountService.getAccounts()
                    .then((res) => {

						this.loadingSpinner = false;
                        this.accounts = res;

                        for(let i = 0; i < this.accounts.length; i++){
                            this.accounts['is_checked'] = false;
                        }
                    })
                    .catch(() => {

                        this.$store.dispatch('showSnackbarMessage', {message: 'Failed Please, try again.', duration: 8000, mode: 'fail'});
                    });
            }

        },
        created(){

            this.setPageName();
            this.getAllAccounts();
        }
    }
</script>

<style lang="scss" scoped>
    .content-header.content-header__stores {
        margin-bottom: 40px;
    }
    .input-field.input-field__stores-filter {
        padding-bottom: 0;
    }

    @include tableStyle(accounts, account);

    .accounts {

        .account {
            padding: 16px 24px;

            &__image {
                margin-right: auto;
            }

            &__data {
                min-width: 16%;
                padding-left: 1.2rem;
            }

            &__remove-btn {

                @include respond(small) {
                    margin-left: auto;
                }
            }
        }
    }

    .content-header__actions-2{
        text-align: left;
        margin-bottom: 20px;
    }

    .checkbox-wtf{
        min-width: 6%!important;
        padding-left: 0 !important;
    }
</style>