<template>
    <md-dialog :md-active.sync="showDialogEditRoom" :md-click-outside-to-close="false">

        <md-dialog-title>Edit current room</md-dialog-title>

        <form class="room-form">

            <AppInputComponent
                    class="room-form__field"
                    required
                    placeholder="Room Name"
                    v-model="$v.room.name.$model"
                    :error="$v.room.name.$invalid && $v.room.name.$dirty"
                    errorText="Room Name is required"
            />
            <AppInputComponent
                    class="room-form__field"
                    required
                    :controlType="'time'"
                    :placeholderInput="'Open Time'"
                    v-model="$v.room.open_at.$model"
                    :inputIcon="'watch_later'"
                    :error="$v.room.open_at.$invalid && $v.room.open_at.$dirty"
                    errorText="Open Time is required"/>
            <AppInputComponent
                    class="room-form__field"
                    required
                    :controlType="'time'"
                    :placeholderInput="'Close Time'"
                    v-model="$v.room.close_at.$model"
                    :inputIcon="'watch_later'"
                    :error="$v.room.close_at.$invalid && $v.room.close_at.$dirty"
                    errorText="Close Time is required"
            />
            <AppInputComponent
                    class="room-form__field select-field"
                    controlType="select"
                    required
                    placeholder="Choose a facility"
                    v-model="$v.room.store_id.$model"
                    :error="$v.room.store_id.$invalid && $v.room.store_id.$dirty"
                    errorText="Store is required">

                <option v-for="(store, index) in stores" :key="index" :value="store.id">{{store.name}}</option>

            </AppInputComponent>
            <md-dialog-actions>
                <md-button class="dialog__btn dialog__btn--danger" @click="closeDialog()">CANCEL</md-button>
                <md-button class="md-primary dialog__btn md-raised md-theme-light"
                           :disabled="$v.room.$invalid"
                           @click="editARoom()">EDIT</md-button>
            </md-dialog-actions>
        </form>
    </md-dialog>
</template>

<script>

    import {required} from 'vuelidate/lib/validators';
    import {DeepFilterMixin} from '@/mixins/DeepFilterMixin';
    import RoomService from '../../services/room.service';

    export default {
        name: 'EditRoomDialog',
        mixins: [DeepFilterMixin],

        props: {
            showDialogEditRoom: {
                type: Boolean,
                default: false
            },
            room: {
                type: Object
            },
            stores: {
                type: Array
            }
        },

        validations: {
            room: {
                name: {required},
                open_at: {required},
                close_at: {required},
                store_id: {required}
            }
        },

        methods: {

            editARoom(){

                let isOpenTimeCorrect = this.timePropsValueObj(this.room.open_at);
                let isCloseTimeCorrect = this.timePropsValueObj(this.room.close_at);

                if (isOpenTimeCorrect && isCloseTimeCorrect) {

                    this.room['open_at'] = this.convertAMPMObj(this.room['open_at']);
                    this.room['close_at'] = this.convertAMPMObj(this.room['close_at']);

                    RoomService.editRoom(this.room)
                        .then(() => {
                            this.$emit('addTheNewRoom');

                            this.$store.dispatch('showSnackbarMessage', {
                                message: 'The room was successfully added.',
                                duration: 4000,
                                mode: 'success'
                            });

                            this.closeDialog();
                        })
                        .catch((err) => {
                            this.$store.dispatch('showSnackbarMessage', {
                                message: `${err.message}. Please, try again.`,
                                duration: 4000,
                                mode: 'fail'
                            });
                        });
                } else {

                    this.$store.dispatch('showSnackbarMessage', {
                        message: 'Please add a valid time on "Open" and "Close" time fields',
                        duration: 4000,
                        mode: 'error'
                    });

                }
            },

            closeDialog() {

                this.$emit('update:showDialogEditRoom', false);
            }
        }
    }
</script>

<style lang="scss" scoped>
    @include dialogSettings();
    .dialog__hint {
        margin-bottom: 16px;
    }
</style>