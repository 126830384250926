import $axios from '../plugins/axios'

class FavoriteArtistService {

    getAllFavoriteArtists() {

        return $axios.get('/favorite-artists')
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    createFavoriteArtists(favoriteArtists) {

        return $axios.post('/favorite-artists', favoriteArtists)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

}

export default new FavoriteArtistService();