<template>
	<md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
		<md-dialog-title>Cancel Subscription</md-dialog-title>

		<md-dialog-content v-if="subscription">
			<p>
				If you cancel now, your store will be active until your subscription ends on
				<strong>{{ subscription.current_period_end | formatDate }}</strong>
			</p>
		</md-dialog-content>

		<form class="plan-form">
			<md-dialog-actions>
				<md-button
					class="dialog__btn dialog__btn--danger"
					id="close-button"
					@click="closeDialog()"
				>
					GO BACK
				</md-button>
				<md-button
					class="md-primary dialog__btn md-raised md-theme-light"
					id="continue-cancel-subscription-button"
					@click.native="cancelSubscription()"
				>
					CONTINUE
				</md-button>
			</md-dialog-actions>
		</form>
	</md-dialog>
</template>

<script>
	import SubscriptionService from '@/services/subscription.service'

	export default {
		name: 'CancelSubscriptionModal',
		components: {},
		props: {
			showDialog: {
				type: Boolean,
				default: false
			},
			subscriptionProp: {
				type: Object
			},
		},
		data: function () {
			return {
				subscription: null
			}
		},
		methods: {
			cancelSubscription() {

				return SubscriptionService.cancel(this.subscription.id)
					.then((res) => {

						this.$emit('refreshList');
						this.closeDialog()
					})
			},
			closeDialog() {
				this.$emit('update:showDialog', false);
			}
		},

		mounted() {

			this.$nextTick(() => {

				this.subscription = this.subscriptionProp;
			})
		}
	}
</script>

<style lang="scss" scoped>

	.md-dialog {

		@include respond(small) {
			margin-top: 80px;
		}

		@media screen and (max-width: 980px) and (min-width: 700px) {
			margin-top: 80px;
		}

		/*width: auto !important;*/
		padding-left: 1.5em;
		padding-right: 1.5em;
		text-align: center;

		.plan-form {
			text-align: center;
		}

		.md-dialog-actions {
			justify-content: center;
		}

		.list-group {

			.current-subscriptions {

				@include respond(small) {
					margin-top: 30px;
				}

				@media screen and (max-width: 980px) and (min-width: 700px) {
					margin-top: 30px;
				}
			}
		}
	}
</style>
