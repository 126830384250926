<template>
  <div v-if="trackData">
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false" style="align-items: center;">

      <div style="flex:100;display: flex;flex-direction: column;align-items: center;" v-if="trackData.song">
        <div style="flex:3;align-self: flex-start;" @click="closeDialog(true)">
          <md-icon class="expand-button md-fab">keyboard_arrow_down</md-icon>

        </div>
        <div style="flex:15;display: flex;flex-direction: column;text-align: center;">
          <div class="song-duration" style="flex:20">
            {{ trackData.song_duration | convertTimeHHMMSS }}
          </div>
          <div style="flex:40;flex-direction: column;">
            <div style="flex:15;" class="song-name">
              {{ trackData.song.title }}
            </div>
            <div style="flex:15;" class="artist-name">

              {{ trackData.song.artist.name }}
            </div>
          </div>
        </div>
        <div style="flex:32">
          <div class="album-art">
            <img class="art-work" :src="imageUrl" alt="Mask group" />
          </div>
        </div>
        <div style="flex:8;display: flex;text-align: center;width: 100%;align-items: center;">
          <div title="like" style="cursor: pointer;flex:30;" @click="likeSong(trackData)">
            <md-icon>thumb_up</md-icon>
          </div>
          <div title="dislike/replace" style="cursor: pointer;flex:40;" @click="dislikeSong(trackData)">
            <md-icon>thumb_down</md-icon>
          </div>
          <div title="replace" style="flex:30;display: flex;" >
            <div style="cursor: pointer;flex:50;" @click="shuffleSong(trackData)"> 
              <md-icon>find_replace</md-icon>
            </div>
            <div style="cursor: pointer;flex:50;" @click="openSearchDialogue(trackData)"> 
              <md-icon>search</md-icon>
            </div>
          </div>
        </div>
        <div style="flex:10;display: flex;text-align: center;width: 100%;align-items: center;">
          <div title="change Genre" style="cursor: pointer;flex:30;">
            <md-menu md-align-trigger style="z-index: 20!important;">
              <md-button md-menu-trigger>
                <h4 class="figtree-medium-white-14px">{{ genreNameProp }}
                </h4>
              </md-button>

              <md-menu-content style="z-index: 20!important;width: 100%;">
                <md-menu-item
                  v-for="(genre, indexInnerGenre) in genresProp.filter(genre => genre.visibility == 1 || genre.visibility == 2)"
                  :key="indexInnerGenre + '_genreOneSong'" :id="'genre-select-' + indexInnerGenre + '_' + genre.name"
                  @click="changeGenreInterval(genre.id, trackData.interval_id, trackData.id, isInterval)">
                  {{ genre.name }}
                </md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
          <div style="flex:40;text-align: -webkit-center;">
            <flexLevels :className="flexClass" />

          </div>
          <div title="Change Volume" style="flex:30;">
            <md-menu v-if="isInterval" style="z-index: 21!important;width: 100%;cursor: pointer;" md-size="small"
              md-align-trigger>
              <md-button md-menu-trigger :id="'volume-menu-button-' + indexProp">
                <h4 class="">
                  <md-icon>volume_up</md-icon>

                  {{ volume }}%
                </h4>
              </md-button>

              <md-menu-content style="z-index: 21!important;width: 100%;">
                <md-menu-item v-for="(volume, index) in [25, 50, 75, 100]" :key="index + '_volume'"
                  :id="'volume-level-select-' + indexProp + '-' + index"
                  @click="changeIntervalVolume(volume, trackData.interval_id)">
                  {{ volume }}%
                </md-menu-item>
              </md-menu-content>
            </md-menu>
            <div v-else>
              <h4>
                <md-icon>volume_up</md-icon>

                {{ volume }}%
              </h4>
            </div>
          </div>
        </div>
        <div class="player-controls">
          <div style="flex:20">
            <div class="media-player">
              <apple-music-progress theme="dark"></apple-music-progress>
            </div>
          </div>
          <div style="flex:10">
            
          </div>
          <div style="flex:60;">
            <div>

              <div v-if="!this.music.isPlaying" @click="playInterval(trackData)">

                <md-icon class="md-size-2x play">play_circle_filled
                </md-icon>
              </div>
              <div v-else @click="playInterval(trackData)">
                <md-icon class="md-size-2x play">pause
                </md-icon>
              </div>
            </div>
          </div>

        </div>
      </div>
    </md-dialog>
    <AppleSearchDialog
        :showDialog.sync="showAppleSearchDialog"
        :key="AppleSearchKey"
        :track-data="trackData"
        @appleSong="changeAppleSong"
    />
  </div>
</template>
  
<script>
import { required } from 'vuelidate/lib/validators';
import AppColorPicker from "@/components/Base/AppColorPicker";
import AppSpinnerComponentButton from "@/components/Base/AppSpinnerComponentButton";
import GenreService from '@/services/genre.service'
import $axios from "@/plugins/axios";
import PlaylistService from '@/services/playlists.service';
import AudioPlayer from "@/components/AudioPlayer/AudioPlayer";
import flexLevels from "@/components/TrainerDashboard/flexLevels";
import AppleSearchDialog from "@/components/TrainerDashboard/AppleSearchDialog";

export default {
  name: 'EditIntervalDialog',
  components: {
    AppColorPicker,
    AppSpinnerComponentButton,
    AudioPlayer,
    flexLevels,
    AppleSearchDialog
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    isInterval: {
      type: Boolean,
      default: false
    },
    trackData: {
      type: [Object, Array],
      required: true
    },
    genresProp: {
      type: [Array],
      default: [],
      required: true

    },

    imageUrl: {
      type: String,
      default: ''
    },

    genreNameProp: {
      type: String,
      default: ''
    },
    flexClass: {
      type: String,
      default: ''
    },
    indexProp: {
      type: Number,
      default: null,
    },
    volume: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    music: null,
    showAppleSearchDialog:false,
    AppleSearchKey:0
  }),
  validations: {

  },
  methods: {
    async changeAppleSong(song,intervalSongId){
      console.log(song,intervalSongId);
      await this.$emit('changeAppleSong', song,intervalSongId);
      this.closeDialog();

    },
    openSearchDialogue(){
      this.showAppleSearchDialog = true;
      this.AppleSearchKey++;
    },
    async playInterval(intervalToPlay) {
      console.log(intervalToPlay)
      await this.$emit('playInterval', intervalToPlay);
    },

    convertTimeHHMMSS(val) {

      let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);

      return hhmmss;
    },

    async changeIntervalVolume(volume, intervalID) {
      await this.$emit('changeIntervalVolume', volume, intervalID);
      this.closeDialog();
    },
    showFlexSelect(event) {
      if (document.querySelector('#flexSelect').style.display == 'block') {

        document.querySelector('#flexSelect').style.display = 'none'
      } else (document.querySelector('#flexSelect').style.display = 'block');
    },

    closeDialog() {

      this.$emit('update:showDialog', false);
    },
    async likeSong(songData) {
      await this.$emit('likeSong', songData);
      this.closeDialog();
    },
    async dislikeSong(songData) {
      await this.$emit('dislikeSong', songData);
      this.closeDialog();
    },
    async shuffleSong(songData) {
      await this.$emit('shuffleSong', songData);
      this.closeDialog();
    },

    async changeGenreInterval(genreId, intervalId, trackDataIntervalSongId, isInterval) {
      await this.$emit('changeGenrePhone', genreId, intervalId, trackDataIntervalSongId, isInterval);
      this.closeDialog();
    },





  },
  filters: {

    convertTimeHHMMSS(val) {

      let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);

      return hhmmss;
    },
  },
  mounted() {

  },
  async created() {
    this.music = MusicKit.getInstance();
  },
  watch: {


  }
}
</script>
  
<style lang="scss" scoped>
@include dialogSettings();

.md-dialog .md-dialog-container {
  max-width: 768px;
}


::v-deep .pick-bp-name input::placeholder {
  color: #BEBEC8 !important;
  font-weight: 500;
}

::v-deep .md-dialog .md-dialog-container {
  align-items: center !important;
  ;
}

::v-deep .pick-bp-name input {
  padding-left: 5px;
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px solid white !important;
  font-size: 1.7rem;
  color: white !important;

  &:active {
    border: none;
    background-color: transparent;
    border-bottom: 1px solid white;
  }

  &:focus {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid white;
  }
}


::v-deep .pick-bp-tags input::placeholder {
  font-size: 1.3rem !important;
}


::v-deep .v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, .7) !important;
}



.menu-container {
  z-index: 100 !important;
}

::v-deep .md-dialog-container {
  background-color: var(--heavy-metal) !important;
}

::v-deep .md-tabs .md-tabs-content {
  background-color: var(--heavy-metal) !important;
}

::v-deep .md-tabs.md-theme-default .md-tabs-navigation {
  background-color: var(--heavy-metal) !important;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--gray);
}



textarea::placeholder {
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  white-space: nowrap;
  color: var(--sonic-silver);
  font-family: var(--font-family-figtree);
  font-size: var(--font-size-s);
  font-weight: 500;
  font-style: normal;
}


.add-opacity {
  opacity: 0.5;
  border: 4px double #3a3a3a;
}

::v-deep .md-tabs-navigation .md-button {
  color: var(--mountain-mist);
  font-family: var(--font-family-figtree);
  font-size: var(--font-size-l);
  font-weight: 500;
  font-style: normal;
}


.song-duration {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #B6B6B6;
}

.song-name {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom: 10px;
}

.artist-name {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #FFFFFF;

}

.album-art {
  width: 272px;
  height: 272px;
  border-radius: 50px;
}

.art-work {
  border-radius: 2px;
  width: 100%;
  height: 100%;
}

.media-player {
  padding-top: 25px;
  width: 250px;
  margin: 0 auto;
}

.menu-container {
  z-index: 100 !important;
}

::v-deep .md-list.md-theme-default {
  background-color: var(--onyx) !important;
  padding: 0;
}
.player-controls{
  flex: 28;
  flex-direction: column;
  text-align: center;
}
</style>
  