<template>
    <div class="component-wrap">
        <div class="inv-container__form-wrap">
            <div class="User-management-header">
                <div style="flex:40;">
                    <AppHeaderComponent style="margin-left:0;" class="figtree-medium-white-32px" headline="User Management">
                        <div class="store-empty-error" v-if="userRole == 1 && filteredStores.length === 0 && inviteForm.account_id">
                            You can't invite anyone because there are no facilities attached to this account. Choose another account
                        </div>
                    </AppHeaderComponent>
                </div>
                <div style="flex:40;">
                    <md-button class="md-primary dialog__btn md-raised md-theme-light figtree-normal-white-16px"
                        id="newInvitation"
                        @click="showNewInvitationModal=!showNewInvitationModal"><md-icon>person_add</md-icon> Add New User
                    </md-button>
                </div>
            </div>

            <AppSpinnerComponent v-if="loadingSpinner" :loading="loadingSpinner"/>

            <md-dialog :md-active="showNewInvitationModal" :md-click-outside-to-close="false">
                <div style="display: flex;text-align: left;margin-bottom:30px ;">
                    <div style="flex:90;text-align: left">
                        <md-dialog-title style="margin: 0;padding: 0">Add User</md-dialog-title>
                    </div>
                    <div style="flex 5;cursor: pointer;" 
                            @click="showNewInvitationModal=false;">
                            <img style="max-width:15;width:15;height: 15;max-height: 15px;" src="@/assets/images/x.png" alt="account-image">

                        </div>
                </div>
                <form class="invite-form">
                    <md-field class="input">
                        <label class="label figtree-normal-white-12px">First Name</label>
                        <md-input  style="width:100%;" class="figtree-normal-white-16px-2"
                                v-model="$v.inviteForm.first_name.$model" 
                                required
                                >
                        </md-input>
                    </md-field>
                    
                    <md-field class="input">
                        <label class="label figtree-normal-white-12px">Last Name</label>
                        <md-input  style="width:100%;" class="figtree-normal-white-16px-2"
                                v-model="$v.inviteForm.last_name.$model" 
                                required
                                >
                        </md-input>
                    </md-field>
                  
                    <md-field class="input">
                        <label class="label figtree-normal-white-12px">Email</label>
                        <md-input  style="width:100%;" class="figtree-normal-white-16px-2"
                                v-model="$v.inviteForm.email.$model" 
                                required
                                >
                        </md-input>
                    </md-field>
                  
                    <md-field class="input">
                            <label>Role</label>
                            <md-select v-model="$v.inviteForm.role_id.$model" required name="Role" id="Role">
                                <md-option  v-for="(role, index) in roles" :key="index" :value="role.id">
                                    {{role.name}}
                                </md-option>
                            </md-select>
                      </md-field>
                  
                    <md-field class="input" v-if="userRole == 1">
                            <label>Choose Account</label>
                            <md-select v-model="$v.inviteForm.account_id.$model" required name="account" id="account">
                                <md-option  v-for="(account, index) in accounts" :key="index" :value="account.id">
                                    {{account.name}}
                                </md-option>
                            </md-select>
                      </md-field>
                   
                    <md-field class="input"  v-if="filteredStores.length > 0">
                            <label>Choose Facility</label>
                            <md-select v-model="$v.inviteForm.store_id.$model" required name="account" id="account">
                                <md-option  v-for="(store, index) in filteredStores" :key="index" :value="store.id">
                                    {{store.name}}
                                </md-option>
                            </md-select>
                      </md-field>
                   
                    <div style=" display: flex;width: 100%;">
                        <div style="flex:50"></div>
                        <div style="flex:50;">
                            <md-dialog-actions  class="form-buttons">
                            
                                <md-button class=" dialog__btn--danger"
                                           id="closeInviteBtn22"
                                           @click="showNewInvitationModal=false;">Cancel</md-button>
                                <md-button style="width:150px;margin-left: 50px;" class="md-primary dialog__btn md-raised md-theme-light"
                                id="sendInvitation"
                                                       :disabled="$v.inviteForm.$invalid"
                                                       @click="inviteUser()">Send Invite
                                </md-button>
                            </md-dialog-actions>
                        </div>
                    </div>

                </form>
            </md-dialog>
        </div>

		<AdminDashboardUsers>

		</AdminDashboardUsers>
        <div  class="invites-filter-cont" v-if="invitedUsers.length">
                <div style="flex:70" class="figtree-medium-white-22px" >
                    Last Invites
                </div>
                <div class="content-header__actions" style="flex:20;text-align: -webkit-right;">
                    <md-field class="search-box" style="padding: 0;">
                        <label style="font-size: 12px;" class=""><md-icon style="font-size: 20px!important;padding: 0;">search</md-icon>Filter By Email</label>
                        <md-input style="padding-top: 8px;font-size: 15px;"
                                v-model="filterUserString" 
                                >
                        </md-input>
                    </md-field>
                </div>
            </div>

        <AppEmptyStateComponent message="There are no invited users yet." v-if="!invitedUsers.length"/>

        <div class="invited-users-list">
            <div class="invited-user figtree-semi-bold-alto-14px md-raised md-layout" v-for="(invitedUser, index) in filteredInvitedUsers"
                 :key="index + '_invited_user'">
                <div class="user-email md-layout-item">
                    {{invitedUser.email}}
                </div>

                <div class="user-name md-layout-item">
                    {{invitedUser.first_name}} {{invitedUser.last_name}}
                </div>

                <div class="user-role md-layout-item">
                    {{invitedUser.role.name}}
                </div>

                <div class="user-reaction md-layout-item">
					NEW
                </div>

                <div class="user-resend md-layout-item" @click="resendInvite(invitedUser.id)">
                    RE-SEND EMAIL
                </div>

                <div class="md-layout md-layout-item md-alignment-center-right">
                    <div class="invited__edit-btn" >
                        <md-button  class="md-icon-button" 
                            @click.stop="openEditInviteModal(invitedUser.id)">
                            <md-icon>edit</md-icon>
                        </md-button>
                    </div>
                </div>

            </div>
        </div>

        <InvitationFormModal @getAllInvitedUsers="getAllInvitedUsers"
                             :showModal.sync="showInvitationFormModal" :key="dialogKey"
                             :invitation-prop="invitation"/>


    </div>
</template>

<script>
    import {required, email, requiredIf, integer} from 'vuelidate/lib/validators';
    import {DeepFilterMixin} from '@/mixins/DeepFilterMixin';
    import InvitationFormModal from "../../components/Invitations/InvitationsFormModal.vue"
    import StoreService from "../../services/store.service"
    import AccountService from "../../services/account.service"
    import RoleService from "../../services/role.service"
    import InvitesService from "../../services/invites.service"
    import {mapGetters} from "vuex";
    import AdminDashboardUsers from "@/pages/AdminDashboard/AdminDashboardUsers";

    export default {
        name: 'AdminDashboardInvitations',
        mixins: [DeepFilterMixin],

        data() {

            return {
                invitedUsers: [],
                loadingSpinner: false,
                showInvitationFormModal: false,
                inviteForm: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    role_id: '',
                    store_id: '',
                    account_id: ''
                },
                roles: [],
                stores: [],
                filteredStores: [],
                accounts: [],
                filterUserString: '',
                invitation: null,
                dialogKey: 0,
                showNewInvitationModal:false
            }
        },

        components: {
            InvitationFormModal,
			AdminDashboardUsers
        },

        validations: {
            inviteForm: {
                first_name: {required},
                last_name: {required},
                email: {required, email},
                role_id: {required, integer},
                store_id: {
                    required: requiredIf(function(){
                        return this.inviteForm.role_id != 1
                }), integer},
                account_id: {
                    required: requiredIf(function(){
                        return this.inviteForm.role_id == 1
                }), integer},
            }
        },

        computed: {

            filteredInvitedUsers() {

                return this.invitedUsers.filter(x => this.filterFunction(x, this.filterUserString));
            },

            ...mapGetters(
                {
					userRole: 'auth/currentUserRole',
                },
            ),

			isIndividualTrainer() {

				return this.userRole == 2 && this.currentUser.account.is_trainer;
			},
        },

        methods: {

            setPageName() {

                this.$store.dispatch('changePageName', { pageNameString: 'Invitations'});
            },

            resendInvite(id){

                this.$axios.post(`/invites/${id}`)
                    .then(() => {

                        this.$store.dispatch('showSnackbarMessage',
                            {message: 'This user was invited again!', duration: 6000,  mode: 'success'})
                    })
                    .catch((error) => {

                        this.showSnackbar(error, 4000);
                    });
            },

            inviteUser() {

                if(this.userRole > 1){
                    this.inviteForm.account_id = this.$store.getters['auth/getCurrentUser'].account_id;
                }

                this.$axios.post('/invites', {
                    first_name: this.inviteForm.first_name,
                    last_name: this.inviteForm.last_name,
                    email: this.inviteForm.email,
                    role_id: this.inviteForm.role_id,
                    account_id: this.inviteForm.account_id,
                    store_id: this.inviteForm.store_id
                    })
                    .then(() => {

                      this.getAllInvitedUsers();

                      this.inviteForm.first_name = '';
                      this.inviteForm.last_name = '';
                      this.inviteForm.email = '';
                      this.inviteForm.role_id = '';
                      this.inviteForm.account_id = '';
                      this.inviteForm.store_id = '';

                      this.$v.$reset();

                      this.$store.dispatch('showSnackbarMessage',
                          {message: 'User was invited!', duration: 6000, mode: 'success'})
                    })
                    .catch((error) => {

                        let errMsg = error.response.data;

                        this.$store.dispatch('showSnackbarMessage',
                            {message: errMsg, duration: 6000,  mode: 'fail'})
                    });
            },

            getAllInvitedUsers() {

                InvitesService.getAllInvited()
                    .then((res) => {

                        this.invitedUsers = res;
                    })
                    .catch(() => {

                        this.$store.dispatch('showSnackbarMessage', {message: 'Failed. Please, try again.',
                            duration: 8000,
                            mode: 'fail'});
                    });
            },

            getAllRoles() {

                RoleService.getAll()
                    .then((res) => {
                        this.roles = res.filter(role => role.id > this.userRole && role.id !== 4);
                    })
                    .catch(() => {

                        this.$store.dispatch('showSnackbarMessage', {message: 'Failed. Please, try again.',
                            duration: 8000,
                            mode: 'fail'});
                    });
            },

            getAllStores(){

                StoreService.getStores()
                    .then((res) => {

                      this.stores = res;
                      this.filteredStores = res;

                    })
                    .catch(() => {

//                        this.$store.dispatch('showSnackbarMessage', {message: 'Failed. Please, try again.',
//                                duration: 8000,
//                                mode: 'fail'});
                    });
            },

            getAllAccounts(){

                if (this.userRole == 1) {

                    AccountService.getAccounts()
                        .then((res) => {

                            this.accounts = res;
                        })
                        .catch(() => {

                            this.$store.dispatch('showSnackbarMessage', {message: 'Failed. Please, try again.', duration: 8000, mode: 'fail'});
                        });
                } else {

                    this.inviteForm.account_id = this.$store.getters['auth/getCurrentUser'].account_id;
                }
            },

            getCurrentInvitation(id) {

                let invitation = this.invitedUsers.find(x => x.id === id)
                this.invitation = Object.assign({}, invitation);
            },

            openEditInviteModal(id) {

                this.getCurrentInvitation(id);
                this.dialogKey++;
                this.showInvitationFormModal = true;
            },

            setStoreAndAccount(value) {

                this.inviteForm.store_id = value;
                this.inviteForm.account_id = value;
            }
        },

        watch: {

            'inviteForm.account_id': function(value){

                if(this.userRole === 1) {
                    if (value) {
                        this.filteredStores = this.stores.filter(function (obj) {
                            return obj.account_id == value;
                        });
                    } else {
                        this.filteredStores = [];
                    }
                }
            },

            'inviteForm.role_id': function(value){

                if (value === 1) {

                    this.setStoreAndAccount(1);
                }
            }
        },

        created(){

            this.getAllRoles();
            this.getAllStores();
            this.getAllAccounts();
            this.setPageName();
            this.getAllInvitedUsers();
        }
    }
</script>

<style lang="scss" scoped>

    .invite-form {
        width: 100%;

        @include respond(medium) {
            display: block;
        }

        .input-field {
            margin-right: 10px;
            width: 25%;

            @include respond(medium) {
                margin-right: 0;
                width: 100%
            }
        }

        .select-field {

            @include respond(medium) {
                margin-right: 0;
                width: 100%
            }

            width: 20%;
            margin-right: 10px;
        }

        .invite-form__submit-btn{
                margin-bottom: 80px;
        }
    }

    .invites-filter-cont{
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: left;width: 100%;
        gap: 15px;

        .filter-input {
            width: 25%;

            @include respond(small) {
                width: 50%
            }
        }
    }

    .invited-user{
        display: flex;
        justify-content: space-between;
	    padding: 0px 0px 0px 10px;
        align-items: center;
        height: 60px;
	    background-color: #212121;
        margin-bottom: 3px;
        gap: 5px;
        cursor: pointer;
        transition: all .2s ease;

        @include respond(small) {
            flex-direction: column;
            text-align: left;
        }

        .accepted-users{
            color: #82ff9e;
        }

        .user-email{
        color: rgba(255, 255, 255, 0.7);
        }

        &:hover {
            background-color: #636262;
            transform: scaleX(1.02);
        }

        .invited {
             &__edit-btn {
                flex: 20;
                font-weight: normal;
                text-align: right;
                 &:hover {
                    cursor: pointer;
                 }
             }
         }

    }

    .store-empty-error{
        color: red;
    }

    ::v-deep .control-field__error{
        bottom: 55px!important;
        position: absolute!important;
    }

    .user-resend:hover{
        color: #56638a;
        cursor: pointer;
    }
    .form-buttons {
        margin-top: 20px;
        // justify-content: space-around;
    }
    #newInvitation{
        width: 215px;
        height: 45px;
        margin-left: 0;
    }
    .User-management-header{
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: left;
        margin-bottom: 20px;gap: 20px;
    }
    .search-box{
        align-items: center;
        background-color: var(--licorice);
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: center;
        width: fit-content;
        font-size: 10px;
        @media only screen and (max-width: 481px) {
           width: 170px;
        };
    }
</style>
