<template>
  <div :class="[`flex-select`,className]">
    <div class="flex_-select">
      <div class="flex_-select-item" @click="$emit('changeFlex',1,index)">
        <flexLevels/>
        <div class="text">
          <div class="stretch  figtree-medium-white-12px">Stretch</div>
        </div>
      </div>

      <div class="flex_-select-item" @click="$emit('changeFlex',2,index)">
        <flexLevels :className="flexLevels2Data.className"/>
        <div class="text">
          <div class="warm-up-cool-down figtree-medium-white-12px">Warm Up/Cool Down</div>
        </div>
      </div>

      <div class="flex_-select-item" @click="$emit('changeFlex',3,index)">
        <flexLevels :className="flexLevels3Data.className"/>
        <div class="text">
          <div class="stretch-training figtree-medium-white-12px">Strength Training</div>
        </div>
      </div>
      <div class="flex_-select-item" @click="$emit('changeFlex',4,index)">
        <flexLevels :className="flexLevels4Data.className"/>
        <div class="text">
          <div class="cardio figtree-medium-white-12px">Cardio</div>
        </div>
      </div>
      <div class="flex_-select-item" @click="$emit('changeFlex',5,index)">
        <flexLevels :className="flexLevels5Data.className"/>
        <div class="text">
          <div class="sprint figtree-medium-white-12px">Sprint</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flexLevels from "@/components/TrainerDashboard/flexLevels";

export default {
  name: "FlexSelect",
  components: {
    flexLevels,
  },
  data: () => ({

    flexLevels2Data: {
      className: "warm-up",
    },

    flexLevels3Data: {
      className: "stretch-training-1",
    },

    flexLevels4Data: {
      className: "cardio-1",
    },

    flexLevels5Data: {
      className: "sprint-1",
    },
  }),
  props: {
    index: {
      type: Number,
      default: 0
    },
    className: {
      type: String,
      default: ''
    }
  },
};
</script>

<style>
.flex-select {
  align-items: flex-start;
  border: 1px none;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  position: absolute;
  right: 23%;
  z-index: 10;
  max-height: 50px;

}

@media only screen and (min-width: 1440px) {
  .sections {
    right: 40%;
  }
}

@media only screen and (max-width: 425px) {
  .sections {
    right: 16%;
  }
}

.flex_-select {
  align-items: flex-start;
  background-color: var(--onyx);
  border-radius: 5px;
  box-shadow: 0px 4px 4px #00000040;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
}

.flex_-select-item {
  align-items: center;
  display: flex;
  padding: 0px 12px;
  position: relative;
  width: 100%;
}

.text {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  padding: 10px 10px 10px 12px;
  width: fit-content;
}

.stretch,
.warm-up-cool-down,
.stretch-training,
.cardio,
.sprint {
  letter-spacing: -0.13px;
  line-height: 18px;
  margin-top: -1px;
  white-space: nowrap;
  width: fit-content;
}

.frame-385 {
  align-items: center;
  display: flex;
  padding: 0px 12px;
  position: relative;
  width: fit-content;
}

.flex_-select-item:hover {
  background-color: var(--cape-cod);
  cursor: pointer;
}

</style>
