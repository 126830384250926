import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import middlewarePipeline from './middlewarePipeline'

Vue.use(VueRouter)

import AppContent from '../AppContent.vue';

import AuthLogin from '@/pages/Auth/AuthLogin.vue'
import AuthRegister from '@/pages/Auth/AuthRegister.vue'
import AuthGuestScreenLogin from '@/pages/Auth/AuthGuestScreenLogin.vue'
import CustomerDashboardUpcomingClasses from '@/pages/CustomerDashboard/CustomerDashboardUpcomingClasses.vue'
import AuthResetPassword from '@/pages/Auth/AuthResetPassword.vue'
import AuthConfirmationCode from '@/pages/Auth/AuthConfirmationCode.vue'
import AdminDashboardInvitations from '@/pages/AdminDashboard/AdminDashboardInvitations.vue'
import StoresDashboardStores from '@/pages/StoresDashboard/StoresDashboardStores.vue'
import StoresDashboardSingleStore from '@/pages/StoresDashboard/StoresDashboardSingleStore.vue'
import ClassesDashboard from '@/pages/ClassesDashboard/ClassesDashboard.vue'
import ClassesDashboardSingleClassDefinition from "../pages/ClassesDashboard/ClassesDashboardSingleClassDefinition";
import RoomsDashboard from '@/pages/RoomsDashboard/RoomsDashboard.vue'
import PeriodsDashboard from '@/pages/Periods/PeriodsDashboard.vue'
import SongsDashboard from '@/pages/Songs/SongsDashboard.vue'
import MusicEngineSettings from '@/pages/MusicEngineSettings/MusicEngineSettings.vue'
import SectionsDashboard from '@/pages/Sections/SectionsDashboard.vue'
import AccountsDashboard from '@/pages/Accounts/AccountsDashboard.vue'
import IntervalTypesDashboard from '@/pages/IntervalTypes/IntervalTypesDashboard.vue'
import ArchivedBlueprints from '@/pages/TemplateDashboard/TemplatesArchivedDashboard.vue'
import DeletedBlueprints from '@/pages/TemplateDashboard/TemplatesDeletedDashboard.vue'

import AppReports from "@/pages/ReportDashboard/AppReports.vue"
import ReportsDashboard from '@/pages/ReportDashboard/ReportsDashboard.vue'
import ReportsAverageLifetimeCustomerValue from '@/pages/ReportDashboard/ReportsAverageLifetimeCustomerValue/ReportsAverageLifetimeCustomerValue.vue'
import ReportsAverageUsageByUser from '@/pages/ReportDashboard/ReportsAverageUsageByUser/ReportsAverageUsageByUser'
import ReportsAverageUsageByStore from '@/pages/ReportDashboard/ReportsAverageUsageByStore/ReportsAverageUsageByStore'
import ReportsStoreRetention from '@/pages/ReportDashboard/ReportsStoreRetention/ReportsStoreRetention'
import ReportsTrialModeConversion from '@/pages/ReportDashboard/ReportsTrialModeConversion/ReportsTrialModeConversion'

import MarketingDashboard from '@/pages/MarketingDashboard/MarketingDashboard'
import MarketingLandingPage from '@/pages/MarketingDashboard/MarketingLandingPage/MarketingLandingPage.vue'

import AboutDashboard from "../pages/AboutDashboard/AboutDashboard";
import NewsDashboard from "../pages/NewsDashboard/NewsDashboard";
import LegalDashboard from "../pages/LegalDashboard/LegalDashboard";
import HowToDashboard from "../pages/HowToDashboard/HowToDashboard";
import Subscriptions from "../pages/Subscriptions/Subscriptions.vue";
import Cards from "../pages/Cards/Cards.vue";

import PageNotFound from '../pages/PageNotFound';
import MissingSubscriptionPage from '../pages/MissingSubscriptionPage';

import TemplateDashboard from '@/pages/TemplateDashboard/TemplateDashboard.vue'
import Period from '@/pages/Periods/Period.vue'

import auth from './middleware/auth'
import guest from './middleware/guest'
import accountTrainerCheck from "@/router/middleware/accounts";

import ContactDashboard from "../pages/ContactDashboard/ContactDashboard";
import rolesCheck from './middleware/roles'
import subscriptionCheck from "./middleware/subscription";
import facilityCheck from "./middleware/facility";

import { roles } from '@/constants'
import AdminDashboardSingleUserDefinition from "../pages/AdminDashboard/UserPage";
import SearchesDashboard from "../pages/Searches/SearchesDashboard";
import AccountPage from "../pages/Accounts/AccountPage";
import PrivacyPolicy from "../pages/Privacy/PrivacyPolicy";
import TermsOfService from "../pages/TermsOfService/TermsOfService";
import GenresDashboard from "../pages/GenresDashboard/GenresDashboard";
import TemplateDashboardPlaylist from "@/pages/TemplateDashboard/TemplateDashboardPlaylist";
import PlaylistsDashboard from "@/pages/Playlists/PlaylistsDashboard";
import AccountInfoDashboard from "@/pages/Accounts/AccountInfoDashboard";
import PlaylistsDeletedDashboard from "@/pages/Playlists/PlaylistsDeletedDashboard";
import PlaylistsArchivedDashboard from "@/pages/Playlists/PlaylistsArchivedDashboard";
import AppleMusic from "../pages/AppleMusic";
import appleMusicCheck from "./middleware/appleMusicCheck";
import resetPasswordCheck from "./middleware/resetPasswordCheck";
import LegalText from "../pages/LegalDashboard/LegalText";

const routes = [
    {
        path: '',
        component: AppContent,
        children: [
            {
                path: '',
                redirect: '/dashboard',
                meta: {
                    middleware: [auth, subscriptionCheck, appleMusicCheck]
                }
            },
            {
                path: '/dashboard',
                component: TemplateDashboard,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER,
                        roles.INVITED_TRAINER
                    ]
                }
            },
            {
                path: '/account/',
                component: AccountPage,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, facilityCheck, accountTrainerCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER,
                        roles.INVITED_TRAINER
                    ]
                }
            },
            {
                path: '/account-info',
                component: AccountInfoDashboard,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER,
                        roles.INVITED_TRAINER
                    ]
                }
            },
            {
                path: '/account/:id',
                component: AccountPage,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, accountTrainerCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN
                    ]
                }
            },
            {
                path: '/accounts/',
                component: AccountsDashboard,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, accountTrainerCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN
                    ]
                }
            },
            {
                path: '/cards',
                component: Cards,
                meta: {
                    middleware: [auth, rolesCheck,resetPasswordCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.INDIVIDUAL_TRAINER
                    ]
                }
            },
            {
                path: '/apple-music',
                component: AppleMusic,
                meta: {
                    middleware: [auth, rolesCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER,
                        roles.INVITED_TRAINER
                    ]
                }
            },
            {
                path: '/subscriptions',
                component: Subscriptions,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER
                    ]
                }
            },
            {
                path: '/search',
                component: SearchesDashboard,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, accountTrainerCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER,
                        roles.INVITED_TRAINER
                    ]
                }
            },
            {
                path: '/periods/:id',
                component: Period,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER,
                        roles.INVITED_TRAINER
                    ]
                }
            },
            {
                path: '/songs',
                component: SongsDashboard,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, accountTrainerCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN
                    ]
                }
            },
            {
                path: '/music-engine-settings',
                component: MusicEngineSettings,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, accountTrainerCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN
                    ]
                }
            },
            {
                path: '/blueprints/:id/sections',
                component: SectionsDashboard,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, accountTrainerCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER,
                        roles.INVITED_TRAINER
                    ]
                }
            },
            {
                path: '/genres',
                component: GenresDashboard,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, accountTrainerCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN
                    ]
                }
            },
            {
                path: '/upcoming-classes',
                component: CustomerDashboardUpcomingClasses,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, accountTrainerCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER,
                        roles.INVITED_TRAINER
                    ]
                }
            },
            {
                path: '/classes',
                component: ClassesDashboard,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, accountTrainerCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER,
                        roles.INVITED_TRAINER
                    ]
                }
            },
            {
                path: '/classes/:id',
                component: ClassesDashboardSingleClassDefinition,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, accountTrainerCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER,
                        roles.INVITED_TRAINER
                    ]
                }
            },
            {
                path: '/rooms',
                component: RoomsDashboard,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, accountTrainerCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER,
                        roles.INVITED_TRAINER
                    ]
                }
            },
            {
                path: '/users',
                component: AdminDashboardInvitations,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, accountTrainerCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER,
                        roles.INVITED_TRAINER
                    ]
                }
            },
            {
                path: '/users/:id',
                component: AdminDashboardSingleUserDefinition,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, accountTrainerCheck , facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER,
                        roles.INVITED_TRAINER
                    ]
                }
            },
            {
                path: '/deleted-blueprints/:id',
                component: DeletedBlueprints,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, accountTrainerCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER,
                        roles.INVITED_TRAINER
                    ]
                }
            },
            {
                path: '/archived-blueprints/:id',
                component: ArchivedBlueprints,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, accountTrainerCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER,
                        roles.INVITED_TRAINER
                    ]
                }
            },
            {
                path: '/deleted-playlists/:id',
                component: PlaylistsDeletedDashboard,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, accountTrainerCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER,
                        roles.INVITED_TRAINER
                    ]
                }
            },
            {
                path: '/archived-playlists/:id',
                component: PlaylistsArchivedDashboard,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, accountTrainerCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER,
                        roles.INVITED_TRAINER
                    ]
                }
            },
            {
                path: '/invitations',
                component: AdminDashboardInvitations,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, accountTrainerCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER,
                        roles.INVITED_TRAINER
                    ]
                }
            },
            {
                path: 'blueprints/:id/periods',
                component: PeriodsDashboard,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER,
                        roles.INVITED_TRAINER
                    ]
                }
            },
            {
                path: 'blueprints/:id/playlists',
                component: PlaylistsDashboard,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER,
                        roles.INVITED_TRAINER
                    ]
                }
            },
            {
                path: 'playlists/:id',
                component: TemplateDashboardPlaylist,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER,
                        roles.INVITED_TRAINER
                    ]
                }
            },
            {
                path: '/interval-types',
                component: IntervalTypesDashboard,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER,
                        roles.INVITED_TRAINER
                    ]
                }
            },
            {
                path: '/stores',
                component: StoresDashboardStores,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, accountTrainerCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER
                    ]
                }
            },
            {
                path: '/stores/:id',
                component: StoresDashboardSingleStore,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, accountTrainerCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER
                    ]
                }
            },
            {
                path: 'reports',
                component: AppReports,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, accountTrainerCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER,
                        roles.INVITED_TRAINER
                    ]
                },

                children: [
                    {
                        path: '',
                        component: ReportsDashboard,
                        meta: {
                            // middleware: [auth]
                        }
                    },
                    {
                        path: 'average-lifetime-customer-value',
                        component: ReportsAverageLifetimeCustomerValue,
                        meta: {
                            // middleware: [auth]
                        }
                    },
                    {
                        path: 'average-usage-by-user',
                        component: ReportsAverageUsageByUser,
                        meta: {
                            // middleware: [auth]
                        }
                    },
                    {
                        path: 'average-usage-by-store',
                        component: ReportsAverageUsageByStore,
                        meta: {
                            // middleware: [auth]
                        }
                    },
                    {
                        path: 'store-retention',
                        component: ReportsStoreRetention,
                        meta: {
                            // middleware: [auth]
                        }
                    },
                    {
                        path: 'trial-mode-conversion',
                        component: ReportsTrialModeConversion,
                        meta: {
                            //, middleware: [auth]
                        }
                    }

                ]
            },
            {
                path: 'reports',
                component: ReportsDashboard,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, accountTrainerCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER,
                        roles.INVITED_TRAINER
                    ]
                }
            },
            {
                path: 'marketing',
                component: MarketingDashboard,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, accountTrainerCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN
                    ]
                }
            },
            {
                path: 'marketing/:id',
                component: MarketingLandingPage,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, accountTrainerCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN
                    ]
                }
            },
            {
                path: 'contact',
                component: ContactDashboard,
                meta: {
                    middleware: [auth, subscriptionCheck, accountTrainerCheck, facilityCheck,appleMusicCheck]
                }
            },
            {
                path: 'about',
                component: AboutDashboard,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, accountTrainerCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER,
                        roles.INVITED_TRAINER
                    ]
                }
            },
            {
                path: 'news',
                component: NewsDashboard,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, accountTrainerCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER,
                        roles.INVITED_TRAINER
                    ]
                }
            },
            {
                path: 'legal',
                component: LegalDashboard,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, accountTrainerCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER,
                        roles.INVITED_TRAINER
                    ]
                }
            },
            {
                path: 'how-to',
                component: HowToDashboard,
                meta: {
                    middleware: [auth, rolesCheck, subscriptionCheck, accountTrainerCheck, facilityCheck,appleMusicCheck],
                    roles: [
                        roles.ADMIN,
                        roles.FACILITY_OWNER,
                        roles.FACILITY_MANAGER,
                        roles.INDIVIDUAL_TRAINER,
                        roles.INVITED_TRAINER
                    ]
                }
            },
            {
                path: 'myshopify',
                beforeEnter() {
                    window.open("https://reflex-radio.myshopify.com", '_blank');
                }
            },
            {
                path: 'myyoutube',
                beforeEnter() {
                    window.open("https://reflex-radio.com/pages/tips-tricks-and-troubleshooting", '_blank');
                }
            },

        ]
    },

    {
        path: '/login',
        name: 'Login',
        component: AuthLogin,
        meta: {
            middleware: [guest]
        }
    },
    {
        path: '/terms-and-conditions',
        name: 'terms-and-conditions',
        component: LegalText,
        meta: {
            middleware: [guest]
        }
    },
    {
        path: '/register/:token?',
        name: 'Register',
        component: AuthRegister,
        meta: {
            middleware: [guest]
        }
    },
    {
        path: '/guest-screen-login',
        name: 'GuestScreenLogin',
        component: AuthGuestScreenLogin,
        meta: {
            middleware: [guest]
        }
    },
    {
        path: '/reset-password/:token',
        name: 'ResetPassword',
        component: AuthResetPassword,
        meta: {
            middleware: [guest]
        }
    },
    {
        path: '/reset-password',
        component: AuthResetPassword,
        meta: {
            middleware: [auth, rolesCheck],
            roles: [
                roles.ADMIN,
                roles.FACILITY_OWNER,
                roles.FACILITY_MANAGER,
                roles.INDIVIDUAL_TRAINER,
                roles.INVITED_TRAINER
            ]
        }
    },
    {
        path: '/confirm-code',
        name: 'ConfirmationCode',
        component: AuthConfirmationCode,
        meta: {
            middleware: [guest]
        }
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        meta: {
            middleware: [guest]
        }
    },
    {
        path: '/terms-of-service',
        name: 'TermsОfService',
        component: TermsOfService,
        meta: {
            middleware: [guest]
        }
    },

    { path: '/404', component: PageNotFound },
    { path: '/subscription-missing', component: MissingSubscriptionPage },
    { path: '*', redirect: '/404' },
];

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

    if (!to.meta.middleware) {
        return next()
    }

    const middleware = to.meta.middleware;

    const context = {
        to,
        from,
        next,
        store
    };

    return middleware[0]({
        ...context,
        nextMiddleware: middlewarePipeline(context, middleware, 1)
    });
});

export default router
