<template>
        <div class="component-wrap">
            <p class="error-text">
				This gym no longer has an active subscription with us! ....I know, we were shocked too!
			</p>
        </div>
</template>

<script>

    export default {
        name: 'MissingSubscriptionPage'
    }
</script>

<style lang="scss" scoped>

    .component-wrap {
        margin: 70px auto;
        text-align: center;
        padding-top: 5em;
    }
    .error-text {
        font-size: 2.6rem;
        line-height: 5rem;
        margin-bottom: 1.6rem;
        color: white;
    }
</style>