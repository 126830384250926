<template>
    <md-dialog :md-active.sync="showModal" :md-click-outside-to-close="true">
        <md-dialog-title style="font-size: 25px;" >{{genre.name}}</md-dialog-title>
                <div class="md-layout-item md-size-100 md-xsmall-size-100" >
                    <ul style="margin-bottom:20px">
                        <p  class="children-style" v-if="(ChildsGenre.filter(parent => parent.parent_id === this.genre.id)).length==0">no children for this genre yet</p>

                        <li  class="children-style" v-else v-for="(genre, index) in ChildsGenre.filter(parent => parent.parent_id === this.genre.id)" :key="index" :value="genre.id">{{genre.name}}</li>
                    </ul>
                   
                       
                        <md-button
                        class="dialog__btn dialog__btn--danger"
                        id="closeBtn"
                        @click="closeDialog()"
                    >
                        close
                    </md-button>
                  </div>
                
    </md-dialog>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
import { array } from "yargs";
    import GenreService from "../../services/genre.service";

    export default {
        name: "GenresChildsModal",

        props: {

            showModal: {
                type: Boolean,
                default: false
            },
           
            genreProp: {
                type: Object,
                default: {
                    name: ''
                }
            },
           
            ChildsGenre:{
                type: [Array, Object],
            }
        },

        data: () => ({
            genre: {
                name: ''
            },
            
            isEditFormModal: false,
            parentGenre:null,
            selectedVisibility :null,
            wholeGenres:[]
        }),

        validations: {
            genre: {
                name: {required},
            }
        },

        methods: {

            closeDialog() {
                this.$emit('update:showModal', false);
            },

            filter(){
               return this.wholeGenres.filter(parent => parent.id === this.genre.parent_id)
            },
        },

        created() {

            if (this.genreProp) {
                this.isEditFormModal = true;
                this.genre = this.genreProp;
            }
            
            if (this.ChildsGenre) {
                this.wholeGenres = this.ChildsGenre;
            }
        },

    }
</script>

<style lang="scss" scoped>
    @include dialogSettings();

    .dialog__hint {
        margin-bottom: 16px;
    }

    .md-dialog {

        .choose-file-btn {
            width: 30%;
        }

        .upload-cont {
            text-align: center;
        }

        .file-upload-label {
            font-size: 2rem;
        }
    }

    .form-buttons {
        margin-top: 20px;
        justify-content: space-around;
    }
    .select-centered {
        margin: 0 auto;
        width: 250px;
        height: 35px;
    }
    .children-style{
        border: .5px #49496c solid;
        padding: 10px;
        font-size: 18px;
    }

</style>