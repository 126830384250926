<template>
    <div class="marketing-wrap">
        <div class="marketing md-raised md-layout">
            <p class="marketing__data md-layout-item md-xlarge-size-25 md-large-size-20 md-medium-size-100 md-small-size-100 md-xsmall-size-100">Campaign Name</p>
            <p class="marketing__data md-layout-item md-xlarge-size-25 md-large-size-20 md-medium-size-100 md-small-size-100 md-xsmall-size-100"><span>Started:</span>09/09/2019</p>
            <p class="marketing__data md-layout-item md-xlarge-size-25 md-large-size-20 md-medium-size-100 md-small-size-100 md-xsmall-size-100"><span>Visitors:</span>2457</p>

            <div class="landing-page__action md-layout-item md-medium-size-100 md-small-size-100 md-xsmall-size-100">
                <md-button
                    class="md-raised md-primary md-raised md-theme-light">
                    Pause</md-button>
            </div>

            <div class=" landing-page__action md-layout-item  md-medium-size-100 md-small-size-100 md-xsmall-size-100">
                <md-button
                    class="md-raised md-primary md-raised md-theme-light">
                    Edit</md-button>
            </div>

            <div class="marketing__remove-btn landing-page__action">
                <md-button class="md-icon-button">
                    <md-icon>delete</md-icon>
                </md-button>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SingleLanfingPageBlock'
}
</script>

<style lang="scss" scoped>

    @include tableStyle(marketing-wrap, marketing);

    .marketing-wrap{
        padding: 0;

        .marketing {
            font-size: 1.6rem;
            padding: 16px 24px;
            margin-bottom: 3px;

            &__remove-btn {
                padding: 3px;
                margin: -3px 0 -3px auto;
            }
        }
    }

</style>
