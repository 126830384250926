import $axios from '../plugins/axios'

class PeriodService {

    getPeriods(vuexContext) {
        
        return $axios.get('/periods')
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getIntervalsByPeriod(id) {

        return $axios.get(`/periods/${id}/intervals`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    deletePeriod(id) {

        return $axios.delete(`/periods/${id}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    createPeriod(period) {

        return $axios.post('/periods', period)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    editPeriod(data) {

        return $axios.put(`/periods/${data.id}`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    reorderPeriod({ data, id }) {

        return $axios.put(`/templates/${id}/periods/order`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getPeriod(id) {

        return $axios.get(`/periods/${id}`)

            .then(res => {
                return res.data;
            })
            .catch((error) => {
                console.log(error)
                throw error;

            })
    }

    getAllPeriodsForCurrentBluePrint(id) {

        return $axios.get(`/templates/${id}/periods`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }
}

export default new PeriodService();