<template>
	<div
		class="custom-input"
		:class="{'valid': energy != 0 || energy != 0}"
		tabindex="0"
		ref="input"
		@click="showDurationOptions = true"
		@mouseleave="showDurationOptions = false"
		@click.stop="$refs.input.focus()">
        
        <span
	        class="custom-input__display"
	        @click="showDurationOptions = !showDurationOptions"
        >
            Flex (Energy): {{ energy }}
        </span>

		<div class="custom-input__options"
		     :class="{
                'active': showDurationOptions,
                'left': offsetSide === 'left',
                'right': offsetSide === 'right'}">

			<ul class="custom-input__options-list">
				<li class="custom-input__option"
				    v-for="(option, index) in options"
				    :key="index + 'key'"
				    @click="energy = option"
				>
					{{ ' Flex (Energy): ' + option }}
				</li>
			</ul>
		</div>

		<div class="custom-input__controls energy-control">
			<button class="energy-up-button"
			        @click.prevent.stop=""
			        @mousedown.prevent.stop="plusOnHold()"
			        @mouseup.prevent.stop="stopHold()">
				<md-icon>arrow_drop_up</md-icon>
			</button>
			<button class="energy-down-button"
			        @click.prevent.stop=""
			        @mousedown.prevent.stop="minusOnHold()"
			        @mouseup.prevent.stop="stopHold()">
				<md-icon>arrow_drop_down</md-icon>
			</button>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'AppTrackDurationInput',
		props: {
			energyData: {
				type: Number,
				default: 1
			},
			showDurationOptionsProp: {
				type: Boolean,
				default: false
			},
			offsetSide: {
				type: String,
				default: 'right'
			}
		},

		data: () => ({
			options: [1, 2, 3, 4, 5]
		}),
		computed: {

			energy: {

				set(newValue) {

					this.$emit('update:energyData', newValue);
					return newValue;
				},

				get() {
					return this.energyData;
				}
			},

			showDurationOptions: {

				set(newValue) {

					this.$emit('update:showDurationOptionsProp', newValue);
					return newValue;
				},

				get() {

					return this.showDurationOptionsProp;
				}
			}
		},
		methods: {

			plusEnergy() {

				if (this.energy + 1 < 5) {

					this.energy = this.energy + 1;
				} else {

					this.energy = 5;
				}
			},

			minusEnergy() {

				if (this.energy - 1 > 0) {

					this.energy = this.energy - 1;
				} else {

					this.energy = 1;
				}
			},

			plusOnHold() {

				this.intervalId = setInterval(this.plusEnergy, 150, this.plusEnergy());
			},

			minusOnHold() {

				this.intervalId = setInterval(this.minusEnergy, 150, this.minusEnergy());
			},

			stopHold() {

				clearInterval(this.intervalId);
			}
		}
	}
</script>

<style lang="scss" scoped>
	@include customSelectInput();
</style>
