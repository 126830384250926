const state = () => ({
    playlists: [],
    isUndoActive: false,
    tempPlaylists: []
});

const getters = {

    getPlaylists(state) {
        return state.playlists;
    },

    getIsUndoActive(state) {
        return state.isUndoActive;
    },

    getTempPlaylists(state) {
        return state.tempPlaylists;
    },
};

const mutations = {

    setPlaylists(state, playlists) {
        state.playlists = playlists.sort((p1, p2) => p2.id - p1.id);
    },

    setIsUndoActive(state, data) {
        state.isUndoActive = data;
    },

    setTempPlaylists(state, playlists) {

        if (playlists){

            state.tempPlaylists = playlists.sort((p1, p2) => p2.id - p1.id);
        } else {

            state.tempPlaylists = '';
        }
    },
}

const actions = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}