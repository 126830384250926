const helpers = {
    getSafe(fn, defaultVal = null) {
        try {
            return fn();
        } catch (_) {
            return defaultVal;
        }
    }
};

export default helpers;
