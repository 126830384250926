<template>
	<div class="component-wrap">


		<div v-if="store" class="deleted-header">
            <div style="flex:30" >
                <div class="figtree-medium-white-32px">{{userRole === 1 ? 'Archived Blueprints' : store.name + ' Archived Blueprints'}}</div>
            </div>
            <div style="flex:30">
                <p class="figtree-medium-white-16px">
                  If you archive a Blueprint or Playlist, it will remain in the Archived folder forever. <br />Find the Blueprint or Playlist below and click the Restore button to bring it back to your Dashboard.
                 </p>
            </div>
        </div>

		<AppEmptyStateComponent message="There are no archived Blueprints at this time."
		                        v-if="bluePrints.length == 1 && !loadingSpinner"/>
		<div v-if="store && bluePrints.length > 1" class="blueprint-container">
            <div class="blueprint-playlist-table">
                <div style="flex: 5;" class="figtree-medium-white-22px">Blueprints</div>
                <div style="flex: 90;">
                    <div class="list-group-header figtree-semi-bold-pink-swan-18px deleted-blueprints">
						<div style="flex:3;"></div>
						<div style="flex:37">Title</div>
                        <div style="flex:15">Playlists</div>
                        <div class="actions-btn" :style="{minWidth: isMobile ? 'unset' : '100px'}"></div>
                        <div class="actions-btn" :style="{minWidth: isMobile ? 'unset' : '100px'}"></div>
                        <div class="actions-btn" :style="{minWidth: isMobile ? 'unset' : '100px'}"></div>

                    </div>

                    <div class="list-group figtree-semi-bold-pink-swan-18px deleted-blueprints" 
					@click="goToSingleBluePrint(bluePrint.id)"
					 v-for="(bluePrint, index) in bluePrints" :key="index + '_bluePrints'"
					  v-if="bluePrint.id" :style="`border-left-color: ${bluePrint.color ? bluePrint.color : getRandomColor(index)};`">
						<div style="flex:3;display: flex;flex-direction: column;gap: 5px;text-align: left;padding: 5px;">
							<div style="flex:40" v-if="bluePrint.playlists && bluePrint.playlists.length > 0 " class="play-button-div" @click.stop="openPlaylistModal(bluePrint.id)">
            					<img style="height: 14px" src="@/assets/images/play.png" alt="Vector"/>
								
							</div>
							<div  style="flex:40;text-align: center;"  v-if="!bluePrint.is_shared">
								<md-icon  style="color: white;font-size: 20px!important;font-weight: normal;">lock_outlined</md-icon>
							</div>
						</div>
                        <div style="flex:37">{{ bluePrint.name }}</div>
                        <div style="flex:15;">{{ bluePrint.playlists.length }} </div>
                        <div class="actions-btn">
                            <div v-if="isMobile"
                                class="figtree-normal-white-18px"
                                @click.stop="editBluePrint(bluePrint.id)"> <md-icon>edit</md-icon>
                            </div>
                            <md-button v-else
                                class="restore-btn figtree-normal-white-18px"
                                id="delete-blueprints-btn"
                                @click.stop="editBluePrint(bluePrint.id)">  Edit
                            </md-button>

                        </div>
						<div class="actions-btn">
                            <div v-if="isMobile"
                                class="figtree-normal-white-18px"
                                @click.stop="unArchiveCurrentBlueprint(bluePrint.id)"> <md-icon>restore</md-icon>
                            </div>
                            <md-button v-else
                                class="restore-btn figtree-normal-white-18px"
                                id="delete-blueprints-btn"
                                @click.stop="unArchiveCurrentBlueprint(bluePrint.id)">  Restore
                            </md-button>

                        </div>
						<div class="actions-btn">
                            <div v-if="isMobile"
                                class="figtree-normal-white-18px"
                                @click.stop="tempRemoveBluePrint(bluePrint.id)"> <md-icon>delete_forever</md-icon>
                            </div>
                            <md-button v-else
                                class="restore-btn figtree-normal-white-18px"
                                id="delete-blueprints-btn"
                                @click.stop="tempRemoveBluePrint(bluePrint.id)">Delete
                            </md-button>

                        </div>



                    </div>
                </div>
			</div>

            </div>
		<AppEmptyStateComponent message="There are no deleted bluePrints to display. as you are from different store" v-if="!store && !loadingSpinner"/>
		<AppSpinnerComponent v-if="loadingSpinner" :loading="loadingSpinner"/>

		<TrainerDashboardBluePrintDialog  v-if="dialogKey"
			@refreshAllBluePrints="getAllBlueprints"
			:showDialog.sync="showDialog"
			:doWeEdit="doWeEdit"
			:blue-print-prop="bluePrintToEdit"
			:key="dialogKey"
		/>

		<BluePrintsPlaylistModal
			v-if="bluePrintPlaylist"
			:show-dialog.sync="showPlaylistModal"
			:playlists-prop="bluePrintPlaylist"
		/>
	</div>
</template>

<script>

	import BluePrintCard from "@/components/BluePrints/BluePrintCard"
	import TrainerDashboardBluePrintDialog from "@/components/TrainerDashboard/TrainerDashboardBluePrintDialog"
	import BluePrintService from '../../services/bluePrint.service';
	import BluePrintsPlaylistModal from "@/components/BluePrints/BluePrintsPlaylistsModal";
  import StoreService from '../../services/store.service';
  import {mapGetters} from "vuex";
  import {RandomColorsMixin} from "../../mixins/RandomColorsMixin";
  import { CheckWindowWidthMixin } from "../../mixins/CheckWindowWidthMixin";

  export default {
		name: 'TemplatesArchivedDashboard',
    mixins: [RandomColorsMixin,CheckWindowWidthMixin],
    data: function () {
      return {
        bluePrints: [],
        tempBluePrints: null,
        bluePrintToEdit: {},
        showPlaylistModal: false,
        bluePrintPlaylist: null,
        loadingSpinner: true,
        showDialog: false,
        dialogKey: 0,
        store: null,
		intervalIdTimeout : null,
		doWeEdit:true
      }
	  
    },

		components: {
			BluePrintCard,
			BluePrintsPlaylistModal,
			TrainerDashboardBluePrintDialog
		},

		async created() {

      this.setPageName();

      await this.getStore();
      this.getAllBlueprints();
      this.setLoadingSpinner(false);

      // this.$root.$on('undoDelete', this.restoreBluePrint);
      //Refresh templates every 5 min
      this.intervalIdTimeout = window.setInterval(() => {

        this.getAllBlueprints();
      }, 300000);
    },

		methods: {
			unArchiveCurrentBlueprint(id) {

				BluePrintService.unArchiveBluePrint(id)
					.then((res) => {

						this.getAllBlueprints();

						this.$store.dispatch('showSnackbarMessage', {
							message: 'The Blueprint was successfully restored to the Dashboard.',
							duration: 3000,
							mode: 'success'
						});
					})
					.catch((err) => {
						this.$store.dispatch('showSnackbarMessage', {
							message: `${err.message}. Please, try again.`,
							mode: 'fail'
						});
					});
			},

			setPageName() {

				this.$store.dispatch('changePageName', {pageNameString: 'Archived Blueprints'});
			},

			async openPlaylistModal(id) {

				await this.getPlaylistsForBlueprint(id);
				this.showPlaylistModal = true;
			},

			getPlaylistsForBlueprint(id) {

				return BluePrintService.getBluePrintPlaylists(id).then((res) => {

					this.bluePrintPlaylist = res;
				}).catch((err) => {

					this.$store.dispatch('showSnackbarMessage', {
						message: `${err.message}. Please, try again.`,
						mode: 'fail',
					});
				});
			},

			// openBuePrintDialog() {
			//
			// 	this.dialogKey++;
			// 	this.bluePrintToEdit = "";
			// 	this.showDialog = true;
			// },

			editBluePrint(bluePrintId) {

				BluePrintService.getSingleBluePrint(bluePrintId)
					.then((res) => {

						this.dialogKey++;
						this.bluePrintToEdit = res;
						this.showDialog = true;
						this.doWeEdit = true;
					})
					.catch((err) => {

						this.$store.dispatch('showSnackbarMessage', {
							message: `${err.message}. Please, try again.`,
							mode: 'fail'
						});
					});
			},

			tempRemoveBluePrint(id) {
        let _this = this;

				this.setLoadingSpinner(true);
				this.bluePrints = this.bluePrints.filter(bluePrint => bluePrint.id != id);
				this.$store.commit('bluePrints/setTempBluePrints', this.bluePrints);

        this.$store.dispatch('showSnackbarMessage', {
          message: 'Last chance to undo the delete.',
          duration: 5000,
          mode: 'success',
          buttonText: 'Undo',
          type: 'bluePrint'
        });

        setTimeout(() => {
          _this.deleteOneElement(id).then(() => {

            this.$emit('setLoadingSpinner', false);
          });
        }, 5000);
			},
      async deleteOneElement(id) {

        await this.deleteIfUndoIsInactive(id);
      },

      deleteBluePrint(id) {

        BluePrintService.deleteBluePrint({id: id})
            .then(() => {
              this.setLoadingSpinner(false);
              this.$store.dispatch('showSnackbarMessage', {
                message: 'The Blueprint was successfully deleted.',
                duration: 3000,
                mode: 'success'
              });
            })
            .catch((err) => {

              this.$store.dispatch('showSnackbarMessage', {
                message: `${err.message}. Please, try again.`,
                mode: 'fail'
              });
            });
      },

      deleteIfUndoIsInactive(id) {

        if (!(this.$store.getters['bluePrints/getIsUndoActive'])) {

          this.deleteBluePrint(id);
        }

        this.$store.commit('bluePrints/setIsUndoActive', false);
      },
			// restoreBluePrint(id) {
			//
			// 	this.$store.commit('bluePrints/setIsUndoActive', true);
			// 	this.getAllBlueprints();
			// 	this.$store.commit('bluePrints/setTempBluePrints', '');
			// 	this.setLoadingSpinner(false);
			//
			// 	this.$store.dispatch('showSnackbarMessage', {
			// 		message: 'The blueprint was successfully restored.',
			// 		duration: 3000,
			// 		mode: 'success'
			// 	});
			// },

			setLoadingSpinner(isLoading) {

				this.loadingSpinner = isLoading;
			},

			getAllBlueprints() {
        const storeId =  this.$route.params.id;
				BluePrintService.getAllArchivedBlueprints(storeId)
					.then((res) => {

						this.bluePrints = res;
						this.setLoadingSpinner(false);
						this.bluePrints.push({});

					})
					.catch((err) => {

						this.$store.dispatch('showSnackbarMessage', {
							message: `${err.message}. Please, try again.`,
							mode: 'fail'
						});
					});

			} ,getStore(){
        const storeId =  this.$route.params.id;
        StoreService.getSingleStore(storeId)
            .then((res) => {
              this.store = res;
            })
            .catch((err) => {
              this.$store.dispatch('showSnackbarMessage', {
                message: `${err.message}. Please, try again.`,
                mode: 'fail'
              });
            });

      },

			goToSingleBluePrint(id) {

				this.$router.push('/blueprints/' + id + '/playlists');
			},

			goBackToDashboard() {

				this.$router.push('/dashboard');
			},

			// turnOffLoadingSpinner() {
			//
			// }
		},
    computed: {

      ...mapGetters(
          {
            userRole: 'auth/currentUserRole',
          },
      ),
    },
		beforeDestroy() {
	        window.clearInterval(this.intervalIdTimeout);
    	}
	}
</script>

<style lang="scss" scoped>
	::v-deep .content-header {
		margin-bottom: 60px !important;
	}

	.content__subtitle {
		text-align: left;
		margin: 5.6rem 0 2.4rem;
	}
	.list-group{
        display: flex;
        align-items: center;
        padding: 0px 0px 0px 10px;
        height: 60px;
        background: #212121;
        margin-bottom: 1px;
        border-left: 2px solid;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
		gap: 5px;

    }
    .list-group-header{
		gap: 5px;

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        height: 56px;
        background: #3A3A3A;
        border-radius: 4px 4px 0px 0px;
        padding: 0px 0px 0px 10px;

    }
    .restore-btn{
        border: 1px solid;
        border-color: var(--cultured-pearl);
        border-radius: 5px;
        width: fit-content;
        height: 40px;
        @media only screen and (max-width: 834px) {
            font-size: var(--font-size-s);
            width: fit-content;
            height: 35px;
        };
    }
    .blueprint-playlist-table{
        flex: 50;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 15px;
        text-align: left;
    }
    .deleted-header{
        display: flex;
        flex-direction: column;
        gap:20px;
        text-align: left;
        width: 100%;
        margin: 40px 0px 30px 0px;
    }
    .blueprint-container{
        display:flex;
        flex-direction: column;
        width: 85%;
        gap: 25px;
        @media only screen and (max-width: 834px) {
            width: 100%;
        };
    }
    .deleted-blueprints{
      @media only screen and (max-width: 834px) {
        font-size: var(--font-size-m);
        };
        @media only screen and (max-width: 520px) {
          font-size: var(--font-size-s);
          };
    }
	.actions-btn{
		flex:15;
		text-align: right;
		@media only screen and (max-width: 481px) {
         flex: 8;
        };
	}
</style>
