import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import $axios from './plugins/axios'
import VueAnalytics from 'vue-analytics'
import moment from 'moment'
import VueCookies from 'vue-cookies'
import VueTour from 'vue-tour'
import VueTheMask from 'vue-the-mask'
import device from "vue-device-detector"
import VueFullscreen from 'vue-fullscreen'

// Vue Material Imports
import {
    MdButton,
    MdTable,
    MdContent,
    MdIcon,
    MdTabs,
    MdDialog,
    MdCheckbox,
    MdList,
    MdApp,
    MdDrawer,
    MdToolbar,
    MdCard,
    MdLayout,
    MdField,
    MdMenu,
    MdTooltip,
    MdSnackbar,
    MdRipple,
    MdDatepicker,
    MdChips,
    MdAutocomplete,
    MdSwitch,

} from 'vue-material/dist/components'
import 'vue-material/dist/vue-material.min.css'

/** Axios plugin settings */
Vue.use({
  install(Vue) {
      Vue.prototype.$axios = $axios
  }
});

/** Vue cookies */
Vue.use(VueCookies)

/** Vue Tour */
require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)

/** Vue Material components */
Vue.use(MdButton);
Vue.use(MdTable);
Vue.use(MdContent);
Vue.use(MdIcon);
Vue.use(MdTabs);
Vue.use(MdDialog);
Vue.use(MdCheckbox);
Vue.use(MdList);
Vue.use(MdApp);
Vue.use(MdDrawer);
Vue.use(MdToolbar);
Vue.use(MdCard);
Vue.use(MdLayout);
Vue.use(MdField);
Vue.use(MdMenu);
Vue.use(MdTooltip);
Vue.use(MdSnackbar);
Vue.use(MdRipple);
Vue.use(MdDatepicker);
Vue.use(MdChips);
Vue.use(MdSwitch);
Vue.use(MdAutocomplete)
Vue.use(VueAnalytics, {
    id: 'UA-170711692-1',
    router
});
Vue.use(VueTheMask);
Vue.use(device);
Vue.use(VueFullscreen)

// Custom Components
import AppInputComponent from './components/Base/AppInputComponent.vue'
import AppHeaderComponent from './components/Base/AppContentHeaderComponent.vue'
import AppSpinnerComponent from './components/Base/AppSpinnerComponent.vue'
import AppEmptyStateComponent from './components/Base/AppEmptyStateComponent.vue'
import AppContentGrid from './components/Base/AppContentGrid.vue'
import AppFloatFabButton from './components/Base/AppFloatFabButton.vue'
import AppTrackDurationInput from './components/Base/AppTrackDurationInput.vue'
import AppTrackBitsInput from './components/Base/AppTrackBitsInput.vue'
import AppTrackTagInput from './components/Base/AppTrackTagInput.vue'
import AppMultiSelectInput from './components/Base/AppMultiSelectInput'
import AppSelectNumberEnergyInput from "./components/Base/AppSelectNumberEnergyInput";
import { ToggleButton } from 'vue-js-toggle-button'

/** Custom Components Use */
Vue.component('AppInputComponent', AppInputComponent);
Vue.component('AppHeaderComponent', AppHeaderComponent);
Vue.component('AppSpinnerComponent', AppSpinnerComponent);
Vue.component('AppEmptyStateComponent', AppEmptyStateComponent);
Vue.component('AppContentGrid', AppContentGrid);
Vue.component('AppFloatFabButton', AppFloatFabButton);
Vue.component('AppTrackDurationInput', AppTrackDurationInput);
Vue.component('AppTrackBitsInput', AppTrackBitsInput);
Vue.component('AppTrackTagInput', AppTrackTagInput);
Vue.component('AppMultiSelectInput', AppMultiSelectInput);
Vue.component('AppSelectNumberEnergyInput', AppSelectNumberEnergyInput);
Vue.component('ToggleButton', ToggleButton)

Vue.component('MdSelect', Vue.options.components.MdSelect.extend({
    methods: {
        isInvalidValue: function isInvalidValue () {
            return this.$el.validity ? this.$el.validity.badInput : this.$el.querySelector('input').validity.badInput
        }
    }
}))

/** Validation Form */
import Vuelidate from 'vuelidate'

Vue.use(Vuelidate);

/** Vue Filter */
Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('MM-DD-YYYY hh:mm')
    }
});

/** Vue Timepicker */
import 'vue2-timepicker/dist/VueTimepicker.css'
import VueTimepicker from 'vue2-timepicker'
Vue.component('VueTimepicker', VueTimepicker);

/** Vue Material Slider */
import VueMaterialSlider from 'vue-material-slider'
Vue.component('VueMaterialSlider', VueMaterialSlider);

/** Custom SCSS file */
import './assets/scss/main.scss'

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
