<template>
    <md-card
        v-bind="$attrs" 
        class="md-theme-light graph-wrap" :ref="'graphWrap' + graphIndex">
        
        <h3 class="graph__name">{{name}}
            <md-button class="md-icon-button graph__menu md-theme-light">
                <md-icon class="md-theme-light">more_horiz</md-icon>
            </md-button>
        </h3>

        <div class="graph__legend" v-if="graphData.length > 1">
            <div class="graph__color"
                v-for="(data, index) in graphData" :key="index + 'data'">
                <span class="graph__color-sample" :style="{'background': data.color}"></span>
                <span class="graph__color-name">{{data.name}}</span>    
            </div>
        </div>

        <div :id="'graph' + graphIndex" ref="graph" width="100%">
            
            <div :id="'tooltip_' + graphIndex" class="tooltip">
                <div class="tooltip-date">
                    <span :id="'date_' + graphIndex"></span>
                </div>
                <div class="tooltip-temperature">
                    <span :id="'value_' + graphIndex"></span>
                </div>
            </div>

        </div>
    </md-card>
</template>

<script>
import * as d3 from 'd3'

export default {
    name: 'ReportLineGraph',
    props: {
        yAxisProperty: {
            type: String,
            required: true
        },
        xAxisProperty: {
            type: String,
            required: true
        },
        graphData: {
            type: Array,
            required: true
        },
        graphIndex: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        }
    },

    data: () => ({

    }),
    computed: {
        graphWidth() {
            return this.$refs[`graphWrap${this.graphIndex}`].$el.clientWidth;
        }
    },
    methods: {
        getMaxYValue() {
            let maxY = 0;
            this.graphData.map(x => {
                if (d3.max(x.data, this.yAccessor) > maxY) {
                    maxY = d3.max(x.data, this.yAccessor);
                }
            })
            return maxY + 10;
        },
        prepareData() {
            this.graphData.map(x => {
                x.data.unshift({
                    value: 0,
                    time: x.data[0].time
                });
                x.data.push({
                    value: 0,
                    time: x.data[x.data.length - 1].time
                });
            });
        },
        yAccessor(d) {
            return d[this.yAxisProperty];
        },
        xAccessor(d) {
            return new Date(d[this.xAxisProperty]);
        },
        // Grids methods
        // gridlines in x axis function
        makeXGridlines() {		
            let x = d3.scaleTime().range([0, this.dimensions.boundedWidth]);
            return d3.axisBottom(x)
                .ticks(10)
        },

        // gridlines in y axis function
        makeYGridlines() {	
            let y = d3.scaleLinear().range([this.dimensions.boundedHeight, 0]);	
            return d3.axisLeft(y)
                .ticks(5)
        },

        initDimensions() {
            this.dimensions = {
                width: this.graphWidth,
                height: 300,
                margin: {
                    top: 25,
                    right: 35,
                    bottom: 40,
                    left: 40,
                },
            };

            this.dimensions.boundedWidth = this.dimensions.width
                - this.dimensions.margin.left
                - this.dimensions.margin.right;
            this.dimensions.boundedHeight = this.dimensions.height
                - this.dimensions.margin.top
                - this.dimensions.margin.bottom;
        },
        initStaticElements() {
            // Draw canvas
            this.wrapper = d3.select(`#graph${this.graphIndex}`)
                .append("svg")
                    .attr("width", this.dimensions.width)
                    .attr("height", this.dimensions.height);
            
            // Draw bounds
            this.bounds = this.wrapper.append("g")
                .style("transform", `translate(${
                    this.dimensions.margin.left}px, ${
                    this.dimensions.margin.top
                }px)`);

            // init static elements
            this.bounds.append("g")
                .attr("class", "x-axis")
                .style("transform", `translateY(${this.dimensions.boundedHeight}px)`);
            
            this.bounds.append("g")
                .attr("class", "y-axis"); 
            
            this.graphData.map((x, i) => {
                this.bounds.append("path")
                    .attr("class", `line-${i} line`);
            })

        },

        drawLine(data, lineName, color) { 
            const line = this.bounds.select(lineName)
                .transition().duration(1000)
                .attr("d", this.lineGenerator(data))
                .style('transform', 'none')
                .attr("fill-opacity","0.2")
                .attr("fill", color)
                .attr("stroke-width", 1)
                .attr("stroke", color);
        },
        
        startDrawProcess() {
            this.maxY = this.getMaxYValue();

            this.yScale = d3.scaleLinear()
                .domain([0, this.maxY])
                .range([this.dimensions.boundedHeight, 0]);

            this.xScale = d3.scaleTime()
                .domain(d3.extent(this.graphData[0].data, this.xAccessor))
                .range([0, this.dimensions.boundedWidth]);
            
            this.lineGenerator = d3.line()
                .x(d => this.xScale(this.xAccessor(d)))
                .y(d => this.yScale(this.yAccessor(d)));
        },

        drawAxes() {
            const yAxisGenerator = d3.axisLeft()
                .scale(this.yScale)
                .ticks(5);

            const yAxis = this.bounds.select('.y-axis')
                .call(yAxisGenerator)
                .style('color', '#000');

            const xAxisGenerator = d3.axisBottom()
                .scale(this.xScale)
                .tickFormat(d3.timeFormat("%B"));

            const xAxis = this.bounds.select(".x-axis")
                .transition().duration(1000)
                .call(xAxisGenerator)
                .style('color', '#000');

            // this.bounds.append("g")			
            //     .attr("class", "grid")
            //     .attr("transform", "translate(0," + this.dimensions.boundedHeight + ")")
            //     .call(this.makeXGridlines()
            //         .tickSize(-this.dimensions.boundedHeight)
            //         .tickFormat(""));

             this.bounds.append("g")			
                .attr("class", "grid")
                .call(this.makeYGridlines()
                    .tickSize(-this.dimensions.boundedWidth)
                    .tickFormat(""));
        },
        onMouseMove(d, i, n) {
            const mousePosition = d3.mouse(n[i]);
            const hoveredDate = this.xScale.invert(mousePosition[0])
            
            const getDistanceFromHoveredDate = d => Math.abs(this.xAccessor(d) - hoveredDate);
            
            this.graphData.map(dataObject => {
                
                const closestIndex = d3.scan(dataObject.data, (a, b) => (
                    getDistanceFromHoveredDate(a) - getDistanceFromHoveredDate(b)
                ));

                const closestDataPoint = dataObject.data[closestIndex];

                const closestXValue = this.xAccessor(closestDataPoint);
                const closestYValue = this.yAccessor(closestDataPoint);

                const formatDate = d3.timeFormat("%B, %Y");
                
                this.tooltip.select(`#date_${this.graphIndex}`)
                    .text(formatDate(closestXValue));
            
                this.tooltip.select(`#value_${this.graphIndex}`)
                    .text(dataObject.name + ' ' + closestYValue);

                const x = this.xScale(closestXValue) + this.dimensions.margin.left;
                const y = this.yScale(closestYValue) + this.dimensions.margin.top;

                // Tooltip top offset size depends on existing(or not existing) graph's color legend
                let tooltipTopOffset = this.graphData.length > 1 ? '42px' : '22px';
                this.tooltip.style("transform", `translate(` + `calc( -50% + ${x}px),` + `calc(-100% + ${y}px)` + `)`);
                this.tooltip.style("top", tooltipTopOffset);
                this.tooltip.style("opacity", 1);

                this.tooltipCircle
                    .attr("cx", this.xScale(closestXValue))
                    .attr("cy", this.yScale(closestYValue))
                    .style("opacity", 1);
            });

        },
        onMouseLeave() {
            this.tooltip.style("opacity", 0);
            this.tooltipCircle.style("opacity", 0)
        },
    },

    mounted() {
        this.prepareData();
        this.initDimensions();
        this.initStaticElements();
        this.startDrawProcess();
        this.drawAxes();
        
        this.graphData.map((x, i) => {
            this.drawLine(x.data, `.line-${i}`, x.color);
        });

        const listeningRect = this.bounds.append("rect")
            .attr("class", "listening-rect")
            .attr("fill", "transparent")
            .attr("width", this.dimensions.boundedWidth)
            .attr("height", this.dimensions.boundedHeight)
            .on("mousemove", (d, i, n) => this.onMouseMove(d, i, n))
            .on("mouseleave", (d, i, n) => this.onMouseLeave(d, i, n));
        
        this.tooltip = d3.select(`#tooltip_${this.graphIndex}`);

        this.tooltipCircle = this.bounds.append("circle")
            .attr("r", 4)
            .attr("stroke", "#af9358")
            .attr("fill", "white")
            .attr("stroke-width", 2)
            .style("opacity", 0);
        
        // TO DO: 
        // 1. Append a vertical line here to show the other values
        // 2. Set up tooltip text to display value for all provided data sets
    }
}
</script>

<style lang="scss" scoped>
.md-card.graph-wrap {
    margin: 0 0 2.4rem!important;
    background-color: $color-white !important;
    font-family: 'Roboto';
    cursor: pointer;

    @include respond(small) {
        margin-bottom: 2.4rem !important;
    }
}
::v-deep .grid line {
  stroke: lightgrey;
  stroke-opacity: 0.5;
  shape-rendering: crispEdges;
}

::v-deep .grid path {
  stroke-width: 0;
}
::v-deep .listening-rect {
    fill: transparent;
}
.graph__name {
    color: #485465;
    padding: 16px 35px 0 40px;
    font-family: Helvetica, 'Roboto';
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: -0.09px;
    position: relative;
    text-align: left;
    text-transform: uppercase;
    width: 100%;
}
.graph__menu {
    position: absolute;
    top: 0;
    right: 20px;
}
.graph__legend {
    display: flex;
    justify-content: flex-end;
    padding: 0 35px 0 40px;

}
.graph__color {
    display: flex;
    align-items: center;
    
    &:not(:first-child) {
        margin-left: 14px;
    }
}
.graph__color-name {
    font-size: 10px;
    color: #485465;
    margin-left: 8px;
}
.graph__color-sample {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

// Tooltip
.tooltip {
    opacity: 0;
    position: absolute;
    left: 0;
    padding: 0.6em 1em;
    background: #fff;
    text-align: center;
    line-height: 1.4em;
    font-size: 0.9em;
    border: 1px solid #ddd;
    z-index: 10;
    transition: all 0.1s ease-out;
    pointer-events: none;
}

.tooltip:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 12px;
    height: 12px;
    background: white;
    border: 1px solid #ddd;
    border-top-color: transparent;
    border-left-color: transparent;
    transform: translate(-50%, 50%) rotate(45deg);
    transform-origin: center center;
    z-index: 10;
}

.tooltip-date {
    margin-bottom: 0.2em;
    font-weight: 600;
    font-size: 1.1em;
    line-height: 1.4em;
}
</style>