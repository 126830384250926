import $axios from '../plugins/axios'

class RoleService {

    getAll() {

        return $axios.get('/roles')
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }
}

export default new RoleService();