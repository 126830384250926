<template>
	<md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">

		<md-dialog-title class="pick-bp-title" style="text-align: left" v-if="!doWeEdit">New Blueprint</md-dialog-title>
		<md-dialog-title class="pick-bp-title" style="text-align: left" v-if="doWeEdit">Edit Blueprint</md-dialog-title>

		<div >

			<!-- <div id="select-template-buttons">
				<md-button v-if="!doWeEdit" :class="{'bp-type-selected': bluePrint.type_id === 1}"
				           id="emom-create-button"
				           class="md-raised md-primary choose-file-btn md-button md-primary md-raised md-theme-light md-theme-default"
				           @click="emomSelect">EMOM
				</md-button>
				<md-button :disabled="showTourGuide" v-if="!doWeEdit"
				           :class="{'bp-type-selected': bluePrint.type_id === 2}"
				           class="md-raised md-primary choose-file-btn md-button md-primary md-raised md-theme-light md-theme-default"
				           id="tabata-create-button"
				           @click="bluePrint.type_id = 2">Work / Rest
				</md-button>
				<md-button :disabled="showTourGuide" v-if="!doWeEdit"
				           :class="{'bp-type-selected': bluePrint.type_id === 3}"
				           class="md-raised md-primary choose-file-btn md-button md-primary md-raised md-theme-light md-theme-default"
				           id="custom-create-button"
				           @click="bluePrint.type_id = 3">CUSTOM BLUEPRINT
				</md-button>
			</div> -->

<!--			<AppInputComponent-->
<!--				class="auth-form__field pick-bp-name"-->
<!--				id="text-field-name-blueprint"-->
<!--				required-->
<!--				placeholder="Name Blueprint Here (Class Name, Workout Type, etc.)"-->
<!--				v-model="$v.bluePrint.name.$model"-->
<!--				:error="$v.bluePrint.name.$invalid && $v.bluePrint.name.$dirty"-->
<!--				errorText="Name is required"-->
<!--			/>-->
      <md-field class="input">
        <label class="label figtree-normal-white-12px">Name Your Blueprint</label>
        <md-input class="input-text figtree-normal-white-16px-2" v-model="$v.bluePrint.name.$model"></md-input>
      </md-field>
<!--      <div class="input">-->
<!--        <div class="frame-407">-->
<!--          <div class="input-label">-->
<!--            <div class="label figtree-normal-white-12px">Name Your Blueprint</div>-->
<!--          </div>-->
<!--          <div class="frame-405">-->
<!--            <div class="frame-403">-->
<!--              <div class="input-text figtree-normal-white-16px-2">Tuesday Classes</div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <textarea v-model="bluePrint.description" class="text-area" placeholder="Add an optional description">
      </textarea>


			<!--            <md-chips class="md-primary shake-on-error pick-bp-tags"-->
			<!--                      v-model="tags"-->
			<!--                      md-placeholder="Add tag (use tags to search for Blueprints, such as the name of the creator or type of workout, etc.)">-->
			<!--                <div class="md-helper-text">(Press "Enter" to add a tag)</div>-->
			<!--            </md-chips>-->

<!--			<div class="share-with-trainers">-->
<!--				<p class="auth-form__field__share"> Privacy Setting </p>-->
<!--				<toggle-button class="toggle-share-btn"-->
<!--				               :disabled="showTourGuide"-->
<!--				               v-model="bluePrint.is_shared"-->
<!--				               color="#a156a0"-->
<!--				               :sync="true"-->
<!--				               :width=100-->
<!--				               :font-size=17-->
<!--				               :height=35-->
<!--				               :labels="{checked: 'Shared', unchecked: 'Private'}"/>-->
<!--			</div>-->


      <div >
        <md-checkbox v-model="bluePrint.is_shared"/>
        <span class="is-shared">
          Shared</span>
      </div>


			<div class="blueprint-color-container">
				<div class="color-label">
					<div class="blueprint-color figtree-medium-white-16px">Blueprint Color</div>
				</div>
				<div class="colors">
					<div class="colors-item-1">
						<div class="rounded-div frame-11" @click="parent($event)"></div>
						<div class="rounded-div frame-12" @click="parent($event)"></div>
						<div class="rounded-div frame-13" @click="parent($event)"></div>
						<div class="rounded-div frame-14" @click="parent($event)"></div>
						<div class="rounded-div frame-15" @click="parent($event)"></div>
						<div class="rounded-div frame-16" @click="parent($event)"></div>
					</div>
					<div class="colors-item-2">
						<div class="rounded-div frame-21" @click="parent($event)"></div>
						<div class="rounded-div frame-22" @click="parent($event)"></div>
						<div class="rounded-div frame-23" @click="parent($event)"></div>
						<div class="rounded-div frame-24" @click="parent($event)"></div>
						<div class="rounded-div frame-25" @click="parent($event)"></div>
						<div class="rounded-div frame-26" @click="parent($event)"></div>
  					</div>
				</div>

			</div>
			<!-- <div class="md-layout-item md-raised md-xlarge-size-95
                        md-large-size-95 md-medium-size-100
                        md-small-size-100 md-xsmall-size-100 upload-cont">

				<span class="file-upload-label" v-if="!bluePrint.image_url">Upload Blueprint Picture</span>

				<div class="upload-blueprint-image upload-dialog__preview">

					<div v-if="isImageTheSame" class="upload-dialog__preview">
						<img
							:src="editPreviewUrl"
							class="preview-image"
							alt="Preview Uploaded Image"
						>
					</div>

					<cropper
						v-if="isImageTheSame == false"
						class="upload-blueprint-image-cropper"
						:stencil-props="{ aspectRatio: 23/9 }"
						:src="previewUrl"
						@change="onChange"
					/>
				</div>

				<input type="file" @change="showPreview($event)" ref="hiddenInput" class="input-file">
				<md-button
					:disabled="showTourGuide"
					class="md-raised md-primary choose-file-btn md-button md-primary md-raised md-theme-light md-theme-default"
					@click.native="$refs.hiddenInput.click()">Choose file
				</md-button>
			</div> -->

<!--			<div class="photo-error" v-if="showPhotoError">You must upload a photo file (png, jpg, jpeg or bmp) !</div>-->

			<md-dialog-actions>
				<md-button class="dialog__btn dialog__btn--danger"
				           id="close-button"
				           :disabled="showTourGuide"
				           @click="closeDialog(true)">Cancel
				</md-button>
				<md-button class="md-primary dialog__btn md-raised md-theme-light"
				           id="submit-button"
				           :disabled="$v.bluePrint.$invalid || (showTourGuide && $tours['myTour'].currentStep != 3)"
				           @click="openSelectedTypeDialog()">Save Blueprint
				</md-button>
			</md-dialog-actions>
		</div>
	</md-dialog>
</template>

<script>
	import {required} from 'vuelidate/lib/validators';
	import {mapGetters} from "vuex";
	import {Cropper as cropper} from "vue-advanced-cropper";
	import 'vue-advanced-cropper/dist/style.css';

	export default {
		name: 'TrainerDashboardBluePrintDialog',
		components: {
			cropper
		},

		props: {
			showDialog: {
				type: Boolean,
				default: false
			},
			bluePrintProp: {
				type_id: 1,
				name: '',
				countdown_beeps: false,
				image_url: '',
				is_shared: false,
				sets: []
			},
			doWeEdit: {
				type: Boolean,
				default: false
			},
      store_id: {
        type: Number,
        default: 0
      }
		},
		data: () => ({
			options:[{label:'terrible option'},{label:'disastorous option'}],
			classes:['frame-11','frame-12','frame-13','frame-14','frame-15','frame-16',
        'frame-21','frame-22','frame-1','frame-11','frame-11','frame-11',],
			bluePrint: {
				name: '',
				image_url: '',
				is_shared: false,
				sets: [],
				countdown_beeps: false,
				description : '',
        color:''
			},
			isImageTheSame: false,
			previewUrl: '',
			editPreviewUrl: '',
			showPhotoError: false,
			fileName: '',
		}),
		validations: {
			bluePrint: {
				name: {required}
			}
		},
		methods: {
			parent: function(event) {

        document.querySelector('.colors-item-1').getElementsByTagName('div').forEach((element) => {
          const className = element.getAttribute('class').split(' ')[1];
          document.querySelector('.' + className).classList.remove("add-opacity");
        });

        document.querySelector('.colors-item-2').getElementsByTagName('div').forEach((element) => {
          const className = element.getAttribute('class').split(' ')[1];
          document.querySelector('.' + className).classList.remove("add-opacity");
        });

			  const className = event.target.getAttribute('class').split(' ')[1];
        const element = document.querySelector('.' + className);
        element.classList.add("add-opacity");
        this.bluePrint.color = getComputedStyle(element).backgroundColor;

    	},
			// saveNameForTourGuide() {
			//
			//     if (this.showTourGuide) {
			//
			//         if (this.timer) {
			//
			//             clearTimeout(this.timer);
			//             this.timer = null;
			//         }
			//
			//         this.timer = setTimeout(() => {
			//
			//             this.goNextStepIfTourGuideActive();
			//         }, 2000);
			//     }
			// },
			async dataUrlToFile(dataUrl, fileName) {

				const res = await fetch(dataUrl);
				const blob = await res.blob();
				return new File([blob], fileName, {type: 'image/png'});
			},

			onChange({coordinates, canvas}) {

				this.coordinates = coordinates

				let image = canvas.toDataURL("image/png");
				this.dataUrlToFile(image, this.fileName)
					.then(res => {
						this.bluePrint.image_url = res;
					});
			},

			showPreview(event) {

				const input = event.target;

				if (input.files && input.files[0]) {

					let splitFile = input.files[0].name.split('.');
					let splitFileLength = splitFile.length;
					let fileExtension = (input.files[0].name.split('.')[splitFileLength - 1]).toLowerCase();

					if (fileExtension === 'png' ||
						fileExtension === 'jpeg' ||
						fileExtension === 'jpg' ||
						fileExtension === 'bmp') {

						this.isImageTheSame = false;
						this.fileName = input.files[0].name;
						this.bluePrint.image_url = input.files[0];
						const reader = new FileReader();

						reader.onload = e => {
							this.previewUrl = e.target.result;
							input.value = '';
						};

						reader.readAsDataURL(input.files[0]);

						this.showPhotoError = false;

						// console.log(this.showPhotoError);
					} else {
						this.showPhotoError = true;
					}
				}
			},

			closeDialog(clearBPName) {
				if (clearBPName) {
					this.bluePrint.name = '';
				}
				this.$emit('update:showDialog', false);
			},

//        submitBluePrint() {
//            this.$store.dispatch('bluePrints/createBluePrint', this.bluePrint)
//                .then(() => {
//                    this.$emit('refreshAllBluePrints');
//                    this.$store.dispatch('showSnackbarMessage', {message: 'The blueprint was successfully created.'});
//                    this.closeDialog();
//                })
//                .catch((err) => {
//                    this.$store.dispatch('showSnackbarMessage', {
//                        message: `${err.message}. Please, try again.`,
//                        mode: 'fail'
//                    });
//                });
//        },

			openSelectedTypeDialog() {

				if (this.$v.bluePrint.$invalid) {
					this.$store.dispatch('showSnackbarMessage', {
						message: 'You must fill all the fields to continue',
						duration: 4000,
						mode: 'fail'
					});
				} else {
					if (this.doWeEdit) {
						this.submitBluePrint(true);
						this.closeDialog(true);
					} else {
						this.submitBluePrint(false);

						// this.$emit('openSelectedTypeDialog', this.bluePrint);
						this.closeDialog(false);
					}

					this.goNextStepIfTourGuideActive();
				}
			},

			submitBluePrint(isEdit) {

				let _this = this;
				let bluePrintObj = this.bluePrint;
				// let currentTagsArray = this.tags;

				let url = '/templates';

				let bodyFormData = new FormData();

				bodyFormData.set('name', bluePrintObj.name);
				bodyFormData.set('is_shared', bluePrintObj.is_shared);
				bodyFormData.set('description', bluePrintObj.description);
				bodyFormData.set('color', bluePrintObj.color);
				if (this.store_id) {
					bodyFormData.set('store_id', this.store_id);
				}

				if (!this.isImageTheSame) {

					bodyFormData.set('image_url', bluePrintObj.image_url);
				}

				if (isEdit == true) {

					// bodyFormData.set('image_url', bluePrintObj.image_url);
					url += '/' + this.bluePrint.id;
				}

				this.$axios({
					method: 'post',
					url: url,
					data: bodyFormData,
					headers: { 'Content-Type': 'multipart/form-data' }
				})
					.then(function (response) {
						console.log(response);

						_this.$emit('refreshAllBluePrints');

						_this.$store.dispatch('showSnackbarMessage', {
							message: 'Saved',
							duration: 4000,
							mode: 'success'
						});

						_this.closeDialog(true);
						console.log('inside template',response.data.id)
            if(!isEdit)
						  _this.$router.push('/blueprints/'+ response.data.id+'/sections');


					})
					.catch(function (error) {
						console.log(error);

						_this.$store.dispatch('showSnackbarMessage', {
							message: "Something went wrong. Try again.",
							duration: 4000,
							mode: 'fail'
						});
					});
			},

			goNextStepIfTourGuideActive() {

				if (this.showTourGuide) {
					this.$nextTick(() => {

						this.$tours['myTour'].nextStep();
					})
				}
			},

			emomSelect() {

				this.bluePrint.type_id = 1

				this.goNextStepIfTourGuideActive();
			}
		},

		computed: {

			...mapGetters(
				{
					userRole: 'auth/currentUserRole',
					showTourGuide: 'getIsTourGuideActive',
				},
			),
		},

    mounted() {
      if(this.doWeEdit) {
        document.querySelector('.colors-item-1').getElementsByTagName('div').forEach((element) => {
          const className = element.getAttribute('class').split(' ')[1];
          if (getComputedStyle(document.querySelector('.' + className)).backgroundColor === this.bluePrint.color) {
            document.querySelector('.' + className).classList.add("add-opacity");
          }
        });
        document.querySelector('.colors-item-2').getElementsByTagName('div').forEach((element) => {
          const className = element.getAttribute('class').split(' ')[1];
          if (getComputedStyle(document.querySelector('.' + className)).backgroundColor === this.bluePrint.color) {
            document.querySelector('.' + className).classList.add("add-opacity");
          }
        });
      }else{
        const element = document.querySelector('.frame-11');
        if(element) element.classList.add("add-opacity");
        this.bluePrint.color = 'rgb(95, 129, 9)';
      }
    },

    created() {
			if (this.bluePrintProp) {

				this.bluePrint = this.bluePrintProp;
				this.editPreviewUrl = this.bluePrintProp.image_url;
				this.isImageTheSame = true;
			}
		}
	}
</script>

<style lang="scss" scoped>
	@include dialogSettings();
	.dialog__hint {
		margin-bottom: 16px;
	}

	.choose-file-btn {
		width: 30%;
		margin-bottom: 30px;
	}

	.upload-cont {
		text-align: center;
	}

	.file-upload-label {
		font-size: 2rem;
	}

	.visible-label {
		margin-bottom: 10px;
		font-size: 1.8rem;
	}

	.input-file {
		display: none;
	}

	.toggle-btn {
		margin-top: 50px;
	}

	.auth-form {
		&__field {
			&__share, &__sample {
				padding-top: 20px;
				padding-bottom: 5px;
				font-size: 20px;
			}
		}
	}

	.pick-bp-title {
		text-align: center;
	}

	::v-deep .pick-bp-name input::placeholder {
		color: #BEBEC8 !important;
		font-weight: 500;
	}

	::v-deep .pick-bp-name input {
		padding-left: 5px;
		background-color: transparent !important;
		border: none !important;
		border-bottom: 1px solid white !important;
		font-size: 1.7rem;
		color: white !important;

		&:active {
			border: none;
			background-color: transparent;
			border-bottom: 1px solid white;
		}

		&:focus {
			background-color: transparent;
			border: none;
			border-bottom: 1px solid white;
		}
	}

	.pick-bp-name {
		padding-bottom: 10px !important;
	}

	::v-deep .pick-bp-tags input::placeholder {
		font-size: 1.3rem !important;
	}

	.share-with-trainers {
		margin-bottom: 30px;
	}

	.toggle-share-btn {
		margin-top: 10px;
	}

	.bp-type-selected {
		background-color: white !important;
		color: #A156A0 !important;
	}

	::v-deep .v-tour__target--highlighted {
		box-shadow: 0 0 0 99999px rgba(0, 0, 0, .7) !important;
	}

	.upload-blueprint-image-cropper {
		min-height: 0;
		max-height: 600px;
		width: 100%;
	}
  .blueprint-color-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 11px;
    width: fit-content;
    margin-bottom: 10px;
  }

  .color-label {
    align-items: flex-start;
    display: flex;
    padding: 10px 0px;
    width: fit-content;
  }

  .blueprint-color {
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    white-space: nowrap;
    width: fit-content;
  }

  .colors {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: fit-content;
  }
  .colors-item-1 {
    align-items: flex-start;
    display: flex;
    gap: 6px;
    width: fit-content;
  }

  .colors-item-2 {
    align-items: flex-start;
    display: flex;
    gap: 6px;
    width: fit-content;
  }

  .rounded-div{
    border-radius: 50px;
    height: 43px;
    min-width: 43px;
  }

  .frame-11 {
    background-color: var(--polished-pine);
  }

  .frame-12 {
    background-color: var(--eucalyptus);
  }

  .frame-13 {
    background-color: var(--vida-loca);
  }

  .frame-14 {
    background-color: var(--frostbite);
  }

  .frame-15 {
    background-color: var(--medium-red-violet);
  }

  .frame-16 {
    background-color: var(--medium-red-violet);
  }

  .frame-21 {
    background-color: var(--wedgewood);
  }

  .frame-22 {
    background-color: var(--blueberry);
  }

  .frame-23 {
    background-color: var(--sapphire);
  }

  .frame-24 {
    background-color: var(--razzmatazz);
  }

  .frame-25 {
    background-color: var(--grenadier);
  }

  .frame-26 {
    background-color: var(--pumpkin-skin);
  }


  .text-area {
    align-items: flex-start;
    background-color: var(--onyx);
    border: 1px solid;
    border-color: var(--sonic-silver-22);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 130px;
    padding: 4px 7px;
    color: var(--white);
    font-family: var(--font-family-figtree);
    font-size: var(--font-size-s);
    font-weight: 500;
    font-style: normal;
    width: 100%;
    resize: none;
  }
  textarea::placeholder {
    letter-spacing: 0;
    line-height: 16px;
    margin-top: -1px;
    white-space: nowrap;
    color: var(--sonic-silver);
    font-family: var(--font-family-figtree);
    font-size: var(--font-size-s);
    font-weight: 500;
    font-style: normal;
  }
  .input {
    align-items: flex-start;
    background-color: var(--tuatara);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--silver-chalice);
    border-radius: 4px 4px 0 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 10px;
    position: relative;
  }

  .label {
    padding-top: 2px;
    padding-left: 10px;
    letter-spacing: 0.4px;
    line-height: 16px;
    margin-top: -1px;
    white-space: nowrap;
    width: fit-content;
  }

  .input-text {
    letter-spacing: 0.15px;
    line-height: 24px;
    margin-top: 6px;
    white-space: nowrap;
    width: 100%;
  }

  .is-shared{
    letter-spacing: 0;
    line-height: 28px;
    min-width: 53px;
    white-space: nowrap;
    vertical-align: super;
    color: var(--material-themerefsecondarysecondary100);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }

  ::v-deep   .md-dialog-container {
    background-color: var(--heavy-metal)!important;
  }

  .add-opacity{
    opacity: 0.5;
    border: 4px double #3a3a3a;
  }
</style>
