import $axios from '../plugins/axios'

class StoreService {

    getStores() {

        return $axios.get('/stores')
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                // console.log(error)
                // throw error;
            })
    }

    deleteSingleStore(storeId) {

        return $axios.delete(`/stores/${storeId}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getSingleStore(id) {

        return $axios.get(`/stores/${id}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getAllUsersByStore(id) {

        return $axios.get(`/stores/${id}/users`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    addStore(store) {

        return $axios.post('/stores', store)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    addUser(storeId, request) {
        return $axios.post(`/stores/${storeId}/users`, request)
            .then(res => {
                return res.data;
            })
            .catch((error) => {
                console.log(error)
                throw error;
            })
    }

    getMyStores() {
        return $axios.get('/users/stores')
            .then(res => {
                return res.data;
            })
            .catch((error) => {
                console.log(error)
                throw error;
            })
    }

}

export default new StoreService();
