<template>
    <md-dialog :md-active.sync="showDialogRoom" :md-click-outside-to-close="false">

        <md-dialog-title>Create new room</md-dialog-title>

        <form class="room-form">

            <AppInputComponent
                    class="room-form__field"
                    required
                    placeholder="Room Name"
                    v-model="$v.room.name.$model"
                    :error="$v.room.name.$invalid && $v.room.name.$dirty"
                    errorText="Room Name is required"
            />
            <AppInputComponent
                    class="room-form__field"
                    required
                    :controlType="'time'"
                    :placeholderInput="'Open Time'"
                    v-model="$v.room.open_at.$model"
                    :inputIcon="'watch_later'"
                    :error="$v.room.open_at.$invalid && $v.room.open_at.$dirty"
                    errorText="Open Time is required"/>
            <AppInputComponent
                    class="room-form__field"
                    required
                    :controlType="'time'"
                    :placeholderInput="'Close Time'"
                    v-model="$v.room.close_at.$model"
                    :inputIcon="'watch_later'"
                    :error="$v.room.close_at.$invalid && $v.room.close_at.$dirty"
                    errorText="Close Time is required"
            />
            <AppInputComponent
                    class="room-form__field select-field"
                    controlType="select"
                    required
                    placeholder="Choose a facility"
                    v-model="$v.room.store_id.$model"
                    :error="$v.room.store_id.$invalid && $v.room.store_id.$dirty"
                    errorText="Store is required">

                <option v-for="(store, index) in stores" :key="index" :value="store.id">{{store.name}}</option>

            </AppInputComponent>
            <md-dialog-actions>
                <md-button class="dialog__btn dialog__btn--danger"
                           id="close-button"
                           :disabled="showTourGuide"
                           @click="closeDialog()">CANCEL</md-button>
                <md-button class="md-primary dialog__btn md-raised md-theme-light"
                           id="create-button"
                           :disabled="$v.room.$invalid"
                           @click="createARoom()">DONE</md-button>
            </md-dialog-actions>
        </form>
    </md-dialog>
</template>

<script>

    import {required} from 'vuelidate/lib/validators';
    import {DeepFilterMixin} from '@/mixins/DeepFilterMixin';
    import StoreService from '../../services/store.service';
    import RoomService from '../../services/room.service';
    import {mapGetters} from "vuex";

    export default {
        name: 'CreateRoomsDialog',
        mixins: [DeepFilterMixin],

        props: {
            showDialogRoom: {
                type: Boolean,
                default: false
            }
        },

        data: () => ({
            room: {
                name: '',
                open_at: '',
                close_at: '',
                store_id: ''
            },
            stores: []
        }),

        validations: {
            room: {
                name: {required},
                open_at: {required},
                close_at: {required},
                store_id: {required}
            }
        },

        methods: {

            getAllStores(){

                StoreService.getStores()
                    .then((res) => {

                        this.stores = res;
                    })
                    .catch(() => {

                        this.$store.dispatch('showSnackbarMessage', {message: 'Failed. Please, try again.', duration: 8000, mode: 'fail'});
                    });
            },

            createARoom(){

                let isOpenTimeCorrect = this.timePropsValueForStr(this.room.open_at);
                let isCloseTimeCorrect = this.timePropsValueForStr(this.room.close_at);

                if (isOpenTimeCorrect && isCloseTimeCorrect) {

                    this.room['open_at'] = this.convertAMPMForStr(this.room['open_at']);
                    this.room['close_at'] = this.convertAMPMForStr(this.room['close_at']);

                    RoomService.createRoom(this.room)
                        .then(() => {

                            if (this.showTourGuide) {

                                this.$store.dispatch('changeOpenMenuForTourGuide', true);
                            }

                            this.$emit('addTheNewRoom');

                            this.$store.dispatch('showSnackbarMessage', {
                                message: 'The room was successfully added.',
                                duration: 4000,
                                mode: 'success'
                            });

                            this.closeDialog();
                        })
                        .catch((err) => {

                            this.$store.dispatch('showSnackbarMessage', {
                                message: `${err.message}. Please, try again.`,
                                duration: 4000,
                                mode: 'fail'
                            });
                        });
                    } else {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: 'Please add a valid time on "Open" and "Close" time fields',
                            duration: 4000,
                            mode: 'error'
                        });

                    }
                },

            closeDialog() {

                this.room.name = '';
                this.room.open_at = '';
                this.room.close_at = '';
                this.room.store_id = '';
                this.$emit('update:showDialogRoom', false);
            }
        },

        computed: {
            ...mapGetters(
                {
                    showTourGuide: 'getIsTourGuideActive'
                },
            )
        },

        created(){

            this.getAllStores();
        }
    }
</script>

<style lang="scss" scoped>
    @include dialogSettings();
    .dialog__hint {
        margin-bottom: 16px;
    }
</style>