<template>
    <div class="logo__wrapper">

        <div class="logo__back">
            <div class="logo__stroke logo__stroke--1"></div>
            <div class="logo__stroke logo__stroke--2"></div>
            <div class="logo__stroke logo__stroke--3"></div>
            <div class="logo__stroke logo__stroke--4"></div>
        </div>

        <p
            class="logo__title"
            :class="{'logo__title--light': colorTheme === 'dark'}">
            Reflex Radio
        </p>

    </div>
</template>

<script>
export default {
    name: 'AnimatedLogoPreloader',
    props: {
        colorTheme: {
            type: String,
            default: 'dark'
        }
    }
}
</script>

<style scoped lang="scss">
.logo {
    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        background: transparent;
        transition: transform .3s ease-in-out;
        padding: 20px;
        // &:hover {
        //     transform: perspective(1000px) translate3d(0, 5rem, 6rem) rotate3d(1, 0, 0, 60deg);
        // }
    }

    &__back {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;

        width: 95px;
        height: 95px;
        border-radius: 50%;
        
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        background-color: $color-accent-purple;

        transform: translateY(-400px);
        animation: jump-down .4s 1 forwards;
        transition: transform .2s ease-in-out;
    }

    &__stroke {
        width: 10px;
        background-color: #ffffff;
        border-radius: 2px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        
        opacity: 0;
        transition: width .2s ease-in-out; 
        transform: translateY(-400px);

        &--1 {
            height: 37px;
            animation: jump-down .6s 1 .3s forwards;
            margin-left: 5px;
        }
        
        &--2 {
            height: 74px;
            animation: jump-down .6s 1 .45s forwards;

        }

        &--3 {
            height: 54px;
            animation: jump-down .6s 1 .6s forwards;
        }

        &--4 {
            height: 22px;
            animation: jump-down .6s 1 .75s forwards;
        }

        &:not(:last-child) {
            margin-right: 7px;
        }
        
    }

    &__title {
        width: 100%;
        opacity: 0;
        font-family: "Champagne & Limousines", 'Open Sans', sans-serif;
        font-size: 3.2rem;
        font-weight: 300;
        line-height: 3.2rem;
        margin: 0;
        color: $color-grey;
        transform: translateY(400px);
        animation: name duration timing-function delay iteration-count direction fill-mode;
        animation: jump-up .4s cubic-bezier(0, 0, 0, 1.08) 1 1.2s alternate forwards;

        &--light {
            color:$color-white;
        }
    }
}

@keyframes jump-down {
    0% {
        opacity: 1;
        transform: translateY(-400px);
    }

    80% {
        transform: translateY(5px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes jump-up {
    0% {
        opacity: 1;
        transform: translateY(100px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>