import UserService from "../../services/user.service";

export default async function resetPasswordCheck({to, next, store, nextMiddleware}) {

    console.log('resetPasswordCheck middleware');

    const user = await UserService.getOne(store.getters['auth/getCurrentUser'].id)
    const pathAddFacility = '/reset-password';

    if (user.is_preregistered && !user.password_reset) {

        return next({
            path: pathAddFacility,
        });
    }

    return nextMiddleware();
}
