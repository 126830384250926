export default async function rolesCheck({to, next, store, nextMiddleware}) {

    let user = store.getters['auth/getCurrentUser'];

    if (user === null) {

        await store.dispatch('auth/getMe')
        user = store.getters['auth/getCurrentUser'];
    }

    const allowedRoles = to.meta.roles;

    const userRole = user ? user.role_id : null;

    if (!allowedRoles.includes(userRole)) {

        let path = '/404';

        return next({
            path: path
        });

    }
    
    return nextMiddleware();
}