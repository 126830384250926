import $axios from '../plugins/axios'

class InvitesService {

    getAllInvited() {

        return $axios.get('/invites')
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }


}

export default new InvitesService();