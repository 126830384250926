<template>
    <div class="component-wrap">
        <AppHeaderComponent headline="Payment Method">
        </AppHeaderComponent>
        <div>
            <div class="md-layout main-card-container md-gutter" style="place-content: center;">
                <div @click="openModal"  class="add-new-card-container" >
                    <div style="flex:25"></div>
                    <div style="flex:35">
                        <md-icon  class="md-theme-light" style="color:white;font-size: 40px!important;">add_circle_outline</md-icon>
                    </div>
                    <div style="flex:30" class="figtree-medium-white-14px">
                        Add new payment method
                    </div>
                    <div style="flex:10"></div>
                </div>
                <div v-for="card in cards" class="card-container" :key="card.id" id="single-card">
                    <div class="card-top flex-box-33">
                        <div style="flex:90;text-align: left;">
                            <img v-if="card.brand.toLocaleLowerCase() == 'visa'" 
                                src="@/assets/images/Visa-card.png" alt="" class="">
                            <img v-else-if="card.brand.toLocaleLowerCase() == 'mastercard'" 
                                src="@/assets/images/Master-card.png" alt="" class="card-img">
                        </div>
                        <div style="flex:10;position: absolute;margin-right: -16px;">
                            <md-tooltip v-if="card.brand.toLocaleLowerCase() != 'mastercard'" class="md-theme-light" md-direction="top">Remove Card</md-tooltip>
                            <md-button class="md-icon-button blueprint-card__button"
                                           id="remove-button"
                                           @click.stop="removeCard(card)">
                                
                                <md-tooltip  v-if="card.brand.toLocaleLowerCase() == 'mastercard'" class="md-theme-light" md-direction="top">Remove Card</md-tooltip>
                                <md-icon v-if="card.brand.toLocaleLowerCase() == 'mastercard'" class="md-theme-light" style="color:white;font-size: 20px!important;">delete_forever</md-icon>

                            </md-button>
                        </div>
                        
                    </div>
                    <div class="card-center flex-box-33">
                        <div class="card-brand">
                           <p class="figtree-medium-white-16px">
                                ****&nbsp;&nbsp;&nbsp;&nbsp;****&nbsp;&nbsp;&nbsp;&nbsp;****&nbsp;&nbsp;&nbsp;&nbsp;{{ card.last4 }}
                           </p> 
                        </div>
                    </div>
                    <div class="card-bottom flex-box-33">
                        <div class="card-last4 figtree-medium-white-14px">
                            <p class="figtree-medium-white-10px">Name</p>
                            {{currentUser.first_name +' ' + currentUser.last_name }}
                        </div>
                        <div class=" exp-date">
                            <div class="figtree-medium-white-10px" style="flex:50;margin-right: 7px;margin-top: -1px;">
                                Expiry
                            </div>
                            <div style="flex:50" class="figtree-medium-white-14px">
                                {{card.exp_month}}/{{card.exp_year}}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <CardModal
			:showDialog.sync="showDialog"
			:disableCloseProp = "isCloseDisable"
			:isAddingFirstCardProp = "isAddingFirstCard"
			@getCards="getCards"
		/>
    </div>
</template>

<script>
    import CardService from '@/services/card.service'
    import CardModal from '@/components/Cards/CardModal'
    import {mapGetters} from 'vuex';

    export default {
        name: 'Cards',
        data: () => ({
            dialogKey: 0,
            showDialog: false,
            isCloseDisable: false,
            cards: [],
			isAddingFirstCard: false,
        }),
        components: {
            CardModal
        },
        methods: {
            removeCard(card){

                return CardService.removeCard(card.id)
                    .then(data => {

                        this.$store.dispatch('showSnackbarMessage',
                            {message: "The card was succesfully removed!", duration: 4000})

                        this.cards.pop();
                        this.$forceUpdate();
                    })
                    .catch(error => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: error.response.data,
                            mode: 'fail'
                        });
                    })
            },

            setPageName() {
                this.$store.dispatch('changePageName', {pageNameString: 'Payment Method'});
            },

            openModal() {

                this.dialogKey++;
                this.showDialog = true;
            },

            getCards() {

                return CardService.getAllCards()
                    .then(data => {

                        this.cards = data;
                    })
                    .catch(error => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: error.message,
                            mode: 'fail'
                        });
                })
            }
        },

        computed: {
            ...mapGetters(
                {
                    currentUser: 'auth/getCurrentUser',
                },
            )
        },

        created() {
            this.setPageName();
            this.getCards();

            if (this.currentUser.account.has_card === false) {

                this.isCloseDisable = true;
                this.isAddingFirstCard = true;
                this.showDialog = true;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .content-header.about-page {
        margin-bottom: 40px;
    }

    .card-container{
        margin: 20px;
        background-color: var(--white-32);
        border: 0.5px solid #606060;
        // border-color: var(--persian-green);
        border-radius: 10px;
        gap: 26px;
        padding: 15px;
        display: flex;
        flex-direction: column;
        width: 234px;
      

        .card-center{
            display: flex;
            justify-content: center;
            align-items: flex-start;
            width: fit-content;
        }

        .card-bottom{
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            text-align: left;
        }

        .card-last4{
            flex:70;
        }

        .exp-date{
            flex:30;
            text-align: right;
            display: flex;
            flex-direction: column;
        }
    }

    .main-card-container{
        justify-content: left;
    }

    #single-card{

        .card-top{
            display: flex;
            align-items: center;
            justify-content: right;
        }
        .flex-box-33{
            flex: 33;
        }
        .card-img{
	        height: 21px;
	        width: 34px;
	        min-width: 34px;
	        border-radius: 5px;
        }
    }
    .add-new-card-container{
        margin: 20px;
        background-color: var(--white-32);
        border: 0.5px solid #606060;
        // border-color: var(--persian-green);
        border-radius: 10px;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        min-height: 165px;
        height: 165px;
        width: 234px;
        cursor: pointer;
    }
</style>