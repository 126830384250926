<template>
    <div class="blueprint-container">
                      <div class="list-group figtree-semi-bold-pink-swan-18px deleted-blueprints" 
                          :style="`border-left-color: ${period.color ? period.color : randomColor};`">
                            <div style="flex:3;display: flex;flex-direction: column;text-align: left;padding: 5px;">
                              <div style="flex:50"  class="play-button-div" @click.stop="playPlaylistMix(period.id, period.name)">
                                <md-icon  style="color: white;font-size: 25px!important;font-weight: normal;margin-top: 2px;">play_arrow</md-icon>

							                </div>
                                <div  style="flex:40;text-align: center;"  v-if="!period.is_shared">
                                    <md-icon  style="color: white;font-size: 18px!important;font-weight: normal;margin-left: 10px;">lock_outlined</md-icon>
                                </div>
                            </div>
                          <div style="flex:37">{{ period.name }}</div>
                          <div style="flex:15;" >{{ songsNumber }} </div>
                          <div style="flex:15">{{ convertToHours(period.duration) }}</div>
  
                          <div class="actions-btn">
                              <div v-if="isMobile"
                                  class="figtree-normal-white-18px"
                                  @click.stop="archivePlaylist(period.id)"> <md-icon style="padding-right: 8px;">restore</md-icon>
                              </div>
                              <md-button v-else
                                  class="restore-btn figtree-normal-white-18px"
                                  id="delete-blueprints-btn"
                                  @click.stop="archivePlaylist(period.id)">  Restore
                              </md-button>
  
                          </div>  
                      </div>
    </div>
  
  </template>
  
  <script>
  import PlaylistService from "@/services/playlists.service"
  import {mapGetters} from "vuex";
import { CheckWindowWidthMixin } from "../../mixins/CheckWindowWidthMixin";
  
  export default {
    name: "ArchivedDeletedPlaylistCard",
    mixins:[CheckWindowWidthMixin],
    props: {
  
      isArchived: {
        type: Boolean,
        default: false
      },
  
      period: {
        type: Object,
        default: {}
      },
  
      isDeleted: {
        type: Boolean,
        default: false
      },
  
      label: {
        type: String,
        default: "View Period"
      },
  
      isDeleteDisableProp: {
        type: Boolean,
        default: false
      },
      randomColor: {
        type: String,
        default: ''
      }
    },
  
    data: () => ({
      timeLineSeconds: 0,
      songsForMix: [],
      songsNumber:0
    }),
  
    methods: {
      getIntervalsByPlaylist() {
            this.songsNumber = 0;
            return PlaylistService.getIntervalsByPlaylist(this.period.id)
                .then(async (res) => {

                    res.forEach((interval, index) => {
                      this.songsNumber += interval.interval_songs.length;

                    });
                    this.loadingSpinner = false;
                });
      },
      convertToHours(duration) {
        if(duration) {
          const timeObj = duration.split(':');
          const res =  (Number(timeObj[0]) !== 0 ? Number(timeObj[0]) + ' Hours ' : '') + (Number(timeObj[1]) !== 0 ? Number(timeObj[1]) + ' Minutes ' : '');
          return res === '' ? Number(timeObj[2]) + ' Seconds ' : res;
        }
      },
      restorePlaylist(id) {
  
        this.$emit('restoreCurrentPlaylist', id);
      },
  
      archivePlaylist(id) {
  
        if (this.isArchived)  
          this.$emit('unArchivePlaylist', id);

        if (this.isDeleted) 
          this.$emit('restoreCurrentPlaylist', id);
        
      },
  
      deleteSegment(id) {
  
        let _this = this;
  
        this.$emit('tempRemovePeriod', id);
  
        this.$store.dispatch('showSnackbarMessage', {
          message: 'Last chance to undo the delete.',
          duration: 5000,
          mode: 'success',
          buttonText: 'Undo',
          type: 'period'
        });
  
        setTimeout(() => {
          _this.deleteOneElement(id).then(() => {
  
            this.$emit('setLoadingSpinner', false);
          });
        }, 5000);
      },
  
      async deleteOneElement(id) {
  
        await this.deleteIfUndoIsInactive(id);
      },
  
      deletePlaylist(id) {
  
        PlaylistService.deletePlaylist(id)
            .then(() => {
  
              this.$emit('refreshPlaylists');
              this.$store.dispatch('showSnackbarMessage', {
                message: 'The playlist was successfully deleted.',
                duration: 3000,
                mode: 'success'
              });
            })
            .catch((err) => {
  
              this.$store.dispatch('showSnackbarMessage', {
                message: `${err.message}. Please, try again.`,
                mode: 'fail'
              });
            });
      },
  
      deleteIfUndoIsInactive(id) {
  
        if (!(this.$store.getters['periods/getIsUndoActive'])) {
  
          this.deletePlaylist(id);
        }
  
        this.$store.commit('periods/setIsUndoActive', false);
      },
  
      // openPlaylistModal(id) {
      //
      // 	this.$emit('showPlaylistModal', id);
      // },
  
      convertTimeHHMMSS(val) {
  
        let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);
  
        return hhmmss;
      },
      convertHHMMSStoSeconds(timeHHMMSS) {
  
        let timeObj = timeHHMMSS.split(':');
        let secondsDuration = (+timeObj[0]) * 60 * 60 + (+timeObj[1]) * 60 + (+timeObj[2]);
  
        return secondsDuration;
      },
  
  
      getTimeline(duration) {
  
        let resultTimeToReturn = this.convertTimeHHMMSS(this.timeLineSeconds); // get and return last timeline
  
        //calculate new timeline
        let timeObj = duration.split(':');
        let secondsDuration = (+timeObj[0]) * 60 * 60 + (+timeObj[1]) * 60 + (+timeObj[2]);
  
        //for first timeline value
        if (this.timeLineSeconds == 0) {
  
          this.timeLineSeconds = secondsDuration;
  
          return "00:00:00";
        }
  
        this.timeLineSeconds = this.timeLineSeconds + secondsDuration;
  
        return resultTimeToReturn;
      },
  
      async playPlaylistMix(id, playlistName) {
        this.songsForMix = [];
        this.timeLineSeconds = 0;
        let intervalsWithTimeline = null;
        let musicIntervals = null;
        let total_duration = null;
  
        await this.$store.dispatch('audioPlayer/resetPlayerData');
  
        await PlaylistService.getIntervalsByPlaylist(id)
            .then((res) => {
              intervalsWithTimeline = res;
            })
  
        for (const interval of intervalsWithTimeline) {
  
          let timelineString = this.getTimeline(interval.duration);
          interval.timeline = timelineString;
        }
  
        await PlaylistService.getPlaylistMixView(id)
            .then((res) => {
              musicIntervals = res;
              const map1 = new Map();
  
              let user_content_rating = (res[0].allow_explicit_content == 0) ? 'clean' : 'explicit';
              total_duration = this.convertHHMMSStoSeconds(res[0].total_duration)
              res.forEach(value => {
                if (map1.has(value.song_id + '_' + value.sections_order + '_' + value.intervals_order + '_' + value.song_inInterval_Order)) {
                  if (map1.get(value.song_id + '_' + value.sections_order + '_' + value.intervals_order + '_' + value.song_inInterval_Order).content_rating === user_content_rating) {
                  } else {
                    map1.set(value.song_id + '_' + value.sections_order + '_' + value.intervals_order + '_' + value.song_inInterval_Order, value)
                  }
                } else {
                  map1.set(value.song_id + '_' + value.sections_order + '_' + value.intervals_order + '_' + value.song_inInterval_Order, value)
                }
              })
  
              musicIntervals = Array.from(map1, ([key, value]) => {
                return value;
              });
              musicIntervals.map(el => this.songsForMix.push(el.streaming_id.toString()));
            })
            .catch((err) => {
              console.log(err)
              this.$store.dispatch('showSnackbarMessage', {
                message: `Mix was not found for this Blueprint`,
                mode: 'fail'
              });
            });
  
        const music = MusicKit.getInstance();
        await music.stop()
        await music.clearQueue();
        await this.set(this.songsForMix)
        await this.$store.dispatch('audioPlayer/updatePlayerForIntervals', {
          url: null,
          name: playlistName,
          playlistId: id,
          playlistDurationInterval: musicIntervals,
          startSeconds: null,
          endSeconds: total_duration,
          autoplay: false,
          isIntervalPlaying: false,
          intervals: intervalsWithTimeline,
          appleIntervals: musicIntervals
        });
  
      },
      async set(songsForMix) {
        const music = MusicKit.getInstance();
        await music.mute()
        await music.setQueue({
              songs: songsForMix,
              startPlaying: true
            }
        );
        await music.pause();
        return;
      },
      showModalTableBluePrintInfo(id) {
  
        this.$emit('showModalTableBluePrintInfo', id);
      },
  
      openURL(url) {
  
        window.open(url, '_blank');
      },
    },
    created() {
      if (this.period.id)this.getIntervalsByPlaylist();
    },
    computed: {
  
      ...mapGetters(
          {
            userRole: 'auth/currentUserRole',
          },
      ),
    },
  }
  </script>
  
  <style lang="scss" scoped>
  @include card();
  
  #blueprint-processing {
    margin-right: 10px;
  }
  
  .explicit-content {
    font-family: var(--font-family-figtree);
    font-size: var(--font-size-xxs);
    font-weight: bolder;
    font-style: normal;
    border: 1.5px solid var(--nobel);
    color: white;
  }
  
  
  .overlap-group {
    flex: 75;
    width: 100%;
  }
  
  .rectangle-267 {
    height: 100%;
    width: 100%;
  }
  
  .address {
    height: 14px;
    text-align: left;
  }
  
  .more-option-div {
    align-items: center;
    background-color: #222222;
    opacity: 0.7;
    border-radius: 12px;
    display: flex;
    height: 24px;
    justify-content: flex-end;
    left: 122px;
    min-width: 24px;
    width: 24px;
    padding: 0 4px;
    position: absolute;
    top: 4px;
  }
  
  .union {
    height: 8px;
  }
  
  .play {
    align-items: center;
    background-color: #faa9a9;
    opacity: 0.8;
    border-radius: 16px;
    box-shadow: 0px 4px 12px -4px #19141466;
    display: flex;
    height: 32px;
    // text-align: center;
    justify-content: center;
    
  }
  .play-button-div{
    background-color: #222222;
    box-shadow: 0px 4px 12px -4px #19141466;
    border-radius: 50%;
    max-width: 30px;
    min-width: 30px;
    min-height: 30px;
    text-align: center;
    cursor: pointer;

  }
  .play-button-div:hover{
    background-color: #3A3A3A;
    
  }
  
  .vector {
    height: 12px;
  }
  
  .card-info {
    display: flex;
    flex: 25;
    padding-left: 12px;
    flex-direction: column;
    text-align: left;
  }
  
  .tuesday-workouts {
    height: 16px;
  }
  
  .main-main-content {
    background-color: var(--white-4);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    height: 202px;
    overflow: hidden;
    width: 85%px;
    // margin-bottom: 10px;
  }
  
  .menu-div {
    position: absolute;
    right: -8px;
    margin-top: 1px;
  }
  
  ::v-deep .md-list.md-theme-default{
    background-color: var(--onyx)!important;
    padding: 0;
  }
  
  ::v-deep .md-list-item-content{
    padding: 0;
  }
  .list-group{
          display: flex;
          align-items: center;
          padding: 0px 0px 0px 10px;
          height: 60px;
          background: #212121;
          margin-bottom: 1px;
          border-left: 2px solid;
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
              gap: 5px;
          width: 100%;
  
      }
  
      .restore-btn{
          border: 1px solid;
          border-color: var(--cultured-pearl);
          border-radius: 5px;
          width: fit-content;
          height: 40px;
          @media only screen and (max-width: 834px) {
              font-size: var(--font-size-s);
              width: fit-content;
              height: 35px;
          };
      }
      .blueprint-container{
          display:flex;
          flex-direction: column;
      }
  .actions-btn{
		flex:15;
		text-align: right;
		@media only screen and (max-width: 481px) {
         flex: 8;
        };
	}
  </style>
  