<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">

        <md-dialog-title v-if="account.id" style="text-align: left;">Edit Account</md-dialog-title>
        <md-dialog-title v-else>Create a new Account</md-dialog-title>

        <AccountForm v-if="account"
                     :account-prop=account
                     :isDialog="true"
                     @closeDialog="closeDialog"
                     @createNewAccount="createNewAccount">
        </AccountForm>

    </md-dialog>
</template>

<script>

    import {email, required, requiredIf} from 'vuelidate/lib/validators';
    import AccountForm from "./AccountForm";

    export default {
        name: 'CreateAccountModal',
        props: {
            showDialog: {
                type: Boolean,
                default: false
            },
            accountProp: {
                type: Object,
                default: null
            },
        },
        data: () => ({
            previewUrl: '',
            showPhotoError: false,
            account: null
        }),
        validations: {
            account: {
                email: {required, email},
                name: {required},
                logo: {
                    required: requiredIf(function(){
                        return !this.account.hasOwnProperty('id')
                    })},
            }
        },
        components: {
            AccountForm
        },
        methods: {

            showPreview(event) {
                const input = event.target;

                console.log(input.files);

                if (input.files && input.files[0]) {

                    let splitFile = input.files[0].name.split('.');
                    let splitFileLength = splitFile.length;
                    let fileExtension = input.files[0].name.split('.')[splitFileLength - 1];

                    if (fileExtension === 'png' || fileExtension === 'jpeg' || fileExtension === 'jpg' ||
                        fileExtension === 'bmp') {

                        this.account.logo = input.files[0];
                        const reader = new FileReader();

                        reader.onload = e => {
                            this.previewUrl = e.target.result;
                            input.value = '';
                        };

                        reader.readAsDataURL(input.files[0]);

                        this.showPhotoError = false;

                        console.log(this.showPhotoError);
                    } else {
                        this.showPhotoError = true;
                    }
                }
            },
            closeDialog() {
                if (!this.$route.path.includes('/account-info'))
                {
                    this.$emit('update:accountProp', null);
                }
                this.$emit('update:showDialog', false);
            },
            createNewAccount(account) {

                let bodyFormData = new FormData();

                let accountObj = account;

                let url = '/accounts';

                if (accountObj.hasOwnProperty('id')) {
                    url = '/accounts/' + accountObj.id;
                }

                bodyFormData.set('name', accountObj.name);
                bodyFormData.set('email', accountObj.email);
                bodyFormData.set('street', accountObj.street);
                bodyFormData.set('suite', accountObj.suite);
                bodyFormData.set('city', accountObj.city);
                bodyFormData.set('state', accountObj.state);
                bodyFormData.set('zip', accountObj.zip);
                bodyFormData.set('active', accountObj.active);
                bodyFormData.set('logo', accountObj.logo);

                let _this = this;

                this.$axios({
                    method: 'post',
                    url: url,
                    data: bodyFormData,
                    headers: {'Content-Type': 'multipart/form-data'}
                })
                    .then(function (response) {
                        console.log(response);

                        _this.$emit('addNewAccount', response.data);

                        _this.$store.dispatch('showSnackbarMessage', {
                            message: 'You have successfully created a store.',
                            duration: 4000,
                            mode: 'success'
                        });

                    })
                    .catch(function (error) {
                        console.log(error);

                        _this.$store.dispatch('showSnackbarMessage', {message: "Something went wrong. Try again.", duration: 4000, mode: 'fail'});
                    });

                this.closeDialog();
            }
        },

        created() {

            this.account = this.accountProp;
        }
    }
</script>

<style lang="scss" scoped>
    @include dialogSettings();
    .dialog__hint {
        margin-bottom: 16px;
    }

    .md-dialog {

        .choose-file-btn {
            width: 30%;
        }

        .upload-cont {
            text-align: center;
        }

        .file-upload-label {
            font-size: 2rem;
        }
    }

    .form-buttons {
        margin-top: 20px;
        justify-content: space-around;
    }

    .input-file {
        display: none;
    }

    .create-form {

        .md-layout-item {
            margin: 0 auto;
        }
    }

    .md-dialog-title {
        text-align: center;
        font-size: 2.3rem;
    }

    .upload-dialog__preview {
        /*max-width: 100px;*/
	    margin: 20px auto;
    }

    .photo-error {
        color: #FF0900;
	    font-size: 1.7rem;
	    margin: 7px auto 0;
    }

    .active-container {
        text-align: center;

        .status-label {
            margin-top: 25px;
            margin-bottom: 20px;
            font-size: 2.4rem;
        }
    }
</style>