<template>
	<div>
		<TheLayoutWrap>
			<transition name="fade" mode="out-in">
				<router-view></router-view>
			</transition>
		</TheLayoutWrap>

		<md-dialog
			class="dialog-finish-tour-guide"
			:md-active.sync="showTourFinish"
			:md-click-outside-to-close="false"
		>
			<md-button
				class="md-primary dialog__btn md-raised md-theme-light"
				id="tour-finish-button"
				@click="tourGuideFinish()"
			>
				Great job you are ready to go
			</md-button>
		</md-dialog>

		<v-tour
			name="myTour"
			:steps="steps"
			:callbacks="{
				onPreviousStep: myCustomPreviousStepCallback,
				onNextStep: myCustomNextStepCallback,
				onSkip: myCustomSkipCallback
			}"
		>
			<template slot-scope="tour">
				<transition name="fade">
					<v-step
						v-if="tour.steps[tour.currentStep]"
						:key="tour.currentStep"
						:step="tour.steps[tour.currentStep]"
						:previous-step="tour.previousStep"
						:next-step="tour.nextStep"
						:stop="tour.stop"
						:skip="tour.skip"
						:is-first="tour.isFirst"
						:is-last="tour.isLast"
						:labels="tour.labels"
						:highlight="tour.highlight"
						:enable-scrolling="tour.enableScrolling"
					>

						<template v-if="tour.currentStep === 0">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>

							</div>
						</template>
						<template v-if="tour.currentStep === 1">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
								<button @click="tour.nextStep" class="v-step__button v-step__button-next">Next step
								</button>

							</div>
						</template>

						<template v-if="tour.currentStep === 2">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
								<button @click="tour.previousStep" class="v-step__button v-step__button-previous">
									Previous step
								</button>
								<button @click="tour.nextStep" class="v-step__button v-step__button-next">Next step
								</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 3">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
								<button @click="tour.previousStep" class="v-step__button v-step__button-previous">
									Previous step
								</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 4">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 5">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
								<button @click="tour.previousStep" class="v-step__button v-step__button-previous">
									Previous step
								</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 6">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 7">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
								<button @click="tour.previousStep" class="v-step__button v-step__button-previous">
									Previous step
								</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 8">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>

							</div>
						</template>

						<template v-if="tour.currentStep === 9">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>

								<button @click="tour.previousStep" class="v-step__button v-step__button-previous">
									Previous step
								</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 10">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 11">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 12">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
								<button @click="tour.previousStep" class="v-step__button v-step__button-previous">
									Previous step
								</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 13">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
								<button @click="tour.previousStep" class="v-step__button v-step__button-previous">
									Previous step
								</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 14">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
								<button @click="tour.previousStep" class="v-step__button v-step__button-previous">
									Previous step
								</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 15">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>

							</div>
						</template>

						<template v-if="tour.currentStep === 16">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
								<button @click="tour.previousStep" class="v-step__button v-step__button-previous">
									Previous step
								</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 17">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 18">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 19">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 20">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 21">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
								<button @click="tour.previousStep" class="v-step__button v-step__button-previous">
									Previous step
								</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 22">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
								<button @click="tour.previousStep" class="v-step__button v-step__button-previous">
									Previous step
								</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 23">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 24">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
								<button @click="tour.previousStep" class="v-step__button v-step__button-previous">
									Previous step
								</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 25">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
								<button @click="tour.previousStep" class="v-step__button v-step__button-previous">
									Previous step
								</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 26">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 27">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 28">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>

							</div>
						</template>

						<template v-if="tour.currentStep === 29">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
								<button @click="tour.previousStep" class="v-step__button v-step__button-previous">
									Previous step
								</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 30">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 31">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
								<button @click="tour.previousStep" class="v-step__button v-step__button-previous">
									Previous step
								</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 32">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 33">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 34">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
								<button @click="tour.previousStep" class="v-step__button v-step__button-previous">
									Previous step
								</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 35">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 36">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 37">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
								<button @click="tour.nextStep" class="v-step__button v-step__button-next">Next step
								</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 38">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
								<button @click="tour.previousStep" class="v-step__button v-step__button-previous">
									Previous step
								</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 39">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
							</div>
						</template>

						<template v-if="tour.currentStep === 40">
							<div slot="actions">
								<button @click="tour.skip" class="v-step__button v-step__button-skip">Skip tour</button>
								<button @click="myCustomFinish" class="v-step__button v-step__button-previous">Finish
								</button>
							</div>
						</template>
					</v-step>
				</transition>
			</template>
		</v-tour>
	</div>
</template>

<script>

	import TheLayoutWrap from "@/components/Layout/TheLayoutWrap.vue"
	import UserService from "@/services/user.service"
	import {mapGetters} from "vuex";

	export default {
		name: 'AppContent',
		components: {

			TheLayoutWrap
		},

		data: () => ({

			steps: [
				{
					target: '#create-blueprint-button',
					content: `Create Blueprint Template`,
					params: {
						placement: 'left',
						highlight: true,
						enableScrolling: false
					}
				},
				{
					target: '#emom-create-button',
					content: 'Select workout template',
					params: {
						placement: 'right',
						highlight: true,
						enableScrolling: false
					}
				},
				{
					target: '#text-field-name-blueprint',
					content: 'Name Blueprint template to reuse template for multiple playlists/mixes\n',
					params: {
						placement: 'left',
						highlight: true,
						enableScrolling: false
					}
				},
				{
					target: '#submit-button',
					content: 'Click Submit to continue\n',
					params: {
						placement: 'left',
						highlight: true
					}
				},
				{
					target: '#every-min-0',
					content: 'Select your interval duration\n',
					params: {
						placement: 'right',
						highlight: true
					}
				},
				{
					target: 'li#\\30 3\\:00-duration-0',
					content: 'Select 03:00 minutes\n',
					params: {
						placement: 'right',
						highlight: true
					}
				},
				{
					target: '#for-min-0',
					content: 'Select the number of intervals/total duration \n',
					params: {
						placement: 'right',
						highlight: true
					}
				},
				{
					target: 'li#\\30 9\\:00-for-min-0',
					content: 'Select 9:00 \n',
					params: {
						placement: 'right',
						highlight: true
					}
				},
				{
					target: '#at-flex-level-0',
					content: 'Select your desired intensity level of the intervals\n',
					params: {
						placement: 'right',
						highlight: true
					}
				},
				{
					target: 'li#\\34 \\ -\\ Cardio-flex-level-0',
					content: 'Select 4 \n',
					params: {
						placement: 'right',
						highlight: true
					}
				},
				{
					target: '#add-set-btn',
					content: 'Add extra sets of intervals',
					params: {
						placement: 'right',
						highlight: true
					}
				},
				{
					target: '#every-min-1',
					content: 'Select your interval duration\n',
					params: {
						placement: 'right',
						highlight: true
					}
				},
				{
					target: 'li#\\30 1\\:00-duration-1',
					content: 'Select 01:00 minutes\n',
					params: {
						placement: 'right',
						highlight: true
					}
				},
				{
					target: '#for-min-1',
					content: 'Select the number of intervals/total duration \n',
					params: {
						placement: 'right',
						highlight: true
					}
				},
				{
					target: 'li#\\30 6\\:00-for-min-1',
					content: 'Select 6:00 \n',
					params: {
						placement: 'right',
						highlight: true
					}
				},
				{
					target: '#at-flex-level-1',
					content: 'Select your desired intensity level of the intervals\n',
					params: {
						placement: 'right',
						highlight: true
					}
				},
				{
					target: 'li#\\35 \\ -\\ Sprint-flex-level-1',
					content: 'Select 5 \n',
					params: {
						placement: 'right',
						highlight: true
					}
				},
				{
					target: '#submit-button-emmom',
					content: 'Finish template and add music here!',
					params: {
						placement: 'top',
						highlight: true
					}
				},
				{
					target: '#genre-id-23 .md-checkbox-container',
					content: 'Check EDM box\n',
					params: {
						placement: 'right',
						highlight: true
					}
				},
				{
					target: '#end-of-interval-sound',
					content: 'Toggle "Do you want end of interval indicators/sound effects" to Yes\n',
					params: {
						placement: 'right',
						highlight: true
					}
				},
				{
					target: '#options-countdown-beeps',
					content: 'Select "Countdown - Male 1"\n\n',
					params: {
						placement: 'left',
						highlight: true
					}
				},
				{
					target: '#countdown-beeps-value-1',
					content: 'Select "Countdown - Male 1"\n\n',
					params: {
						placement: 'left',
						highlight: true
					}
				},
				{
					target: '#end-of-workout-sound',
					content: 'Toggle "Do you want and end of workout sound effects" to Yes\n\n',
					params: {
						placement: 'right',
						highlight: true
					}
				},
				{
					target: '#options-mix-indicators-beeps',
					content: 'Select "DJ Backspin 1"\n\n',
					params: {
						placement: 'right',
						highlight: true
					}
				},
				{
					target: '#mix-indicators-beeps-value-0',
					content: 'Select "DJ Backspin 1"\n\n',
					params: {
						placement: 'left',
						highlight: true
					}
				},
				{
					target: '#submit-button-emmom',
					content: 'Finish template and add music here!',
					params: {
						placement: 'top',
						highlight: true
					}
				},
				{
					target: 'button#plus-icon-0',
					content: 'Expand to see songs in this interval',
					params: {
						placement: 'right',
						highlight: true,
					}
				},
				{
					target: '#play-icon-inner-0-0',
					content: 'Play first song listed in the interval',
					params: {
						placement: 'top',
						highlight: true,
					}
				},
				{
					target: '#flex-level-select-0',
					content: 'Change Flex Level from 4 to 3',
					params: {
						placement: 'right',
						highlight: true,
					}
				},
				{
					target: '#flex-level-select-0-2 ',
					content: 'Select 3',
					params: {
						placement: 'right',
						highlight: true,
					}
				},
				{
					target: '#genre-select-0-0',
					content: 'Adjust music category from EDM to Hip Hop for the songs listed in this interval',
					params: {
						placement: 'right',
						highlight: true,
					}
				},
				{
					target: 'li#genre-select-0-0-Hip\\ Hop',
					content: 'Select Hip Hop',
					params: {
						placement: 'right',
						highlight: true,
					}
				},
				// {
				// 	target: '#like-dislike-replace-buttons',
				// 	content: 'Buttons to Like, Dislike (and Replace), or just Replace each song \n',
				// 	params: {
				// 		placement: 'left',
				// 		highlight: true,
				// 	}
				// },
				{
					target: '#show-name-input-0',
					content: 'Check Box and name interval \n',
					params: {
						placement: 'right',
						highlight: true,
					}
				},
				{
					target: '#interval-name-input-0',
					content: 'Name interval Squats and click outside the box\n',
					params: {
						placement: 'right',
						highlight: true,
					}
				},
				{
					target: '#volume-menu-button-0',
					content: 'Change volume to 50%\n',
					params: {
						placement: 'right',
						highlight: true,
					}
				},
				{
					target: '#volume-level-select-0-1',
					content: 'Change volume to 50%\n',
					params: {
						placement: 'left',
						highlight: true,
					}
				},
				{
					target: '#generate-mix-button',
					content: 'After making your song selections, click generate mix. Our AI DJ will work it\'s magic! \n',
					params: {
						placement: 'right',
						highlight: true,
					}
				},
				{
					target: '#name-playlist',
					content: 'Name your Mix within this Blueprint \n',
					params: {
						placement: 'right',
						highlight: true,
					}
				},
				{
					target: '#submit-button-playlist',
					content: 'Click Submit to generate and save your Mix \n',
					params: {
						placement: 'right',
						highlight: true,
					}
				},
				{
					target: '#play-whole-mix-button',
					content: 'Click play mix button\n',
					params: {
						placement: 'right',
						highlight: true,
					}
				},
				{
					target: '#play-stop .md-icon',
					content: 'Once mix is Ready, you play here or on the Blueprint dashboard.\n',
					params: {
						placement: 'top',
						highlight: true
					}
				}
			],
		}),

		methods: {

			myCustomPreviousStepCallback(currentStep) {

				document.body.classList.remove('v-tour--active');
			},

			myCustomNextStepCallback(currentStep) {
			},

			myCustomSkipCallback() {

				this.tourGuideFinish();
			},

			myCustomFinish() {

				this.tourGuideFinish();
				this.$tours['myTour'].finish();
			},

			tourGuideFinish() {

				this.$store.dispatch('changeIsTourGuideActive', false);
				this.$store.dispatch('changeShowTourGuideRibbon', true);

				UserService.setActiveUserTourGuide();

				this.$router.push('/dashboard');
			}
		},

		computed: {
			...mapGetters(
				{
					showTourFinish: 'getShowTourFinish'
				},
			)
		},

		mounted() {

			// this.$nextTick(() => {

			//start tour guide manualy
			// this.$store.dispatch('changeIsTourGuideActive', true);
			// this.$tours['myTour'].start();

			// this.$store.dispatch('changeOpenMenuForTourGuide', true);

			// console.log("curent step: " + this.$tours['myTour'].currentStep);

			// UserService.getUserTourGuide()
			// 	.then( res => {
			//
			// 		this.$store.dispatch('changeIsTourGuideActive', true);
			// 		// this.$store.dispatch('changeOpenMenuForTourGuide', true);
			//
			// 		this.$tours['myTour'].start();
			// 	});

			// this.$store.dispatch('changeIsTourGuideActive', true);
			// // this.$store.dispatch('changeOpenMenuForTourGuide', true);
			//
			// this.$tours['myTour'].start();
			// });
		}
	}
</script>

<style lang="scss" scoped>

	::v-deep body.v-tour--active {
		pointer-events: auto !important;
	}

	::v-deep .v-tour__target--highlighted {
		box-shadow: 0 0 0 99999px rgba(0, 0, 0, .7) !important;
	}

	@media only screen and (min-width: 1278px) {

		::v-deep .v-tour {

			&--active {
				pointer-events: auto !important;
			}

			.v-step {

				&__header {
					background-color: transparent;
				}

				position: absolute;
				max-width: 250px;
				left: 120px !important;
				top: 105px !important;

				background: #FFFFFF;
				color: #000000;
				font-size: 14px;
				font-family: 'Roboto';
				font-weight: 500;
				border-radius: 10px;

				&__button {
					font-size: 1rem !important;
					color: #000000 !important;;
					border: .05rem solid $color-accent-purple !important;

					&:hover {
						background-color: $color-accent-purple;
						color: white !important;
					}
				}

				&__content {
					margin: 0 !important;
				}
			}
		}

		::v-deep .v-step__arrow {

			background: url(../public/arrow-guide.png) no-repeat !important;
			border-width: 0 !important;
			border-left-color: transparent !important;
			border-top-color: transparent;
			border-bottom-color: transparent;
			left: -13rem !important;
			top: calc(-13rem) !important;
			margin-left: 0;
			margin-right: 0;
			width: 200px !important;
			height: 150px !important;
		}

		::v-deep .v-step__arrow.v-step__arrow--dark {

			background: url(../public/arrow-guide.png) no-repeat !important;
			border-width: 0 !important;
			border-left-color: transparent !important;
			border-top-color: transparent;
			border-bottom-color: transparent;
			left: -14.5rem !important;
			top: calc(-143%) !important;
			margin-left: 0;
			margin-right: 0;
			width: 200px !important;
			height: 150px !important;
		}

		#v-step-53e90a60 {
			left: 380px !important;
		}

		#v-step-002c7ed4 {
			left: 21rem !important;
		}

		#v-step-d1fbd2ae {
			left: -115px !important;

			::v-deep .v-step__arrow {

				left: 0 !important;
				transform: rotateY(180deg);
			}
		}

		#v-step-b7063c72 {
			left: -70px !important;

			::v-deep .v-step__arrow {

				left: 0 !important;
				transform: rotateY(180deg);
			}
		}

		#v-step-43857eea {
			left: -320px !important;

			::v-deep .v-step__arrow {

				left: 0 !important;
				transform: rotateY(180deg);
			}
		}

		#v-step-49e9d954, #v-step-39eb8042 {
			left: 33rem !important;
		}

		#v-step-bc373fbc {
			left: 40rem !important;
		}

		#v-step-19a670df, #v-step-834edece, #v-step-00c3742b {
			left: 23rem !important;
		}

		#v-step-26e8a4b8 {
			top: -100px !important;

			::v-deep .v-step__arrow {
				transform-origin: 103% 105%;
				transform: rotateX(180deg);
			}
		}

		#v-step-b7fc07d0 {
			max-width: 220px;
			border-radius: 10px;

			left: 450px !important;
			top: 115px !important;
		}

		#v-step-d82adf5a {
			left: -50px !important;
			top: -100px !important;

			::v-deep .v-step__arrow {
				transform: rotate(180deg);
				transform-origin: 103% 105%;
			}
		}

		#v-step-79ac4e86 {
			left: -10px !important;
			top: -100px !important;

			::v-deep .v-step__arrow {
				transform: rotate(180deg);
				transform-origin: 103% 105%;

			}
		}

		//bottom right positon
		#v-step-24531534 {
			left: 260px !important;
			top: 50px !important;
		}

		#v-step-269e7dec, #v-step-d9234c78, #v-step-0b410070,
		#v-step-269e7dea, #v-step-d9234c76, #v-step-30f40964,
		#v-step-298d054d, #v-step-f447c9fc, #v-step-f81bcea8,
		#v-step-dc444bc2, #v-step-6b062d07, #v-step-ac44431e,
		#v-step-0277efa8, #v-step-b67ef1de, #v-step-dc98bd7e,
		#v-step-1058ac67, #v-step-3ac8c432, #v-step-f05fa5fe,
		#v-step-a74fa238 {
			left: 290px !important;
			top: 120px !important;
		}

		//bottom right
		#v-step-eb28e5b8, #v-step-eb28e5b6 {
			left: 140px !important;
			top: 120px !important;
		}

		#v-step-46547cba, #v-step-28613985 {
			left: 410px !important;
		}

		#v-step-28613985 {
			left: 470px !important;
		}

		//top left
		#v-step-7ddb1690 {
			left: -240px !important;
			top: -60px !important;

			::v-deep .v-step__arrow {
				transform: rotate(180deg);
				transform-origin: 103% 115%;

			}
		}

		#v-step-56128b88, #v-step-1fabe528 {
			left: 100px !important;
			top: -240px !important;

			::v-deep .v-step__arrow {
				transform: rotate(260deg);
				transform-origin: 103% 105%;

			}
		}

		#v-step-46b1381a, {
			left: 50px !important;
			top: -240px !important;

			::v-deep .v-step__arrow {
				transform: rotate(260deg);
				transform-origin: 103% 105%;
			}
		}

		#v-step-29ae7316 {
			left: 200px !important;
			top: -260px !important;

			::v-deep .v-step__arrow {
				transform: rotate(260deg);
				transform-origin: 103% 105%;
			}
		}

		#v-step-0c15c6b8 {
			display: none;
		}

		//playlist page

		#v-step-a70ef3d8 {
			top: 120px !important;
		}
		// bottom left possition
		#v-step-7e132c12 {
			top: 130px !important;
			left: -20px !important;

			::v-deep .v-step__arrow {
				transform: rotate(180deg) rotateX(180deg);
				transform-origin: 103% 105%;
			}
		}

		#v-step-257c50ad {
			top: 90px !important;
			left: -70px !important;

			::v-deep .v-step__arrow {
				transform: rotate(180deg) rotateX(180deg);
				transform-origin: 103% 105%;
			}
		}

		#v-step-065bb7c9 {
			top: 100px !important;
			left: -120px !important;

			::v-deep .v-step__arrow {
				transform: rotate(180deg) rotateX(180deg);
				transform-origin: 103% 105%;
			}
		}

		#v-step-d54b49e4 {
			top: 130px !important;
		}

		#v-step-9c993e0e {
			top: 160px !important;
		}

		//final step
		#v-step-1d434433 {
			left: 115px !important;
			top: -170px !important;

			::v-deep .v-step__arrow {
				transform: rotate(260deg);
				transform-origin: 103% 105%;
			}
		}
	}

	@include respond(medium) {
		::v-deep .v-tour {
			&--active {
				pointer-events: auto !important;
			}

			.v-step {
				font-size: 12px;
				padding: 0.5rem !important;
				max-width: 110px;
			}
		}

		#v-step-9c993e0e, #v-step-e4472fe2, #v-step-f81bcea8, #v-step-dc98bd7e,
		#v-step-6b062d07 {
			left: 150px !important;
		}

		#v-step-269e7dec, #v-step-d9234c78, #v-step-0b410070, #v-step-298d054d,
		#v-step-f447c9fc, #v-step-0277efa8 {
			left: 100px !important;
		}
	}
</style>
