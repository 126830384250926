import $axios from '../plugins/axios'

class UserService {

    getAll() {

        return $axios.get('/users')
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getOne(id) {

        return $axios.get(`/users/${id}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    updateUser(data) {

        return $axios.put(`/users/${data.id}`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getUserTourGuide() {

        return $axios.get('/user-tour-guide')
            .then(res => {

                return res.data;
            })
    }

    setActiveUserTourGuide() {

        return $axios.post('/user-tour-guide', { template_created: true })
            .then(res => {

                return res.data;
            })
    }
}

export default new UserService();