<template>
    <div id="activateAcc-main" class="auth-container">
        <div class="auth-container__form-wrap">
            
            <AnimatedLogoPreloader/>  
            
            <p class="auth-container__form-wrap__form-description">
                We've just sent you an email confirmation code.<br/>  When you get it, enter it here to confirm your phone registration.
            </p>
            
            <form class="auth-form auth-form--with-subhead" v-on:keyup.enter="confirmPhone()">
                <AppInputComponent
                        class="auth-form__field"
                        required
                        placeholder="Enter your confirmation code"
                        v-model="$v.code.$model"
                        :error="$v.code.$error && !$v.code.required"
                        errorText="Confirmation Code is required"
                />

                <md-button class="md-primary md-raised auth-form__submit-btn md-theme-light"
                           :disabled="$v.$invalid"
                           @click="confirmPhone()">NEXT
                </md-button>
            </form>

        </div>

    </div>
</template>

<script>
    import {required} from 'vuelidate/lib/validators'
    import AnimatedLogoPreloader from "@/components/Common/AnimatedLogoPreloader";

    export default {
        name: 'AuthActivateAccount',
        data: () => ({
            code: ''
        }),
        components: {
            AnimatedLogoPreloader
        },
        validations: {
            code: {required},
        },
        methods: {
            confirmPhone(){
                this.$store.dispatch('showSnackbarMessage', {message: 'Successfully confirmed', mode: 'fail'});
            }
        }

    }
</script>

<style lang="scss" scoped>

    @include authPage;

    .logo-image{
        margin-bottom: 2.3%;
    }
</style>