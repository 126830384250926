<template>
    <md-dialog  :md-active.sync="showModal" :md-click-outside-to-close="false">

        <!-- <md-dialog-title>Update invitation</md-dialog-title> -->
        <div style="display: flex;text-align: left;margin-bottom:30px ;">
                    <div style="flex:90;text-align: left">
                        <md-dialog-title style="margin: 0;padding: 0">Edit User</md-dialog-title>
                    </div>
                    <div style="flex 5;cursor: pointer;" 
                            @click="closeDialog()">
                            <img style="max-width:15;width:15;height: 15;max-height: 15px;" src="@/assets/images/x.png" alt="account-image">

                        </div>
                </div>
        <form style="margin:0"  class=" ">
            <md-field   class="input">
                        <label class="label figtree-normal-white-12px">First Name</label>
                        <md-input  style="width:100%;" class="figtree-normal-white-16px-2"
                                v-model="$v.singleInvitation.first_name.$model" 
                                required
                                >
                        </md-input>
            </md-field>
        
            <md-field   class="input">
                        <label class="label figtree-normal-white-12px">Last name</label>
                        <md-input  style="width:100%;" class="figtree-normal-white-16px-2"
                                v-model="$v.singleInvitation.last_name.$model" 
                                required
                                >
                        </md-input>
            </md-field>

            <md-field   class="input">
                        <label class="label figtree-normal-white-12px">Email</label>
                        <md-input  style="width:100%;" class="figtree-normal-white-16px-2"
                                v-model="$v.singleInvitation.email.$model" 
                                required
                                >
                        </md-input>
            </md-field>
            
            <md-field  class="input">
                <label>Choose Role</label>
                <md-select v-model="$v.singleInvitation.role_id.$model" required name="Role" id="Role">
                    <md-option  v-for="(role, index) in roles" :key="index" :value="role.id">
                        {{role.name}}
                    </md-option>
                </md-select>
            </md-field>
            

            <div  v-if="userRole == 1">
                <md-field style="width:100%;" class="input">
                    <label>Choose Account</label>
                    <md-select v-model="$v.singleInvitation.account_id.$model" required name="Role" id="Role">
                        <md-option  v-for="(account, index) in accounts" :key="index" :value="account.id">
                            {{account.name}}
                        </md-option>
                    </md-select>
                </md-field>      
            </div>
            <md-field style="width:100%;" class="input"  v-if="filteredStore.length > 0">
                    <label>Choose Facility</label>
                    <md-select v-model="$v.singleInvitation.store_id.$model" required name="Role" id="Role">
                        <md-option   v-for="(store, index) in filteredStore" :key="index" :value="store.id">{{store.name}}
                        </md-option>
                    </md-select>
                </md-field>


            <div class="store-empty-error" v-if="filteredStore.length === 0 && singleInvitation.account_id">
                You can't update invitation because there are no stores attached to this account. Choose another account
            </div>

            <div class="md-layout-item md-raised md-xlarge-size-90 md-large-size-90 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
                <div class="md-layout md-gutter">
                    <div class="md-layout-item md-xlarge-size-80 md-large-size-60 md-medium-size-80 md-small-size-80 md-xsmall-size-80">
                    </div>
                </div>
            </div>
            <div style=" display: flex;width: 100%;">
                        <div style="flex:50"></div>
                        <div style="flex:50;">
                            <md-dialog-actions  class="form-buttons">
                                <md-button class="dialog__btn dialog__btn--danger" @click="closeDialog()">Cancel</md-button>
                                <md-button style="width:152px" class="md-primary dialog__btn md-raised md-theme-light"
                                        id="editBtn"
                                        :disabled="$v.singleInvitation.$invalid"
                                        @click="updateInvitation()">Save User
                                </md-button>
                            </md-dialog-actions>
                        </div>
            </div>
        </form>
    </md-dialog>
</template>

<script>

    import {required, email} from 'vuelidate/lib/validators';
    import AccountService from "../../services/account.service";
    import StoreService from '../../services/store.service';
    import RoleService from "../../services/role.service";
    import {mapGetters} from "vuex";

    export default {
        name: 'InvitationFormModal',

        props: {
            showModal: {
                type: Boolean,
                default: false
            },
            invitationProp: {
                type: Object,
                default: () => {
                    return {
                        first_name: '',
                        last_name: '',
                        email: '',
                        account_id: '',
                        store_id: '',
                        role_id: ''
                    }
                }
            }
        },

        data: () => ({
            singleInvitation: {
                first_name: '',
                last_name: '',
                email: '',
                account_id: '',
                store_id: '',
                role_id: ''
            },
            roles: [],
            accounts: [],
            stores: [],
            filteredStore: []
        }),

        validations: {
            singleInvitation: {
                first_name: {required},
                last_name: {required},
                email: {required, email},
                account_id: {required},
                store_id: {required},
                role_id: {required}
            }
        },

        methods: {

            closeDialog() {

                this.$emit('update:showModal', false);
            },

            getAllRoles() {

                RoleService.getAll()
                    .then((res) => {

                        this.roles = res;
                    })
                    .catch(() => {

                        this.$store.dispatch('showSnackbarMessage', {message: 'Failed. Please, try again.',
                            duration: 8000,
                            mode: 'fail'});
                    });
            },

            getAllStores() {

                return StoreService.getStores()
                    .then((res) => {

                        this.stores = res;

                        if(this.userRole == 2){
                            this.filteredStore = this.stores;
                        }
                    })
                    .catch(() => {

                        this.$store.dispatch('showSnackbarMessage', {message: 'Failed. Please, try again.', duration: 8000, mode: 'fail'});
                    });
            },

            getAllAccounts() {

                if (this.userRole == 1) {

                    return AccountService.getAccounts()
                        .then((res) => {

                            this.accounts = res;
                        })
                        .catch(() => {

                            this.$store.dispatch('showSnackbarMessage', {message: 'Failed Please, try again.', duration: 8000, mode: 'fail'});
                        });
                } else {

                    this.singleInvitation.account_id = this.$store.getters['auth/getCurrentUser'].account_id;
                }
            },

            updateInvitation() {

                let _this = this;
                let invitationObj = this.singleInvitation;

                this.$axios.put('/invites/' + invitationObj.id, invitationObj)
                    .then((response) => {

                        _this.$emit('getAllInvitedUsers');

                        _this.$store.dispatch('showSnackbarMessage', {
                            message: 'Invitation updated successfully',
                            duration: 4000,
                            mode: 'success'
                        });
                    })
                    .catch((error) => {

                        let errMsg = error.response.data;

                        this.$store.dispatch('showSnackbarMessage',
                            {message: errMsg, duration: 6000, mode: 'fail'})
                    });

                this.closeDialog();
            },

            async fetchData() {

                await this.getAllRoles();
                await this.getAllStores();
                await this.getAllAccounts();
            }
        },

        computed: {

            ...mapGetters(
                {
                  userRole: 'auth/currentUserRole',
                },
            ),
        },

        watch: {

            'singleInvitation.account_id': function(value){

                if (value) {

                    this.filteredStore = this.stores.filter(function( obj ) {
                        return obj.account_id == value;
                    });
                } else {

                }
            }
        },
        created() {

            let _this = this;

            _this.fetchData().then(() => {

                if (_this.invitationProp) {

                    _this.singleInvitation = _this.invitationProp;
                }
            });

        }
    }
</script>

<style lang="scss" scoped>
    @include dialogSettings();
    .dialog__hint {
        margin-bottom: 16px;
    }

    .md-dialog {

        .choose-file-btn {
            width: 30%;
        }

        .upload-cont {
            text-align: center;
        }

        .file-upload-label {
            font-size: 2rem;
        }
    }

    .form-buttons {
        margin-top: 20px;
        justify-content: space-around;
    }

    .input-file {
        display: none;
    }

    .update-form {

        .md-layout-item {
            margin: 0 auto;
        }
    }

    .md-dialog-title {
        text-align: center;
        font-size: 2.3rem;
    }

    .upload-dialog__preview {
        /*max-width: 100px;*/
	    margin: 20px auto;
    }

    .photo-error {
        color: #FF0900;
	    font-size: 1.7rem;
	    margin: 7px auto 0;
    }


    .md-helper-text {
        color: white !important;
    }

    .store-empty-error{
        color: red;
    }

</style>
