import $axios from '../plugins/axios'

class BluePrintService {

    getBluePrints(storeId, showPrivateBlueprints) {

        return $axios.get(`/templates/stores/${storeId}`, {
            params: {
                showPrivateBlueprints: showPrivateBlueprints
            }
        })
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }
    archiveBluePrint(data) {

        return $axios.post(`/templates/archives`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    unArchiveBluePrint(id) {

        return $axios.delete(`/templates/archives/${id}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getAllArchivedBlueprints(storeId) {

        return $axios.get(`/templates/archives/${storeId}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getAllDeletedBlueprints(storeId) {

        return $axios.get(`/templates/deleted/${storeId}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    restoreBlueprint(data) {

        return $axios.put(`templates/${data.id}/recover`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    createBluePrint(data) {

        return $axios.post(`/templates`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    deleteBluePrint(data) {

        return $axios.delete(`/templates/${data.id}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getSingleBluePrint(id) {

        return $axios.get(`/templates/${id}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getSingleBluePrintJobs(id) {

        return $axios.get(`/templates/${id}/jobs`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getBluePrintMix(id) {

        return $axios.get(`/templates/${id}/mix`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    updateBluePrint(data) {

        return $axios.put(`/templates/${data.id}`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getBluePrintPlaylists(id) {

        return $axios.get(`/templates/${id}/playlists`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }
}

export default new BluePrintService();
