import $axios from '../plugins/axios'

class ClassService {

    getClasses() {

        return $axios.get(`/class-definitions`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getSingleClass(id) {

        return $axios.get(`/class-definitions/${id}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log('error' + error);
                throw error;
            })
    }

    getSingleClassDefinition(id) {

        return $axios.get(`/class-definitions/${id}/classes`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log('error' + error);
                throw error;
            })
    }

    deleteSingleClass(id) {

        return $axios.delete(`/class-definitions/${id}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    editSingleClass(data) {

        return $axios.put(`/class-definitions/${data.id}`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    createSingleClass(singleClass) {

        return $axios.post('/class-definitions', singleClass)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    editSingleClassRoom(data) {

        return $axios.put(`/classes/${data.id}`, { room_id: data.room_id })
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getClassMix(id) {

        return $axios.get(`/classes/${id}/mix`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }
}

export default new ClassService();