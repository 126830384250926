<template>
	<md-button class="md-primary md-raised auth-form__submit-btn md-theme-light"
	           @click="$emit('click');"
			  :disabled="isDisabled">
		<span v-if="buttonLoadingSpinner === false">
			<slot></slot>
		</span>

		<AppSpinnerComponent v-if="buttonLoadingSpinner"
		                     :color="'#f6f6f6'"
		                     :loading="buttonLoadingSpinner"
		                     :size="'20px'"
		                     :type="'clip'"/>
	</md-button>
</template>

<script>
	import AppSpinnerComponent from "@/components/Base/AppSpinnerComponent";

	export default {
		name: "AppSpinnerComponentButton",
		components: {
			AppSpinnerComponent,
		},

		props: {

			isButtonLoadingSpinnerProp: {
				type: Boolean,
				default: false
			},

			isDisabledProp: {
				type: Boolean,
				default: false
			}
		},

		computed: {

			buttonLoadingSpinner() {

				return this.isButtonLoadingSpinnerProp;
			},

			isDisabled() {

				return this.isDisabledProp;
			}
		}
	}
</script>

<style scoped>

</style>