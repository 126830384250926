<template>
	<div class="component-wrap">
		<div class="div-top"
			:style="`background: linear-gradient(180deg, rgba(91.37, 91.37, 91.37, 0) 0%,${blueprint.color ? blueprint.color  : getRandomColor(Math.floor((Math.random() * 10) + 1))} 100%)`">
			<div style="text-align: left">
				<div class="figtree-normal-white-12px-2">{{ blueprint.is_shared ? 'Shared' : 'Private' }}</div>
				<h1 class="blueprint-name figtree-bold-white-46px">{{ blueprint.name }}</h1>
				<div v-if="blueprint.playlists" class="figtree-medium-white-20px">{{ blueprint.playlists.length }}
					Playlists</div>
			</div>
			<div class="button">
				<div class="button-1 figtree-normal-white-16px" @click="navigateToSectionPage">
          {{ blueprint.playlists && blueprint.playlists.length > 0 ? 'Show Sections' : 'Edit Blueprint' }}
        </div>
			</div>
		</div>

		<!-- 
		<AppHeaderComponent :headline=bluePrintName></AppHeaderComponent>

		<AppEmptyStateComponent message="There are no Workout Segments to display."
								v-if="playlists.length == 0 && !loadingSpinner"/>
		<AppHeaderComponent>

		<div class="md-layout md-alignment-center-space-between">
			<div class="md-layout-item md-size-100 md-small-size-100">
				<h2 style="visibility: hidden;"> PLAYLISTS/MIXES </h2>
			</div>

			<div class="md-layout-item md-size-70 md-small-size-100" style="margin-left: 30px; margin-bottom: 20px;">
					<div class="content-header__actions">
						<md-button
							class="md-raised md-primary md-raised md-theme-light md-fab"
							id="create-playlist-button"
							hintText="New Blueprint"
							@click.native="openPlaylistDialog()">
							<md-icon class="md-theme-light">add</md-icon>
						</md-button>

						<md-button
							class="md-raised md-primary md-raised md-theme-light deleteBtn"
							id="deleted-playlists-button"
							@click="goToDeletedPlaylists()">Deleted Playlists
						</md-button>

						<md-button
							class="md-raised md-primary md-raised md-theme-light archivedBtn"
							id="archived-playlists-button"

							@click="goToArchivedPlaylists()">Archived Playlists
						</md-button>
					</div>
			</div>
		</div>
	</AppHeaderComponent> -->

		<div class="playlist-dev">
			<AppHeaderComponent headline='Playlists'>
				<div class="content-header__actions">

					<md-button class="md-raised md-primary md-raised md-theme-light deleteBtn"
						id="deleted-playlists-button" @click="goToDeletedPlaylists()">Deleted Playlists
					</md-button>

					<md-button class="md-raised md-primary md-raised md-theme-light archivedBtn"
						id="archived-playlists-button" @click="goToArchivedPlaylists()">Archived Playlists
					</md-button>
				</div>
			</AppHeaderComponent>
			<div class="container">
				<div class="main-main-content">
					<div class="add-playlist-icon" @click="createPlaylistDialog">
						<img class="playlist-add-icon" src="@/assets/images/add.png"
							alt="add_FILL0_wght400_GRAD0_opsz48 3" />
					</div>
					<div class="add-playlist">
						<div class="add-playlist-icon-text figtree-medium-white-14px">Add Playlist</div>
					</div>
				</div>
				<div style="cursor: pointer" v-for="(playlist, index) in playlists">
					<PlaylistCard :id="'playlist_' + index" v-show="playlist.id" :label="'View Playlist'"
						:period="playlist" :key="index + '_periods'" :isDeleteDisableProp="isDeletePlaylistDisable"
						:randomColor="getRandomColor(index)" @editPlaylist="editPlaylist"
						@setLoadingSpinner="setLoadingSpinner" @refreshPlaylists="getAllPlaylistsForCurrentBluePrint"
						@tempRemovePlaylist="tempRemovePlaylist" @restorePlaylist="restorePlaylist"
						@archivePlaylist="archiveCurrentPlaylist(playlist.id)"
						@click.native="goToPlaylist(playlist.id)" />
				</div>

			</div>
		</div>

		<AppSpinnerComponent v-if="loadingSpinner" :loading="loadingSpinner" />

		
		<PlaylistDialog :showDialog.sync="showDialog" :key="dialogKey" :doWeEdit="doWeEdit"
			:playListProp="playlistToEdit" @refreshAllPlaylists="getAllPlaylistsForCurrentBluePrint" />

	</div>
</template>

<script>
import TrainerDashboardBluePrintFlexRate from '@/components/TrainerDashboard/TrainerDashboardBluePrintFlexRate';
import BluePrintService from '../../services/bluePrint.service';
import PlaylistsService from '@/services/playlists.service'
import draggable from 'vuedraggable'
import { mapGetters } from "vuex";
import PlaylistCard from "@/components/Playlists/PlaylistCard";
import PlaylistDialog from "../../components/TrainerDashboard/PlaylistDialog";
import { RandomColorsMixin } from "../../mixins/RandomColorsMixin";

export default {
	name: 'PlaylistsDashboard',
	mixins: [RandomColorsMixin],
	data: function () {
		return {
			doWeEdit: false,
			playlists: [],
			tempPlaylists: null,
			playlistToEdit: {},
			blueprint: '',
			loadingSpinner: true,
			showDialog: false,
			dialogKey: 0
		}
	},

	components: {
		PlaylistDialog,
		PlaylistCard,
		draggable,
		TrainerDashboardBluePrintFlexRate
	},

	methods: {

		setPageName() {

			this.$store.dispatch('changePageName', { pageNameString: 'Playlists/Mixes' });
		},

		navigateToSectionPage() {
			this.$router.push('/blueprints/' + this.blueprint.id + '/sections');
		},

		createPlaylistDialog() {
			if (this.blueprint.sections.length === 0) {
				this.$store.dispatch('showSnackbarMessage', {
					message: `Please add sections first before creating playlists.`,
					mode: 'fail',
				});
			}
			else {
				this.dialogKey++;
				this.showDialog = true;
				this.doWeEdit = false;
			}
		},

		openPlaylistDialog() {

			this.doWeEdit = false;
			this.playlistToEdit = null;
			this.dialogKey++;
			this.showDialog = true;
		},

		editPlaylist(periodId) {

			PlaylistsService.getSinglePlaylist(periodId)
				.then((res) => {
					this.doWeEdit = true;
					this.dialogKey++;
					this.playlistToEdit = res;
					this.showDialog = true;
				})
				.catch((err) => {

					this.$store.dispatch('showSnackbarMessage', {
						message: `${err.message}. Please, try again.`,
						mode: 'fail',
					});
				})
		},

		tempRemovePlaylist(id) {

			this.playlists = this.playlists.filter(playlist => playlist.id != id);
			this.$store.commit('playlists/setTempPlaylists', this.playlists);
		},

		restorePlaylist(id) {

			// this.$store.commit('playlists/setIsUndoActive', true);
			this.getAllPlaylistsForCurrentBluePrint();

			this.$store.dispatch('showSnackbarMessage', {
				message: 'The period was successfully restored.',
				duration: 3000,
				mode: 'success'
			});

		},

		getAllPlaylistsForCurrentBluePrint() {

			let bluePrintId = this.$route.params.id;

			PlaylistsService.getPlaylistsForBluePrint(bluePrintId)
				.then((res) => {

					// console.log(res);
					this.playlists = res;
					this.setLoadingSpinner(false);
					this.playlists.push({});
				})
				.catch((err) => {

					this.$store.dispatch('showSnackbarMessage', {
						message: `${err.message}. Please, try again.`,
						mode: 'fail',
					});
				});
		},

		goToPlaylist(id) {

			this.$router.push('/playlists/' + id);
		},

		setLoadingSpinner(isLoading) {

			this.loadingSpinner = isLoading;
		},

		getBluePrintName() {

			BluePrintService.getSingleBluePrint(this.$route.params.id)
				.then((res) => {

					this.blueprint = res;
				})
				.catch(() => {

					this.$store.dispatch('showSnackbarMessage', {
						message: 'Failed. Please, try again.',
						duration: 8000,
						mode: 'fail'
					});
				});
		},

		archiveCurrentPlaylist(id) {

			let userId = this.$store.getters['auth/getCurrentUser'].id;

			PlaylistsService.archivePlaylist({ playlist_id: id, user_id: userId }).then((res) => {

				this.getAllPlaylistsForCurrentBluePrint();

				this.$store.dispatch('showSnackbarMessage', {
					message: 'The blueprint was successfully archived.',
					duration: 3000,
					mode: 'success',
				});
			}).catch((err) => {
				this.$store.dispatch('showSnackbarMessage', {
					message: `${err.message}. Please, try again.`,
					mode: 'fail',
				});
			});
		},

		goToArchivedPlaylists() {

			this.$router.push('/archived-playlists/' + this.$route.params.id);
		},

		goToDeletedPlaylists() {

			this.$router.push('/deleted-playlists/' + this.$route.params.id);
		}
	},

	computed: {

		...mapGetters(
			{
				userRole: 'auth/currentUserRole',
			},
		),

		isDeletePlaylistDisable: {

			get() {

				return this.playlists.length > 2;
			},
		},

		listPlaylists: {

			get() {

				return this.playlists;
			},

			set(allPlaylist) {

				let bluePrintId = this.$route.params.id;
				allPlaylist.forEach((period, index) => {
					period.order = index;
				})

				PlaylistsService.restorePlaylist({ data: allPlaylist, id: bluePrintId })
					.then((res) => {
					})
					.catch(() => {

						this.$store.dispatch('showSnackbarMessage', {
							message: 'Failed. Please, try again.',
							duration: 8000,
							mode: 'fail'
						});
					});

				this.playlists = allPlaylist;
			}
		}
	},

	created() {
		this.setPageName();
		this.getBluePrintName();

		if (this.tempPlaylists) {

			this.playlists = this.tempPlaylists;
			this.setLoadingSpinner(false);
		} else {

			this.getAllPlaylistsForCurrentBluePrint();
		}

		this.$root.$on('undoDelete', this.restorePlaylist);
	},

	destroyed() {

		this.$root.$off('undoDelete', this.restorePlaylist);
	}
}
</script>

<style lang="scss" scoped>
.content__subtitle {
	text-align: left;
	margin: 5.6rem 0 2.4rem;
}

#archived-playlists-button {
	margin-left: 2.4rem;

	@include respond(xsmall) {
		margin-left: 0;
	}
}

#create-playlist-button {
	border-radius: 50%;

	@include respond(xsmall) {
		border-radius: 0;
	}
}

.content-header {

	&__actions {
		align-items: center !important;

		@include respond(small) {

			::v-deep .md-button {
				margin-bottom: 2rem !important;

			}
		}
	}
}

.container {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap: 20px;
	//width: 95%;
}

.main-main-content {
	background-color: var(--white-4);
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	height: 202px;
	overflow: hidden;
	position: relative;
	width: 152px;
	margin-bottom: 10px;
}

.add-playlist {
	display: flex;
	margin-left: 12px;
	width: 128px;
	text-align: center;
}

.playlist-add-icon {
	margin: auto;
	height: 48px;
	width: 48px;
}

.add-playlist-icon {
	display: flex;
	background-color: var(--cape-cod);
	border-radius: 6px;
	box-shadow: 0px 4px 4px #00000040;
	height: 130px;
	margin-left: 11px;
	margin-top: 12px;
	width: 130px;
	cursor: pointer;
}

.blueprint-name {
	margin-bottom: 20px;
	line-height: normal;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;


	@media only screen and (max-width: 620px) {
		color: var(--material-themerefsecondarysecondary100);
		font-family: var(--font-family-figtree);
		font-size: var(--font-size-xxl);
		font-weight: 700;
		font-style: normal;
	}

	;
}

.div-top {
	background-color: unset;
	height: 300px;
	padding: 30px;
}

.button {
	align-items: center;
	border: 1px solid;
	border-color: var(--material-themerefsecondarysecondary1007);
	border-radius: 4px;
	display: flex;
	height: 44px;
	justify-content: center;
	padding: 8px 24px;
	width: 148px;
	margin-top: 50px;
}

.button-1 {
	letter-spacing: 0;
	line-height: 20px;
	margin-left: -1.5px;
	margin-right: -1.5px;
	white-space: nowrap;
	width: fit-content;
	cursor: pointer;
}

.title {
	letter-spacing: 0;
	line-height: 40px;
	min-width: 213px;
	white-space: nowrap;
	margin: 25px 0px 25px 0px;
	text-align: left;
}

.playlist-dev {
	padding: 30px;
}
</style>
