import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import stores from './modules/stores'
import accounts from './modules/accounts'
import users from './modules/users'
import classes from './modules/classes'
import rooms from './modules/rooms'
import bluePrints from './modules/bluePrints'
import periods from './modules/periods'
import songs from './modules/songs'
import genres from './modules/genres'
import moods from './modules/moods'
import publishers from './modules/publishers'
import artists from './modules/artists'
import intervals from './modules/intervals'
import searches from "./modules/searches";
import subscriptions from "./modules/subscriptions";
import favoriteGenres from "./modules/favoriteGenres";
import favoriteArtists from "./modules/favoriteArtists";
import cards from "./modules/cards";
import audioPlayer from "./modules/audioPlayer";
import playlists from "@/store/modules/playlists";
import music from './music';
import keys from '../../apiKeys';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        snackbarMessageData: {
            message: '',
            duration: 3000,
            mode: 'success'
        },
        pageName: '',
        loadingSpinner: false,
        loadingOverlay: false,
        IsTourGuideActive: false,
        openMenuForTourGuide: false,
        showTourFinish: false,
        showTourGuideRibbon: false,
    },

    getters: {

        getSnackbarMessage(state) {
            return state.snackbarMessageData;
        },
        getCurrentPageName(state) {
            return state.pageName;
        },
        getLoadingSpinner(state) {
            return state.loadingSpinner;
        },
        getLoadingOverlay(state) {
            return state.loadingOverlay;
        },

        getIsTourGuideActive(state) {
            return state.IsTourGuideActive;
        },

        getOpenMenuForTourGuide(state) {
            return state.openMenuForTourGuide;
        },

        getShowTourFinish(state) {
            return state.showTourFinish;
        },

        getShowTourGuideRibbon(state) {
            return state.showTourGuideRibbon;
        },
    },

    mutations: {

        setSnackbarMessage(state, {message, duration = 4000, mode = 'success', buttonText = 'Close'}) {
            state.snackbarMessageData = {message, duration, mode, buttonText};
        },

        setPageName(state, data) {
            state.pageName = data.pageNameString;
        },

        setLoadingSpinner(state, data) {
            state.loadingSpinner = data.loading;
        },

        setLoadingOverlay(state, data) {
            state.loadingOverlay = data.loading;
        },

        setIsTourGuideActive(state, data) {
            state.IsTourGuideActive = data;
        },

        setOpenMenuForTourGuide(state, data) {
            state.openMenuForTourGuide = data;
        },

        setShowTourFinish(state, data) {
            state.showTourFinish = data;
        },

        setShowTourGuideRibbon(state, data) {
            state.showTourGuideRibbon = data;
        }
    },

    actions: {

        showSnackbarMessage(vuexContext, messageData) {
            vuexContext.commit('setSnackbarMessage', messageData);
        },

        changePageName(vuexContext, nameData) {
            vuexContext.commit('setPageName', nameData);
        },

        changeLoadingSpinner(vuexContext, data) {
            vuexContext.commit('setLoadingSpinner', data);
        },

        changeLoadingOverlay(vuexContext, data) {
            vuexContext.commit('setLoadingOverlay', data);
        },

        changeIsTourGuideActive(vuexContext, data) {
            vuexContext.commit('setIsTourGuideActive', data);
        },

        changeOpenMenuForTourGuide(vuexContext, data) {
            vuexContext.commit('setOpenMenuForTourGuide', data);
        },

        changeShowTourFinish(vuexContext, data) {
            vuexContext.commit('setShowTourFinish', data);
        },

        changeShowTourGuideRibbon(vuexContext, data) {
            vuexContext.commit('setShowTourGuideRibbon', data);
        },
    },

    modules: {
        auth,
        stores,
        accounts,
        users,
        classes,
        bluePrints,
        rooms,
        periods,
        songs,
        genres,
        moods,
        publishers,
        artists,
        intervals,
        searches,
        subscriptions,
        favoriteGenres,
        favoriteArtists,
        cards,
        audioPlayer,
        playlists,
        music
    }
});

MusicKit.configure({
    developerToken: keys.appleMusic.developerToken,
    app: {
        name: keys.appleMusic.name,
        build: keys.appleMusic.build,
    },
    loggingEnabled: false,
});
console = {
    ...console,
    info: () => {},
  };

export default store;
