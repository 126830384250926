import $axios from '../plugins/axios'

class RoomService {

    getRooms() {

        return $axios.get('/rooms')
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    createRoom(room) {

        return $axios.post('/rooms', room)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    editRoom(data) {

        return $axios.put(`/rooms/${data.id}`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getSingleRoom(id) {

        return $axios.get(`/rooms/${id}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    deleteRoom(id) {

        return $axios.delete(`/rooms/${id}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

}

export default new RoomService();