const musicState = {
    storeFront: '',
    auth: {
        isAuthorized: false,
    },
    search: '',
    searchResults: null,
    searchTyping: false,
};

const getters = {};

const mutations = {
    setSearchTyping(state, typing) {
        state.searchTyping = typing;
    },
    setSearch(state, search) {
        state.search = search;
    },
    setSearchResults(state, results) {
        state.searchResults = results;
    },
    setAuth(state, { auth }) {
        state.auth.isAuthorized = auth;
    },
    setStoreFront(state, { storefront }) {
        state.storeFront = storefront;
    },
};

const actions = {
    initializeState() {},
};

export default {
    namespaced: true,
    state: musicState,
    getters,
    mutations,
    actions,
};
