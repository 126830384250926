<template>
    <div class="component-wrap">

        <AppHeaderComponent :headline=bluePrintName>
            <div class="content-header__actions">
                <md-button class="md-raised md-primary md-raised md-theme-light"
                           id="go-to-blueprint"
                           @click="goToBluePrint(period.template_id)">
                    Go to the blueprint
                </md-button>

                <md-button class="md-raised md-primary md-raised md-theme-light"
                           id="add-button"
                           @click="openExerciseDialog(false)"
                           v-if="userRole < 5">
                    Add exercise / rest
                </md-button>
            </div>
        </AppHeaderComponent>

        <h2 class="period-name"> {{ period.name }} </h2>
		<h3 class="period-duration"> Total duration: {{ period.duration }} </h3>

		<AppSpinnerComponent v-if="loadingSpinner" :loading="loadingSpinner"/>

        <div class="blueprint__emptystate" v-if="intervals.length === 0">
            <md-icon class="md-size-5x md-theme-light">library_music</md-icon>
            <p class="md-body-1">Nothing added yet. Add your first music or rest section to get started.</p>
        </div>

        <div class="tracks-table" v-if="intervals.length">
            <div class="track-hdr">
                <h2 class="track-hdr__name">Duration</h2>
                <h2 class="track-hdr__name">Energy</h2>
                <h2 class="track-hdr__name">Genre</h2>
                <h2 class="track-hdr__name">Workout Type</h2>
            </div>

            <draggable v-model="listIntervals"  class="list-group" @start="drag=true" @end="drag=false">
                <TrainerDashboardBluePrintTrack
                        v-for="(interval, index) in intervals"
                        :trackData="interval"
                        :key="index + 'track'"
                        @refreshAllIntervals="getIntervalsByPeriod"
                        @openEdit="openEditModal"/>
            </draggable>
        </div>

<!--        <h2 class="content__subtitle">Interval</h2>-->

<!--        <div class="preferences">-->
<!--            <TrainerDashboardBluePrintPreferences/>-->
<!--            <TrainerDashboardClassPreferences/>-->
<!--        </div>-->

<!--        <h2 class="content__subtitle">Flex rate</h2>-->

<!--        <TrainerDashboardBluePrintFlexRate/>-->

        <!-- end of custom empty state for blue print -->

        <TrainerDashboardTrackDialog
                :interval-prop="interval"
                :isThisRest="isRest"
                :allIntervals="intervals"
                :showDialog.sync="showExerciseDialog"
                :key="dialogKey"
                @periodCreated="getIntervalsByPeriod"/>
    </div>
</template>

<script>
    import TrainerDashboardBluePrintTrack from "@/components/TrainerDashboard/TrainerDashboardBluePrintTrack.vue";
    import TrainerDashboardTrackDialog from "@/components/TrainerDashboard/TrainerDashboardTrackDialog";
    import TrainerDashboardBluePrintPreferences from '@/components/TrainerDashboard/TrainerDashboardBluePrintPreferences';
    import TrainerDashboardClassPreferences from '@/components/TrainerDashboard/TrainerDashboardClassPreferences';
    import TrainerDashboardBluePrintFlexRate from '@/components/TrainerDashboard/TrainerDashboardBluePrintFlexRate';
    import BluePrintService from '../../services/bluePrint.service';
    import IntervalService from '../../services/interval.service';
    import PeriodService from '../../services/period.service';
    import {mapGetters} from "vuex";
    import draggable from 'vuedraggable'

    export default {
        name: 'Period',

        data: () => ({
            period: {},
            intervals: [],
            interval: {},
            bluePrintName: '',
            showExerciseDialog: false,
            loadingSpinner: true,
            dialogKey: 0,
            isRest: false
        }),

        props: {
            periodProp: {
                type: Object,
                default() {
                    return {
                        id: '',
                        intervals: '',
                        name: '',
                        order: '',
                        template_id: ''
                    }

                }
            }
        },

        components: {
            draggable ,
            TrainerDashboardBluePrintTrack,
            TrainerDashboardTrackDialog,
            TrainerDashboardClassPreferences,
            TrainerDashboardBluePrintPreferences,
            TrainerDashboardBluePrintFlexRate
        },

        methods: {

            openExerciseDialog(isiTRest) {

                this.dialogKey++;
                this.isRest = isiTRest;
                this.showExerciseDialog = true;
                this.interval = null;
            },

            openEditModal(id) {

                this.getCurrentInterval(id);
                this.dialogKey++;
                this.showExerciseDialog = true;
            },

            getIntervalsByPeriod() {

                let periodId = this.$route.params.id;

                return PeriodService.getIntervalsByPeriod(periodId)
                    .then((res) => {

                        this.intervals = res;

                        let periodId = this.$route.params.id;
                        this.intervals.forEach((interval, index) => {
                            interval.order = index;
                        });

                        this.loadingSpinner = false;
                    })
                    .catch(() => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: 'Failed. Please, try again.',
                            duration: 8000,
                            mode: 'fail'
                        });
                    });
            },

            getCurrentInterval(id) {

                let intervalObj = this.intervals.find(x => x.id === id)
                this.interval = Object.assign({}, intervalObj);

                let intervalDuration = this.interval.duration;

                let durationObject = {
                    min: Number(intervalDuration.split(':')[1]),
                    sec: Number(intervalDuration.split(':')[2])
                };

                this.interval['durationObj'] = durationObject;
            },

            goToBluePrint(id){

                this.$router.push('/blueprints/' + id + '/periods');
            },

            getSinglePeriod() {

                return PeriodService.getPeriod(this.$route.params.id)
                    .then((res) => {

                        this.period = res;
                    })
                    .catch(() => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: 'Failed. Please, try again.',
                            duration: 8000,
                            mode: 'fail'
                        });
                    });
            },

            setBluePrintName(id) {

                BluePrintService.getSingleBluePrint(id)
                    .then((res) => {

                        this.bluePrintName  = res.name;
                    })
                    .catch(() => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: 'Failed. Please, try again.',
                            duration: 8000,
                            mode: 'fail'
                        });
                    });
            },

            async fetchData(){

                await this.getSinglePeriod();
                await this.getIntervalsByPeriod();
                await this.setBluePrintName(this.period.template_id);
            }
        },

        computed: {

            ...mapGetters(
                {
                  userRole: 'auth/currentUserRole',
                },
            ),

            listIntervals: {

                get() {

                    return this.intervals;
                },

                set(intervalList) {

                    let periodId = this.$route.params.id;
                    intervalList.forEach((interval, index) => {
                        interval.order = index;
                    });

                    IntervalService.reorderIntervals({ data: intervalList, id: periodId })
                        .then((res) => {
                        })
                        .catch(() => {

                            this.$store.dispatch('showSnackbarMessage', {
                                message: 'Failed. Please, try again.',
                                duration: 5000,
                                mode: 'fail'
                            });
                        });

                    this.intervals = intervalList;
                }
            }
        },

        created() {

            this.fetchData();
        },

    }
</script>

<style lang="scss" scoped>
    .content__subtitle {
        text-align: left;
        margin: 5.6rem 0 2.4rem;
    }

    .blueprint__emptystate {
        width: 250px;
        margin: 100px auto;
        text-align: center;
        color: $color-light-grey;

        .md-icon {
            margin-bottom: 2.4rem;
        }
    }

    .preferences {
        display: flex;
        justify-content: space-between;

        @include respond(small) {
            flex-direction: column;
        }

        & > * {
            width: 49%;
            @include respond(small) {
                width: 100%;
            }
        }
    }

    .track-hdr{
        width: 100%;
        height: 57px;
        display: flex;
        border-radius: 3px;
        transition: all .2s ease-in;
        backface-visibility: hidden;
        z-index: 1;
        position: relative;
        // overflow: hidden;

        @include respond(small) {
            margin-top: 3rem;
            padding-left: 6rem;
            flex-direction: column;
            height: auto;
        }

        &__name{
            text-align: left;
            min-width: 17%;
            font-size: 2.4rem;
            font-weight: 400;
            text-decoration: underline;
            color: $color-white;
            line-height: 57px;
            padding-left: 2.4rem;

            @include respond(small) {
                padding-left: 1.6rem;
            }
        }
    }

    .period-name {
        text-align: left;
    }

	.period-duration {
		text-align: right;
		padding-top: 10px;

		@include respond(small) {
			text-align: left;
		}
	}
</style>
