<template>
    <div class="component-wrap">

        <HeaderComponent headline="Upcoming classes"></HeaderComponent>

        <AppSpinnerComponent v-if="loadingSpinner" :loading="loadingSpinner"/>

        <BluePrintCard
                v-for="(bluePrint, index) in bluePrints"
                :key="index + '_bluePrints'"
                class="md-layout-item md-xlarge-size-30 md-large-size-30 md-medium-size-30 md-small-size-50 md-xsmall-size-100">
        </BluePrintCard>

        <AppEmptyStateComponent message="There are no Upcoming Classes to display." v-if="!upComingClasses.length"/>

    </div>
</template>

<script>

    import BluePrintCard from "@/components/BluePrints/BluePrintCard";

    export default {
        name: 'UpComingClasses',
        data: function() {
            return {
                upComingClasses: [],
                loadingSpinner: false,
                snackbar: {
                    show: false,
                    message: '',
                    duration: 4000
                },
            }
        },
        components: {
			BluePrintCard
        },
        created() {

        },
        methods: {

        }
    }
</script>

<style lang="scss" scoped>

    .design-orders-tabs {
        //$color-black
        border: 1px solid rgba(#222, .4);
    }
</style>