<template>
	<div id="login-main" class="auth-container">
		<AnimatedLogoPreloader></AnimatedLogoPreloader>
		<div class="auth-container__form-wrap">
			<div class="modal-title">
    			<div class="emom-title">
					<h1 class="title" id="login-header">Login</h1>
				</div>
			</div>
			
			<form class="auth-form" v-on:keyup.enter="login()">
				<div style="display: flex;flex-direction: column;gap: 0px;">
					<div style="flex:15">
						<md-field  :class="[{ 'md-invalid': $v.loginForm.email.$invalid && loginForm.email!=''},'input']">
							<label class="label figtree-normal-white-12px">Email</label>
							<md-input autocomplete="off" id="user-input" style="width:100%;" class="figtree-normal-white-16px-2"
									v-model="$v.loginForm.email.$model"
									required
									>
								</md-input>
								<span class="md-error" v-if="$v.loginForm.email.$invalid">Valid email is required</span>
						</md-field>
					</div>
					<div style="flex:15">
						<md-field id="password-field" :class="[{ 'md-invalid': $v.loginForm.password.$error && !$v.loginForm.password.required},'input']" >
							<label class="label figtree-normal-white-12px">Password</label>
							<md-input id="password-input" 
							 style="width:100%;" class="figtree-normal-white-16px-2"
									v-model="$v.loginForm.password.$model"
									required
									type="password"
									>
								</md-input>
								<span class="md-error" v-if="$v.loginForm.password.$error && !$v.loginForm.password.required">Password is required</span>
						</md-field>
					</div>
					<div  style="flex:20;display: flex;text-align: right;justify-content: right;margin-bottom: 10px;" class="auth-form__link" tabindex="0">
						<div class="forgot-pass">
							<p
								class="auth-form__link--forgot-pass-link figtree-medium-white-16px"
								@click="openResetPasswordDialog()">Forgot password ?</p>
						</div>
					</div>
					<div style="flex:20;min-height: 45px;"></div>
					<div style="flex:20">
						<AppSpinnerComponentButton style="width: 100%;height: 48px;"
							@click="login()"
							:is-disabled-prop = "$v.loginForm.$invalid"
							:is-button-loading-spinner-prop = "buttonLoadingSpinner"
							id="login-btn"
							class="figtree-semi-bold-white-18px"
						>
							Login <img style="width:24px;height: 24px;" src="@/assets/images/arrow-right-alt.png" alt="account-image">

						</AppSpinnerComponentButton>
					</div>
					<div class=" guest-login">
						<p class="">
    								<span class="figtree-medium-white-16px">Not registered yet? </span
    							><span class="figtree-medium-white-16px auth-form__link auth-form__link--log-as-guest" @click="goToRegister()">Create an Account</span>
  						</p>
							
						</div>
				</div>

				
			</form>
		</div>

		<AuthResetPasswordDialog
			:key="dialogKey"
			:showDialog.sync="showResetPassRequestDialog"/>

	</div>
</template>

<script>
import AuthResetPasswordDialog from '@/components/Auth/AuthResetPasswordDialog';
import AnimatedLogoPreloader from '@/components/Common/AnimatedLogoPreloader';
import {required, email} from 'vuelidate/lib/validators';
import AppSpinnerComponentButton from "@/components/Base/AppSpinnerComponentButton";

export default {
	name: 'AuthLogin',
	data: () => ({
		loginForm: {
			email: '',
			password: '',
		},
		dialogKey: 0,
		showResetPassRequestDialog: false,
		buttonLoadingSpinner: false,
	}),
	components: {
		AppSpinnerComponentButton,
		AnimatedLogoPreloader,
		AuthResetPasswordDialog,
	},
	validations: {
		loginForm: {
			email: {required, email},
			password: {required},
		},
		resetForm: {
			emailForResetLink: {required, email},
		},
	},
	methods: {
		login() {

			this.buttonLoadingSpinner = true;

			this.$store.dispatch('auth/login', this.loginForm).then((res) => {

				this.buttonLoadingSpinner = false;

				//Set axios Authorization header
				this.$axios.defaults.headers.common['Authorization'] = `Bearer ${res.auth_token}`;

				this.$router.push('/dashboard');
				// location.replace('/dashboard');
			}).catch((error) => {

				console.log(error)
				this.buttonLoadingSpinner = false;
			});
		},
		openResetPasswordDialog() {
			this.dialogKey++;
			this.showResetPassRequestDialog = true;
		},
		goToRegister() {
			this.$router.replace('/register');
		},
	},
	created() {
		setTimeout(()=>{
			const userInput = document.getElementById('user-input');
			const loginHeader = document.getElementById('login-header');
			const passField = document.getElementById('password-field');
			passField.classList.add('md-has-value')
			loginHeader.click()
			userInput.focus();
      },1)
	},
};
</script>

<style lang="scss" scoped>
@include authPage();

.guest-login {
	text-align: center;
	flex:20;
}

.forgot-pass {
	text-align: right;
}

.auth-form__link ,.auth-form__link--forgot-pass-link {
	color: var(--lavender);
	cursor: pointer;
}

.auth-form__link--forgot-pass-link:hover, .auth-form__link--log-as-guest:hover {
	color: white;
}

#login-main{
	@media only screen and (max-width: 481px) {
				height: 890px!important;
			};
}
</style>