<template>
    <div class="component-wrap">
        <AppHeaderComponent
                class="about-page"
                headline="Reflex Radio - About">

        </AppHeaderComponent>
    </div>
</template>

<script>
    export default {
        name: 'AboutDashboard',
        data: () => ({

        }),
        methods: {
            setPageName() {
                this.$store.dispatch('changePageName', {pageNameString: 'About'});
            },
        },
        created() {
            this.setPageName();
        }

    }
</script>

<style lang="scss" scoped>
    .content-header.about-page {
        margin-bottom: 40px;
    }
</style>