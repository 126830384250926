<template>
  <div v-if="trackData">
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false" style="align-items: center;">

      <div style="flex:100;display: flex;flex-direction: column;align-items: center;width: 100%;" v-if="trackData.song">
        <div style="flex:3;align-self: flex-start;" @click="closeDialog(true)">
          <md-icon class="expand-button md-fab">arrow_back_ios</md-icon>
        </div>
        <div style="flex:90;margin-bottom: 20px;display: flex;flex-direction: column;width: 100%;"
          class="rectangle-319 figtree-medium-sonic-silver-14px">


          <AppInputComponent style="flex:5;padding-bottom: 0;justify-content: end;width: 100%;"
            class="create-form__field" required placeholder="Search and select to Replace..." v-model="searchTerm" />
          <div :class="['scrollbar-div', { 'scrollbar-div-active': searchTerm && !loading }]">
            <div
              v-if="searchTerm && !loading && searchResults && searchResults.data.results.songs && searchResults.data.results.songs.data">

              <div class="song" v-for="(song, index) in searchResults.data.results.songs.data" :key="index"
                @click="appleSong(song, trackData)">
                <div class="song-image">
                  <img width="40px" height="40px" :src="getUrl(song.attributes.artwork, 40)"
                    alt="`artwork of track ${track.attributes.name}`" />
                </div>

                <div class="song-details">
                  <p><span v-if="song.attributes.contentRating === 'explicit'"
                      class="explicit-content figtree-normal-white-12px">E</span>{{
                        song.attributes.name
                      }} ,
                    {{ song.attributes.artistName }}</p>
                  <a>{{ song.attributes.albumName }}</a>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </md-dialog>

  </div>
</template>

<script>
import AppColorPicker from "@/components/Base/AppColorPicker";
import AppSpinnerComponentButton from "@/components/Base/AppSpinnerComponentButton";
import AudioPlayer from "@/components/AudioPlayer/AudioPlayer";
import flexLevels from "@/components/TrainerDashboard/flexLevels";
import { MusicKitHelperMixin } from "../../mixins/MusicKitHelperMixin";

export default {
  name: 'AppleSearchDialog',
  mixins: [MusicKitHelperMixin],

  components: {
    AppColorPicker,
    AppSpinnerComponentButton,
    AudioPlayer,
    flexLevels,
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },

    trackData: {
      type: [Object, Array],
      required: true
    },

  },
  data: () => ({
    searchTerm: '',
    searchResults: [],
    loading: false,

  }),
  validations: {

  },
  methods: {
    async appleSong(song, trackData) {
      await this.$emit('appleSong', song, trackData.id);
      this.searchTerm = '';
      this.closeDialog();
    },
    closeDialog() {
      this.$emit('update:showDialog', false);
    },
  },

  async created() {
    this.music = MusicKit.getInstance();
  },
  watch: {
    async searchTerm() {
      if (!this.searchTerm) return;
      this.loading = true;
      const music = await MusicKit.getInstance();

      try {
        const queryParameters = { term: this.searchTerm, types: ['songs'], limit: 25 };
        this.searchResults = await music.api.music(`/v1/catalog/${music.storefrontCountryCode}/search`, queryParameters);
        this.loading = false;
      } catch (e) {
        console.log('error', e)
        this.loading = false;
      }

    }

  }
}
</script>

<style lang="scss" scoped>
@include dialogSettings();

.md-dialog .md-dialog-container {
  max-width: 768px;
}

::v-deep .pick-bp-name input::placeholder {
  color: #BEBEC8 !important;
  font-weight: 500;
}

::v-deep .md-dialog .md-dialog-container {
  align-items: center !important;
  ;
}

::v-deep .pick-bp-name input {
  padding-left: 5px;
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px solid white !important;
  font-size: 1.7rem;
  color: white !important;

  &:active {
    border: none;
    background-color: transparent;
    border-bottom: 1px solid white;
  }

  &:focus {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid white;
  }
}


::v-deep .pick-bp-tags input::placeholder {
  font-size: 1.3rem !important;
}


::v-deep .v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, .7) !important;
}

.menu-container {
  z-index: 100 !important;
}

::v-deep .md-dialog-container {
  background-color: var(--heavy-metal) !important;
}

::v-deep .md-tabs .md-tabs-content {
  background-color: var(--heavy-metal) !important;
}

::v-deep .md-tabs.md-theme-default .md-tabs-navigation {
  background-color: var(--heavy-metal) !important;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--gray);
}

.song-details {
  flex-shrink: 0;
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;

  a,
  p {
    padding: 0;
    margin: 0;
    color: white;
    text-decoration: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  a:hover {
    text-decoration: underline;
  }



}

.song-image {
  height: 40px;
  border-radius: 4px;
  flex: 25;
  min-width: 40px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }

  position: relative;
}

.song {
  display: flex;
  padding: 8px;
  border-radius: 8px;
  width: 100%;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  text-align: left;
}

.scrollbar-div {
  width: 100%;
  background-color: #2C2C2C;
  z-index: 30;
  flex: 95;
}

.scrollbar-div-active {
  background-color: #3a3a3a !important;
}

.explicit-content {
  font-family: var(--font-family-figtree);
  font-size: var(--font-size-xxs);
  font-weight: bolder;
  font-style: normal;
  border: 1.5px solid var(--nobel);
  color: white;
  margin-right: 5px;
}
</style>
