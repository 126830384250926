import SearchService from '../../services/search.service';

const state = () => ({
    searchResults: null
});

const getters = {
    getSearchResults(state) {
        return state.searchResults;
    }
};

const mutations = {
    setSearchResults(state, searchResults) {
        state.searchResults = searchResults;
    }
}

const actions = {

    getSearchResults(vuexContext, searchInput) {

        return SearchService.getSearchResults(searchInput)
            .then(res => {

                vuexContext.commit('setSearchResults', res);
                return res;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}