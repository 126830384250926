export const DeepFilterMixin = {
    methods: {
        filterFunction(data, filter) {
            const filters = filter.split(' ');
            let keywordsFound = 0;

            let isFound;
      
            for (const fil of filters) {
              isFound = false;

              for (const prop in data) {

                  if(data[prop] && typeof data[prop] === 'object'){
                      for (const propDeep in data[prop]) {
                          if (data[prop][propDeep] && data[prop][propDeep].toString().toLowerCase().indexOf(fil.toLowerCase()) !== -1) {
                              isFound = true;
                          }
                      }
                  }
                  else{
                      if (data[prop] && data[prop].toString().toLowerCase().indexOf(fil.toLowerCase()) !== -1) {
                          isFound = true;
                      }
                  }
              }

              if (isFound) {
                keywordsFound++;
              }

              if (keywordsFound === 0) {
                return false;
              }
            }
            // check each keyword
      
            return keywordsFound === filters.length;
            // if we found less keywords then filters array contain - return false
          },

        convertAMPMForStr(timeObj){
            let timeType = timeObj.split(':')[1].split(' ')[1];
            let hours = timeObj.split(':')[0];
            let minutes = timeObj.split(':')[1].split(' ')[0];

            hours = Number(hours);

            if(timeType == 'PM' && hours != 12){
                hours = hours + 12;
            }

            if (timeType == 'AM' && hours < 10) {
                hours = '0' + hours;
            }

            if (timeType == 'AM' && hours == 12) {
                hours = '00';
            }

            let currentTime = hours + ':' + minutes;

            return currentTime;
        },

        timePropsValueForStr(timeObj) {

            console.log(timeObj);

            let isTimeType = false;
            let isHours = false;
            let isMinutes = false;

            let timeType = timeObj.split(':')[1].split(' ')[1];
            let hours = timeObj.split(':')[0];
            let minutes = timeObj.split(':')[1].split(' ')[0];

            if(timeType.length === 2){
                isTimeType = true;
            }

            if(!isNaN(hours)){
                isHours = true;
            }

            if(!isNaN(minutes)){
                isMinutes = true;
            }

            return isTimeType && isHours && isMinutes;
        },

        timePropsValueObj(obj) {

            for (let key in obj) {
                if (obj[key].length === 0)
                    return false;
            }

            return true;
        },

        convertAMPMObj(timeObj){

            let timeType = timeObj['A'];
            let hours = Number(timeObj['hh']);

            if(timeType == 'PM' && hours != 12){
                hours = hours + 12;
            }

            if (timeType == 'АM' && hours == 12) {
                hours = 0;
            }

            hours = hours.toString();

            let currentTime = hours + ':' + timeObj['mm'];

            return currentTime;
        },

        convertTimeFrom24FormatToTimeObject(timeObj) {

            let hours = timeObj.split(':')[0];
            let minutes = timeObj.split(':')[1];
            let timeType = 'AM';

            let hoursNumber = Number(hours);

            if(hoursNumber > 12){
                hours = hoursNumber - 12;
                timeType = 'PM';
            }

            if (hoursNumber == 0) {
                hours = 12;
                timeType = 'AM';
            }

            if(hoursNumber == 12){
                timeType = 'PM';
            }

            if (Number(hours) < 10 && timeType == 'PM'){
                hours = '0' + hours.toString();
            }

            let timeAsObj = {
                hh: hours.toString(),
                mm: minutes,
                A: timeType
            };

            return timeAsObj;
        },

        convertTimeFormat(timeObj){

            const time = this.convertTimeFrom24FormatToTimeObject(timeObj);

            let timeAsStr = time.hh + ':' + time.mm + ' ' + time.A;

            return timeAsStr;
        },

        convertTimeFormatEdit(timeObj){

            const time = this.convertTimeFrom24FormatToTimeObject(timeObj);

            return time;
        },

        convertTimeFormatUndo(timeObj){

            console.log(timeObj);

            let hours = timeObj.split(':')[0];
            let minutes = timeObj.split(':').split(' ')[0];
            let timeType = timeObj.split(':').split(' ')[1];

            let timeAsObj = {
                hh: hours,
                mm: minutes,
                A: timeType
            };

            return timeAsObj;
        },
    }
}