import $axios from '../plugins/axios'

class CardService {

    getAllCards() {

        return $axios.get('/cards')
            .then(res => {

                return res.data;
            })
    }

    removeCard(id) {

        return $axios.delete(`/cards/${id}`)
            .then(res => {

                return res.data;
            })
    }

    getOneCard(id) {

        return $axios.get('/cards/${id}')
            .then(res => {

                return res.data;
            })
    }

    create(token) {

        return $axios.post('/cards', {stripe_token: token})
            .then(res => {

                return res.data;
            })
    }
}

export default new CardService();