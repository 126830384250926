const state = () => ({
    favoriteGenres: []
});

const getters = {
    getFavoriteGenres(state) {
        return state.favoriteGenres;
    }
};

const mutations = {
    setFavoriteGenres(state, favoriteGenres) {
        state.favoriteGenres = favoriteGenres;
    }
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}