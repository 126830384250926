import $axios from '../plugins/axios'

class FavoriteGenreService {

    getAllFavoriteGenres() {

        return $axios.get('/favorite-genres')
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    createFavoriteGenres(favoriteGenres) {

        return $axios.post('/favorite-genres', favoriteGenres)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }
}

export default new FavoriteGenreService();