<template>
    <div class="report-wrap">

        <AppHeaderComponent headline="Average Usage By User" class="content-header--mb" />
        
        <!-- Trial Mode Conversion -->
        <RepportLineGraph
            name="Average Usage By User"
            yAxisProperty="value"
            xAxisProperty="time"
            graphIndex="5"
            :graphData="[graphData]"/>

        
        <md-table
            md-card
            v-model="users"
            md-sort="name"
            md-sort-order="asc"
            class="custom-table">    

            <md-table-row
                slot="md-table-row"
                slot-scope="{ item }">
                <md-table-cell md-label="ID" md-sort-by="id" md-numeric>{{ item.id }}</md-table-cell>
                <md-table-cell md-label="Name" md-sort-by="name">{{ item.name }}</md-table-cell>
                <md-table-cell md-label="Email" md-sort-by="email">{{ item.email }}</md-table-cell>
                <md-table-cell md-label="Gender" md-sort-by="gender">{{ item.gender }}</md-table-cell>
                <md-table-cell md-label="Job Title" md-sort-by="title">{{ item.title }}</md-table-cell>
            </md-table-row>
        </md-table>
  
    </div>
</template>

<script>
import RepportLineGraph from "@/components/Reports/ReportLineGraph"

export default {
    name: 'AverageUsageByUser',
    components: {
        RepportLineGraph
    },
    data: () => ({
        graphData: {
            name: "Value",
            color: "#a0c8e2",
            data: [
                {
                    value: 40,
                    time: '01/01/2019'
                },
                {
                    value: 30,
                    time: '02/01/2019'
                },
                {
                    value: 45,
                    time: '03/01/2019'
                },
                {
                    value: 42,
                    time: '04/01/2019'
                },
                {
                    value: 37,
                    time: '05/01/2019'
                },
                {
                    value: 40,
                    time: '06/01/2019'
                },
                {
                    value: 45,
                    time: '07/01/2019'
                },
                {
                    value: 48,
                    time: '08/01/2019'
                },
                {
                    value: 46,
                    time: '09/01/2019'
                },
                {
                    value: 40,
                    time: '10/01/2019'
                },
                {
                    value: 48,
                    time: '11/01/2019'
                },
                {
                    value: 46,
                    time: '12/01/2019'
                }
            ]
        },
        users: [
        {
          id: 1,
          name: 'Shawna Dubbin',
          email: 'sdubbin0@geocities.com',
          gender: 'Male',
          title: 'Assistant Media Planner'
        },
        {
          id: 2,
          name: 'Odette Demageard',
          email: 'odemageard1@spotify.com',
          gender: 'Female',
          title: 'Account Coordinator'
        },
        {
          id: 3,
          name: 'Lonnie Izkovitz',
          email: 'lizkovitz3@youtu.be',
          gender: 'Female',
          title: 'Operator'
        },
        {
          id: 4,
          name: 'Thatcher Stave',
          email: 'tstave4@reference.com',
          gender: 'Male',
          title: 'Software Test Engineer III'
        },
        {
          id: 5,
          name: 'Clarinda Marieton',
          email: 'cmarietonh@theatlantic.com',
          gender: 'Female',
          title: 'Paralegal'
        },
         {
          id: 6,
          name: 'Shawna Dubbin',
          email: 'sdubbin0@geocities.com',
          gender: 'Male',
          title: 'Assistant Media Planner'
        },
        {
          id: 7,
          name: 'Odette Demageard',
          email: 'odemageard1@spotify.com',
          gender: 'Female',
          title: 'Account Coordinator'
        },
        {
          id: 8,
          name: 'Lonnie Izkovitz',
          email: 'lizkovitz3@youtu.be',
          gender: 'Female',
          title: 'Operator'
        },
        {
          id: 9,
          name: 'Thatcher Stave',
          email: 'tstave4@reference.com',
          gender: 'Male',
          title: 'Software Test Engineer III'
        },
        {
          id: 10,
          name: 'Clarinda Marieton',
          email: 'cmarietonh@theatlantic.com',
          gender: 'Female',
          title: 'Paralegal'
        }
      ]
    }),
    methods: {

    }
}
</script>

<style lang="scss" scoped>
.report-wrap {
    padding-bottom: 300px;
}
.content-header.content-header--mb {
    margin-bottom: 40px;
}
@include appTableStyles;
</style>