<template>
  <div class="component-wrap">
    <AppHeaderComponent
        class="about-page"
        headline="">

    </AppHeaderComponent>

    <legal-text></legal-text>
  </div>
</template>

<script>
import LegalText from "./LegalText";

export default {
  name: 'LegalDashboard',
  components: {LegalText},
  methods: {
    setPageName() {
      this.$store.dispatch('changePageName', {pageNameString: 'Legal Disclosures'});
    },
  },
  created() {
    this.setPageName();
  }

}
</script>

<style lang="scss" scoped>
.content-header.about-page {
  margin-bottom: 40px;
}

a {
  color: red !important;
}

h1 {
  line-height: 4rem;
}

.hdr {
  margin-top: 40px;
}

::v-deep .md-tabs.md-theme-default .md-tabs-navigation{
  background: unset!important;
  margin-bottom: 30px;
  color: var(--lavender)!important;
  font-family: var(--font-family-figtree)!important;
  font-size: var(--font-size-l)!important;
  font-weight: 700!important;
  font-style: normal!important;
  border-bottom: 1px solid #242424;
}
li{
  list-style-type: none!important;
}
</style>
