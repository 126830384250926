<template>
    <div class="component-wrap">

        <AppHeaderComponent :headline=bluePrintName></AppHeaderComponent>

        <AppEmptyStateComponent message="There are no Workout Segments to display." v-if="periods.length == 0 && !loadingSpinner"/>

            <AppContentGrid>
                <draggable v-model="listPeriods"  class="list-group"  @start="drag=true" @end="drag=false">
                    <PeriodCard
						v-for="(period, index) in periods"
						v-show="period.id"
						:label="'View Period'"
						:period="period"
						:key="index + '_periods'"
                        @editPeriod="editPeriod(period.id)"
                        @setLoadingSpinner="setLoadingSpinner"
						@refreshPeriods="getAllPeriodsForCurrentBluePrint"
						@tempRemovePeriod="tempRemovePeriod"
						@restorePeriod="restorePeriod"
						@click.native="goToPeriod(period.id)"/>
                </draggable>
            </AppContentGrid>

        <AppSpinnerComponent v-if="loadingSpinner" :loading="loadingSpinner"/>

<!--        <h2 class="content__subtitle">Flex rate</h2>-->

<!--        <TrainerDashboardBluePrintFlexRate/>-->

        <AppFloatFabButton
                id="add-button"
                icon="add"
                hintText="New Workout Segment"
                @click.native="openPeriodDialog()"
                v-if="userRole < 5"
        />

        <CreatePeriodDialog
            @refreshAllPeriods="getAllPeriodsForCurrentBluePrint"
            :show-dialog.sync="showDialog"
            :period-prop="periodToEdit"
            :key="dialogKey"
        />

    </div>
</template>

<script>

	import PeriodCard from "@/components/Periods/PeriodCard";
    import CreatePeriodDialog from "../../components/Periods/CreatePeriodDialog"
    import TrainerDashboardBluePrintFlexRate from '@/components/TrainerDashboard/TrainerDashboardBluePrintFlexRate';
    import PeriodService from '../../services/period.service';
    import BluePrintService from '../../services/bluePrint.service';
    import draggable from 'vuedraggable'
    import {mapGetters} from "vuex";

    export default {
        name: 'PeriodsDashboard',

        data: function() {
            return {
                periods: [],
                tempPeriods: null,
                periodToEdit: {},
                bluePrintName: '',
                loadingSpinner: true,
                showDialog: false,
                dialogKey: 0
            }
        },

        components: {
            draggable,
			PeriodCard,
            CreatePeriodDialog,
            TrainerDashboardBluePrintFlexRate
        },

        methods: {

            setPageName(){

                this.$store.dispatch('changePageName', { pageNameString: 'Workout Segments'});
            },

            openPeriodDialog() {

                this.periodToEdit = null;
                this.dialogKey++;
                this.showDialog = true;
            },

            editPeriod(periodId) {

                PeriodService.getPeriod(periodId)
                    .then( (res) => {

                        this.dialogKey++;
                        this.periodToEdit = res;
                        this.showDialog = true;
                    })
                    .catch((err) => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: `${err.message}. Please, try again.`,
                            mode: 'fail',
                        });
                    })
            },

            tempRemovePeriod(id){

                this.periods = this.periods.filter(period => period.id != id);
                this.$store.commit('periods/setTempPeriods', this.periods);
            },

            restorePeriod(id) {

                this.$store.commit('periods/setIsUndoActive', true);
                this.getAllPeriodsForCurrentBluePrint();

                this.$store.dispatch('showSnackbarMessage', {
                    message: 'The period was successfully restored.',
                    duration: 3000,
                    mode: 'success'
                });

            },

            getAllPeriodsForCurrentBluePrint(){

                let bluePrintId = this.$route.params.id;

                this.$store.dispatch('periods/getAllPeriodsForCurrentBluePrint', {id: bluePrintId})
                    .then((res) => {

                        this.periods = res;
                        this.setLoadingSpinner(false);
                    })
                    .catch((err) => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: `${err.message}. Please, try again.`,
                            mode: 'fail'
                        });
                    });
            },

            goToPeriod(id){

                this.$router.push('/periods/'  + id);
            },

            setLoadingSpinner(isLoading) {

              this.loadingSpinner = isLoading;
            },

            getBluePrintName() {

                BluePrintService.getSingleBluePrint(this.$route.params.id)
                    .then((res) => {

                        this.bluePrintName  = res.name;
                    })
                    .catch(() => {

                        this.$store.dispatch('showSnackbarMessage', {
                          message: 'Failed. Please, try again.',
                          duration: 8000,
                          mode: 'fail'
                        });
                    });
            }
        },

        computed: {

            ...mapGetters(
                {
                  userRole: 'auth/currentUserRole',
                },
            ),

            listPeriods: {

                get() {

                    return this.periods;
                },

                set(periodsList) {

                    let bluePrintId = this.$route.params.id;
                    periodsList.forEach((period, index) => {
                        period.order = index;
                    })

                    PeriodService.reorderPeriod({ data: periodsList, id: bluePrintId })
                        .then((res) => {
                        })
                        .catch(() => {

                            this.$store.dispatch('showSnackbarMessage', {
                                message: 'Failed. Please, try again.',
                                duration: 8000,
                                mode: 'fail'
                            });
                        });

                    this.periods = periodsList;
                }
            }
        },

        created() {
            
            this.setPageName();
            this.getBluePrintName();
            this.tempPeriods = this.$store.getters["periods/getTempPeriods"]

            if (this.tempPeriods.length) {

                this.periods = this.tempPeriods;
                this.setLoadingSpinner(false);
            } else {

              this.getAllPeriodsForCurrentBluePrint();
            }

            this.$root.$on('undoDelete', this.restorePeriod);
        },

        destroyed() {

            this.$root.$off('undoDelete', this.restorePeriod);
        }
    }
</script>

<style lang="scss" scoped>
    .grid-wrapper{
        .list-group{
            /*display: inline-flex!important;*/
            //display: -webkit-inline-box !important;
        }
    }
    
    .content__subtitle {
        text-align: left;
        margin: 5.6rem 0 2.4rem;
    }
</style>