import $axios from '../plugins/axios'

class IntervalService {

    getAllIntervalTypes() {

        return $axios.get('/interval-types')
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    deleteSingleIntervalTypes(intervalTypeId) {

        return $axios.delete(`/interval-types/${intervalTypeId}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    editIntervalType(data) {

        return $axios.put(`/interval-types/${data.id}`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }


    createIntervalType(data) {

        return $axios.post('/interval-types', data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    createInterval(data) {

        return $axios.post('/intervals', data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    editInterval(data) {

        return $axios.put(`/intervals/${data.id}`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    reorderIntervals({ data, id }) {

        return $axios.put(`/playlists/${id}/intervals/order`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    reorderIntervalsMoreThenTwoMin({ data, id }) {

        return $axios.put(`/intervals/${id}/interval-songs/order`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    deleteInterval(id) {

        return $axios.delete(`/intervals/${id}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    editSongInterval(data, id) {

        return $axios.put(`/intervals/${id}`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    editSongIntervalForTourGuide(data, id) {

        return $axios.put(`/intervals/${id}`, {...data, is_tour_guide: true})
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    changeSongForTourGuide(data, id) {

        return $axios.put(`/interval-songs/${id}`, {...data, is_tour_guide: true})
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    changeSong(data, id) {

        return $axios.put(`/interval-songs/${id}`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

}

export default new IntervalService();