import $axios from '../plugins/axios'

class UserStreamingService {
    authorize(data) {
        return $axios.post('/UserStreamingService', data)
            .then((res) => {
                return res;
            })
            .catch((error) => {
                console.log(error)
                throw error;
            });
    }
}

export default new UserStreamingService();
