const state = () => ({
    artists: []
});

const getters = {
    getArtists(state) {
        return state.artists;
    }
};

const mutations = {
    setArtists(state, artists) {
        state.artists = artists;
    }
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}