import UserService from "../../services/user.service";

export default async function facilityCheck({to, next, store, nextMiddleware}) {

	const user = await UserService.getOne(store.getters['auth/getCurrentUser'].id)
	const pathAddFacility = '/stores';

	if ((user.role_id === 2 || user.role_id === 4) &&
		(user.account.has_store === false)) {

		return next({
			path: pathAddFacility,
		});
	}

	return nextMiddleware();
}
