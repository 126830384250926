<template>
    <div class="component-wrap track md-elevation-5" @mouseleave="showChildComponents = false">

        <h4 class="track__name">{{trackData.duration}}</h4>
        <h4 class="track__name">{{trackData.energy}}</h4>
        <h4 class="track__name">{{trackData.genre.name}}</h4>
        <h4 class="track__name">{{trackData.type.name}}</h4>

        <!-- end of tags section -->
    
        <div class="track__actions">
            <md-button class="md-icon-button"
                       id="edit-button"
                       @click="openTheEdit(trackData.id)"
                       v-if="userRole < 5">
                <md-icon class="md-theme-default md-primary">edit</md-icon>
            </md-button>

            <md-button class="md-icon-button"
                       id="remove-button"
                       @click="removeInterval(trackData.id)"
                       v-if="userRole < 5">
                <md-icon class="md-theme-default md-primary">delete_sweep</md-icon>
            </md-button>
        </div>

    </div>    
</template>

<script>
/** TO DO:
 *  - set up v-model binding for time and bits inputs
*/
import IntervalService from '../../services/interval.service';
import {mapGetters} from "vuex";

export default {
    name: 'TrainerDashboardBluePrintTrack',

    props: {
        trackData: {
           type: Object,
           required: true
        }
    },

    data: () => ({
    }),

    computed: {

        track() {

            return this.trackData;
        },

        ...mapGetters(
            {
              userRole: 'auth/currentUserRole',
            },
        ),
    },

    methods: {

        openTheEdit(id){

            this.$emit('openEdit', id);
        },

        removeInterval(intervalId) {

            IntervalService.deleteInterval(intervalId)
                .then(() => {

                    this.$emit('refreshAllIntervals');

                    this.$store.dispatch('showSnackbarMessage', {
                        message: 'The interval was successfully deleted.',
                        duration: 4000,
                        mode: 'success'
                    });
                })
                .catch((err) => {

                    this.$store.dispatch('showSnackbarMessage', {
                        message: `${err.message}. Please, try again.`,
                        duration: 4000,
                        mode: 'fail'
                    });
                });
        }
    }
}
</script>

<style lang="scss" scoped>
.track {
    width: 100%;
    height: 57px;
    display: flex;
    border-radius: 3px;
    background: $color-white;
    transition: all .2s ease-in;
    backface-visibility: hidden;
    z-index: 1;
    position: relative;
    // overflow: hidden;

    @include respond(small) {
        padding-left: 6rem;
        flex-direction: column;
        height: auto;
    }

    &:not(:first-of-type) {
        margin-top: -1rem;

        @include respond(small) {
            margin-top: -24rem;
        }
    }
    
    &:hover {
        z-index: 10000;
        transform: scale(1.05, 1.05);
    }

    &__color {
        width: 10rem;
        height: 100%;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .2);
        background-color: $color-accent-red;
     
        @include respond(small) {
            width: 6rem;
            position: absolute;
            left: 0;
        }
    }

    &__name {
        text-align: left;
        min-width: 17%;
        font-size: 2.4rem;
        font-weight: 400;
        color: $color-black;
        line-height: 57px;
        padding-left: 2.4rem;

        @include respond(small) {
            padding-left: 1.6rem;
            border-bottom: 1px solid $color-grey-light;
        }
    }

    &__time,
    &__tags,
    &__bits {
        min-width: 15%;
        display: flex;
        align-items: center;

         @include respond(small) {
            height: 6rem;
            padding: .8rem 0 .8rem 1.6rem;
            border-bottom: 1px solid $color-grey-light;
        }
    }

    &__tags {
        overflow-y: hidden;
            -webkit-overflow-scrolling: touch;
        max-width: 300px;
        width: auto;
        position: relative;
        padding-right: 40px;

        &::-webkit-scrollbar {
            height: 2px;
        }
        
        -ms-overflow-style: none;

    }

    &__tag {
        font-weight: 600;
        font-size: 1.4rem;
        text-transform: uppercase;
        color: $color-light-grey;
        margin-right: 1rem;
        min-width: fit-content;
        cursor: pointer;
        padding: 3px 5px;

        &:hover {
            box-shadow: 1px 2px 4px rgba(0, 0, 0, .2);
            border: 2px solid $color-danger;
            padding: 13px;
            background: rgba($color-danger, .2);
        }
    }

    &__tag-input {
        position: sticky;
        right: -40px;
        background: #fff;
    }

    &__actions {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        
        @include respond(small) {
            height: 6rem;
            width: 100%;
            margin: 0;
        }
        
        .md-icon-button {
            height: 100%;
            border-radius: 0;
            border-left: 1px solid $color-black;

            @include respond(small) {
                width: 50%;
                margin: 0;
                border-color: $color-grey-light;

                &:first-child {
                    border: none;
                }
            }
        }

        .md-button:hover .md-icon {
            color: $color-danger;
        }

    }
}
</style>