<template>
    <md-dialog :md-active.sync="showDialogEditClass" :md-click-outside-to-close="false">

        <md-dialog-title>Edit Class</md-dialog-title>

        <div class="free-rooms" v-if="showFindRoomInputForm && showNoAvailableRoomsDesign">
<!--            <div class="free-rooms__label">-->
<!--                <p>-->
<!--                    No available rooms for chosen date/time were found. <br/>-->
<!--                    Here is the schedule where you can see when each room is available.-->
<!--                </p>-->
<!--            </div>-->
<!--            <div class="md-layout free-rooms_rooms" v-for="(freeRoom, index) in freeRooms" :key="index + '_free'">-->
<!--                <div class="md-layout-item md-raised md-xlarge-size-25 md-large-size-25 md-medium-size-100 md-small-size-100 md-xsmall-size-100">-->
<!--                    Room #{{freeRoom.name}}-->
<!--                </div>-->
<!--                <div class="md-layout-item md-raised md-xlarge-size-75 md-large-size-75 md-medium-size-100 md-small-size-100 md-xsmall-size-100" v-for="(freeDate, index) in freeRoom.freeDates" :key="index + '_dates'">-->
<!--                    {{freeDate}}-->
<!--                </div>-->
<!--            </div>-->

<!--            conflict data rooms-->
            <md-dialog-title> Conflict rooms</md-dialog-title>

            <div class="md-layout md-gutter">
                <div class="md-layout-item md-raised md-xlarge-size-25 md-large-size-25 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
                     v-for="(conflict, index) in conflicts" :key="index" :value="conflict.id"
                     @click="openEditConflictDialog(conflict)">
                    <md-card class="md-accent"
                             md-with-hover
                             :class="{ resolved: conflict.isEdit }">
                        <md-ripple>
                            <md-card-header>
                                <div class="md-title">{{ conflict.room.name }}</div>

                            </md-card-header>

                            <md-card-content>
                              {{ conflict.data }} <br>
                              {{ conflict.start_time }} - {{ conflict.end_time }}
                            </md-card-content>
                        </md-ripple>
                    </md-card>
                </div>
            </div>

            <md-button class="dialog__btn dialog__btn--danger" @click="closeDialog()">CANCEL</md-button>
            <md-button class="md-primary dialog__btn md-raised md-theme-light"
                       @click="returnToRechoseDate()">RETURN</md-button>
        </div>

        <!--            conflict edit dialog -->
        <form v-if="showConflictRoomsForm" class="room-form">
            <div class="md-layout">
                <md-dialog-title> Update conflict</md-dialog-title>
            </div>

            <AppInputComponent
                    controlType="date"
                    :disabledDates="disabledDates"
                    :dateLabel="'Date'"
                    class="recurring-form__field date-pick-field"
                    required
                    v-model="$v.selectedConflict.data.$model"
                    :error="$v.selectedConflict.data.$invalid && $v.selectedConflict.data.$dirty"
                    errorText="Start Date is required"
            />

            <AppInputComponent
                    class="recurring-form__field"
                    required
                    :controlType="'time'"
                    :placeholderInput="'Start Time'"
                    v-model="$v.selectedConflict.start_time.$model"
                    :inputIcon="'watch_later'"
                    :error="$v.selectedConflict.start_time.$invalid && $v.selectedConflict.start_time.$dirty"
                    errorText="Start Time is required"/>
            <AppInputComponent
                    class="recurring-form__field"
                    required
                    :controlType="'time'"
                    :placeholderInput="'End Time'"
                    v-model="$v.selectedConflict.end_time.$model"
                    :inputIcon="'watch_later'"
                    :error="$v.selectedConflict.end_time.$invalid && $v.selectedConflict.end_time.$dirty"
                    errorText="End Time is required"
            />

           <AppInputComponent
                    class="room-form__field select-field"
                    controlType="select"
                    required
                    placeholder="Choose Room"
                    v-model="$v.selectedConflict.room.id.$model"
                    :error="$v.selectedConflict.room.id.$invalid && $v.selectedConflict.room.id.$dirty"
                    errorText="Room selection is required">

                <option v-for="(room, index) in rooms" :key="index" :value="room.id">{{room.name}}</option>

            </AppInputComponent>

            <md-dialog-actions>
                <md-button class="dialog__btn dialog__btn--danger" @click="goToConflictRooms()">BACK</md-button>
                <md-button class="md-primary dialog__btn md-raised md-theme-light"
                           :disabled="$v.selectedConflict.$invalid"
                           @click="updateConflictRoom()">Update</md-button>
            </md-dialog-actions>

        </form>

        <form v-if="showFindRoomInputForm && !showNoAvailableRoomsDesign && !showConflictRoomsForm" class="room-form">

            <AppInputComponent
                    v-if="currentUserRole == 4"
                    class="room-form__field select-field"
                    controlType="select"
                    required
                    placeholder="Choose Room"
                    v-model="$v.singleClass.room_id.$model"
                    :error="$v.singleClass.room_id.$invalid && $v.singleClass.room_id.$dirty"
                    errorText="Room selection is required">

                <option v-for="(room, index) in rooms" :key="index" :value="room.id">{{room.name}}</option>

            </AppInputComponent>

            <div  v-if="currentUserRole < 4">
                <AppInputComponent
                    class="room-form__field select-field"
                    controlType="select"
                    required
                    placeholder="Choose a facility"
                    v-model="store_id">

                <option v-for="(store, index) in stores" :key="index" :value="store.id">{{store.name}}</option>

                </AppInputComponent>

                <AppInputComponent
                      class="room-form__field select-field"
                      controlType="select"
                      required
                      placeholder="Choose Room"
                      v-model="$v.singleClass.room_id.$model"
                      :error="$v.singleClass.room_id.$invalid && $v.singleClass.room_id.$dirty"
                      errorText="Room selection is required">

                  <option v-for="(room, index) in filteredRooms" :key="index" :value="room.id">{{room.name}}</option>

                </AppInputComponent>
            </div>


            <md-dialog-actions>
                <md-button class="dialog__btn dialog__btn--danger" @click="closeDialog()">CANCEL</md-button>
                <md-button class="md-primary dialog__btn md-raised md-theme-light"
                           :disabled="$v.singleClass.$invalid"
                           @click="editSingleClass()">FINISH EDITING</md-button>
            </md-dialog-actions>
        </form>

        <form v-if="!showFindRoomInputForm && !showNoAvailableRoomsDesign && !showConflictRoomsForm" class="recurring-form">
            <div class="md-layout md-gutter">
            <AppInputComponent
                    class="recurring-form__field md-layout-item md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
                    required
                    placeholder="Class Name"
                    v-model="$v.singleClass.name.$model"
                    :error="$v.singleClass.name.$invalid && $v.singleClass.name.$dirty"
                    errorText="Name is required"
            />

            <AppInputComponent
                    controlType="date"
                    :disabledDates="disabledDates"
                    :dateLabel="'Choose Start Date'"
                    class="recurring-form__field date-pick-field md-layout-item md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
                    required
                    v-model="$v.singleClass.start_date.$model"
                    :error="$v.singleClass.start_date.$invalid && $v.singleClass.start_date.$dirty"
                    errorText="Start Date is required"
            />

            <AppInputComponent
                    class="recurring-form__field md-layout-item md-xlarge-size-50 md-large-size-50 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
                    required
                    :controlType="'time'"
                    :placeholderInput="'Start Time'"
                    v-model="$v.singleClass.start_time.$model"
                    :inputIcon="'watch_later'"
                    :error="$v.singleClass.start_time.$invalid && $v.singleClass.start_time.$dirty"
                    errorText="Start Time is required"/>
            <AppInputComponent
                    class="recurring-form__field md-layout-item md-xlarge-size-50 md-large-size-50 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
                    required
                    :controlType="'time'"
                    :placeholderInput="'End Time'"
                    v-model="$v.singleClass.end_time.$model"
                    :inputIcon="'watch_later'"
                    :error="$v.singleClass.end_time.$invalid && $v.singleClass.end_time.$dirty"
                    errorText="End Time is required"
            />

            <div class="md-layout-item md-layout" v-if="isReccuringClass">
                <div class="md-layout-item  md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
                    <p>REPEATS EVERY:</p>
                </div>

	            <div class="md-layout-item md-raised md-xlarge-size-35 md-large-size-35 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
	                <AppInputComponent
	                      class="recurring-form__field md-pr-4"
	                      required
	                      type="number"
	                      min="1"
	                      placeholder="1 for every, 2 for every second, ..."
	                      v-model="$v.singleClass.repeat_pattern_number.$model"
	                      :error="$v.singleClass.repeat_pattern_number.$invalid && $v.singleClass.repeat_pattern_number.$dirty"
	                      errorText="Repeat every is required"
	                />
                </div>

	            <div class="md-layout-item md-raised md-xlarge-size-65 md-large-size-65 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
		            <AppInputComponent
			            class="recurring-form__field recurring-form__field select-field"
			            controlType="select"
			            required
			            placeholder="Choose Repeat Pattern"
			            v-model="$v.singleClass.repeat_pattern_id.$model"
			            :error="$v.singleClass.repeat_pattern_id.$invalid && $v.singleClass.repeat_pattern_id.$dirty"
			            errorText="Repeat Pattern is required">

	                    <option v-for="(pattern, index) in repeatPatterns" :key="index" :value="pattern.id">{{pattern.name}}</option>
		            </AppInputComponent>
                </div>
            </div>

            <div class="md-layout-item md-raised md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
                  <AppInputComponent v-if="isMonthlySelected"
                                     v-model="repeat_pattern_monthly_id"
                                     class="recurring-form__field recurring-form__field select-field"
                                     controlType="select"
                                     required
                                     placeholder="Choose Repeat Monthly Pattern"
                                     errorText="Repeat Pattern is required">

                    <option v-for="(pattern, index) in repeatPatternsMonthly" :key="index" :value="pattern.id">{{pattern.name}}</option>

                  </AppInputComponent>

                  <md-field
                      v-if="isWeeklySelected"
                      class="days-of-week-select">
                    <label>Choose Repeat Weekly Days</label>
                    <md-select v-model="selectedDaysOfTheWeek" name="days" id="days" multiple>
                      <md-option v-for="(pattern, index) in daysOfTheWeek" :key="index" :value="pattern.id">
                        {{pattern.name}}
                      </md-option>
                    </md-select>
                  </md-field>
            </div>

            <AppInputComponent v-if="isReccuringClass"
                    controlType="date"
                    :disabledDates="disabledDates"
                    :dateLabel="'Repeat Until Date'"
                    class="recurring-form__field date-pick-field  md-layout-item md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
                    required
                    v-model="$v.singleClass.repeat_until.$model"
                    :error="$v.singleClass.repeat_until.$invalid && $v.singleClass.repeat_until.$dirty"
                    errorText="Repeat Until Date is required"
            />

            <AppInputComponent
                    class="recurring-form__field select-field md-layout-item md-xlarge-size-50 md-large-size-50 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
                    controlType="select"
                    required
                    placeholder="Choose Blueprint"
                    v-model="$v.singleClass.template_id.$model"
                    :error="$v.singleClass.template_id.$invalid && $v.singleClass.template_id.$dirty"
                    errorText="Blueprint selection is required">

                <option v-for="(template, index) in templates" :key="index" :value="template.id">{{template.name}}</option>

            </AppInputComponent>


            <AppInputComponent
                    class="recurring-form__field select-field md-layout-item md-xlarge-size-50 md-large-size-50 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
                    controlType="select"
                    required
                    placeholder="Choose trainer"
                    v-model="$v.singleClass.trainer_id.$model"
                    :error="$v.singleClass.trainer_id.$invalid && $v.singleClass.trainer_id.$dirty"
                    errorText="Trainer selection is required">

                <option v-for="(trainer, index) in trainersUsers" :key="index" :value="trainer.id">{{ trainer | fullName  }}</option>

            </AppInputComponent>

            <div class="md-layout-item md-size-50 md-raised toggle-btn">
                <div class="visible-label">Hide class before start:</div>
                <toggle-button v-model="singleClass.hide_before_start" color="#a156a0" :sync="true" :width=110 :font-size=17 :height=35
                               :labels="{checked: 'Yes', unchecked: 'No'}"/>
            </div>

            <div class="md-layout-item md-size-50 md-raised toggle-btn" >
                <div class="visible-label">Hide class after end:</div>
                <toggle-button v-model="singleClass.hide_after_end" color="#a156a0" :sync="true" :width=110 :font-size=17 :height=35
                               :labels="{checked: 'Yes', unchecked: 'No'}"/>
            </div>

             <p>Total number of classes that will be generated:
	             <span v-if="$v.singleClass.$invalid"> 0 </span>

	             <span v-if="!$v.singleClass.$invalid && !isReccuringClass"> 1 </span>

	             <span v-if="!$v.singleClass.$invalid && isReccuringClass">
			            {{ Math.floor(timesToRepeatClasses / this.singleClass.repeat_pattern_number) }}
	             </span>
             </p>

            <md-dialog-actions class="md-layout-item md-size-100 md-small-size-100 md-xsmall-size-100 md-raised toggle-btn md-alignment-top-right">
                <md-button class="dialog__btn dialog__btn--danger" @click="closeDialog()">CANCEL</md-button>
                <md-button class="md-primary dialog__btn md-raised md-theme-light"
                           :disabled="$v.singleClass.$invalid"
                           @click="findARoom()">CHANGE THE ROOM</md-button>
                <md-button class="md-primary dialog__btn md-raised md-theme-light"
                           :disabled="$v.singleClass.$invalid"
                           @click="editSingleClass()">FINISH EDITING</md-button>
            </md-dialog-actions>
            </div>
        </form>
    </md-dialog>
</template>

<script>

    import {required, requiredIf} from 'vuelidate/lib/validators';
    import {DeepFilterMixin} from '@/mixins/DeepFilterMixin';
    import UserService from '../../services/user.service';
    import StoreService from '../../services/store.service';
    import RoomService from "../../services/room.service";
    import BluePrintService from '../../services/bluePrint.service';
    import ClassService from '../../services/class.service';
    import moment from "moment";

    export default {
        name: 'EditSingleClassDialog',
        mixins: [DeepFilterMixin],

        props: {
            showDialogEditClass: {
                type: Boolean,
                default: false
            },
            singleClassProp: {
                type: Object,
                default: null
            },
            roomsProp: {
                type: Array,
                default: null
            },
            isReccuringClassProp: {
                type: Boolean,
                default: false
            }
        },

        data() {

            return {
                disabledDates: date => {
                    return new Date(Date.now()) > date;
                },
                freeRooms: [],
                singleClass: {
                    name: null,
                    start_date: null,
                    repeat_until: null,
                    start_time: null,
                    end_time: null,
                    repeat_pattern_id: null,
                    stop_criteria: null,
                    repeat_pattern_number: null,
                    repeat_pattern_days: null,
                    room_id: null,
                    repeat_replacements: null,
                    template_id: null,
                    trainer_id: null,
                    hide_before_start: null,
                    hide_after_end: null
                },
                rooms: null,
                singleClassRoom: {
                  id: '',
                  room_id: null,
                },
                repeat_pattern_monthly_id: null,
                showFindRoomInputForm: false,
                showNoAvailableRoomsDesign: false,
                numberOfClasses: 0,
                selectedDaysOfTheWeek: [],
                isMonthlySelected: false,
                isDailySelected: false,
                isWeeklySelected: false,
                nameOfTheDay: '',
                numberWeekOfTheMonth: '',
                currentUserRole: {},
                stores: [],
                filteredRooms: [],
                store_id: '',
                showConflictRoomsForm: false,
                conflicts: [],
                singleReplacementChange: {
                                            initial_start_date: '',
                                            initial_start_time: '',
                                            initial_end_time: '',
                                            new_start_date: '',
                                            new_start_time: '',
                                            new_end_time: ''
                },
                selectedConflict: {},
                templates: [],
                users: []
            }
        },

        validations: {
            singleClass: {
                name: {required},
                start_date: {required},
                repeat_until: {
                    required: requiredIf(function(){
                        return this.isReccuringClass
                    })
                },
                start_time: {required},
                end_time: {required},
                repeat_pattern_id: {
                    required: requiredIf(function(){
                        return this.isReccuringClass
                    })
                },
                repeat_pattern_number: {
	                required: requiredIf(function(){
		                return this.isReccuringClass
	                })
                },
                room_id: {required},
                template_id: {required},
                trainer_id: {required},
            },
            selectedConflict: {
                start_time: {required},
                end_time: {required},
                data: {required},
                room: {
                    id: {required},
                },
            }
            // singleClassRoom: {
            //      room_id: {required},
            // }
        },

        computed: {

            isReccuringClass(){

                let booleanValue = !(this.singleClass.repeat_pattern_id == 0 || this.singleClass.repeat_pattern_id == null);

                return booleanValue;
            },

            repeatPatterns(){

                let patterns = [
                    {
                        id: 1,
                        name: 'Days'
                    },
                    {
                        id: 2,
                        name: 'Weeks'
                    },
                    {
                        id: 3,
                        name: 'Months'
                    }
                ];

                return patterns;
            },

            repeatPatternsMonthly(){

                let patterns = [
                    {
                        id: 1,
                        name: 'Monthly on day ' + moment(this.singleClass.start_date).date()
                    },
                    {
                        id: 2,
                        name: 'Monthly on the ' + this.numberWeekOfTheMonth + ' ' + this.nameOfTheDay
                    },
                    {
                        id: 3,
                        name: 'Monthly on the last ' + this.nameOfTheDay
                    }
                ];

              return patterns;
            },

            daysOfTheWeek(){

                let patterns = [
                    {
                        id: 1,
                        name: 'Monday'
                    },
                    {
                        id: 2,
                        name: 'Tuesday'
                    },
                    {
                        id: 3,
                        name: 'Wednesday'
                    },
                    {
                        id: 4,
                        name: 'Thursday'
                    },
                    {
                        id: 5,
                        name: 'Friday'
                    },
                    {
                        id: 6,
                        name: 'Saturday'
                    },
                    {
                        id: 7,
                        name: 'Sunday'
                    },
                ];

                return patterns;
            },

            timesToRepeatClasses() {

                if (this.singleClass.start_date && this.isReccuringClassProp) {

                  let startDate = this.singleClass.start_date.toString().split('T')[0];
                  let endDate = this.singleClass.repeat_until.toString().split('T')[0];

                  let start = moment(startDate);
                  let end = moment(endDate);
                  let difference = end.diff(start, "days");

                  let numberToDivide = 1;

                  switch (this.singleClass.repeat_pattern_id) {
                    case '1':
                      numberToDivide = 1;
                      break;
                    case '2':
                      numberToDivide = 7;
                      break;
                    case '3':
                      numberToDivide = 30;
                      break;
                  }

                  if (!isNaN(difference)) {

                    this.numberOfClasses = (difference / numberToDivide);
                    this.numberOfClasses = Math.floor(this.numberOfClasses);

                    this.numberOfClasses = this.numberOfClasses;
                    return this.numberOfClasses;
                  }

                  return 0;
                }
            },

            trainersUsers() {

                return this.users.filter(user => user.role_id == 4);
            },
        },
        methods: {

            getAllBlueprints(){

                return BluePrintService.getBluePrints()
                    .then((res) => {

                        this.templates = res;

                    })
                    .catch((err) => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: `${err.message}. Please, try again.`,
                            mode: 'fail'
                        });
                    });

            },

            getAllUsers(){

                return UserService.getAll()
                    .then(data => {

                        this.users = data;
                    })
                    .catch((error) => {

                         this.$store.dispatch('showSnackbarMessage', {
                            message: 'Failed. Please, try again.',
                            duration: 8000,
                            mode: 'fail'
                        });
                    });
            },

            getAllStores(){

                return StoreService.getStores()
                    .then((res) => {
                        this.stores = res;
                    })
                    .catch(() => {
                        this.$store.dispatch('showSnackbarMessage', {message: 'Failed. Please, try again.', duration: 8000, mode: 'fail'});
                    });
            },

            getAllRooms(){

                return RoomService.getRooms()
                    .then((res) => {

                        this.rooms = res;
                    })
                    .catch(() => {
                        this.$store.dispatch('showSnackbarMessage', {message: 'Failed. Please, try again.', duration: 8000, mode: 'fail'});
                    });
            },

            returnToRechoseDate(){

                this.singleClass['start_time'] = this.convertTimeFormatUndo(this.singleClass['start_time']);
                this.singleClass['end_time'] = this.convertTimeFormatUndo(this.singleClass['end_time']);

                this.showNoAvailableRoomsDesign = false;
                this.showFindRoomInputForm = false;
            },

            closeDialog() {

                this.$emit('update:showDialogEditClass', false);
            },

            findARoom() {

                let isOpenTimeCorrect = this.timePropsValueObj(this.singleClass.start_time);
                let isCloseTimeCorrect = this.timePropsValueObj(this.singleClass.end_time);

                if (isOpenTimeCorrect && isCloseTimeCorrect) {

                    this.singleClass['start_time'] = this.convertAMPMObj(this.singleClass['start_time']);
                    this.singleClass['end_time'] = this.convertAMPMObj(this.singleClass['end_time']);

                    this.singleClassRoom.room_id = '';
                    this.showFindRoomInputForm = true;
                } else {
                    this.$store.dispatch('showSnackbarMessage', {
                        message: 'Please add a valid time on "Open" and "Close" time fields',
                        duration: 4000,
                        mode: 'error'
                    });

                }
            },

            setupSingleClassForCreating() {

                let singleClassObj = this.singleClass;

                singleClassObj['class_type_id'] = 2;
                singleClassObj.start_date = singleClassObj.start_date.toString().split('T')[0];

                if (!this.isReccuringClass) {

                    singleClassObj['class_type_id'] = 1;
                    singleClassObj['repeat_pattern_id'] = null;
                    singleClassObj.repeat_until = '';
                } else {

                    singleClassObj.repeat_until = singleClassObj.repeat_until.toString().split('T')[0];
                }

                if (this.isWeeklySelected) {

                    singleClassObj['repeat_pattern_days'] = "[" + this.selectedDaysOfTheWeek.toString() + "]";
                }

                return singleClassObj;
            },

            convertSingleClassTimeOnConflict() {

                this.singleClass['start_time'] = this.convertTimeFormat(this.singleClass['start_time']);
                this.singleClass['end_time'] = this.convertTimeFormat(this.singleClass['end_time']);
            },

            editSingleClass(){

                let singleClassObj = this.setupSingleClassForCreating();

                let timeIsCorrect = false;

                if (!this.showFindRoomInputForm) {

                    let isOpenTimeCorrect = this.timePropsValueObj(this.singleClass.start_time);
                    let isCloseTimeCorrect = this.timePropsValueObj(this.singleClass.end_time);

                    if (isOpenTimeCorrect && isCloseTimeCorrect) {

                        this.singleClass['start_time'] = this.convertAMPMObj(this.singleClass['start_time']);
                        this.singleClass['end_time'] = this.convertAMPMObj(this.singleClass['end_time']);

                        timeIsCorrect = true;
                    } else {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: 'Please add a valid time on "Open" and "Close" time fields',
                            duration: 4000,
                            mode: 'error'
                        });

                    }
                } else {

                    timeIsCorrect = true;
                }

                if (timeIsCorrect) {

                    ClassService.editSingleClass(singleClassObj)
                        .then(() => {

                            this.$emit('refreshAllClasses');

                            this.$store.dispatch('showSnackbarMessage', {
                                message: 'The class was successfully added.',
                                duration: 4000,
                                mode: 'success'
                            });

                            this.closeDialog();
                        })
                        .catch((err) => {

                            this.showFindRoomInputForm = true;
                            if (err.response.data['conflicts'].length) {

                                let selectedRoom = this.rooms.find(x => x.id == this.singleClass.room_id);
                                const getConflictsFromArray = data =>
                                    data.reduce((acc, item) => {
                                        acc.push({
                                                    'data': item['start_datetime'].split(' ')[0],
                                                    'start_time': item['start_datetime'].split(' ')[1],
                                                    'end_time': item['end_datetime'].split(' ')[1],
                                                    'room': selectedRoom,
                                                    'isEdit': false
                                                  });

                                        return acc;
                                    }, []);

                                this.convertSingleClassTimeOnConflict();
                                this.conflicts =  getConflictsFromArray(err.response.data['conflicts']);
                                this.showNoAvailableRoomsDesign = true;

                            } else {

                                this.$store.dispatch('showSnackbarMessage', {
                                    message: `${err.message}. Please, try again.`,
                                    duration: 4000,
                                    mode: 'fail'
                                });
                            }
                        });
                }
            },

            saveReplacementInitial(conflict) {

                this.singleReplacementChange = {};

                this.singleReplacementChange.initial_start_date = conflict.data;
                this.singleReplacementChange.initial_start_time = conflict.start_time;
                this.singleReplacementChange.initial_end_time = conflict.end_time;
            },

            convertSelectedConflictTime(conflict) {

                this.selectedConflict = Object.assign({}, conflict);
                this.selectedConflict.start_time = this.convertTimeFormat(this.selectedConflict.start_time);
                this.selectedConflict.end_time = this.convertTimeFormat(this.selectedConflict.end_time);
            },

            openEditConflictDialog(conflict){

                this.saveReplacementInitial(conflict);
                this.convertSelectedConflictTime(conflict);

                this.showNoAvailableRoomsDesign = false;
                this.showConflictRoomsForm = true;
            },

            goToConflictRooms(){

              this.showNoAvailableRoomsDesign = true;
              this.showConflictRoomsForm = false;
            },

            updateConflictRoom(){

              this.selectedConflict.isEdit = true;
              this.selectedConflict.start_time = this.convertAMPMForStr(this.selectedConflict.start_time) + ':00';
              this.selectedConflict.end_time = this.convertAMPMForStr(this.selectedConflict.end_time) + ':00';

              this.singleReplacementChange.new_start_date = this.selectedConflict.data;
              this.singleReplacementChange.new_start_time = this.selectedConflict.start_time
              this.singleReplacementChange.new_end_time = this.selectedConflict.end_time

              this.singleClass.repeat_replacements.push(this.singleReplacementChange);

              this.editSingleClass();

              this.openEditConflictForm();
            },

            openEditConflictForm() {

                this.showNoAvailableRoomsDesign = true;
                this.showConflictRoomsForm = false;
            },

            setupRepeatPatternId() {

              switch (Number(this.singleClass.repeat_pattern_id)) {

                    case 1:
                        this.isWeeklySelected = false;
                        this.isMonthlySelected = false;
                        break;
                    case 2:
                        this.isWeeklySelected = true;
                        this.isMonthlySelected = false;
                        break;
                    case 3:
                        this.isWeeklySelected = false;
                        this.isMonthlySelected = true;
                        break;

                    default:
                        this.isWeeklySelected = false;
                        this.isMonthlySelected = false;
                }
            },

            async fetchData(){

                await this.getAllBlueprints();
                await this.getAllUsers();
                await this.getAllStores();
                await this.getAllRooms();
                this.singleClass = this.singleClassProp;
                this.rooms = this.roomsProp;
            }
        },

        watch: {

            'singleClass.repeat_pattern_id': function(value){

                if (!value) {

                    this.singleClass.repeat_pattern_id = null;
                }

                switch (Number(value)) {

                    case 1:
                        this.isWeeklySelected = false;
                        this.isMonthlySelected = false;
                        break;
                    case 2:
                        this.isWeeklySelected = true;
                        this.isMonthlySelected = false;
                        break;
                    case 3:
                        this.isWeeklySelected = false;
                        this.isMonthlySelected = true;
                        break;
                }
              },

              'singleClass.start_date': function(value) {

                  let dayOfTheWeek =  moment(this.singleClass.start_date).day();
                  this.nameOfTheDay = moment.weekdays(true, dayOfTheWeek);

                  let numToString = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth'];
                  let firstDayOfMonth = moment().startOf('month').day() - 1;

                  let numberWeek = Math.ceil((moment(this.singleClass.start_date).date() + firstDayOfMonth) / 7); // get number of the week for repeat
                  this.numberWeekOfTheMonth = numToString[numberWeek - 1];
              },

              'singleClass.repeat_pattern_days': function(value) {

                  if (this.singleClass.repeat_pattern_days) {

                      let selectedDays = this.singleClass.repeat_pattern_days;
                      selectedDays = selectedDays.substring(1, selectedDays.length-1).split(",").map(x => +x);

                      this.selectedDaysOfTheWeek = selectedDays;
                  } else {

                      this.selectedDaysOfTheWeek = '';
                  }
              },

              'singleClass.repeat_pattern_number': function(value) {

                this.singleClass.repeat_pattern_number = value;
               },

              'store_id': function(value){

                  if (value) {

                      this.filteredRooms = this.rooms.filter(room => room.store_id == value);
                  } else {

                      this.filteredRooms = [];
                  }
              },
        },

        created() {

            let _this = this;

            _this.fetchData().then(() => {

                _this.selectedDaysOfTheWeek = '';
                _this.setupRepeatPatternId();

                if (_this.singleClass.repeat_pattern_days) {

                    let selectedDays = _this.singleClass.repeat_pattern_days;
                    selectedDays = selectedDays.substring(1, selectedDays.length-1).split(",").map(x => +x);

                    _this.selectedDaysOfTheWeek = selectedDays;
                }

                _this.currentUserRole = _this.$store.state.auth.user.role_id;
            });

        },

        filters: {

             fullName(value) {

                 return value.first_name.charAt(0).toUpperCase() + value.first_name.slice(1) + ' '
                        + value.last_name.charAt(0).toUpperCase() + value.last_name.slice(1);
             }
        }
    }
</script>

<style lang="scss" scoped>
    @include dialogSettings();
    .dialog__hint {
        margin-bottom: 16px;
    }

    .recurring-form {

        .md-layout-item {
          margin: 0 auto;
        }

        .control-field {

            ::v-deep &__input-icon {
                bottom: 30px;
                right: 40px;
            }
        }

        .days-of-week-select {
            background-color: #d8d8d8;
            border: solid 2px #f0f1f2;
            color: #8996a6;
            font-size: 1.3rem;
            font-weight: 300;
            line-height: 1.85;
            padding-left: 24px;
            transition: all .4s ease-out, border 1ms;
            width: 100%;

            label {
                color: #8996a6;
                font: 400 13.3333px Arial;
            }
        }

        @media only screen and (min-width: 1279px) {
            .md-pr-4 {
                padding-right: 40px;
            }
        }
    }

    .md-layout-item.toggle-btn {
        text-align: center;
        padding-bottom: 20px;
    }

    .md-card.md-theme-default.md-accent {
        width: 100%;
        background-color: #ff1744;
    }

    .md-card.md-accent.md-theme-default.md-with-hover:hover {
        background-color: #DB2955;
    }

    .md-card.md-accent.md-theme-default.resolved {
        background-color: #a156a0!important;
    }

    .md-card {
        margin: 10px;
        display: inline-block;
        vertical-align: top;
        padding-bottom: 20px;
    }

</style>