const state = () => ({
    intervals: []
});

const getters = {
    getIntervals(state) {
        return state.intervals;
    }
};

const mutations = {
    setIntervals(state, intervals) {
        state.intervals = intervals;
    }
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}