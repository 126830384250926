<template>
  <div id="resetPass-main" class="auth-container">
    <div class="auth-container__form-wrap">

      <AnimatedLogoPreloader/>

      <form class="auth-form" v-on:keyup.enter="resetPass()">
        <AppInputComponent
            class="auth-form__field"
            required
            type="password"
            placeholder="New Password"
            v-model="$v.password.$model"
            :error="$v.password.$invalid && $v.password.$dirty"
            errorText="Password is required"
        />
        <AppInputComponent
            required
            class="auth-form__field"
            type="password"
            placeholder="Confirm New Password"
            v-model="$v.confirmPassword.$model"
            :error="!$v.confirmPassword.sameAsPassword"
            errorText="Passwords must match"
        />

        <md-dialog-actions>
          <md-button v-if="firstTime" class="dialog__btn dialog__btn--danger"
                     @click="logOut()">Log Out
          </md-button>

          <md-button class='md-primary dialog__btn md-raised md-theme-light pay-with-stripe'
                     :disabled="$v.$invalid"
                     @click="resetPass()">RESET
          </md-button>
        </md-dialog-actions>
      </form>
    </div>

  </div>
</template>

<script>
import {required, sameAs} from 'vuelidate/lib/validators'
import AnimatedLogoPreloader from "@/components/Common/AnimatedLogoPreloader";

export default {
  name: 'AuthResetPassword',
  data: () => ({
    password: '',
    confirmPassword: '',
    firstTime: false
  }),
  components: {
    AnimatedLogoPreloader
  },
  validations: {
    password: {required},
    confirmPassword: {required, sameAsPassword: sameAs('password')}
  },
  methods: {
    resetPass() {

      let resetToken = this.$route.params.token
      let password = this.password;

      if (this.firstTime) {
        this.$axios.post('/auth/reset-password-preregistration', {
          password
        })
            .then(res => {

              console.log(res);

              this.$store.dispatch('showSnackbarMessage', {message: 'Successfully reset'});
              this.$router.push('/dashboard');
            })
            .catch(error => {
              this.$store.dispatch('showSnackbarMessage', {message: error.response.data, duration: 4000, mode: 'fail'});

            })
      } else {

        this.$axios.post('/auth/reset-password', {
          password,
          reset_password_token: resetToken
        })
            .then(res => {

              console.log(res);

              this.$store.dispatch('showSnackbarMessage', {message: 'Successfully reset'});
              this.$router.replace('/login');
            })
            .catch(error => {
              this.$store.dispatch('showSnackbarMessage', {message: error.response.data, duration: 4000, mode: 'fail'});

            })
      }
    },
    checkResetToken() {

      let resetToken = this.$route.params.token

      console.log(resetToken)
      this.$axios.get('/auth/reset-tokens/' + resetToken)
          .then(res => {

            console.log(res)
          })
          .catch(error => {

            console.log(error)
            this.$router.replace('/login');
          })
    },
    logOut() {
      this.$store.dispatch('auth/signOut').then(() => {

        this.$store.dispatch('audioPlayer/updateSongToPlayFromBluePrint', {song: null, name: null});
        this.$router.replace('/login');
      });
    },
  },
  created() {
    if (this.$route.params.token)
      this.checkResetToken();
    else this.firstTime = true;
  }
}
</script>

<style lang="scss" scoped>
@include authPage;

.md-dialog-actions {
  justify-content: space-between;
}
</style>
