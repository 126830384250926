import $axios from '../plugins/axios'

class SubscriptionService {

    get() {

        return $axios.get('/subscriptions')
            .then(res => {

                return res.data;
            })
    }

    exportSubscriptions() {

        return $axios.get('/subscriptions/export')
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    cancel(id) {

        return $axios.put('/subscriptions/' + id, {cancel: true})
            .then(res => {

                return res.data;
            })
    }
    extend(id) {
        return $axios.put('/subscriptions/' + id, {extend: true})
            .then(res => {
                return res.data;
            })
    }

    resubscribe(subscriptionId,cardId) {

        return $axios.post('/subscriptions/resubscribe',{
            subscriptionId: subscriptionId,
            cardId: cardId
        })
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }
}

export default new SubscriptionService();
