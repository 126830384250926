<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-dialog-title>Blueprint info </md-dialog-title>

        <div class="blue-print-infos">
	        <div class="blue-print-infos__header md-layout md-raised">
		        <h2 class="blue-print-infos__data md-layout-item md-size-10 md-small-size-100">ID</h2>
		        <h2 class="blue-print-infos__data md-layout-item md-size-25 md-small-size-100">Created date</h2>
		        <h2 class="blue-print-infos__data md-layout-item md-size-20 md-small-size-100">Processed</h2>
		        <h2 class="blue-print-infos__data md-layout-item md-size-25 md-small-size-100">Processed date</h2>
		        <h2 class="blue-print-infos__data md-layout-item md-size-20 md-small-size-100">Last error</h2>
	        </div>

            <div class="blue-print-info md-raised md-layout"
                 v-for="(bluePrintInfo, index) in bluePrintInfos"
                 :key="index + '_store'"
                 :class="{ 'highlight-row': bluePrintInfo.last_error != null }">

                <p class="blue-print-info__data md-layout-item md-size-10 md-small-size-100">
                    {{bluePrintInfo.id}}
                </p>

                <p class="blue-print-info__data md-layout-item md-size-25 md-small-size-100">
                    {{bluePrintInfo.created_at}}
                </p>

                <p class="blue-print-info__data md-layout-item md-size-20 md-small-size-100">
	                <span v-if="bluePrintInfo.processed"> Yes</span>
	                <span v-else> No</span>
	            </p>

                <p class="blue-print-info__data md-layout-item md-size-25 md-small-size-100">
	                <span v-if="bluePrintInfo.processed_at"> {{bluePrintInfo.processed_at }} </span>
	                <span v-else> - </span>
                </p>

                <p class="blue-print-info__data md-layout-item md-size-20 md-small-size-100">
	                <span v-if="bluePrintInfo.last_error"> {{bluePrintInfo.last_error }} </span>
	                <span v-else> - </span>
                </p>

            </div>

            <div class="blue-print-infos__form-buttons">
                <md-dialog-actions class="form-buttons">
                    <md-button class="dialog__btn dialog__btn--danger" @click="closeDialog()">CLOSE</md-button>
                </md-dialog-actions>
            </div>
        </div>

    </md-dialog>
    
</template>

<script>
    export default {
        name: "BluePrintCardModalTableInfo",

        props: {

            showDialog: {

                type: Boolean,
                default: false
            },

            bluePrintInfosProp: {

                type: Array,
                default: null,
            }
        },

        data: () => ({

            key: 1
        }),

        methods: {
            closeDialog() {

                this.$emit('update:showDialog', false);
            },
        },

        computed: {

            bluePrintInfos() {

                return this.bluePrintInfosProp;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @include dialogSettings();

    @include tableStyle(blue-print-infos, blue-print-info);

    .blue-print-infos {

	    &__header {
		    padding-bottom: 15px;
	    }

        .blue-print-info {

            cursor: default;

            &:hover {
                background-color: #212121;
            }

            &__data {
                line-height: normal;
	            min-width: 5%;

	            @include respond(large) {
		            padding: 0.5rem;
	            }

	            @include respond(xlarge) {
		            padding: 1rem;
	            }
            }
        }
    }
    .highlight-row {
        background-color: #FF0900!important;
    }
</style>