<template>
    <md-dialog :md-active.sync="showDialog" :class="{'md-dialog-fullscreen':false}">
        <md-dialog-title>Export Playlists To Apple Music</md-dialog-title>
        
        <md-field class="input">
            <label class="label figtree-normal-white-12px">Playlist Name</label>
            <md-input  style="width:100%;" class="figtree-normal-white-16px-2" v-model="playlistName"></md-input>
          </md-field>
        <p v-if="checkIfPlaylistExist">Please Note that this playlist name already exists on your apple playlist library it's recommended to change it!</p>
        <form class="reset-form">
            <md-dialog-actions>
                <md-button class="dialog__btn dialog__btn--danger" @click="closeDialog()">Exit</md-button>
                <md-button class="md-primary md-raised dialog__btn md-theme-light"
                            @click="ExportPlaylist()">Export</md-button>
            </md-dialog-actions>
        </form>
    </md-dialog>
</template>

<script>
export default {
    name: 'ExportPlaylistToAppleModel',
    data() {
        return {
        playlistName:null,
        checkIfPlaylistExist:null
    }},
    props: {
        showDialog: {
            type: Boolean,
            default: false
        },
        name:{
            type:String,
            default:''
        }
    },
    methods: {
         closeDialog() {
            this.$emit('update:showDialog', false);
        },
        
       async ExportPlaylist() {
            await this.$emit('ExportPlaylist', this.playlistName);
            this.closeDialog()
        },
        async checkIfPlaylistNameExist(){
            const music = MusicKit.getInstance();
            let res = null;
            let applePlaylistId = null;

            let Results = await music.api.music(`/v1/me/library/playlists`);
            this.checkIfPlaylistExist = Results.data.data.find(o => o.attributes.name === this.playlistName);
        }
    },
    watch:{
        playlistName(val){
            this.playlistName = this.playlistName.trim();
            this.checkIfPlaylistNameExist();
        }
    },
    created() {
        this.playlistName = this.name;
        this.checkIfPlaylistNameExist();
    },
}
</script>

<style lang="scss" scoped>

@include dialogSettings();
.dialog-message{
   margin-bottom: 1.6rem;
}
::v-deep .md-dialog-container {
  background-color: var(--heavy-metal) !important;
  max-height: 40%;

}
.label {
  padding-top: 2px;
  padding-left: 10px;
  letter-spacing: 0.4px;
  line-height: 16px;
  margin-top: -1px;
  white-space: nowrap;
  width: fit-content;
}
.input {
  align-items: flex-start;
  background-color: var(--tuatara);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--silver-chalice);
  border-radius: 4px 4px 0 0;
  padding-left: 10px;
}
</style>