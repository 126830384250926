<template>
	<div :class="{
	        'custom-input custom-input__large': isEnergy,
	        'control-field': !isEnergy,
	        'valid': energyValue != 0
        }"
	>

		<label
			v-if="hasLabelSlot()"
			:style="{ width: returnWidth() }"
			class="control-field__label"
		>
			<slot name="label"/>
		</label>

		<input
			v-if="controlType === 'input' && !isEnergy"
			v-bind="$attrs"
			:value="value"
			:type="type"
			ref="inputField"
			class="control-field__input"
			:class="{'control-field__input--invalid': error}"
			@input="$emit('input', $event.target.value)"
		>

		<span
			v-if="isEnergy"
			class="custom-input__display"
		>
            <p v-if="energyValue == 0"> {{ $attrs.placeholder }} </p>
            <p v-else> Flex (Energy): {{ energyValue }} </p>
        </span>

		<vue-timepicker
			v-if="controlType === 'time'"
			format="hh:mm A"
			:value="value"
			:placeholder="placeholderInput"
			class="control-field__input timeInput"
			@input="$emit('input', $event)"
			:class="{'control-field__input--invalid': error}"
		/>
		<!--<input-->
		<!--v-if="controlType === 'time'"-->
		<!--v-bind="$attrs"-->
		<!--:value="value"-->
		<!--:type="type"-->
		<!--ref="timeInput"-->
		<!--@focus="$refs.timeInput.type = 'time'"-->
		<!--@blur="$refs.timeInput.type = 'text'"-->
		<!--class="control-field__input"-->
		<!--:class="{'control-field__input&#45;&#45;invalid': error}"-->
		<!--@input="$emit('input', $event.target.value)">-->

		<md-datepicker
			v-if="controlType === 'date'"
			onkeydown="return false"
			md-immediately
			:md-disabled-dates="disabledDates"
			v-bind="$attrs"
			:value="value"
			ref="dateInput"
			:type="type"
			class="control-field__date-input custom-datepicker"
			@input="$emit('input', $event)"
			:class="{'control-field__input--invalid': error}"
		>
			<label>{{ dateLabel }}</label>
		</md-datepicker>

		<textarea
			v-if="controlType === 'textarea'"
			v-bind="$attrs"
			:value="value"
			class="control-field__textarea"
			:class="{'control-field__textarea--invalid': error}"
			@input="$emit('input', $event.target.value)"
		>
		</textarea>

		<select
			v-if="controlType === 'select' && !isDisabled"
			v-bind="$attrs"
			:value="value"
			@input="$emit('input', $event.target.value)"
			class="control-field__select"
			:class="{'control-field__select--invalid': error}"
		>
			<option value="" selected>{{ $attrs.placeholder }}</option>
			<slot/>
		</select>

		<select
			v-if="controlType === 'select' && isDisabled"
			v-bind="$attrs"
			:value="value"
			disabled
			@input="$emit('input', $event.target.value)"
			class="control-field__select"
			:class="{'control-field__select--invalid': error}"
		>
			<option value="" selected>{{ $attrs.placeholder }}</option>
			<slot/>
		</select>

		<md-icon class="md-theme-default md-primary control-field__input-icon">{{ inputIcon }}</md-icon>

		<div class="custom-input__controls"
		     v-if="isEnergy"
		>
			<button
				@click.prevent.stop=""
				@mousedown.prevent.stop="plusOnHold()"
				@mouseup.prevent.stop="stopHold()">
				<md-icon>arrow_drop_up</md-icon>
			</button>

			<button
				@click.prevent.stop=""
				@mousedown.prevent.stop="minusOnHold()"
				@mouseup.prevent.stop="stopHold()">
				<md-icon>arrow_drop_down</md-icon>
			</button>
		</div>

		<transition name="slide">
			<p v-if="error" class="control-field__error">{{ errorText ? errorText : 'Field is required' }}</p>
		</transition>
	</div>
</template>

<script>
	export default {
		name: 'InputComponent',

		props: {
			type: {
				type: String,
				default: 'text',
			},

			inputIcon: {
				type: String,
				default: ''
			},

			isDisabled: {
				type: Boolean,
				default: false
			},

			controlType: {
				type: String,
				default: 'input'
			},

			value: {
				type: [String, Date, Object, Number],
				default: ''
			},

			error: {
				type: [String, Boolean],
				default: ''
			},

			errorText: {
				type: String,
				default: ''
			},

			labelWidth: {
				type: String,
				default: 'unset'
			},

			dateLabel: {
				type: String,
				default: ''
			},

			disabledDates: {
				type: Function
			},

			placeholderInput: {
				type: String,
				default: ''
			},

			energyData: {
				type: Number,
				default: 0
			},

			isEnergy: {
				type: Boolean,
				default: false
			},
		},

		data: () => ({
			energyValue: 0
		}),

		methods: {

			returnWidth() {

				if (window.innderWidth > 768 && this.labelWidth !== 'unset') {
					return this.labelWidth;
				}
				return '100%';
			},

			hasLabelSlot() {

				return !!this.$slots.label;
			},

			plusEnergy() {

				if (this.energyValue + 1 < 10) {

					this.energyValue += 1;
				} else {

					this.energyValue = 10;
				}

				this.$emit('update:energyData', this.energyValue);
			},

			minusEnergy() {

				if (this.energyValue - 1 > 0) {

					this.energyValue -= 1;
				} else {

					this.energyValue = 1;
				}

				this.$emit('update:energyData', this.energyValue);
			},

			plusOnHold() {
				this.intervalId = setInterval(this.plusEnergy, 150, this.plusEnergy());
			},

			minusOnHold() {
				this.intervalId = setInterval(this.minusEnergy, 150, this.minusEnergy());
			},

			stopHold() {
				clearInterval(this.intervalId);
			}
		},

		updated() {

			clearInterval(this.intervalId);
			this.energyValue = this.energyData;
		},
	}
</script>

<style scoped lang="scss">

	.control-field {
		position: relative;
		display: flex;
		padding-bottom: 25px;
		flex-direction: column;
		justify-content: space-between;
	}

	.control-field__select,
	.control-field__textarea,
	.control-field__input {
		background-color: #363636;
		border: 1px solid $color-accent-purple;
		color: #8996a6;
		font-size: 1.3rem;
		font-weight: 300;
		line-height: 1.85;
		padding-left: 24px;
		transition: all .4s ease-out, border 1ms;
		width: 100%;
	}

	.control-field__select:active,
	.control-field__textarea:active,
	.control-field__input:active,
	.control-field__select:focus,
	.control-field__textarea:focus,
	.control-field__input:focus {
		background-color: #363636;
		color: #fff;
		border-radius: 5px;
		outline: none;
	}

	.control-field__textarea:valid,
	.control-field__select:valid,
	.control-field__input:valid {
		background-color: #363636;
		border: 1px solid $color-accent-purple;
		border-radius: 5px;
		color: #fff;
	}

	.control-field__textarea.control-field__textarea--invalid,
	.control-field__select.control-field__select--invalid,
	.control-field__input.control-field__input--invalid {
		background-color: #363636;
		border: 1px solid $color-danger;
	}

	.control-field__select:focus,
	.control-field__textarea:focus,
	.control-field__input:focus {
		border: 2px solid $color-accent-purple;
	}

	.control-field__select,
	.control-field__input {
		height: 48px;
	}

	.control-field__textarea {
		height: 130px;
	}

	.control-field__textarea {
		padding-top: 8px;
	}

	.control-field__select {
		appearance: none;
		border-radius: 0;
	}

	.control-field__input::placeholder,
	.control-field__textarea::placeholder {
		color: #8996a6;
	}

	.control-field__label {
		display: block;
		font-size: 1.8rem;
		font-weight: 300;
		line-height: 1.5;
		margin-bottom: 16px;
		color: white;
	}

	.control-field__error {
		position: static;
		bottom: 7px;
		width: 100%;
		padding: 2px 0 2px 24px;
		background-color: $color-white;
		font-size: 1.1rem;
		font-weight: 500;
		line-height: 1.4rem;
		vertical-align: middle;
		color: $color-danger;
		text-align: left;
	}

	.control-field__input-icon {
		position: absolute;
		bottom: 38px;
		right: 10px;
		width: 24px;
		height: 24px;
	}

	.control-field__date-input {
		border-bottom: 2px solid white;

		label {
			color: white !important;
		}
	}

	.vue__time-picker {
		padding-left: 0;
	}

	::v-deep .display-time {
		height: 100% !important;
		width: 100% !important;
		/*background-color: #D8D8D8;*/
		padding-left: 20px !important;
	}

	option {
		padding: 10px;
	}

	@include customSelectInput();
</style>
