const state = () => ({
    publishers: []
});

const getters = {
    getPublishers(state) {
        return state.publishers;
    }
};

const mutations = {
    setPublishers(state, publishers) {
        state.publishers = publishers;
    }
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}