<template>
    <md-menu
        v-if="tagsToAdd.length"
        md-size="medium" 
        :md-offset-x="127"
        :md-offset-y="-36"
        class="tag-input md-theme-light">
      
      <md-button md-menu-trigger class="md-icon-button tag-input__btn">
          <md-icon class="md-theme-light">add</md-icon>
      </md-button>

      <md-menu-content class="tag-input__list md-theme-light">
        <md-menu-item
            class="tag-input__tag"
            v-for="(tag, index) in tagsToAdd"
            :key="index + 'tag'"
            @click.native="addedTags.push(tag)">
            {{tag.name}}</md-menu-item>
      </md-menu-content>

    </md-menu>
</template>

<script> 
export default {
    name: 'AppTrackTagInput',
    props: {
        addedTagsProp: {
            type: Array,
            required: true
        },
        tagsToAddProp: {
            type: Array,
            required: true
        }
    },
    data: () => ({

    }),
    computed: {
        tagsToAdd() {
            return this.tagsToAddProp.filter(x => {
                return this.addedTagsProp.findIndex(x2 => x2.id === x.id) < 0;
            });
        },
        addedTags: {
            get() {
                return this.addedTagsProp;
            },
            set(newVaue) {
                this.$emit('update:addedTagsProp', newVaue);
                return newVaue;
            }
        }    
    }
}
</script>

<style lang="scss" scoped>
.tag-input {

    &__btn {
        width: 24px;
        min-width: 24px;
        height: 24px;
        border: 2px solid $color-black;
        border-radius: 50%;

        .md-icon {
            color: $color-black !important;
        }
    }
}

.md-menu-content.md-menu-content-medium {
    min-width: 100px;
    max-width: 300px;
}

::v-deep .md-list.md-theme-default {
	background: #fff;
	border-radius: 5px;
    padding: 4px 0;

    .md-list-item-default {
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: 500;;
        color: $color-grey;
        cursor: pointer;
        
        &:hover {
            background-color: rgba($color-light-grey, .7);
        };
    }


    .md-list-item-content {
        height: 24px;
        min-height: 24px;
        padding: .4rem 1.6rem;
    }
}
</style>