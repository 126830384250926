<template>
	<div>
		<p class="auth-container__form-wrap__form-description">
			Are you a gym member looking to connect with your gym that already subscribes to Reflex Radio? Yes
		</p>

		<p class="auth-container__form-wrap__form-description">
			Please provide your information<span v-if="!isInviteProp"> and Gym Code </span>.
		</p>

		<form
			class="auth-form auth-form--with-subhead  md-layout md-alignment-center-center md-gutter"
			v-on:keyup.enter="register()"
		>
			<div class="md-layout-item md-size-60 md-medium-size-100">
				<AppInputComponent
					class="auth-form__field"
					required
					placeholder="First Name"
					v-model="$v.regForm.first_name.$model"
					:error="$v.regForm.first_name.$error && !$v.regForm.first_name.required"
					errorText="First Name is required"
				/>
			</div>

			<div class="md-layout-item md-size-60 md-medium-size-100">
				<AppInputComponent
					class="auth-form__field"
					required
					placeholder="Last Name"
					v-model="$v.regForm.last_name.$model"
					:error="$v.regForm.last_name.$error && !$v.regForm.last_name.required"
					errorText="Last Name is required"
				/>
			</div>

			<div class="md-layout-item md-size-60 md-medium-size-100">
				<AppInputComponent
					class="auth-form__field"
					required
					placeholder="Email"
					v-model="$v.regForm.email.$model"
					:error="$v.regForm.email.$invalid && $v.regForm.email.$dirty"
					errorText="Valid email is required"
				/>
			</div>

			<div class="md-layout-item md-size-60 md-medium-size-100">
				<AppInputComponent
						class="auth-form__field"
						required
						type="password"
						placeholder="Password"
						v-model="$v.regForm.password.$model"
						:error="$v.regForm.password.$error && !$v.regForm.password.required"
						errorText="Password is required"
				/>
			</div>

			<AppInputComponent
				v-if="!this.isInviteProp"
				class="auth-form__field code-field md-layout-item md-size-60 md-medium-size-100"
				required
				placeholder="Code"
				v-model="$v.regForm.code.$model"
				:error="$v.regForm.code.$error && !$v.regForm.code.required"
				errorText="Code is required"
			/>

      <div  class="md-layout-item md-size-60 md-medium-size-100">
        <md-checkbox v-model="termsAndConditionsApproved"/>
        <span class="auth-container__form-wrap__form-description" style="vertical-align: super"> I agree to the <a style="color: #fca5fb" @click="openTermsAndConditions">
          Terms of Use Agreement and the Privacy Policy.</a></span>
      </div>

			<div class="md-layout-item md-size-100"/>

			<md-button
				class="md-primary md-layout-item md-size-30 md-medium-size-100 md-raised back-button"
				v-if="!isInviteProp"
				@click="goBack()"
			>
				GO BACK
			</md-button>

			<md-button
				class="md-primary md-layout-item md-size-30 md-medium-size-100 md-raised auth-form__submit-btn md-theme-light"
				:disabled="$v.regForm.$invalid || !termsAndConditionsApproved"
				@click="register()"
			>
				REGISTER
			</md-button>
		</form>
	</div>
</template>

<script>
	import {email, required, requiredIf} from "vuelidate/lib/validators";

	export default {
		name: "AuthRegisterFormMember",

		props: {

			regFormProp: {
				type: Object,
				default: {
					first_name: null,
					last_name: null,
					email: null,
					password: null,
					account_email: null,
					account_name: null,
					is_wtf_member: false,
				}
			},

			isInviteProp: {
				type: Boolean,
				default: false,
			},

			isRegisterProp:{
				type: Boolean,
				default: false
			},
		},

		data: () => ({
			regForm: {
				first_name: null,
				last_name: null,
				email: null,
                password: null,
				code: null,
			},
      termsAndConditionsApproved: false
		}),

		validations: {

			regForm: {
				first_name: {required},
				last_name: {required},
				email: {required, email},
                password: {required},
                code: {
					required: requiredIf(function () {
						return !this.isInviteProp;
					})
				}
			},
		},

		methods: {

			register() {

				this.$emit('register', this.regForm)
			},

			goBack() {

				this.$emit('update:isRegisterProp', false);
			},

      openTermsAndConditions() {
        let routeData = this.$router.resolve({name: 'terms-and-conditions'});
        window.open(routeData.href, '_blank');
      }
		},


		created() {

			if (this.regFormProp.email) {
                this.regForm = this.regFormProp;
            }
		}
	}
</script>

<style lang="scss" scoped>
	@include authPage;

</style>
