<template>
    <md-dialog :md-active.sync="showDialog">
        <md-dialog-title>Request password reset link</md-dialog-title>
        
        <p class="dialog-message">
            Enter your email and press submit button. We will send you reset link shortly.
            Be aware that this link will be active for the next 24 hours after you receive it.
        </p>
        
        <form class="reset-form">
            <AppInputComponent
                    class="auth-form__field"
                    required
                    placeholder="Email"
                    v-model="$v.resetForm.emailForResetLink.$model"
                    :error="$v.resetForm.emailForResetLink.$invalid && $v.resetForm.emailForResetLink.$dirty"
                    errorText="Valid email is required"
            />
            <md-dialog-actions>
                <md-button class="dialog__btn dialog__btn--danger" @click="closeDialog()">Cancel</md-button>
                <md-button class="md-primary md-raised dialog__btn md-theme-light"
                            :disabled="$v.resetForm.$invalid"
                            @click="submitEmail()">SUBMIT</md-button>
            </md-dialog-actions>
        </form>
    </md-dialog>
</template>

<script>

import {required, email} from 'vuelidate/lib/validators';

export default {
    name: 'AuthResetModal',
    props: {
        showDialog: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        resetForm: {
            emailForResetLink: ''
        },
    }),
    validations: {
        resetForm: {
            emailForResetLink: {required, email}
        }
    },
    methods: {
         closeDialog() {
            this.resetForm.emailForResetLink = '';
            this.$emit('update:showDialog', false);
        },

        submitEmail() {

            let resetEmail = this.resetForm.emailForResetLink;

            this.$axios.post('auth/forgotten-password', {email: resetEmail})
                .then(() => {
                    this.closeDialog();

                    this.$store.dispatch('showSnackbarMessage',
                        {message: 'Reset link was sent! Check your email!', duration: 12000})
                })
                .catch((error) => {
                    this.$store.dispatch('showSnackbarMessage',
                        {message: "The user doesn't exist!", duration: 6000})
                });
        }
    }
}
</script>

<style lang="scss" scoped>

@include dialogSettings();
.dialog-message{
   margin-bottom: 1.6rem;
}
</style>