<template>
    <md-dialog :md-active.sync="showDialog" :class="{'md-dialog-fullscreen':false}">
        <md-dialog-title>Full Screen Mode</md-dialog-title>
        
        <p class="dialog-message figtree-medium-white-16px">
            Please activate Full Screen Mode to play this playlist/mix.
            Please do not leave Full Screen Mode and do not lock your device once in Full Screen Mode. Enjoy!
        </p>
        
        <form class="reset-form">
            <md-dialog-actions>
                <md-button class="dialog__btn dialog__btn--danger" @click="closeDialog()">Exit</md-button>
                <md-button class="md-primary md-raised dialog__btn md-theme-light"
                            @click="activateFullScreen()">Full Screen Mode</md-button>
            </md-dialog-actions>
        </form>
    </md-dialog>
</template>

<script>
export default {
    name: 'FullScreenActivationDialog',
    props: {
        showDialog: {
            type: Boolean,
            default: false
        }
    },
    methods: {
         closeDialog() {
            this.$emit('update:showDialog', false);
            this.$emit('update:FullscreenExited', true);
        },
        
        activateFullScreen() {
            this.$emit('update:showDialog', false);
            this.$emit('activateFullScreen');

        }
    }
}
</script>

<style lang="scss" scoped>

@include dialogSettings();
.dialog-message{
   margin-bottom: 1.6rem;
}
::v-deep .md-dialog-container {
  background-color: var(--heavy-metal) !important;
  max-height: 40%;

}
</style>