<template>
    <div class="component-wrap">
        <AppHeaderComponent
            class="marketing-page"
            headline="Landing pages">
            <md-button
                @click.native="openDialog()"
                class="md-raised md-primary md-raised md-theme-light">
                Add landing page</md-button>
        </AppHeaderComponent>

    <SingleLandingPageBlock
        v-for="(page, index) in pages"
        :key="index + '_page'"
        @click.native="$router.push(`/marketing/${index}`)"/>

    <CreateLandingPageDialog
        @landingPageCreated="createLandingPage"
        :key="dialogKey"
        :showDialog.sync="showDialog"/>

    </div>
</template>

<script>
import SingleLandingPageBlock from '@/components/Marketing/SingleLandingPageBlock.vue'
import CreateLandingPageDialog from '@/components/Marketing/CreateLandingPageDialog.vue'

export default {
    name: 'MarketingDashboard',
    data: () => ({
        pages: [1, 2, 3, 4, 5, 6, 7, 8, 10],
        showDialog: false,
        dialogKey: 0
    }),
    components: {
        SingleLandingPageBlock,
        CreateLandingPageDialog
    },
    methods: {
        openDialog() {
            this.dialogKey++;
            this.showDialog = true;
        },
        createLandingPage(page) {
            console.log('Creating landing page', page);
        }
    }
}
</script>

<style lang="scss" scoped>
.content-header.marketing-page {
    margin-bottom: 40px;
}
</style>