export const DurationHelperMixin = {
    methods: {
        convertDuration(value) {
            if (value.toString().length === 4) {
                value = value.toString().substring(0, 2) + ':' + value.toString().substring(2);
            } else if (value.toString().length === 6) {
                value = value.toString().substring(0, 2) + ':' + value.toString().substring(2, 4) + ':' + value.toString().substring(4);
            }

            let splitValue = value.split(':');
            let seconds;
            if (splitValue.length <= 2) {
                seconds = (+splitValue[0]) * 60 + (+splitValue[1]);
            } else {
                seconds = (+splitValue[0]) * 60 * 60 + (+splitValue[1]) * 60 + (+splitValue[2]);
            }

            return this.convertTimeHHMMSS(seconds)
        },
        convertTimeHHMMSS(val) {
            return new Date(val * 1000).toISOString().substr(11, 8);
        },
        beautifyDuration(val) {
            return val.indexOf("00:") === 0 && val.length > 5 ? val.substr(3) : val;
        },
    }
}
