import PeriodService from '../../services/period.service';

const state = () => ({
    periods: [],
    isUndoActive: false,
    tempPeriods: []
});

const getters = {
    getPeriods(state) {
        return state.periods;
    },
    
    getIsUndoActive(state) {
        return state.isUndoActive;
    },

    getTempPeriods(state) {
        return state.tempPeriods;
    },
};

const mutations = {
    setPeriods(state, periods) {
        state.periods = periods;
    },

    setIsUndoActive(state, data) {
        state.isUndoActive = data;
    },

    setTempPeriods(state, periods) {

        if (periods){

            state.tempPeriods = periods;
        } else {

            state.tempPeriods = '';
        }
    },

}

const actions = {

    getPeriods(vuexContext) {

        return PeriodService.getPeriods()
            .then(res => {

                vuexContext.commit('setPeriods', res);
                return res;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    },

    getAllPeriodsForCurrentBluePrint(vuexContext, data) {

        return PeriodService.getAllPeriodsForCurrentBluePrint(data.id)
            .then(res => {

                vuexContext.commit('setPeriods', res);
                return res;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}