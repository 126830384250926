<template>
    <md-dialog :md-active.sync="showDialogRecurringClass" :md-click-outside-to-close="false">

        <md-dialog-title v-if="isReccuringClass">Create Recurring Class</md-dialog-title>
        <md-dialog-title v-if="!isReccuringClass">Create One-Off Class</md-dialog-title>

        <div class="free-rooms" v-if="showFindRoomInputForm && showNoAvailableRoomsDesign">
<!--            <div class="free-rooms__label">-->
<!--                <p>-->
<!--                    No available rooms for chosen date/time were found. <br/>-->
<!--                    Here is the schedule where you can see when each room is available.-->
<!--                </p>-->
<!--            </div>-->
<!--            <div class="md-layout free-rooms_rooms" v-for="(freeRoom, index) in freeRooms" :key="index + '_free'">-->
<!--                <div class="md-layout-item md-raised md-xlarge-size-25 md-large-size-25 md-medium-size-100 md-small-size-100 md-xsmall-size-100">-->
<!--                    Room #{{freeRoom.name}}-->
<!--                </div>-->
<!--                <div class="md-layout-item md-raised md-xlarge-size-75 md-large-size-75 md-medium-size-100 md-small-size-100 md-xsmall-size-100" v-for="(freeDate, index) in freeRoom.freeDates" :key="index + '_dates'">-->
<!--                    {{freeDate}}-->
<!--                </div>-->
<!--            </div>-->

<!--            conflict data rooms-->
            <md-dialog-title> Conflict rooms</md-dialog-title>

            <div class="md-layout md-gutter">
                <div class="md-layout-item md-raised md-xlarge-size-25 md-large-size-25 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
                     v-for="(conflict, index) in conflicts" :key="index" :value="conflict.id"
                     @click="openEditConflictDialog(conflict)">
                    <md-card class="md-accent"
                             md-with-hover
                             :class="{ resolved: conflict.isEdit }">
                        <md-ripple>
                            <md-card-header>
                                <div class="md-title">{{ conflict.room.name }}</div>

                            </md-card-header>

                            <md-card-content>
                              {{ conflict.data }} <br>
                              {{ conflict.start_time }} - {{ conflict.end_time }}
                            </md-card-content>
                        </md-ripple>
                    </md-card>
                </div>
            </div>

            <md-button class="dialog__btn dialog__btn--danger" @click="closeDialog()">CANCEL</md-button>
            <md-button class="md-primary dialog__btn md-raised md-theme-light"
                       @click="returnToRechoseDate()">RETURN</md-button>
        </div>

      <!--            conflict edit dialog -->
        <form v-if="showConflictRoomsForm" class="room-form">
            <div class="md-layout">
                <md-dialog-title> Update conflict</md-dialog-title>
            </div>

            <AppInputComponent
                    controlType="date"
                    :disabledDates="disabledDates"
                    :dateLabel="'Date'"
                    class="recurring-form__field date-pick-field"
                    required
                    v-model="$v.selectedConflict.data.$model"
                    :error="$v.selectedConflict.data.$invalid && $v.selectedConflict.data.$dirty"
                    errorText="Start Date is required"
            />

            <AppInputComponent
                    class="recurring-form__field"
                    required
                    :controlType="'time'"
                    :placeholderInput="'Start Time'"
                    v-model="$v.selectedConflict.start_time.$model"
                    :inputIcon="'watch_later'"
                    :error="$v.selectedConflict.start_time.$invalid && $v.selectedConflict.start_time.$dirty"
                    errorText="Start Time is required"/>
            <AppInputComponent
                    class="recurring-form__field"
                    required
                    :controlType="'time'"
                    :placeholderInput="'End Time'"
                    v-model="$v.selectedConflict.end_time.$model"
                    :inputIcon="'watch_later'"
                    :error="$v.selectedConflict.end_time.$invalid && $v.selectedConflict.end_time.$dirty"
                    errorText="End Time is required"
            />

           <AppInputComponent
                    class="recurring-form__field select-field"
                    controlType="select"
                    required
                    placeholder="Choose Room"
                    v-model="$v.selectedConflict.room.id.$model"
                    :error="$v.selectedConflict.room.id.$invalid && $v.selectedConflict.room.id.$dirty"
                    errorText="Room selection is required">

                <option v-for="(room, index) in rooms" :key="index" :value="room.id">{{room.name}}</option>

            </AppInputComponent>

            <md-dialog-actions>
                <md-button class="dialog__btn dialog__btn--danger" @click="goToConflictRooms()">BACK</md-button>
                <md-button class="md-primary dialog__btn md-raised md-theme-light"
                           :disabled="$v.selectedConflict.$invalid"
                           @click="updateConflictRoom()">Update</md-button>
            </md-dialog-actions>

        </form>

        <form v-if="showFindRoomInputForm &&
                    !showNoAvailableRoomsDesign &&
                    !showConflictRoomsForm"
              class="room-form">

            <AppInputComponent
                    v-if="currentUserRole == 4"
                    class="room-form__field select-field"
                    controlType="select"
                    required
                    placeholder="Choose Room"
                    v-model="$v.singleClass.room_id.$model"
                    :error="$v.singleClass.room_id.$invalid && $v.singleClass.room_id.$dirty"
                    errorText="Room selection is required">

                <option v-for="(room, index) in rooms" :key="index" :value="room.id">{{room.name}}</option>

            </AppInputComponent>

            <div  v-if="currentUserRole != 4">
                <AppInputComponent
                    class="room-form__field select-field"
                    controlType="select"
                    required
                    placeholder="Choose a facility"
                    v-model="store_id">

                <option v-for="(store, index) in stores" :key="index" :value="store.id">{{store.name}}</option>

                </AppInputComponent>

                <AppInputComponent
                      class="room-form__field select-field"
                      controlType="select"
                      required
                      placeholder="Choose Room"
                      v-model="$v.singleClass.room_id.$model"
                      :error="$v.singleClass.room_id.$invalid && $v.singleClass.room_id.$dirty"
                      errorText="Room selection is required">

                  <option v-for="(room, index) in filteredRooms" :key="index" :value="room.id">{{room.name}}</option>

                </AppInputComponent>
            </div>

            <md-dialog-actions>
                <md-button
                    class="dialog__btn dialog__btn--danger"
                    @click="closeDialog()"
                    :disabled="showTourGuide">
                    CANCEL
                </md-button>

                <md-button
                    class="md-primary dialog__btn md-raised md-theme-light"
                    id="finish-button"
                    :disabled="$v.singleClass.$invalid"
                    @click="createRecurringClass()">
                    FINISH
                </md-button>
            </md-dialog-actions>

        </form>

        <form v-if="!showFindRoomInputForm && !showNoAvailableRoomsDesign && !showConflictRoomsForm"
              class="recurring-form">
            <div class="md-layout md-gutter">

                <AppInputComponent
                        class="recurring-form__field md-layout-item md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
                        required
                        placeholder="Class Name"
                        v-model="$v.singleClass.name.$model"
                        :error="$v.singleClass.name.$invalid && $v.singleClass.name.$dirty"
                        errorText="Name is required"
                />

                <AppInputComponent
                    controlType="date"
                    :disabledDates="disabledDates"
                    :dateLabel="'Choose Start Date'"
                    class="recurring-form__field date-pick-field md-layout-item md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
                    required
                    v-model="$v.singleClass.start_date.$model"
                    :error="$v.singleClass.start_date.$invalid && $v.singleClass.start_date.$dirty"
                    errorText="Start Date is required"
                />

                <AppInputComponent
                        class="recurring-form__field md-layout-item md-xlarge-size-50 md-large-size-50 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
                        required
                        :controlType="'time'"
                        :placeholderInput="'Start Time'"
                        v-model="$v.singleClass.start_time.$model"
                        :inputIcon="'watch_later'"
                        :error="$v.singleClass.start_time.$invalid && $v.singleClass.start_time.$dirty"
                        errorText="Start Time is required"/>

                <AppInputComponent
                        class="recurring-form__field md-layout-item md-xlarge-size-50 md-large-size-50 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
                        required
                        :controlType="'time'"
                        :placeholderInput="'End Time'"
                        v-model="$v.singleClass.end_time.$model"
                        :inputIcon="'watch_later'"
                        :error="$v.singleClass.end_time.$invalid && $v.singleClass.end_time.$dirty"
                        errorText="End Time is required"
                />

                <div class="md-layout-item md-layout" v-if="isReccuringClass">
                    <div class="md-layout-item  md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
                        <p>REPEATS EVERY:</p>
                    </div>

                    <div class="md-layout-item md-raised md-xlarge-size-35 md-large-size-35 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
                      <AppInputComponent
                              class="recurring-form__field auth-form__field md-pr-4"
                              required
                              placeholder="1 for every, 2 for every second, ..."
                              type="number"
                              min="1"
                              v-model="$v.singleClass.repeat_pattern_number.$model"
                              :error="$v.singleClass.repeat_pattern_number.$invalid && $v.singleClass.repeat_pattern_number.$dirty"
                              errorText="Repeat every is required"
                      />
                    </div>

                    <div class="md-layout-item md-raised md-xlarge-size-65 md-large-size-65 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
                      <AppInputComponent
                              class="recurring-form__field recurring-form__field select-field"
                              controlType="select"
                              required
                              placeholder="Choose Repeat Pattern"
                              v-model="$v.singleClass.repeat_pattern_id.$model"
                              :error="$v.singleClass.repeat_pattern_id.$invalid && $v.singleClass.repeat_pattern_id.$dirty"
                              errorText="Repeat Pattern is required">

                          <option v-for="(pattern, index) in repeatPatterns" :key="index" :value="pattern.id">{{pattern.name}}</option>

                      </AppInputComponent>
                    </div>

                    <AppInputComponent v-if="isReccuringClass"
                                       controlType="date"
                                       :disabledDates="disabledDatesRepeatUntil"
                                       :dateLabel="'Repeat Until'"
                                       :key="singleClass.repeat_pattern_id"
                                       class="recurring-form__field date-pick-field  md-layout-item md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
                                       required
                                       v-model="$v.singleClass.repeat_until.$model"
                                       :error="$v.singleClass.repeat_until.$invalid && $v.singleClass.repeat_until.$dirty"
                                       errorText="Repeat Until Date is required"
                    />
                </div>

                <div class="md-layout-item md-raised md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
                      <AppInputComponent v-if="isMonthlySelected"
                              v-model="repeat_pattern_monthly_id"
                              class="recurring-form__field recurring-form__field select-field"
                              controlType="select"
                              required
                              placeholder="Choose Repeat Monthly Pattern"
                              errorText="Repeat Pattern is required">

                        <option v-for="(pattern, index) in repeatPatternsMonthly" :key="index" :value="pattern.id">{{pattern.name}}</option>

                      </AppInputComponent>

                      <md-field
                          v-if="isWeeklySelected"
                          class="days-of-week-select">
                            <label>Choose Repeat Weekly Days</label>
                            <md-select v-model="selectedDaysOfTheWeek" name="days" id="days" multiple>
                              <md-option v-for="(pattern, index) in daysOfTheWeek" :key="index" :value="pattern.id">
                                {{pattern.name}}
                                </md-option>
                            </md-select>
                      </md-field>
                </div>

                <AppInputComponent
                        class="recurring-form__field select-field md-layout-item md-xlarge-size-50 md-large-size-50 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
                        controlType="select"
                        required
                        placeholder="Choose playlist"
                        v-model="$v.singleClass.playlist_id.$model"
                        :error="$v.singleClass.playlist_id.$invalid && $v.singleClass.playlist_id.$dirty"
                        errorText="Playlist selection is required">

                    <option v-for="(template, index) in templates" :key="index" :value="template.id">{{template.name}}</option>

                </AppInputComponent>

                <AppInputComponent
                        class="recurring-form__field select-field md-layout-item md-xlarge-size-50 md-large-size-50 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
                        controlType="select"
                        required
                        placeholder="Choose trainer"
                        v-model="$v.singleClass.trainer_id.$model"
                        :error="$v.singleClass.trainer_id.$invalid && $v.singleClass.trainer_id.$dirty"
                        errorText="Trainer selection is required">

                    <option v-for="(trainer, index) in trainersUsers" :key="index" :value="trainer.id">{{ trainer | fullName }}</option>

                </AppInputComponent>

                <div class="md-layout-item md-size-50 md-raised toggle-btn">
                    <div class="visible-label">Hide class before start:</div>
                    <toggle-button v-model="singleClass.hide_before_start" color="#a156a0" :sync="true" :width=110 :font-size=17 :height=35
                                   :labels="{checked: 'Yes', unchecked: 'No'}"/>
                </div>

                <div class="md-layout-item md-size-50 md-raised toggle-btn" >
                    <div class="visible-label">Hide class after end:</div>
                    <toggle-button v-model="singleClass.hide_after_end" color="#a156a0" :sync="true" :width=110 :font-size=17 :height=35
                                   :labels="{checked: 'Yes', unchecked: 'No'}"/>
                </div>

	            <p> Total number of classes that will be generated:
		            <span v-if="$v.singleClass.$invalid"> 0 </span>

		            <span v-if="!$v.singleClass.$invalid && !isReccuringClass"> 1 </span>

		            <span v-if="!$v.singleClass.$invalid && isReccuringClass">
			            {{ Math.floor(timesToRepeatClasses / this.singleClass.repeat_pattern_number) }}
		            </span>
	            </p>

                <md-dialog-actions class="md-layout-item md-size-100 md-small-size-100 md-xsmall-size-100 md-raised toggle-btn md-alignment-top-right">
                    <md-button
                        class="dialog__btn dialog__btn--danger"
                        id="cancel-button"
                        @click="closeDialog()">
                        CANCEL
                    </md-button>

                    <md-button
                        class="md-primary dialog__btn md-raised md-theme-light"
                        id="find-room"
                        :disabled="$v.singleClass.$invalid"
                        @click="findARoom()">
                        FIND A ROOM
                    </md-button>
                </md-dialog-actions>

            </div>
        </form>
    </md-dialog>
</template>

<script>

    import {required, requiredIf} from 'vuelidate/lib/validators';
    import {DeepFilterMixin} from '@/mixins/DeepFilterMixin';
    import UserService from '../../services/user.service';
    import StoreService from '../../services/store.service';
    import moment from 'moment'
    import RoomService from "../../services/room.service";
    import BluePrintService from "../../services/bluePrint.service";
    import ClassService from '../../services/class.service';
    import {mapGetters} from "vuex";

    export default {
        name: 'CreateSingleClassDialog',
        mixins: [DeepFilterMixin],

        props: {
            showDialogRecurringClass: {
                type: Boolean,
                default: false
            },
            isReccuringClass: {
                type: Boolean,
                default: false
            }
        },

        data() {

            return {
                singleClass: {
                    name: '',
                    start_date: '',
                    repeat_until: '',
                    start_time: '',
                    end_time: '',
                    repeat_pattern_id: null,
                    stop_criteria: '',
                    repeat_pattern_number: null,
                    repeat_pattern_days: [],
                    room_id: null,
                    repeat_replacements: [],
                    playlist_id: null,
                    trainer_id: null,
                    hide_before_start: false,
                    hide_after_end: false
                },
                singleClassRoom: {
                  id: '',
                  room_id: 0,
                },
                repeat_pattern_monthly_id: null,
                selectedDaysOfTheWeek: [],
                freeRooms: [],
                showFindRoomInputForm: false,
                showNoAvailableRoomsDesign: false,
                rooms: [],
                numberOfClasses: 0,
                isMonthlySelected: false,
                isDailySelected: false,
                isWeeklySelected: false,
                nameOfTheDay: '',
                numberWeekOfTheMonth: '',
                currentUserRole: {},
                stores: [],
                filteredRooms: [],
                store_id: '',
                showConflictRoomsForm: false,
                conflicts: [],
                singleReplacementChange: {
                                            initial_start_date: '',
                                            initial_start_time: '',
                                            initial_end_time: '',
                                            new_start_date: '',
                                            new_start_time: '',
                                            new_end_time: ''
                },
                selectedConflict: {},
                templates: [],
                users: []
            }
        },

        validations: {
            singleClass: {
                name: {required},
                start_date: {required},
                repeat_until: {
                    required: requiredIf(function(){
                    return this.isReccuringClass
                })},
                start_time: {required},
                end_time: {required},
                repeat_pattern_id: {
                    required: requiredIf(function(){
                        return this.isReccuringClass
                    })
                },
                repeat_pattern_number:  {
                    required: requiredIf(function(){
                        return this.isReccuringClass
                    })
                },
                room_id: {
                    required: requiredIf(function(){
                        return this.showFindRoomInputForm
                    })
                },
                playlist_id: {required},
                trainer_id: {required},
            },
            selectedConflict: {
                start_time: {required},
                end_time: {required},
                data: {required},
                room: {
                    id: {required},
                },
            }
              // singleClassRoom: {
              //     room_id: {required}
              // },
        },

        computed: {

            repeatPatterns(){

                let patterns = [
	                {
		                id: 1,
		                name: 'Days'
	                },
	                {
		                id: 2,
		                name: 'Weeks'
	                },
	                {
		                id: 3,
		                name: 'Months'
	                }
                ];

                return patterns;
            },

            repeatPatternsMonthly(){

                let patterns = [
                    {
                        id: 1,
                        name: 'Monthly on day ' + moment(this.singleClass.start_date).date()
                    },
                    {
                        id: 2,
                        name: 'Monthly on the ' + this.numberWeekOfTheMonth + ' ' + this.nameOfTheDay
                    },
                    {
                        id: 3,
                        name: 'Monthly on the last ' + this.nameOfTheDay
                    }
                ];

              return patterns;
            },

            daysOfTheWeek(){

                let patterns = [
                    {
                        id: 1,
                        name: 'Monday'
                    },
                    {
                        id: 2,
                        name: 'Tuesday'
                    },
                    {
                        id: 3,
                        name: 'Wednesday'
                    },
                    {
                        id: 4,
                        name: 'Thursday'
                    },
                    {
                        id: 5,
                        name: 'Friday'
                    },
                    {
                        id: 6,
                        name: 'Saturday'
                    },
                    {
                        id: 7,
                        name: 'Sunday'
                    },
                ];

                return patterns;
            },

            timesToRepeatClasses() {

                let startDate = this.singleClass.start_date.toString().split('T')[0];
                let endDate = this.singleClass.repeat_until.toString().split('T')[0];

                let start = moment(startDate);
                let end = moment(endDate);
                let difference = end.diff(start, "days");

                let numberToDivide = 1;

                switch(this.singleClass.repeat_pattern_id){
                    case '1':
                        numberToDivide = 1;
                        break;
                    case '2':
                        numberToDivide = 7;
                        break;
                    case '3':
                        numberToDivide = 30;
                        break;
                }

                if(!isNaN(difference)){

                    this.numberOfClasses = (difference / numberToDivide);
                    this.numberOfClasses = Math.floor(this.numberOfClasses);

                    if(this.singleClass.repeat_pattern_number) {

                      this.numberOfClasses = this.numberOfClasses;
                    }
                }

                return this.numberOfClasses;
            },

            trainersUsers() {

                return this.users.filter(user => user.role_id == 4);
            },

            ...mapGetters(
                {
                    showTourGuide: 'getIsTourGuideActive'
                },
            )
        },
        methods: {

            getAllBlueprints(){

                // return this.$store.dispatch('bluePrints/getBluePrints')
                return BluePrintService.getBluePrints()
                    .then((res) => {

                        this.templates = res;
                        // this.setLoadingSpinner(false);
                        // this.bluePrints.push({});

                    })
                    .catch((err) => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: `${err.message}. Please, try again.`,
                            mode: 'fail'
                        });
                    });

            },

            getAllUsers(){

                return UserService.getAll()
                    .then(data => {

                        this.users = data;
                    })
                    .catch((error) => {

                         this.$store.dispatch('showSnackbarMessage', {
                            message: 'Failed. Please, try again.',
                            duration: 8000,
                            mode: 'fail'
                        });
                    });
            },

            getAllStores(){

                return StoreService.getStores()
                    .then((res) => {
                        this.stores = res;
                    })
                    .catch(() => {
                        this.$store.dispatch('showSnackbarMessage', {message: 'Failed. Please, try again.', duration: 8000, mode: 'fail'});
                    });
            },

            disabledDates(date) {

                return new Date(Date.now()) >= date;
            },

            disabledDatesRepeatUntil(date) {

                let dateObj = {};
                let startDate = '';

                switch(this.singleClass.repeat_pattern_id){
                    case '1':
                        startDate = new Date(this.singleClass.start_date);
                        startDate.setDate(startDate.getDate() + 2);
                        dateObj = startDate >= date;
                        break;
                    case '2':
                        startDate = new Date(this.singleClass.start_date);
                        startDate.setDate(startDate.getDate() + 7);
                        dateObj = startDate >= date;
                        break;
                    case '3':
                        startDate = new Date(this.singleClass.start_date);
                        startDate.setDate(startDate.getDate() + 30);
                        dateObj = startDate >= date;
                        break;
                }

                return dateObj;
            },

            getAllRooms(){

                return RoomService.getRooms()
                    .then((res) => {
                        this.rooms = res;
                    })
                    .catch(() => {
                        this.$store.dispatch('showSnackbarMessage', {message: 'Failed. Please, try again.', duration: 8000, mode: 'fail'});
                    });
            },

            returnToRechoseDate(){

                this.singleClass['start_time'] = this.convertTimeFormat(this.singleClass['start_time']);
                this.singleClass['end_time'] = this.convertTimeFormat(this.singleClass['end_time']);

                this.showNoAvailableRoomsDesign = false;
                this.showFindRoomInputForm = false;
            },

            closeDialog() {

                if (this.showTourGuide) {

                    this.$router.push('/dashboard');
                }
                this.$emit('update:showDialogRecurringClass', false);
            },

            findARoom() {

                let isOpenTimeCorrect = this.timePropsValueForStr(this.singleClass.start_time);
                let isCloseTimeCorrect = this.timePropsValueForStr(this.singleClass.end_time);

                if (isOpenTimeCorrect && isCloseTimeCorrect) {

                    this.singleClassRoom.room_id = '';
                    this.showFindRoomInputForm = true;
                } else {

                    this.$store.dispatch('showSnackbarMessage', {
                        message: 'Please add a valid time on "Open" and "Close" time fields',
                        duration: 4000,
                        mode: 'error'
                    });
                }
            },

            setupSingleClassForCreating() {

                this.singleClass['start_time'] = this.convertAMPMForStr(this.singleClass['start_time']);
                this.singleClass['end_time'] = this.convertAMPMForStr(this.singleClass['end_time']);
                let singleClassObj = this.singleClass;

                singleClassObj['class_type_id'] = 2;
                singleClassObj.start_date = singleClassObj.start_date.toString().split('T')[0];
                singleClassObj['repeat_pattern_days'] = parseInt(this.singleClass.repeat_pattern_days);

                if (!this.isReccuringClass) {

                    singleClassObj['class_type_id'] = 1;
                    singleClassObj['repeat_pattern_id'] = null;
                }

                if (this.isWeeklySelected) {

                   singleClassObj['repeat_pattern_days'] = "[" + this.selectedDaysOfTheWeek.toString() + "]";
                }

                return singleClassObj;
            },

            createRecurringClass(){

                let singleClassObj = this.setupSingleClassForCreating()

                ClassService.createSingleClass(singleClassObj)
                    .then(() => {

                        this.$emit('refreshAllClasses');

                        this.$store.dispatch('showSnackbarMessage', {
                          message: 'The class was successfully added.',
                          duration: 4000,
                          mode: 'success'
                        });

                        this.closeDialog();
                    })
                    .catch((err) => {

                        if (err.response.data['conflicts'].length) {

                            this.$store.dispatch('showSnackbarMessage', {
                                message: 'There are conflicts with this class. Please resolve them',
                                duration: 4000,
                                mode: 'success'
                            });

                            let selectedRoom = this.rooms.find(x => x.id == this.singleClass.room_id);
                            const getConflictsFromArray = data =>
                                data.reduce((acc, item) => {
                                    acc.push({
                                                'data': item['start_datetime'].split(' ')[0],
                                                'start_time': item['start_datetime'].split(' ')[1],
                                                'end_time': item['end_datetime'].split(' ')[1],
                                                'room': selectedRoom,
                                                'isEdit': false
                                              });

                                    return acc;
                                }, []);

                            this.conflicts =  getConflictsFromArray(err.response.data['conflicts']);
                            this.showNoAvailableRoomsDesign = true;
                        } else {

                           this.$store.dispatch('showSnackbarMessage', {
                              message: `${err.message}. Please, try again.`,
                              duration: 4000,
                              mode: 'fail'
                           });
                        }
                    });
            },

            saveReplacementInitial(conflict) {

                this.singleReplacementChange = {};

                this.singleReplacementChange.initial_start_date = conflict.data;
                this.singleReplacementChange.initial_start_time = conflict.start_time;
                this.singleReplacementChange.initial_end_time = conflict.end_time;
            },

            convertSelectedConflictTime(conflict) {

                this.selectedConflict = Object.assign({}, conflict);
                this.selectedConflict.start_time = this.convertTimeFormat(this.selectedConflict.start_time);
                this.selectedConflict.end_time = this.convertTimeFormat(this.selectedConflict.end_time);
            },

            openEditConflictDialog(conflict){

                this.saveReplacementInitial(conflict);
                this.convertSelectedConflictTime(conflict);

                this.showNoAvailableRoomsDesign = false;
                this.showConflictRoomsForm = true;
            },

            goToConflictRooms(){

                this.showNoAvailableRoomsDesign = true;
                this.showConflictRoomsForm = false;
            },

            updateConflictRoom(){

                this.selectedConflict.isEdit = true;
                this.selectedConflict.start_time = this.convertAMPMForStr(this.selectedConflict.start_time) + ':00';
                this.selectedConflict.end_time = this.convertAMPMForStr(this.selectedConflict.end_time) + ':00';

                this.singleReplacementChange.new_start_date = this.selectedConflict.data;
                this.singleReplacementChange.new_start_time = this.selectedConflict.start_time
                this.singleReplacementChange.new_end_time = this.selectedConflict.end_time

                this.singleClass.repeat_replacements.push(this.singleReplacementChange);

                this.createRecurringClass();

                this.openEditConflictForm();
            },

            openEditConflictForm() {

                this.showNoAvailableRoomsDesign = true;
                this.showConflictRoomsForm = false;
            },

            async fetchData(){

                await this.getAllBlueprints();
                await this.getAllUsers();
                await this.getAllStores();
                await this.getAllRooms();
            }
        },

        afterCreate() {

            this.fetchData();
        },

        created() {
            this.fetchData();
            this.currentUserRole = this.$store.state.auth.user.role_id;
            this.singleClass.start_date = moment().format('YYYY-MM-DD');
        },

        watch: {

            'singleClass.start_date': function(value) {

                let dayOfTheWeek =  moment(this.singleClass.start_date).day();
                this.nameOfTheDay = moment.weekdays(true, dayOfTheWeek);

                let numToString = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth'];
                let firstDayOfMonth = moment().startOf('month').day() - 1;

                let numberWeek = Math.ceil((moment(this.singleClass.start_date).date() + firstDayOfMonth) / 7); // get number of the week for repeat monthly pattern
                this.numberWeekOfTheMonth = numToString[numberWeek - 1];
            },

            'store_id': function(value){

                  if (value) {

                      this.filteredRooms = this.rooms.filter(room => room.store_id == value);
                  } else {

                      this.filteredRooms = [];
                  }
            },

            'singleClass.repeat_pattern_id': function(value){

                  if (!value) {

                      this.singleClass.repeat_pattern_id = null;
                  }

                  switch (Number(value)) {

                      case 1:
                          this.isWeeklySelected = false;
                          this.isMonthlySelected = false;
                          break;
                      case 2:
                          this.isWeeklySelected = true;
                          this.isMonthlySelected = false;
                          break;
                      case 3:
                          this.isWeeklySelected = false;
                          this.isMonthlySelected = true;
                          break;
                  }
            },
        },

        filters: {

             fullName(value) {

                 return value.first_name.charAt(0).toUpperCase() + value.first_name.slice(1) + ' '
                        + value.last_name.charAt(0).toUpperCase() + value.last_name.slice(1);
             }
        }
    }
</script>

<style lang="scss" scoped>
    @include dialogSettings();
    .dialog__hint {
        margin-bottom: 16px;
    }

    .md-dialog-title {
        margin-bottom: 1rem;
    }

    .recurring-form {

        .md-layout-item {
          margin: 0 auto;
        }

        .control-field {
            padding-bottom: 14px;

            ::v-deep &__input-icon {
                bottom: 30px;
                right: 25px;
            }
        }

        .days-of-week-select {
            background-color: #d8d8d8;
            border: solid 2px #f0f1f2;
            color: #8996a6;
            font-size: 1.3rem;
            font-weight: 300;
            line-height: 1.85;
            padding-left: 24px;
            transition: all .4s ease-out, border 1ms;
            width: 100%;

            label {
                color: #8996a6;
                font: 400 13.3333px Arial;
            }
        }

        @media only screen and (min-width: 1279px) {
            .md-pr-4 {
                padding-right: 40px;
            }
        }
    }

    .md-card.md-theme-default.md-accent {
      width: 100%;
      background-color: #ff1744;
    }

    .md-card.md-accent.md-theme-default.md-with-hover:hover {
      background-color: #DB2955;
    }

    .md-card.md-accent.md-theme-default.resolved {
      background-color: #a156a0!important;
    }

    .md-card {
      margin: 10px;
      display: inline-block;
      vertical-align: top;
      padding-bottom: 20px;
    }

    .md-layout-item.toggle-btn {
        text-align: center;
        padding-bottom: 20px;
    }

</style>
