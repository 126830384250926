<template>
	<div v-show="isPlayerActive || showPlayer" class="player">
		<div
			v-if="isIntervalPlaying == false"
			class="close-button"
			id="close-media-player"
			@click="closePlayer"
		>
			<md-icon id="close-icon  " class="md-theme-default "> close</md-icon>
		</div>

		<div class="player-controls md-layout md-alignment-center-center">
			<div class="md-layout-item md-size-20 md-small-size-100  mix-info-mobile-view ">
				<div class="md-layout-item md-size-100 player-info-text">
					<div id="song-name-mobile-view">
						<div>
							<span> {{ songName }} </span>
						</div>
					</div>
				</div>

				<div class="md-layout-item md-size-100 player-info-text md-small-hide"
				     v-if="isIntervalPlaying == false"
				>
					<div id="song-title-mix-mobile-view">
						<div>
						<span>
							{{ currentIntervalPlayedSongName }}
						</span>
						</div>
					</div>
				</div>

				<div class="md-layout-item md-size-100 player-info-text md-small-hide"
				     v-if="isIntervalPlaying == false"
				>
					<div id="song-artist-mix-mobile-view">
						<div>
						<span>
							{{ currentIntervalPlayedArtistName }}
						</span>
						</div>
					</div>
				</div>
			</div>

			<div
				class="md-layout md-layout-item md-size-20 md-small-size-100 volume-control-player md-alignment-center-right md-small-hide">

				<div class="md-layout-item md-size-40 player-info-text md-medium-hide">
					<div id="interval-playing-note" style=" font-size: 1.2rem;">
						<span> <b>Current:</b> {{ currentIntervalNote }} </span>
					</div>

					<div id="next-interval-playing-note" style=" font-size: 1.2rem;">
						<span> <b>Up next:</b> {{ nextIntervalNote }} </span>
					</div>
				</div>

				<div class="md-layout-item md-size-10 md-small-size-10">
					<div id="mute">
						<a @click.prevent="mute">
							<md-icon v-if="!muted"> volume_up</md-icon>
							<md-icon v-else> volume_off</md-icon>
						</a>
					</div>
				</div>

				<div class="md-layout-item md-size-50 md-medium-size-80 md-small-size-10">
					<div id="volume-bar">
						<a :title="volumeTitle" :alt="volumeTitle">
							<input
								v-model="volume"
								v-show="showVolume"
								class="player-volume"
								type="range"
								min="0"
								max="100"
							/>
						</a>
					</div>
				</div>

			</div>

			<div
				class="md-layout md-layout-item md-size-60 md-small-size-100 control-player md-alignment-center-center">

				<div
					class="md-layout md-layout-item md-size-10 md-medium-size-10 md-xsmall-size-40 md-alignment-center-right">
					<div class="md-layout-item ">

					</div>

					<div class="md-layout-item">
						<div>
							<md-icon id="prev-interval" @click.native="goToPreviousIntervalOfMix" class="md-size-1x">
								skip_previous
							</md-icon>
						</div>
					</div>
				</div>

				<div
					class="md-layout md-layout-item md-size-5  md-medium-size-10 md-xsmall-size-20 md-alignment-center-center">
					<div class="md-layout-item ">
						<div id="play-stop">
							<a @click.prevent="playing = !playing" :title="(playing) ? 'Pause' : 'Play'">
								<md-icon class=" pause md-size-2x" v-if="playing">
									pause_circle_filled
								</md-icon>
								<md-icon class="md-size-2x play" v-else>play_circle_filled
								</md-icon>
							</a>
						</div>
					</div>
				</div>

				<div
					class="md-layout md-layout-item md-size-10   md-medium-size-10 md-xsmall-size-40 md-alignment-center-left">
					<div class="md-layout-item ">
						<div id="next-interval">
							<md-icon @click.native="goToNextIntervalOfMix" class="md-size-1x"> skip_next</md-icon>
						</div>
					</div>

					<div class="md-layout-item ">
						<div id="restart">
							<md-icon @click.native="restart" class="md-size-1x"> restart_alt</md-icon>
						</div>
					</div>
				</div>

				<div class="md-layout-item  md-layout md-alignment-center-right md-size-100" id="timeline-sector">
					<div class="md-layout-item md-size-15 md-small-size-20 md-alignment-center-right">
						<div class="player-time player-time-start" id="current-time">
							<div class="player-time-current">{{ currentTime }}</div>
						</div>
					</div>

					<div class="md-layout-item md-size-70 md-small-size-60 ">

						<div class="player-timeline">
							<input
								v-model="percentComplete"
								:style="{ background: createBackgroundString }"
								id="duration-input"
								class="player-progress"
								type="range"
								min="0"
								max="100"
							/>

							<div
								v-for="(interval, index) in intervalsEndsInPercentageInTheMix"
								v-if="interval.showEndOnPlayer"
								:key="index + '_interval_end'"
								:class="`player-interval-split color-flex__${interval.flexEnergy}` "
								:style="`width: ${interval.endTimeOfInterval}%;
								         z-index: ${-index + intervalsEndsInPercentageInTheMix.length};`"
							>
								<div
									v-if="index !== intervalsEndsInPercentageInTheMix.length - 1"
									class="vertical"
								/>
							</div>
						</div>
					</div>

					<div class="md-layout-item md-size-15 md-small-size-20 ">
						<div class="player-time player-time-end">
							<div class="player-time-total">{{ durationTime }}</div>
						</div>
					</div>
				</div>
			</div>

			<div class="md-layout-item md-size-20 md-small-size-100  mix-info md-small-hide">
				<div class="md-layout-item md-size-100 player-info-text">
					<div id="song-name">
						<div>
							<span> {{ songName }} </span>
						</div>
					</div>
				</div>

				<div class="md-layout-item md-size-100 player-info-text md-small-hide"
				     v-if="isIntervalPlaying == false"
				>
					<div id="song-title-mix">
						<div>
							<span>
								{{ currentIntervalPlayedSongName }}
							</span>
						</div>
					</div>
				</div>

				<div class="md-layout-item md-size-100 player-info-text md-small-hide"
				     v-if="isIntervalPlaying == false"
				>
					<div id="song-artist-mix">
						<div>
							<span>
								{{ currentIntervalPlayedArtistName }}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<audio :loop="innerLoop" ref="audiofile" :src="songFile" preload="auto"></audio>

		<AudioPlayerPlaylist
			:show-playerlist-prop.sync="showPlayerlist"
			:intervals-prop="intervals"
			:durationSecondsProp="durationSeconds"
			:percentCompleteProp.sync="percentComplete"
			:key="dialogKey + '_playlist'"
		/>
	</div>
</template>

<script>

	import AudioPlayerPlaylist from "@/components/AudioPlayer/AudioPlayerPlaylist";
	import PlaylistService from "@/services/playlists.service";
	import {mapGetters} from "vuex";

	export default {
		components: {AudioPlayerPlaylist},
		props: {
			file: {
				type: String,
				default: null
			},

			loop: {
				type: Boolean,
				default: false
			},

			showPlayer: {
				type: Boolean,
				default: false
			},

			currentSecondsForTemplateProp: {
				type: Number,
				default: null
			},

			durationSecondsForTemplateProp: {
				type: Number,
				default: null
			}
		},

		data: () => ({
			audio: undefined,
			currentSeconds: 0,
			durationSeconds: 0,
			innerLoop: false,
			loaded: false,
			playing: false,
			previousVolume: 35,
			showVolume: true,
			volume: 100,
			isPlayerActive: false,
			showPlayerlist: false,
			intervals: null,
			dialogKey: 0,
			currenPlayedInterval: null,
			nextIntervalNote: '',
		}),

		filters: {

			getNameForCurrentPlayingInterval(currentInterval) {

				if (currentInterval.song.title) {

					return currentInterval.song.title;
				} else {

					return "";
				}
			},

			getArtistNameForCurrentPlayingInterval(currentInterval) {

				if (currentInterval.song.artist.name) {

					return currentInterval.song.artist.name;
				} else {

					return "";
				}
			},
		},

		computed: {

			...mapGetters(
				{
					artistName: 'audioPlayer/getArtist',
					songName: 'audioPlayer/getSongName',
					songFile: 'audioPlayer/getSong',
					startSeconds: 'audioPlayer/getStartSeconds',
					endSeconds: 'audioPlayer/getEndSeconds',
					playlistId: 'audioPlayer/getPlaylistId',
					playlistDurationInterval: 'audioPlayer/getPlaylistDurationInterval',
					autoPlay: 'audioPlayer/getAutoplay',
					isIntervalPlaying: 'audioPlayer/getIsIntervalPlaying',
					intervalsWithTimeline: 'audioPlayer/getIntervals'
				},
			),

			currentTime() {

				let result = this.convertTimeHHMMSS(this.currentSeconds);

				return (result) ? result : "00:00";

			},

			durationTime() {

				return this.convertTimeHHMMSS(this.durationSeconds);
			},

			muted() {

				return this.volume / 100 === 0;
			},

			volumeTitle() {

				return `Volume (${this.volume}%)`;
			},

			progressStyle() {

				return {width: this.percentComplete + '%'}
			},

			createBackgroundString() {

				return `linear-gradient(to right, #A156A0FF  0%, #A156A0FF ${this.percentComplete}%, #fff ${this.percentComplete}%, #fff 100%)`;
			},

			percentComplete: {

				get: function () {

					if (this.durationSeconds == 0 || this.currentSeconds == 0) {
						return 0;
					}

					return Number(this.currentSeconds / this.durationSeconds * 100);
				},

				set: function (newValue) {

					this.audio.currentTime = Number((this.durationSeconds * newValue) / 100);
					return newValue;
				}
			},

			stylePercentComplete() {

				return {background: `linear-gradient(to right, #82CFD0 0%, #82CFD0 ' + ${this.percentComplete} + '%, #fff ' + ${this.percentComplete} + '%, white 100%)`};
			},

			intervalsEndsInPercentageInTheMix() {

				return this.intervalsWithTimeline ? this.getIntervalsEndingTimeInPercentage(this.intervalsWithTimeline) : [];
			},

			mainIntervalsEndsInPercentage() {

				return this.intervalsWithTimeline ? this.getIntervalsEndingForNotes(this.intervalsEndsInPercentageInTheMix) : [];
			},

			currentIntervalPlayed() {

				let currentTimeline = -1;

				for (const argumentsKey in this.intervalsWithTimeline) {

					if (this.intervalsWithTimeline[argumentsKey].interval_songs.length > 1) {

						currentTimeline = this.convertHHMMSStoSeconds(this.intervalsWithTimeline[argumentsKey].timeline);

						for (const songKey in this.intervalsWithTimeline[argumentsKey].interval_songs) {

							let intervalNote = {name: this.intervalsWithTimeline[argumentsKey].name}
							let interval = {...intervalNote, ...this.intervalsWithTimeline[argumentsKey].interval_songs[songKey]};

							currentTimeline += interval.song_duration;

							if (this.currentSeconds < currentTimeline) {

								return interval;
							}
						}

					} else {

						let intervalTimelineSecond = this.convertHHMMSStoSeconds(this.intervalsWithTimeline[argumentsKey].timeline);
						let durationSecond = this.convertHHMMSStoSeconds(this.intervalsWithTimeline[argumentsKey].duration);

						let currentTimeline = intervalTimelineSecond + durationSecond;

						if (this.currentSeconds < currentTimeline) {

							let intervalNote = {name: this.intervalsWithTimeline[argumentsKey].name}
							let interval = {...intervalNote, ...this.intervalsWithTimeline[argumentsKey].interval_songs[0]};

							return interval;
						}

					}
				}

				if (this.currentSeconds >= currentTimeline) {

					return null;
				}
			},

			currentIntervalPlayedSongName() {

				if (this.currentIntervalPlayed) {

					return this.currentIntervalPlayed.song.title;
				}

				return "";
			},

			currentIntervalPlayedArtistName() {

				if (this.currentIntervalPlayed) {

					return this.currentIntervalPlayed.song.artist.name;
				}

				return "";
			},

			currentIntervalNote() {

				if (this.currentIntervalPlayed) {

					return this.currentIntervalPlayed.name;
				}

				return "";
			},
		},

		watch: {

			startSeconds: {

				handler: function (newVal, oldVal) {

					this.audio.currentTime = newVal;
				}
			},

			endSeconds: {

				handler: function (newVal, oldVal) {

					this.durationSeconds = newVal;
				}
			},

			durationSecondsForTemplateProp(value) {

				// this.durationSeconds = value;
			},

			songFile(newSong, oldSong) {

				this.playing = false;

				if (newSong) {

					let playPromise = this.audio.play();

					if (!this.isPlayerActive) {

						this.isPlayerActive = true
					}

					if (playPromise !== undefined && this.autoPlay) {
						playPromise.then(_ => {
							// Automatic playback started!
							this.audio.currentTime = this.startSeconds;

							return this.audio.play();

						})
							.catch(error => {
								// Auto-play was prevented
								this.audio.currentTime = this.startSeconds;

								return this.audio.play();
							});
					}
				}
			},

			currentIntervalPlayed(newValue, oldValue) {

				if (newValue == null) {

					return;
				}

				if (oldValue == null || newValue.id != oldValue.id) {

					this.getNextIntervalNote();
				}
			},

			playing(value) {

				if (value) {

					return this.audio.play();
				}

				this.audio.pause();
			},

			volume(value) {

				this.audio.volume = this.volume / 100;
			}
		},

		methods: {

			convertTimeHHMMSS(val) {

				if (!val) {

					return
				}

				let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);

				return hhmmss.indexOf("00:") === 0 ? hhmmss.substr(3) : hhmmss;
			},

			convertHHMMSStoSeconds(timeHHMMSS) {

				// console.log('timeHHMMSS: ' + timeHHMMSS);
				let timeObj = timeHHMMSS.split(':');
				let secondsDuration = (+timeObj[0]) * 60 * 60 + (+timeObj[1]) * 60 + (+timeObj[2]);

				return secondsDuration;
			},

			getPlaylistIntervals(id) {

				return PlaylistService.getIntervalsByPlaylist(id)
					.then((res) => {

						this.intervals = res;
					})
					.catch(() => {

						this.$store.dispatch('showSnackbarMessage', {
							message: 'Failed. Please, try again.',
							duration: 8000,
							mode: 'fail'
						});
					});
			},

			// async openPlaylistModal() {
			//
			// 	await this.getPlaylistIntervals(this.playlistId);
			// 	this.dialogKey++;
			// 	this.showPlayerlist = true;
			// },

			download() {

				this.stop();
				window.open(this.file, 'download');
			},

			load() {

				if (this.audio.readyState >= 2) {

					this.loaded = true;

					if (!this.endSeconds) {

						this.durationSeconds = Number(this.audio.duration)
					}

					if (this.autoPlay) {

						return this.playing = true;
					}
				}

				// throw new Error('Failed to load sound file.');
			},

			mute() {

				if (this.muted) {
					return this.volume = this.previousVolume;
				}

				this.previousVolumeq = this.volume;
				this.volume = 0;
			},

			seek(e) {

				if (!this.loaded) return;

				const el = e.target.getBoundingClientRect();
				const seekPos = (e.clientX - el.left) / el.width;

				this.audio.currentTime = Number(this.durationSeconds * seekPos);
			},

			stop() {

				this.playing = false;
				// this.currentSecondsForTemplateProp = 0;
				// this.audio.currentTime = 0;
			},

			update(e) {

				// this.$nextTick(() => {
				if (this.currentSeconds >= this.durationSeconds) {
					this.stop();
				}
				this.currentSeconds = Number(this.audio.currentTime);
				// });
			},

			async restart() {

				this.audio.currentTime = 0;
				this.playing = true;
			},

			closePlayer() {

				// console.log("close player");

				this.$store.dispatch('audioPlayer/resetPlayerData');

				this.isPlayerActive = false;
			},

			calculateIntervalsEndTimeInSeconds(intervals) {

				let arrayWithEndSeconds = [];
				let currentIntervalEndSecond = 0;

				intervals.map((song) => {

					currentIntervalEndSecond += song.song_duration; // ending seconds for an interval
					arrayWithEndSeconds.push({
						endSeconds: currentIntervalEndSecond,
						flexEnergy: song.song.energy,
						note: song.name,
						showEndOnPlayer: song.showEndOnPlayer

					});
				});

				return arrayWithEndSeconds;
			},

			getAllIntervalsEndTimeInSeconds(intervals) {

				let allIntervalsSongs = [];

				intervals.map((interval, index) => {

					interval.interval_songs.map((intervalSong, index, arr) => {
						intervalSong.song.energy = interval.energy;
						intervalSong.name = interval.name;

						if (arr.length - 1 === index) {

							intervalSong.showEndOnPlayer = true;
						}
					});

					allIntervalsSongs.push(interval.interval_songs);
				})
				allIntervalsSongs = allIntervalsSongs.flat();

				return this.calculateIntervalsEndTimeInSeconds(allIntervalsSongs);
			},

			calcPercentEndWidth(endSeconds) {

				return Number((endSeconds / this.durationSeconds * 100).toFixed(2));
			},

			convertEndTimesFromSecondsToPercents(intervalsEndsInSeconds) {

				let intervalsEndsInPercentage = [];

				intervalsEndsInSeconds.map((interval) => {

					intervalsEndsInPercentage.push({
						endTimeOfInterval: this.calcPercentEndWidth(interval.endSeconds),
						flexEnergy: interval.flexEnergy,
						note: interval.note,
						showEndOnPlayer: interval.showEndOnPlayer
					});
				});

				return intervalsEndsInPercentage;
			},

			getIntervalsEndingTimeInPercentage(intervals) {

				let intervalsEndsInSeconds = this.getAllIntervalsEndTimeInSeconds(intervals);
				let intervalsEndsInPercentage = this.convertEndTimesFromSecondsToPercents(intervalsEndsInSeconds);

				return intervalsEndsInPercentage;
			},

			getIntervalsEndingForNotes(intervals) {

				let newArray = intervals.filter((interval) => {
					return interval.showEndOnPlayer;
				})

				return newArray;
			},

			goToPreviousIntervalOfMix() {

				if (this.percentComplete < this.intervalsEndsInPercentageInTheMix[0].endTimeOfInterval) {

					this.percentComplete = 0;
					return 0;
				}

				this.intervalsEndsInPercentageInTheMix.forEach((intervalEnd) => {

					if (this.percentComplete > intervalEnd.endTimeOfInterval) {

						this.percentComplete = intervalEnd.endTimeOfInterval;
						return intervalEnd.endTimeOfInterval;
					}
				});
			},

			goToNextIntervalOfMix() {

				if (this.percentComplete.toFixed(2) < this.intervalsEndsInPercentageInTheMix[0].endTimeOfInterval) {

					this.percentComplete = this.intervalsEndsInPercentageInTheMix[0].endTimeOfInterval;
					return this.intervalsEndsInPercentageInTheMix[0].endTimeOfInterval;
				}

				this.intervalsEndsInPercentageInTheMix.forEach((intervalEnd, index) => {

					if (this.percentComplete.toFixed(2) >= intervalEnd.endTimeOfInterval) {

						if ((index + 2) == this.intervalsEndsInPercentageInTheMix.length) {

							this.percentComplete = intervalEnd.endTimeOfInterval;
							return intervalEnd.endTimeOfInterval;
						}

						this.percentComplete = this.intervalsEndsInPercentageInTheMix[index + 1].endTimeOfInterval;
						return this.intervalsEndsInPercentageInTheMix[index + 1].endTimeOfInterval;
					}
				});
			},

			getNextIntervalNote() {

				if (this.percentComplete.toFixed(2) < this.mainIntervalsEndsInPercentage[0].endTimeOfInterval) {

					this.nextIntervalNote = this.mainIntervalsEndsInPercentage[1].note;
					return;
				}

				if (this.percentComplete.toFixed(2) >= this.mainIntervalsEndsInPercentage.at(-2).endTimeOfInterval) {

					this.nextIntervalNote = null;
					return;
				}

				this.mainIntervalsEndsInPercentage.forEach((intervalEnd, index) => {

					if (this.percentComplete.toFixed(2) > intervalEnd.endTimeOfInterval) {

						if ((index + 2) == this.mainIntervalsEndsInPercentage.length) {

							this.nextIntervalNote = this.mainIntervalsEndsInPercentage[index + 1].note
							return;
						}

						this.nextIntervalNote = this.mainIntervalsEndsInPercentage[index + 2].note
					}
				});
			},

		},

		created() {

			this.durationSeconds = this.endSeconds;
			this.innerLoop = this.loop;
		},

		mounted() {

			this.$nextTick(() => {

				this.audio = this.$el.querySelectorAll('audio')[0];
				this.audio.addEventListener('timeupdate', this.update);
				this.audio.addEventListener('loadeddata', this.load);
				this.audio.addEventListener('pause', () => {
					this.playing = false;
				});

				this.audio.addEventListener('play', () => {
					this.playing = true;
				});

				if (this.songFile) {

					this.getNextIntervalNote();
				}
			});
		},

		async beforeDestroy() {

			//when leaving playlist/{id} page we save current seconds to play from it on next page
			if (!(this.$route.fullPath.includes('playlists/'))) {

				// console.log("save current seconds setStartSeconds: " + this.currentSeconds );
				await this.$store.dispatch('audioPlayer/updateStartSeconds', {startSeconds: this.currentSeconds});

				if (this.playing == false) {

					await this.$store.dispatch('audioPlayer/updateAutoPlay', {autoplay: false});
				} else {

					await this.$store.dispatch('audioPlayer/updateAutoPlay', {autoplay: true});
				}
			}
		}
	}
</script>

<style lang="scss" scoped>

	$player-bg: #fff;
	$player-border-color: darken($player-bg, 12%);
	$player-link-color: darken($player-bg, 75%);
	$player-progress-color: $player-border-color;
	$player-seeker-color: $player-link-color;
	$player-text-color: $player-link-color;

	.player-wrapper {
		align-items: center;
		background-color: $player-bg;
		background-image: linear-gradient(90deg, #fff 0, darken(#fff, 12%));
		display: flex;
		height: 100vh;
		justify-content: center;
	}

	.player {
		background-color: $player-bg;
		border-radius: 5px;
		border: 1px solid $player-border-color;
		box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
		color: $player-text-color;
		display: inline-block;
		line-height: 1.5625;
		position: relative;

		#close-media-player {
			position: absolute;
			background: $color-accent-red;
			color: white !important;
			top: -1px;
			right: -1px;
			cursor: pointer;
			z-index: 5;

			.md-icon {
				color: white !important;
			}
		}

		.player-controls {
			display: flex;

			position: relative;

			> div {

				&:last-child {
					border-right: none;
				}

				a {
					cursor: pointer;
					color: $player-link-color;
					display: block;
					line-height: 0;
					padding: 1em;
					text-decoration: none;
				}
			}
		}

		.player-timeline {
			background-color: $color-black;
			height: 8px;
			position: relative;
			border-radius: 5rem;

			#duration-input {
				background: linear-gradient(to right, $color-accent-red 0%, $color-accent-red 50%, #fff 50%, #fff 100%);
				border: solid 1px $color-black;
				border-radius: 8px;
				height: 7px;
				outline: none;
				transition: background 450ms ease-in;
				-webkit-appearance: none;
				width: 100%;

			}

			.color-flex {
				&__1 {
					background-color: blue;
				}

				&__2 {
					background-color: lightgreen;
				}

				&__3 {
					background-color: yellow;
				}

				&__4 {
					background-color: orange;
				}

				&__5 {
					background-color: red;
				}
			}

			input[type='range']::-webkit-slider-thumb {
				-webkit-appearance: none !important;
				background-color: red;
				//border: 1px solid #000000;
				height: 20px;
				width: 20px;
				border-radius: 50%;
			}

			.player-progress,
			.player-seeker {
				bottom: 0;
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
			}

			.player-progress {
				background-color: $color-accent-purple;
				z-index: 99;
				border-radius: 5rem;
			}

			.player-seeker {
				cursor: pointer;
				width: 100%;
				z-index: 2;
			}

			.md-icon {
				color: $color-accent-red !important;
			}

			.player-interval-split {
				height: 100%;
				position: absolute;
				top: -14px;
				bottom: 0;
				left: 0;

				.vertical {
					border-right: 4px solid red;
					height: 35px;

					@include respond(small) {
						//display: none;
					}
				}

				.md-icon {
					color: green !important;
				}
			}
		}

		.player-time {
			display: flex;
			justify-content: space-between;

			&-start {
				margin-right: 0.5rem;
				justify-content: flex-end;
			}

			&-end {
				margin-left: 1rem;
			}

			.player-time-current {
				font-weight: 700;
				padding-left: 5px;
				padding-right: 5px;
			}

			.player-time-total {
				opacity: 0.5;
				padding-right: 5px;
			}
		}

		.md-icon {
			color: $color-black !important;
		}

		.pause, .play {
			color: $color-accent-red !important;
		}
	}

	.player {
		width: 100%;
	}

	div#song-name {
		text-align: center;
		margin: auto;
		padding-left: 10px;
	}

	.player-info-text {
		padding-top: 3px;
		padding-bottom: 0;
	}

	#volume-bar {
		border-radius: 10rem;

		/*Chrome*/
		@media screen and (-webkit-min-device-pixel-ratio: 0) {
			input[type='range'] {
				border-radius: 10rem;
				overflow: hidden;
				-webkit-appearance: none;
				background-color: $color-black;
			}

			input[type='range']::-webkit-slider-thumb {
				width: 0;
				-webkit-appearance: none;
				height: 15px;
				box-shadow: -200px 0 0 200px $color-accent-red;
			}

		}
		/** FF*/
		input[type="range"]::-moz-range-progress {
			background-color: $color-accent-red;
		}

		input[type="range"]::-moz-range-track {
			background-color: $color-black;
		}

		/* IE*/
		input[type="range"]::-ms-fill-lower {
			background-color: $color-accent-red;
		}

		input[type="range"]::-ms-fill-upper {
			background-color: $color-black;
		}
	}

	.player-volume {
		display: inline-block;
		height: 1.1rem;
		margin: 0 0 0 2px;
		width: 100%;
		color: $color-accent-red !important;
	}

	#current-time-position-playing {
		display: flex;
		margin-right: -10px;
		margin-top: -1vh;
	}

	#interval-end-position {
		display: flex;
		margin-right: -10px;
		margin-top: -1vh;
	}

	#timeline-sector {
		margin-bottom: 1rem;
	}

	#restart:hover, #next-interval, #prev-interval {
		cursor: pointer;
	}

	.mix-info-mobile-view {
		display: none;

		@media only screen and (max-width: 961px) {
			display: block;
		}
	}

</style>