<template>
    <div
        class="custom-input"
        :class="{'valid': time.min != 0 || time.sec != 0}"
        tabindex="0"
        ref="input"
        @click="showDurationOptions = true"
        @click.stop="$refs.input.focus()">
        
        <span
            class="custom-input__display"
            @click="showDurationOptions = !showDurationOptions"
        >
            {{formatTime(time.min) + ':' + formatTime(time.sec) + 's'}}
        </span>

         <div class="custom-input__options"
            :class="{
                'active': showDurationOptions,
                'left': offsetSide === 'left',
                'right': offsetSide === 'right'}">
            
            <ul class="custom-input__options-list">
                <li class="custom-input__option"
                    v-for="(option, index) in options"
                    :key="index + 'key'"
                    @click.stop="selectTime(option)"
                >
                    {{formatTime(option.min) + ':' + formatTime(option.sec) + 's'}}
                </li>

                <li class="custom-input__option"
                    @click.stop="showCustomTimeSelect">
                    Select custom time
                </li>
            </ul>
        </div>

        <div class="custom-input__controls">
            <button @click.prevent.stop=""
                    @mousedown.prevent.stop="plusOnHold()"
                    @mouseup.prevent.stop="stopHold()">
                <md-icon>arrow_drop_up</md-icon>
            </button>
            <button @click.prevent.stop=""
                    @mousedown.prevent.stop="minusOnHold()"
                    @mouseup.prevent.stop="stopHold()">
                <md-icon>arrow_drop_down</md-icon>
            </button>
        </div>

    </div>
</template>

<script>
export default {
    name: 'AppTrackDurationInput',
    props: {
        timeData: {
            type: Object,
            default: () => ({
                min: 0,
                sec: 0
            })
        },
        showDurationOptionsProp: {
            type: Boolean,
            default: false
        },
        offsetSide: {
            type: String,
            default: 'right'
        }
    },

    data: () => ({
        options: [
            {
                min: 0,
                sec: 15
            },{
                min: 0,
                sec: 30
            },{
                min: 0,
                sec: 45
            },{
                min: 1,
                sec: 0
            },{
                min: 1,
                sec: 15
            },{
                min: 1,
                sec: 30
            },{
                min: 1,
                sec: 45
            },{
                min: 2,
                sec: 0
            },{
                min: 2,
                sec: 15
            },{
                min: 2,
                sec: 30
            },{
                min: 2,
                sec: 45
            },{
                min: 3,
                sec: 0
            },{
                min: 3,
                sec: 15
            },{
                min: 3,
                sec: 30
            },{
                min: 3,
                sec: 45
            },{
                min: 4,
                sec: 0
            },{
                min: 4,
                sec: 15
            },{
                min: 4,
                sec: 30
            },{
                min: 4,
                sec: 45
            },{
                min: 5,
                sec: 0
            }
        ]
    }),
    computed: {
        time: {
            set(newValue) {
                this.$emit('update:timeData', newValue);
                return newValue;
            },
            get() {
                return this.timeData;
            }
        },
        showDurationOptions: {
            set(newValue) {
                this.$emit('update:showDurationOptionsProp', newValue);
                return newValue;
            },
            get() {
                return this.showDurationOptionsProp;
            }
        }
    },
    methods: {
        formatTime(number) {
            if (number / 10 < 1) {
                return '0' + number;
            }
            return number;
        },

        plusTime() {
            if (this.time.sec + 1 < 60) {
                this.time.sec += 1;
            } else {
                this.time.min++;
                this.time.sec = 0;
            }
            this.$emit('update:timeData', this.time);
        },

        minusTime() {
            if (this.time.sec - 1 > 0) {
                this.time.sec -= 1;
            } else {
                
                if (this.time.min > 0) {
                    this.time.min--;
                    this.time.sec = 60;
                } else {
                    this.time.sec = 0;
                }
            }
            this.$emit('update:timeData', this.time);
        },
        plusOnHold() {
            this.intervalId = setInterval(this.plusTime, 150, this.plusTime());
        },
        minusOnHold() {
            this.intervalId = setInterval(this.minusTime, 150, this.minusTime());
        },
        stopHold() {
            clearInterval(this.intervalId);
        },

        showCustomTimeSelect() {

            this.$emit('showCustomSelectTime');
        },

        selectTime(option) {

            this.time = option;
            this.showDurationOptions = false;
        }
    }
}
</script>

<style lang="scss" scoped>
@include customSelectInput();
</style>