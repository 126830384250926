<template>
    <div class="blueprint-card md-elevation-5" tabindex="0">
        <md-tooltip class="md-theme-light" md-direction="top" v-if="!isDeleted">View Period</md-tooltip>

        <div class="blueprint-card__media" :style="{ background: 'linear-gradient(111deg, #a156a0, #EF476F), #a156a0' }">
            <h3 class="blueprint-card__title">{{period.name}}</h3>
        </div>

        <div class="blueprint-card__bottom">
            <div id="actions-buttons">
				<md-button
					class="md-icon-button blueprint-card__button"
					id="edit-button"
					v-if="!isDeleted"
					@click.stop="editPeriod(period.id)"
				>
					<md-tooltip class="md-theme-light" md-direction="top">Edit Period</md-tooltip>
					<md-icon class="md-theme-light">create</md-icon>
				</md-button>

				<md-button
					class="md-icon-button blueprint-card__button"
					id="remove-button"
					v-if="userRole < 5 && !isDeleted"
					@click.stop="deleteSegment(period.id)"
				>
					<md-tooltip class="md-theme-light" md-direction="top">Delete Period</md-tooltip>
					<md-icon class="md-theme-light">delete_forever</md-icon>
				</md-button>
            </div>

            <h4 class="blueprint-card__time"> {{ period.duration }}</h4>
        </div>
    </div>
</template>

<script>
    import PeriodService from '../../services/period.service';
    import {mapGetters} from "vuex";

    export default {
        name: "DashboardCardTemplate",

        props: {
            period: {
                type: Object,
                default: {}
            },

            isDeleted: {
                type: Boolean,
                default: false
            },

            label: {
                type: String,
                default: "View Period"
            }
        },

        methods: {

            editPeriod(id) {

                this.$emit('editPeriod', id);
            },

            deleteSegment(id){

                let _this = this;

				this.$emit('tempRemovePeriod', id);

				this.$store.dispatch('showSnackbarMessage', {
							message: 'Last chance to undo the delete.',
							duration: 5000,
							mode: 'success',
							buttonText: 'Undo',
							type: 'period'
				});

				setTimeout(() => { _this.deleteOneElement(id).then(() => {

					 this.$emit('setLoadingSpinner', false);
				   });
				}, 5000);
            },

            async deleteOneElement(id) {

                await this.deleteIfUndoIsInactive(id);
            },

            deletePeriod(id) {

                PeriodService.deletePeriod(id)
                    .then(() => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: 'The period was successfully deleted.',
                            duration: 3000,
                            mode: 'success'
                        });
                    })
                    .catch((err) => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: `${err.message}. Please, try again.`,
                            mode: 'fail'
                        });
                    });
            },

            deleteIfUndoIsInactive(id) {

                if (!(this.$store.getters['periods/getIsUndoActive'])) {

					this.deletePeriod(id);
                }

                this.$store.commit('periods/setIsUndoActive', false);
            },

            openURL(url) {

                console.log(url);
                window.open(url, '_blank');
            },
        },

        computed: {

            ...mapGetters(
                {
                  userRole: 'auth/currentUserRole',
                },
            ),
        },
    }
</script>

<style lang="scss" scoped>
	@include card();
</style>