const keys = {
    appleMusic: {
        developerToken:'eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiIsImtpZCI6IlJaODlCUkg4M04ifQ.eyJpc3MiOiI0Wjk2OUE0N0ZOIiwiZXhwIjoxNzI5OTQzMTg4LCJpYXQiOjE3MTQzOTExODh9.sseRhxLUzfrcn2FNYbqeoilC1UBFPgLO28YSLlFh093HvaviqNKAc4kUEa4Y5mDZViuJYAcLm8Y2wKcbfyG8LQ',
         name: 'Reflex Radio Web app',
        build: 'ver1',
        version: 'ver1',
    },
};

export default keys;
