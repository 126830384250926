<template>
	<div class="component-wrap">

		<div  class="deleted-header">
            <div style="flex:30" >
                <div class="figtree-medium-white-32px">Deleted Playlists</div>
            </div>
            <div style="flex:30">
                <p class="figtree-medium-white-16px">
					If you delete a Blueprint or Playlist, you can bring it back within 90 days of deleting it. <br />Find the Blueprint or Playlist below and click the Restore button to bring it back to your Dashboard.
                 </p>
            </div>
        </div>

		<AppEmptyStateComponent message="There are no deleted Playlists at this time."
								v-if="playlists.length == 1 && !loadingSpinner"/>
								<div v-if="playlists.length > 1 && !loadingSpinner" class="blueprint-container">
            <div class="blueprint-playlist-table">
                <div style="flex: 5;" class="figtree-medium-white-22px">Playlists</div>
                <div style="flex: 90;">
		    		<div class="list-group-header figtree-semi-bold-pink-swan-18px deleted-blueprints">
						<div style="flex:3;"></div>
						<div style="flex:37">Title</div>
            		    <div style="flex:15">Songs</div>
            		    <div style="flex:15">Duration</div>
            		    <!-- <div class="actions-btn" :style="{minWidth: isMobile ? 'unset' : '100px'}"></div> -->
            		    <div class="actions-btn" style="opacity: 0;" >re</div>
					
            		</div>
    				<div class="">
				        <ArchivedDeletedPlaylistCard
				            v-for="(playlist, index) in playlists"
				            v-show="playlist.id"
				            :period="playlist"
				            :isDeleted="true"
				            :key="index + '_playlists'"
				            :randomColor="getRandomColor(index)"
				            @refreshPlaylists="getAllPlaylists"
				            @setLoadingSpinner="setLoadingSpinner"
				            @restoreCurrentPlaylist="restorePlaylist"
				        />
				    </div>
				</div>
			</div>
			</div>
		<AppSpinnerComponent v-if="loadingSpinner" :loading="loadingSpinner"/>

	</div>
</template>

<script>

	import ArchivedDeletedPlaylistCard from "@/components/Playlists/ArchivedDeletedPlaylistCard";
	import PlaylistsService from '@/services/playlists.service'
  import {RandomColorsMixin} from "../../mixins/RandomColorsMixin";

	export default {
		name: 'PlaylistsDeletedDashboard',
    mixins: [RandomColorsMixin],
		data: function () {
			return {
				playlists: [],
				loadingSpinner: true,
				intervalIdTimeout:null
			}
		},

		components: {
			
			ArchivedDeletedPlaylistCard
		},

		created() {

			this.setPageName();

			this.setLoadingSpinner(false);
			this.getAllPlaylists();

			this.intervalIdTimeout = window.setInterval(() => {

				this.getAllPlaylists();
			}, 300000);
		},

		methods: {

			setPageName() {

				this.$store.dispatch('changePageName', {pageNameString: 'Archived Playlists'});
			},

			setLoadingSpinner(isLoading) {

				this.loadingSpinner = isLoading;
			},

			restorePlaylist(id) {

				PlaylistsService.restorePlaylist({id: id})
					.then((res) => {

						this.getAllPlaylists();

						this.$store.dispatch('showSnackbarMessage', {
							message: 'The Playlist was successfully restored.',
							duration: 3000,
							mode: 'success'
						});
					})
					.catch((err) => {

						this.$store.dispatch('showSnackbarMessage', {
							message: `${err.message}. Please, try again.`,
							mode: 'fail'
						});
					});
			},

			getAllPlaylists() {

				PlaylistsService.getAllDeletedPlaylists(this.$route.params.id)
					.then((res) => {

						this.playlists = res;
						this.setLoadingSpinner(false);
						this.playlists.push({});
					})
					.catch((err) => {
						this.$store.dispatch('showSnackbarMessage', {
							message: `${err.message}. Please, try again.`,
							mode: 'fail'
						});
					});

			},

			goToSinglePlaylist(id) {

				// this.$router.push('/blueprints/' + id + '/periods');
			},

			goBackToDashboard(id) {

				this.$router.push('/dashboard');
			},

			turnOffLoadingSpinner() {

			}
		},
		beforeDestroy() {
        	window.clearInterval(this.intervalIdTimeout);
    	},
	}
</script>

<style lang="scss" scoped>
	::v-deep .content-header {
		margin-bottom: 60px !important;
	}

	.content__subtitle {
		text-align: left;
		margin: 5.6rem 0 2.4rem;
	}
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
    //width: 95%;
  }
  .list-group-header{
		gap: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        height: 56px;
        background: #3A3A3A;
        border-radius: 4px 4px 0px 0px;
        padding: 0px 0px 0px 10px;

    }
	.blueprint-playlist-table{
        flex: 50;
        display: flex;
        flex-direction: column;
        width: 85%;
        gap: 15px;
        text-align: left;
		@media only screen and (max-width: 834px) {
            width: 100%;
        };
    }
	.blueprint-container{
        display:flex;
        flex-direction: column;
		width: 100%;

        gap: 25px;
        @media only screen and (max-width: 834px) {
            width: 100%;
        };
    }
	.actions-btn{
		flex:15;
		text-align: right;
		@media only screen and (max-width: 481px) {
         flex: 8;
        };
	}
 	.deleted-header{
        display: flex;
        flex-direction: column;
        gap:20px;
        text-align: left;
        width: 100%;
        margin: 40px 0px 30px 0px;
    }
</style>
