const state = () => ({

    classes: []
});

const getters = {
    getClasses(state) {

        return state.classes;
    }
};

const mutations = {
    setClasses(state, classes) {

        state.classes = classes;
    }
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}