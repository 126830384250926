<template>
    <md-card class="md-theme-light">
        <h3>How does Blueprint match class preferences</h3>

        <div class="circles">
            <div class="circle circle--blue-print"></div>
            <div class="circle circle--class"></div>
            <p class="overlapping__percent">
                <span>21%</span>
            </p>
        </div>

    </md-card>
</template>

<script>
export default {
    name: 'TrainerDashboardBluePrintPreferences'
}
</script>

<style lang="scss" scoped>
.md-card {
    margin: 0;
    background: $color-white;
    color: $color-grey;
    text-align: left;
    padding: 2.4rem 3.2rem;

    .circles {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2.4rem;  
        height: 19.8rem; 
        position: relative;
    }
    .circle {
        width: 15rem;
        height: 15rem;
        border-radius: 50%;
        position: absolute;

        &--class {
            right: 16rem;
            margin-left: 2rem;
            background-color: rgba($color-success, .9);
        }

        &--blue-print  {
            left: 16rem;
            background-color: rgba($color-accent-red, .9);
        }
    }

    .overlapping__percent {
        color: $color-grey;
        font-size: 1.6rem;
        font-weight: 900;
        
        width: 55px;
        height: 55px;
        text-align: center;
        border-radius: 50%;
        border: 3px solid $color-grey;
        background-color: $color-white;
        padding: 1.5rem 0 0;

        position: absolute;

        span {
            margin: 1rem 0 0 0;
        }
    }
}
// overwriting default framework's styles with !important
.md-card {
    margin: 0 !important;

    @include respond(small) {
        margin-bottom: 2.4rem !important;
    }
}
</style>