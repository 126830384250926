import $axios from '../plugins/axios'

class GenreService {

    getAllGenres(vuexContext) {

        return $axios.get('/genres')
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }
    getAllParentGenres(vuexContext) {

        return $axios.get('/ParentGenres')
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    createGenre(genre) {

        return $axios.post('/genres', genre)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    editGenre(genre) {

        return $axios.put('/genres/' + genre.id, genre)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }
    editGenreParent(genre,parentGenre) {

        return $axios.put('/genres/' + genre.id, parentGenre)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getGenre(id) {

        return $axios.get('/genres/' + id)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    deleteGenre(id) {

        return $axios.delete('/genres/' + id)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }
}

export default new GenreService();