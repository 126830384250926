<template>
    <div class="component-wrap">
        <div class="app-content-container">

            <AnimatedLogoPreloader></AnimatedLogoPreloader>

            <AppHeaderComponent
                    class="privacy-page"
                    headline="Privacy Policy">
            </AppHeaderComponent>

            <div>
                <p>
                    Reflex Radio built the Reflex Radio app as a Free app. This SERVICE is
                    provided by Reflex Radio at no cost and is intended for use as is.
                </p>

                <br>
                <p>
                    This page is used to inform visitors regarding our policies with the
                    collection, use, and disclosure of Personal Information if anyone decided
                    to use our Service.
                </p>

                <br>
                <p>
                    If you choose to use our Service, then you agree to the collection and use
                    of information in relation to this policy. The Personal Information that
                    we collect is used for providing and improving the Service. We will not
                    use or share your information with anyone except as described in this
                    Privacy Policy.
                </p>

                <br>
                <p>
                    The terms used in this Privacy Policy have the same meanings as in our
                    Terms and Conditions, which is accessible at Reflex Radio unless otherwise
                    defined in this Privacy Policy.
                </p>
            </div>

            <h2>Information Collection and Use</h2>

            <div>
                <p>
                    For a better experience, while using our Service, we may require you to
                    provide us with certain personally identifiable information, including but
                    not limited to email. The information that we request will be retained by
                    us and used as described in this privacy policy.
                </p>

                <br />
                <p>
                    The app does use third party services that may collect information used to
                    identify you.
                </p>

                <br />
                <p>
                    Link to privacy policy of third party service providers used by the app
                </p>

            </div>

            <md-button class="md-primary md-raised md-theme-light" href="https://www.google.com/policies/privacy/">
                Google Play Services
            </md-button>

            <h2>Lod Data</h2>

            <div>
                <p>
                    We want to inform you that whenever you use our Service, in a case of an
                    error in the app we collect data and information (through third party
                    products) on your phone called Log Data. This Log Data may include
                    information such as your device Internet Protocol (“IP”) address, device
                    name, operating system version, the configuration of the app when
                    utilizing our Service, the time and date of your use of the Service, and
                    other statistics.
                </p>
            </div>

            <h2>Cookies</h2>

            <div>
                <p>
                    Cookies are files with a small amount of data that are commonly used as
                    anonymous unique identifiers. These are sent to your browser from the
                    websites that you visit and are stored on your device's internal memory.
                </p>

                <br />
                <p>
                    This Service does not use these “cookies” explicitly. However, the app may
                    use third party code and libraries that use “cookies” to collect
                    information and improve their services. You have the option to either
                    accept or refuse these cookies and know when a cookie is being sent to
                    your device. If you choose to refuse our cookies, you may not be able to
                    use some portions of this Service.
                </p>
            </div>

            <h2>Service Providers</h2>

            <div>
                <p>
                    We may employ third-party companies and individuals due to the following
                    reasons:
                </p>

                <ul class="list-container">
                    <li>To facilitate our Service;</li>
                    <li>To provide the Service on our behalf;</li>
                    <li>To perform Service-related services; or</li>
                    <li>To assist us in analyzing how our Service is used.</li>
                </ul>

                <p>
                    We want to inform users of this Service that these third parties have
                    access to your Personal Information. The reason is to perform the tasks
                    assigned to them on our behalf. However, they are obligated not to
                    disclose or use the information for any other purpose.
                </p>
            </div>

            <h2>Security</h2>

            <div>
                <p>
                    We value your trust in providing us your Personal Information, thus we are
                    striving to use commercially acceptable means of protecting it. But
                    remember that no method of transmission over the internet, or method of
                    electronic storage is 100% secure and reliable, and we cannot guarantee
                    its absolute security.
                </p>
            </div>

            <h2>Links to Other Sites</h2>

            <div>
                <p>
                    This Service may contain links to other sites. If you click on a
                    third-party link, you will be directed to that site. Note that these
                    external sites are not operated by us. Therefore, we strongly advise you
                    to review the Privacy Policy of these websites. We have no control over
                    and assume no responsibility for the content, privacy policies, or
                    practices of any third-party sites or services.
                </p>
            </div>

            <h2>Children’s Privacy</h2>

            <div>
                <p>
                    These Services do not address anyone under the age of 13. We do not
                    knowingly collect personally identifiable information from children under
                    13\. In the case we discover that a child under 13 has provided us with
                    personal information, we immediately delete this from our servers. If you
                    are a parent or guardian and you are aware that your child has provided us
                    with personal information, please contact us so that we will be able to do
                    necessary actions.
                </p>
            </div>

            <h2>Changes to This Privacy Policy</h2>

            <div>
                <p>
                    We may update our Privacy Policy from time to time. Thus, you are advised
                    to review this page periodically for any changes. We will notify you of
                    any changes by posting the new Privacy Policy on this page.
                </p>
            </div>

            <h3>This policy is effective as of 2020-09-09</h3>

            <h2>Contact Us</h2>

            <div>
                <p>
                    If you have any questions or suggestions about our Privacy Policy, do not
                    hesitate to contact us at admin@reflexradio.app.
                </p>

                <br />
                <p>
                    This privacy policy page was created at
                    <a href="https://privacypolicytemplate.net">
                        privacypolicytemplate.net
                    </a>
                    and modified/generated by
                </p>

                <md-button class="md-primary md-raised md-theme-light"
                           href="https://app-privacy-policy-generator.firebaseapp.com/">
                    App Privacy Policy Generator
                </md-button>
            </div>
        </div>
    </div>
</template>

<script>
    import AnimatedLogoPreloader from "@/components/Common/AnimatedLogoPreloader";

    export default {
        name: 'PrivacyPolicy',
        data: () => ({}),

        components: {

            AnimatedLogoPreloader
        },

        methods: {

            setPageName() {

                this.$store.dispatch('changePageName', {pageNameString: 'Privacy Policy'});
            },
        },

        created() {

            this.setPageName();
        }

    }
</script>

<style lang="scss" scoped>
    @include appContentContainer();

    .privacy-page {
        margin-top: 20px;
    }
</style>