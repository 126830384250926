<template>
	<md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false"
		v-on:keyup.enter="openPreSelectGenresDialog()">

		<md-dialog-title class="figtree-medium-white-20px">Custom</md-dialog-title>

		<md-field class="input">
			<label class="label figtree-normal-white-12px">Section Name</label>
			<md-input class="input-text figtree-normal-white-16px-2" v-model="title"></md-input>
		</md-field>

		<div class="container">
			<div class="box-1">
				<div class="row">
					<div class="figtree-medium-pink-swan-16px">Set</div>
				</div>
			</div>
			<div class="box-3">
				<div class="row">
					<div class="figtree-medium-pink-swan-16px">Set Length</div>
				</div>
			</div>
			<div class="box-4">
				<div class="row">
					<div class="figtree-medium-pink-swan-16px">Flex</div>
				</div>
			</div>
			<div class="box-5">
				<div class="row">
					<div class=""></div>
				</div>
			</div>



		</div>
		<div v-for="(set, index) in bluePrint.sets" :key="index + '_set'" :value="index + '_val-set'">
			<div class="container border">

				<div class="box-1">
					<div class="row">
						<div class="">{{ Number(index) + 1 }}</div>
					</div>
				</div>
				<div class="box-2">
					<div class="row-2">
						<div class="bp-inputs-container">
							<md-menu v-if="!allowInputs[index]" md-size="medium" md-align-trigger class="menu-to-style" style="width: 90px;">
								<md-button md-menu-trigger>
									<div> {{ beautifyDuration(set.duration) }}</div>
								</md-button>

								<md-menu-content class="menu-container">
                  <md-menu-item @click="showInput(index)">Custom</md-menu-item>
                  <md-menu-item v-for="(dur, index_dur) in durations" :key="index_dur + '_duration'"
										@click="changeDuration(dur, index)">
										{{ dur }}
									</md-menu-item>
								</md-menu-content>
							</md-menu>
              <div v-else @change="handleInput(index)">
				<md-tooltip class="md-theme-light" md-direction="top" style="font-weight: bold;font-size: 12px;">Input time as 'HH:MM:SS'</md-tooltip>
                <the-mask :id="'index'+index"  v-model="inputValue" :mask="['##:##:##']" class="mask-field-custom-input"/>
              </div>
						</div>
					</div>
				</div>

				<div class="box-4" style="align-items: center;">
					<div class="row" style="align-items: center;">
						<div @click="showFlexSelect($event, index)" class="row-3" style="align-items: center;">
							<flexLevels :className="flexClass[index]" :key="'flex_' + index" :id="'flex_' + index" />
							<FlexSelect :key="'flexSelect_' + index" :id="'flexSelect_' + index" :index="index"
								style="display:none" @changeFlex="setFlex" />
						</div>
					</div>
				</div>
				<div class="box-5">
					<md-menu>
						<md-button md-menu-trigger class="md-icon-button tag-input__btn">
							<md-icon style="color: white">more_vert</md-icon>
						</md-button>
					
						<md-menu-content style="background-color: var(--onyx)!important;z-index: 12;">
							<md-menu-item @click="duplicateSet(index)">
								<md-button class="figtree-medium-white-12px">Duplicate Set
								</md-button>
							</md-menu-item>
							<md-menu-item v-if="bluePrint.sets.length > 1" @click="deleteSet(index)">
								<md-button class="figtree-medium-white-12px">Delete Set</md-button>
							</md-menu-item>
						</md-menu-content>
					</md-menu>
				</div>
			</div>

			<div class="set-description">
				<p class="figtree-medium-pink-swan-16px-2" v-if="typeId === 1">
					Every {{ set.duration }} minutes for {{ set.for_minutes }} minutes
				</p>
			</div>

		</div>

		<div class="add-set figtree-medium-lavender-16px">
			<div class="frame-399" @click="addNewSetRow">
				<md-icon class="md-theme-light" style="color: var(--lavender)">add</md-icon>
				<div class="add-set-1 figtree-medium-lavender-16px">Add Set</div>
			</div>
		</div>

    <textarea v-model="description" class="text-area figtree-medium-alto-14px" placeholder="Add note for this workout">
		</textarea>

		<md-dialog-actions>
			<md-button class="dialog__btn dialog__btn--danger" id="close-button"
				@click="closeDialog(true)">Cancel</md-button>
				<AppSpinnerComponentButton
				class="md-primary dialog__btn md-raised md-theme-light" style="max-width: fit-content!important;"
				id="submit-button-emmom"
				v-if="bluePrintProp"
				:is-button-loading-spinner-prop="buttonLoadingSpinner"
				:is-disabled-prop="$v.bluePrint.$invalid || !title ||buttonLoadingSpinner || validateSection"
				@click="openPreSelectGenresDialog()">
				Save Workout
			</AppSpinnerComponentButton>
		</md-dialog-actions>
	</md-dialog>
</template>

<script>

import { required } from 'vuelidate/lib/validators';
import { mapGetters } from "vuex";
import AppSpinnerComponentButton from "@/components/Base/AppSpinnerComponentButton";
import flexLevels from "@/components/TrainerDashboard/flexLevels";
import FlexSelect from '@/components/TrainerDashboard/FlexSelect';
import {DurationHelperMixin} from "../../mixins/DurationHelperMixin";
import SectionsService from '@/services/sections.service';

export default {
	name: 'CustomBlueprintDialog',
  mixins: [DurationHelperMixin],
	props: {
		showDialog: {
			type: Boolean,
			default: false
		},
		typeId: {
			type: Number,
			default: 3
		},
		bluePrintProp: {
			name: "",
			image_url: '',
			is_shared: false,
			type_id: 3
		},
		section:{
			type: Object,
			default: ()=>{}
		}
	},
	data: () => ({
    inputValue: "",
    allowInputs:[],
		buttonLoadingSpinner: false,
		durations: [
			'00:10',
			'00:15',
			'00:20',
			'00:30',
			'00:40',
			'00:50',
			'01:00',
			'01:15',
			'01:30',
			'01:45',
			'02:00',
			'02:15',
			'02:30',
			'02:45',
			'03:00',
			'03:15',
			'03:30',
			'03:45',
			'04:00',
			'04:30',
			'05:00',
			'06:00',
			'07:00',
			'08:00',
			'09:00',
			'10:00',
			'11:00',
			'12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00',
			'24:00', '25:00', '26:00', '27:00', '28:00', '29:00', '30:00', '31:00', '32:00', '33:00', '34:00', '35:00',
			'36:00', '37:00', '38:00', '39:00', '40:00', '41:00', '42:00', '43:00', '44:00', '45:00', '46:00', '47:00',
			'48:00', '49:00', '50:00', '51:00', '52:00', '53:00', '54:00', '55:00', '56:00', '57:00', '58:00', '59:00', '60:00'
		],
		bluePrint: {
			type_id: 3,
			sets: [],
			name: '',
			image_url: '',
			is_shared: false,
			countdown_beeps: false,
		},
		isImageTheSame: true,
		previewUrl: '',
		showPhotoError: false,
		title: '',
    description: '',
		flexClass: [],
	}),
	components: {
		AppSpinnerComponentButton,
		flexLevels,
		FlexSelect
	},

	validations: {
		bluePrint: {
			sets: {
				required,
				$each: {
					duration: {
						required
					},
					flex: {
						required
					}
				}
			}
		}
	},

	methods: {
    handleInput(index) {
      this.allowInputs[index] = false;
      this.changeDuration(this.inputValue,index);
      this.inputValue = '';
    },

    showInput(index){
      setTimeout(()=>{
        document.getElementById('index'+index).focus();
      },1)
      this.allowInputs[index] = true;
      this.$forceUpdate();
    },

		populateData(intervals) {
			intervals.forEach((interval, index) => {

				this.bluePrint.sets.push({
					duration: interval.duration,
					flex: interval.energy
				});
			});

			this.bluePrint.sets.forEach((bluePrint, index) => {
				this.setFlex(bluePrint.flex, index);
			});
		},
		deleteSet(index){
			this.bluePrint.sets.splice(index, 1);
			this.allowInputs.splice(index, 1);

		},
		duplicateSet(index){
			let newSet = this.bluePrint.sets;
			this.bluePrint.sets = [];
			newSet.forEach((element,i) => {
				this.bluePrint.sets.push(element)
				if (i == index) {
					const newSetCopy = {
							duration: element.duration,
							flex: element.flex,
						};
					this.bluePrint.sets.push(newSetCopy)
					this.setFlex(element.flex,index+1);
				}
			});
		},
		showFlexSelect(event, index) {
			if (document.querySelector('#flexSelect_' + index).style.display == 'block') {

				document.querySelector('#flexSelect_' + index).style.display = 'none'
			} else (document.querySelector('#flexSelect_' + index).style.display = 'block');
		},
		setFlex(val, index) {

			this.bluePrint.sets[index].flex = val;
			switch (val) {
				case 1:
					this.flexClass[index] = 'stretch';
					break;
				case 2:
					this.flexClass[index] = 'warm-up';
					break;
				case 3:
					this.flexClass[index] = 'stretch-training-1';
					break;
				case 4:
					this.flexClass[index] = 'cardio-1';
					break;
				case 5:
					this.flexClass[index] = 'sprint-1';
					break;
				default:
					break;
			}
			this.$forceUpdate();

			if (this.disableSelectFieldsFromGoToNextStep == false) {

				this.goNextStepIfTourGuideActive();
			}
		},
		async openPreSelectGenresDialog() {
			const req = {
				title: this.title,
        description: this.description,
        order: Number(this.bluePrintProp.sections.length+1),
				type_id: this.typeId,
				template_id: this.bluePrintProp.id,
				sets: this.bluePrint.sets
			};
			if (!this.section) {
				SectionsService.createSection(req)
			   .then(() => {
					 this.$emit('refreshAllBluePrints');
           this.$store.dispatch('showSnackbarMessage', {
             message: 'You have successfully created a section.',
             duration: 4000,
             mode: 'success'
           });
			   })
			}
			else {
				delete req.template_id;
				delete req.order;
				SectionsService.UpdateSection(this.section.id, req)
					.then(() => {
						this.$emit('refreshAllBluePrints');
            this.$store.dispatch('showSnackbarMessage', {
              message: 'Section was updated successfully',
              duration: 4000,
              mode: 'success'
            });
					})
					.catch(() => {
            this.$store.dispatch('showSnackbarMessage', {
              message: 'Failed. Please, try again.',
              duration: 8000,
              mode: 'fail'
            });
					});
			}
			this.closeDialog(false);

		},

		removeSetRow() {
			this.bluePrint.sets.pop();
			this.$forceUpdate();
		},

		changeDuration(value, index) {
			this.bluePrint.sets[index].duration = this.convertDuration(value);
		},

		changeFlex(value, index) {
			this.bluePrint.sets[index].flex = value;
		},

		addNewSetRow() {

			this.bluePrint.sets.push({
				duration: '00:00:00',
				flex: '1'
			});
      this.allowInputs.push(false);
			this.$forceUpdate();
		},

		goBackToPreviousScreen() {

			this.bluePrint.sets = [];

			let bpFromBack = {
				isBack: true,
				bpObj: this.bluePrint
			};

			this.$emit('goBack', bpFromBack);
			this.closeDialog(false);
		},

		closeDialog(deleteBPName) {
			if (deleteBPName) {
				this.bluePrint.name = '';
			}
			this.$emit('update:showDialog', false);
		},

	},

	computed: {
     validateSection(){

      for (let i = 0; i < this.bluePrint.sets.length; i++) {
        let durationWor = this.bluePrint.sets[i].duration.split(':')[0] + this.bluePrint.sets[i].duration.split(':')[1] + this.bluePrint.sets[i].duration.split(':')[2];
        if (/^0*$/.test(durationWor)) {
          return true;
        }
      }
      return false;
    },
		totalTime() {
			let totalSeconds = 0;
			let theTime = 0;

			for (let i = 0; i < this.bluePrint.sets.length; i++) {

				if (this.bluePrint.sets[i].duration.toString().length === 4) {
					this.bluePrint.sets[i].duration = this.bluePrint.sets[i].duration.toString().substring(0, 2) + ':' + this.bluePrint.sets[i].duration.toString().substring(2);
				}

				let splitValue = this.bluePrint.sets[i].duration.split(':');

				totalSeconds += (+splitValue[0]) * 60 + (+splitValue[1]);
			}

			let minutes = Math.floor(totalSeconds / 60);

			let remainingSeconds = totalSeconds - minutes * 60;

			if (remainingSeconds == 0) {

				theTime = minutes.toString() + ':00'
			} else {

				theTime = minutes.toString() + ':' + remainingSeconds.toString();
			}

			return theTime;
		},

		...mapGetters(
			{
				userRole: 'auth/currentUserRole',
			},
		),
	},

	created() {
		if (this.section) {
			this.title = this.section.title;
			this.populateData(this.section.intervals);

		}
	  else if(this.bluePrintProp.sections){
		this.title =  'Section ' + Number(this.bluePrintProp.sections.length+1)
		if (this.typeId === 3) {
			this.addNewSetRow();
		}
	  }
	},
}
</script>

<style lang="scss" scoped>
@include dialogSettings();



::v-deep .pick-bp-name input::placeholder {
	color: #BEBEC8 !important;
	font-weight: 500;
}

::v-deep .pick-bp-name input {
	padding-left: 5px;
	background-color: transparent !important;
	border: none !important;
	border-bottom: 1px solid white !important;
	font-size: 1.7rem;
	color: white !important;

	&:active {
		border: none;
		background-color: transparent;
		border-bottom: 1px solid white;
	}

	&:focus {
		background-color: transparent;
		border: none;
		border-bottom: 1px solid white;
	}
}

::v-deep .pick-bp-tags input::placeholder {
	font-size: 1.3rem !important;
}

::v-deep .toggle-share-btn .v-switch-core {
	width: 85px !important;
}

.bp-inputs-container {
	text-align: center;
	margin-bottom: 20px;

	.left-labels {
		vertical-align: bottom;
		display: inline-block;
		width: 3em;
		margin-left: 1.4rem;
		margin-right: 2.4rem;
		font-size: 2rem;
	}

	.mask-field {
		color: white;
		text-align: center;
		font-size: var(--font-size-s);
		width: 12%;
		height: 45px;
		background-color: transparent;
		border: 2px solid #A156A0;
		border-radius: 7px;
	}
}

::v-deep #add-set-btn {
	position: relative !important;
	width: 30px;
	bottom: 0 !important;
	right: 0 !important;
	height: 30px;
}

#back-button {
	margin-right: auto;
}


::v-deep #remove-set-btn {
	position: relative !important;
	width: 30px;
	bottom: 0 !important;
	right: 0 !important;
	height: 30px;
}


.menu-container {
	z-index: 100 !important;
}

.menu-to-style {

	::v-deep .md-ripple {
		border: 2px solid #A156A0 !important;
		border-radius: 6px !important;
	}
}

.second-masks {
	width: 100% !important;
}

.second-masks {
	border: none !important;
}

.container {
	align-items: center;
	text-align: -webkit-center;
	display: flex;
	margin-bottom: 3px;
}

.border {
	background-color: var(--onyx);
	border-radius: 5px 5px 0 0;
}

.box-1 {
	flex: 8;
}

.box-2 {
	flex: 20;
}

.box-3 {
	flex: 20;
}

.box-4 {
	flex: 15;
	position: relative;
}

.box-5 {
	flex: 7;
}

.row {
	height: 56px;
	padding: 16px 0 16px 0;
}

.row-2 {
	height: 56px;
	padding: 10px 0 10px 0;
  ::v-deep .md-ripple {
    max-width: 85px;
  }
}

::v-deep .md-dialog-container {
	background-color: var(--heavy-metal) !important;
}

.add-set-1 {
	letter-spacing: 0;
	line-height: 24px;
	margin-top: -1px;
	white-space: nowrap;
	width: fit-content;
}

.note {
	align-items: center;
	background-color: var(--cape-cod);
	border-radius: 5px;
	display: flex;
	height: 41px;
	padding: 10px;
	margin-bottom: 40px;
}

.add-a-note-for-this-workout {
	letter-spacing: 0;
	line-height: 16px;
	margin-top: -1px;
	white-space: nowrap;
	width: fit-content;
}

.frame-399 {
	cursor: pointer;
	align-items: center;
	display: flex;
	justify-content: space-between;
	width: 93px;
}

.add-set {
	margin-top: 10px;
	margin-bottom: 20px;
	align-items: center;
	border: 1px dashed;
	border-color: var(--lavender);
	border-radius: 5px;
	display: flex;
	min-height: 60px;
	justify-content: center;
	padding: 10px;
}

.pick-bp-title {
	text-align: left;
}
::v-deep .md-list.md-theme-default{
  background-color: var(--onyx)!important;
  padding: 0;
}

::v-deep .md-list-item-content{
  padding-left: 5px;
}

.text-area {
  align-items: flex-start;
  background-color: var(--onyx);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 4px 7px;
  color: var(--white);
  font-family: var(--font-family-figtree);
  font-size: var(--font-size-s);
  font-weight: 500;
  font-style: normal;
  width: 100%;
  resize: none;
  min-height:45px;
}

.mask-field-custom-input {
  color: white;
  text-align: center;
  font-size: var(--font-size-s);
  font-weight: bold;
  max-width: 60px;
  max-height: 35px;
  height: 45px;
  background-color: transparent;
  border: 2px solid #A156A0;
  border-radius: 7px;
}
</style>
