export const roleNameParser = {
    methods: {
        getRoleName(roleId) {
            let roleName = '';
            switch (roleId) {
                case 1:
                    roleName = 'Admin'
                    break;
                case 2:
                    roleName = 'Franchise/Facility Owner'
                    break;
                case 3:
                    roleName = 'Facility Manager'
                    break;
                case 4:
                    roleName = 'Individual Trainer'
                    break;
                case 5:
                    roleName = 'Facility Trainer'
                    break;

                default:
                    roleName = 'Not defined'
            }
            return roleName;
        }
    }
}
