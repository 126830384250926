<template>
<div class="component-wrap">
    <AppHeaderComponent headline="Reports"></AppHeaderComponent>

    <!-- Monthly Store Retention Graph -->
    <RepportLineGraph
        @click.native="$router.push('/reports/store-retention')"
        name="Monthly store retention"
        class="full-width"
        yAxisProperty="value"
        xAxisProperty="time"
        graphIndex="1"
        :graphData="mainGraph"
    />

    <!-- Average Lifitime Customer Value -->
    <RepportLineGraph
        @click.native="$router.push('/reports/average-lifetime-customer-value')"
        name="AVERAGE LIFETIME CUSTOMER VALUE"
        class="half-width"
        yAxisProperty="value"
        xAxisProperty="time"
        graphIndex="2"
        :graphData="[mainGraph[0]]"
    />

    <!-- How Many Stores Use App -->
    <RepportLineGraph
        @click.native="$router.push('/reports/average-usage-by-store')"
        name="HOW MANY STORE USE APP"
        class="half-width"
        yAxisProperty="value"
        xAxisProperty="time"
        graphIndex="3"
        :graphData="[mainGraph[1]]"
    />

    <!-- How Many Users Use App -->
    <RepportLineGraph
        @click.native="$router.push('/reports/average-usage-by-user')"
        name="HOW MANY USERS USE APP"
        class="half-width"
        yAxisProperty="value"
        xAxisProperty="time"
        graphIndex="4"
        :graphData="[mainGraph[2]]"
    />

    <!-- Trial Mode Conversion -->
    <ReportPieChart
        name="Trial mode conversion"
        class="half-width"
        graphIndex="6"
        :graphData="pieChartData"
        @click.native="$router.push('/reports/trial-mode-conversion')"
    />
    
</div>
</template>

<script>

import RepportLineGraph from "@/components/Reports/ReportLineGraph"
import ReportPieChart from "@/components/Reports/ReportPieChart"

export default {
    name: 'ReportsDashboard',
    components: {
        RepportLineGraph,
        ReportPieChart
    },
    data: () => ({
        pieChartData: [
            {key: 'yes', value: '40', color: '#53a8e2'},
            {key: 'no', value: '60', color: '#76ddfb'}
        ],
        mainGraph: [
            {
                name: "Turnover",
                color: "#53a8e2",
                data: [
                    {
                        value: 40,
                        time: '01/01/2019'
                    },
                    {
                        value: 30,
                        time: '02/01/2019'
                    },
                    {
                        value: 45,
                        time: '03/01/2019'
                    },
                    {
                        value: 42,
                        time: '04/01/2019'
                    },
                    {
                        value: 37,
                        time: '05/01/2019'
                    },
                    {
                        value: 40,
                        time: '06/01/2019'
                    },
                    {
                        value: 45,
                        time: '07/01/2019'
                    },
                    {
                        value: 48,
                        time: '08/01/2019'
                    },
                    {
                        value: 46,
                        time: '09/01/2019'
                    },
                    {
                        value: 40,
                        time: '10/01/2019'
                    },
                    {
                        value: 48,
                        time: '11/01/2019'
                    },
                    {
                        value: 46,
                        time: '12/01/2019'
                    }
                ]
            },
            {
                name: "New",
                color: "#76ddfb",
                data: [
                    {
                        value: 20,
                        time: '01/01/2019'
                    },
                    {
                        value: 24,
                        time: '02/01/2019'
                    },
                    {
                        value: 22,
                        time: '03/01/2019'
                    },
                    {
                        value: 28,
                        time: '04/01/2019'
                    },
                    {
                        value: 35,
                        time: '05/01/2019'
                    },
                    {
                        value: 32,
                        time: '06/01/2019'
                    },
                    {
                        value: 35,
                        time: '07/01/2019'
                    },
                    {
                        value: 42,
                        time: '08/01/2019'
                    },
                    {
                        value: 45,
                        time: '09/01/2019'
                    },
                    {
                        value: 39,
                        time: '10/01/2019'
                    },
                    {
                        value: 42,
                        time: '11/01/2019'
                    },
                    {
                        value: 36,
                        time: '12/01/2019'
                    }
                ]
            },
            {
                name: "Existing",
                color: "#2c82be",
                data: [
                    {
                        value: 15,
                        time: '01/01/2019'
                    },
                    {
                        value: 10,
                        time: '02/01/2019'
                    },
                    {
                        value: 8,
                        time: '03/01/2019'
                    },
                    {
                        value: 9,
                        time: '04/01/2019'
                    },
                    {
                        value: 16,
                        time: '05/01/2019'
                    },
                    {
                        value: 10,
                        time: '06/01/2019'
                    },
                    {
                        value: 12,
                        time: '07/01/2019'
                    },
                    {
                        value: 16,
                        time: '08/01/2019'
                    },
                    {
                        value: 5,
                        time: '09/01/2019'
                    },
                    {
                        value: 10,
                        time: '10/01/2019'
                    },
                    {
                        value: 15,
                        time: '11/01/2019'
                    },
                    {
                        value: 16,
                        time: '12/01/2019'
                    }
                ]
            },
        ]
    }),

	methods: {

		setPageName() {

			this.$store.dispatch('changePageName', { pageNameString: 'Reports' });
		}
	},

	created() {

		this.setPageName();
	}
}
</script>

<style lang="scss" scoped>
.component-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 200px;
}
.full-width {
    width: 100%;
}
.half-width {
    width: 49%;
}
</style>