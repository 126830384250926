<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">

        <md-dialog-title>Edit user</md-dialog-title>

        <UserForm v-if="currentUser"
                :user-prop=currentUser
                :isDialog="true"
                @closeDialog="closeDialog"
                @updateCurrentUser="editUser"
                :key="key"
        />

    </md-dialog>
</template>

<script>
    import UserForm from '@/components/Users/UserForm.vue'

    export default {
        name: 'UsersEditDialog',
        props: {
            showDialog: {
                type: Boolean,
                default: false
            },
            userProp: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            currentUser: null,
            key: 1
        }),
        components: {
            UserForm
        },

        methods: {
            closeDialog() {

                this.$emit('update:showDialog', false);
            },
            editUser(user) {

                this.$emit('updateCurrentUser', user);
                this.closeDialog();
            },

        },
        created() {

            this.currentUser = this.userProp;
        }
    }
</script>

<style lang="scss" scoped>
    @include dialogSettings();
    .dialog__hint {
        margin-bottom: 16px;
    }

    .active-container{
        text-align:center;

        .status-label {
            margin-top: 25px;
            margin-bottom: 20px;
            font-size: 2.4rem;
        }
    }

</style>