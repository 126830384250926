import $axios from '../plugins/axios'

class SetupService {

    setupFirstLogin() {

        return $axios.put('/setup')

            .catch((error) => {

                console.log(error)
                throw error;
            })
    }
}

export default new SetupService();