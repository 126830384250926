import $axios from '../../plugins/axios'

const state = () => ({
    moods: []
});

const getters = {
    getMoods(state) {
        return state.moods;
    }
};

const mutations = {
    setMoods(state, moods) {
        state.moods = moods;
    }
}

const actions = {
    getAllMoods(vuexContext) {
        return $axios.get('/moods')
            .then(res => {
                vuexContext.commit('setMoods', res.data);
                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}