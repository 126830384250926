<template>
  <div class="reports-wrap">
      <router-view></router-view>
  </div>
</template>

<script>
export default {
    name: 'AppReports'
}
</script>

<style>

</style>