<template>
    <div>

        <div class="user  figtree-semi-bold-alto-14px">

            <p class="user__data">{{fullName}}</p>
            <p class="user__data user__data--grey">{{user.email}}</p>
            <p class="user__data user__data--grey">{{roleName}}</p>
            <div class="user__data user__actions-btn" style="">
                <div class="user__activeStatus">
                    <p class="user__activeStatus user__activeStatus--div">
    
                        {{user.active ? 'Active' : "Inactive"}}
                    </p>
                </div>
    
                <div class="user__login-btn">
                    <md-button v-if="currentUser.role_id === 1 && userData.id !== currentUser.id" class="md-icon-button" 
                               id="loginToThisUser"
                               @click="impersonateUser(user)">
                                <md-icon style="color:white">login</md-icon>
                    </md-button>
                </div>
                <div class="user__remove-btn"
                        v-if="userRole < 4" >
                        <md-button  class="md-icon-button" 
                               id="EditThisUser"
                               @click="editUser(user.id)">
                            <md-icon>edit</md-icon>
                        </md-button>
                </div>
            </div>

<!--            <div class="user__remove-btn"-->
<!--                 @click="removeUser(user.id)">-->
<!--                <md-icon>delete_forever</md-icon>-->
<!--            </div>-->

        </div>

    </div>
</template>

<script>
    import {roleNameParser} from '@/mixins/roleNameParser.mixin.js'
    import {mapGetters} from "vuex";

    export default {
        name: 'SingleStoreUserBlock',
        mixins: [roleNameParser],
        props: {
            userData: {
                type: Object,
                required: true
            }
        },
        data: () => ({

        }),
        computed: {
            user() {
                return this.userData;
            },
            fullName() {
                return this.userData.first_name + ' ' + this.userData.last_name;
            },
            roleName() {
                return this.getRoleName(this.userData.role_id);
            },

            ...mapGetters(
                {
                    userRole: 'auth/currentUserRole',
                    currentUser: 'auth/getCurrentUser',
                },
            ),
        },
        methods: {
            impersonateUser(user) {

                this.$store.dispatch('auth/impersonateUser', user.id)
                    .then(() => {
                        this.$store.dispatch('showSnackbarMessage', {
                            message: `You are browsing as ${user.first_name + ' ' + user.last_name}`,
                            duration: 4000,
                            mode: 'success'
                        });
                        setTimeout(() => {
//                            this.$router.push('/dashboard');
                            window.location.replace("/dashboard");
                        }, 1000);
                    })
                    .catch(err => {
                        this.$store.dispatch('showSnackbarMessage', {
                            message: 'Failed. Please, try again.',
                            duration: 8000,
                            mode: 'fail'
                        });
                    })
            },
            removeUser(id) {
                this.$emit('removeUser', id);
            },

            editUser(id) {
                this.$emit('editUser', id);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .user {
        background-color: var(--log-cabin);
        text-align: left;
        min-height: 65px;;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 15px 20px;
        margin-bottom: 2px;
        flex-wrap: nowrap;
        cursor: pointer;
        gap: 10px;
        transition: all .2s ease;
        @include respond(small) {
                    flex-direction: column;
                    gap: 2px;
                }
        // @include respond(small) {
        //     flex-direction: column;
        // }

        &:hover {
            background-color: #636262;
            transform: scaleX(1.02);
        }
        &__actions-btn{
            flex:25;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
        }
        &__data {
            flex:25;
            overflow: hidden;
            text-overflow: ellipsis;




            @include respond(small) {
                width: 100%;
                margin: 4px 0;
            }
        }
        &__activeStatus{
            flex:50;
            &--div{
                align-items: center;
                background-color: var(--persian-green);
                border-radius: 58px;
                padding: 5px 10px;
                width: fit-content;
                @include respond(small) {
                    font-size: x-small;
                }
            }
        }
        &__login-btn{
            flex: 20;
            font-weight: normal;
        }
        &__remove-btn {
            padding: 3px;
            flex: 20;
            font-weight: normal;
            text-align: right;

        }
        &__login-icons{
            width: 62px;
            height: 66px;
            max-width: 62px;
            max-height: 66px;

        }
    }
</style>