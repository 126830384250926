export default async function accountTrainerCheck({to, next, store, nextMiddleware}) {

	console.log("account middleware");

	let user = store.getters['auth/getCurrentUser'];

	if (user === null) {

		await store.dispatch('auth/getMe')
		user = store.getters['auth/getCurrentUser'];
	}

	// if (user.account.is_trainer) {
    //
	// 	let path = '/404';
    //
	// 	return next({
	// 		path: path
	// 	});
	// }

	return nextMiddleware();
}