<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">

        <md-dialog-title v-if="type.id">Update a Movement Type</md-dialog-title>
        <md-dialog-title v-else>Create Movement Type</md-dialog-title>

        <form class="reset-form">

            <AppInputComponent
                    class="auth-form__field"
                    required
                    placeholder="Movement Type Name"
                    v-model="$v.type.name.$model"
                    :error="$v.type.name.$invalid && $v.type.name.$dirty"
                    errorText="Name is required"
            />

            <md-dialog-actions>
                <md-button class="dialog__btn dialog__btn--danger" @click="closeDialog()">Cancel</md-button>

                <md-button class="md-primary dialog__btn md-raised md-theme-light"
                           v-if="type.id"
                           id="update-button"
                           :disabled="$v.type.$invalid"
                           @click="submitIntervalType()">UPDATE</md-button>

                <md-button class="md-primary dialog__btn md-raised md-theme-light"
                           id="create-button"
                           v-else
                           :disabled="$v.type.$invalid"
                           @click="submitIntervalType()">CREATE</md-button>
            </md-dialog-actions>
        </form>
    </md-dialog>
</template>

<script>
    import {required} from 'vuelidate/lib/validators';
    import IntervalService from "../../services/interval.service"

    export default {
        name: 'IntervalTypeModal',
        props: {
            showDialog: {
                type: Boolean,
                default: false
            },
            typeProp: {
                type: Object,
                default: {
                    name: ''
                }
            },
            allTypes: {
                type: Array,
                default: []
            }
        },

        data: function () {
            return {
                type: {
                    name: ''
                },
                typeNameEdit: ''
            }
        },

        validations() {

            let type = {
                name: {required}
            };

            return {
                type
            }
        },

        methods: {

            closeDialog() {

                this.type.name = '';
                this.$emit('update:showDialog', false);
            },

            submitIntervalType() {

                let typeName = this.type.name;
                typeName = typeName.toLowerCase();

                if (this.allTypes.some(type => type.name.toLowerCase() === typeName) &&
                    typeName !== this.typeNameEdit.toLowerCase()) {

                    this.$store.dispatch('showSnackbarMessage', {message: 'Movement Type with this name, already exist please choose another name'});
                } else {

                    if (this.type.hasOwnProperty('id')) {

                        IntervalService.editIntervalType(this.type)
                            .then(() => {

                                this.$emit('refreshAllIntervalTypes');
                                this.$store.dispatch('showSnackbarMessage', {message: 'The movement type was successfully edited.'});
                                this.closeDialog();
                            })
                            .catch((err) => {

                                this.$store.dispatch('showSnackbarMessage', {
                                    message: `${err.message}. Please, try again.`,
                                    mode: 'fail'
                                });
                            });
                    } else {

                        IntervalService.createIntervalType(this.type)
                            .then(() => {

                                this.$emit('refreshAllIntervalTypes');
                                this.$store.dispatch('showSnackbarMessage', {message: 'The movement type was successfully created.'});
                                this.closeDialog();
                            })

                            .catch((err) => {
                                this.$store.dispatch('showSnackbarMessage', {
                                    message: `${err.message}. Please, try again.`,
                                    mode: 'fail'
                                });
                            });
                    }
                }
            }
        },

        created(){

            let _this = this;

            if (_this.typeProp) {

                _this.type = _this.typeProp;
            }

            if (this.type.hasOwnProperty('id')) {

                this.typeNameEdit = this.type.name;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @include dialogSettings();
    .dialog__hint {
        margin-bottom: 16px;
    }
</style>