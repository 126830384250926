export const CheckWindowWidthMixin = {
    data: () => ({
        isMobile:false,
        isTablet:false,
        isDesktop:false,
        MobileWidth:481,
        TabletWidth:834,
        showActions:true,
    }),
    methods: {
        CheckWindowWidth() {
            this.isDesktop = (document.documentElement.clientWidth > this.TabletWidth);
            this.isTablet = (document.documentElement.clientWidth < this.TabletWidth && document.documentElement.clientWidth > this.MobileWidth);
            this.isMobile = (document.documentElement.clientWidth < this.MobileWidth);
            this.showActions = (document.documentElement.clientWidth > this.MobileWidth);
        },
    },

    mounted() {
        this.CheckWindowWidth();
        window.addEventListener("resize", this.CheckWindowWidth)
    },
}