<template>
    <md-dialog :md-active.sync="showFavorite" :md-click-outside-to-close="false">

        <md-dialog-title v-if="isGenresFormActive">Add favorites genres</md-dialog-title>
        <md-dialog-title v-if="!isGenresFormActive">Add favorites artists</md-dialog-title>

        <form class="favorite-form"
              v-on:keyup.enter="goToArtistFavoriteForm()">

            <div class="md-layout md-gutter"
                 v-if="isGenresFormActive">
                <md-checkbox class="genre-option md-layout-item md-xlarge-size-25 md-large-size-25 md-medium-size-25 md-small-size-100 md-xsmall-size-100"
                             v-for="(genre, index) in genresList" :key="index" :value="genre.id"
                             v-model="selectedGenres"
                >
                    {{genre.name}}
                </md-checkbox>
            </div>

            <div class="md-layout md-gutter"
                 v-if="!isGenresFormActive">
                <md-checkbox class="artist-option md-layout-item md-xlarge-size-25 md-large-size-25 md-medium-size-25 md-small-size-100 md-xsmall-size-100"
                             v-for="(artist, index) in artistsList" :key="index" :value="artist.id"
                             v-model="selectedArtists"
                >
                    {{artist.name}}
                </md-checkbox>
            </div>

            <md-button class="md-primary md-raised favorite-form__submit-btn md-theme-light"
                       id="next-button"
                       @click.native="goToArtistFavoriteForm()"
                       v-if="isGenresFormActive">Next
            </md-button>

            <md-button class="md-primary md-raised favorite-form__submit-btn md-theme-light"
                       id="finish-button"
                       @click.native="finishAddingFavorite()"
                       v-if="!isGenresFormActive">Finish
            </md-button>
        </form>
    </md-dialog>
</template>

<script>
    import AnimatedLogoPreloader from "@/components/Common/AnimatedLogoPreloader";
    import {required, email} from 'vuelidate/lib/validators';
    import SetupService from "../../services/setup.service";
    import UserService from "../../services/user.service";
    import GenreService from "../../services/genre.service";
    import ArtistService from "../../services/artist.service";
    import FavoriteGenreService from "../../services/favoriteGenre.service";
    import FavoriteArtistService from "../../services/favoriteArtist.service";
    import {mapGetters} from "vuex";

    export default {
        name: 'AuthLogin',
        props: {
            showFavorite: {
                type: Boolean,
                default: false
            },
        },
        data: () => ({
            genresList: [],
            artistsList: [],
            selectedGenres: [],
            selectedArtists: [],
            isGenresFormActive: true,
        }),

        components: {
            AnimatedLogoPreloader
        },

        validations: {
            selectedGenres: {required, email},
            selectedArtists: {required, email},
        },

        methods: {

            closeDialog() {
                this.$emit('update:showFavorite', false);
            },

            goToArtistFavoriteForm() {

                this.isGenresFormActive = false;
            },

            addFavoriteGenresForTheUser() {

                FavoriteGenreService.createFavoriteGenres({ genres: Object.values(this.selectedGenres) })
                    .then((res) => {
                    })
                    .catch((err) => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: `${err.message}. Please, try again.`,
                            mode: 'fail'
                        });
                    });
            },

            addFavoriteArtistsForTheUser() {

                FavoriteArtistService.createFavoriteArtists({ artists: Object.values(this.selectedArtists) })
                    .then((res) => {
                    })
                    .catch((err) => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: `${err.message}. Please, try again.`,
                            mode: 'fail'
                        });
                    });
            },

            setupUserFirstLogin() {

                SetupService.setupFirstLogin()
                    .then((data) => {

                        this.$store.dispatch('auth/getMe')
                        this.$store.dispatch('showSnackbarMessage', {
                            message: 'Favorites genres and artists added successfully',
                            duration: 4000,
                            mode: 'success'
                        });
                    })
                    .catch((error) => {

                        let errMsg = error.response.data;

                        this.$store.dispatch('showSnackbarMessage',
                            {message: errMsg, duration: 6000, mode: 'fail'})
                    });
            },

            finishAddingFavorite() {

                this.addFavoriteArtistsForTheUser();
                this.addFavoriteGenresForTheUser();

                this.setupUserFirstLogin();
                this.$store.dispatch('auth/getMe')

                this.closeDialog();

                // trigger tour guide

				UserService.getUserTourGuide()
					.then( res => {

						if (res == false) {

							this.$store.dispatch('changeIsTourGuideActive', true);
							// this.$store.dispatch('changeOpenMenuForTourGuide', true);

							this.$tours['myTour'].start();
						}

						if (res.template_created == false) {

							this.$store.dispatch('changeIsTourGuideActive', true);
							// this.$store.dispatch('changeOpenMenuForTourGuide', true);

							this.$tours['myTour'].start();
						}
					});
				// trigger tour guide

                // if (this.userRole == 2 && !this.currentUser.account.is_trainer) {
				//
                //     this.$store.dispatch('changeIsTourGuideActive', true);
                //     this.$store.dispatch('changeOpenMenuForTourGuide', true);
				//
                //     this.$nextTick(() => {
				//
                //         this.$tours['myTour'].start();
                //     });
                // }
            },

            getGenresList() {

                return GenreService.getAllGenres()
                    .then((res) => {

                        this.genresList = res;
                    })
                    .catch((err) => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: `${err.message}. Please, try again.`,
                            mode: 'fail'
                        });
                    });
            },

            getArtistsList() {

                return ArtistService.getAllArtist()
                    .then((res) => {

                        this.artistsList = res;
                    })
                    .catch((err) => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: `${err.message}. Please, try again.`,
                            mode: 'fail'
                        });
                    });
            },

            async fetchData() {

               await this.getArtistsList();
               await this.getGenresList();
            }
        },

        computed: {
            ...mapGetters(
                {
                    userRole: 'auth/currentUserRole',
                    currentUser: 'auth/getCurrentUser',
                },
            )
        },

        created() {

            this.fetchData();
        }
    }
</script>

<style lang="scss" scoped>
    @include dialogSettings();
    .dialog__hint {
        margin-bottom: 16px;
    }

    .md-dialog-title {
        font-size: 2.3rem;
    }

    .form-buttons {
        margin-top: 20px;
        justify-content: space-around;
    }

    .md-checkbox {
        display: flex;
        margin: 16px 0 16px 0;
    }

    ::v-deep label.md-checkbox-label {
        color: white;
    }

    .md-button {
        float: right;
    }
</style>