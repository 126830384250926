<template>
    <div class="component-wrap">
        <AppHeaderComponent
                class="about-page"
                headline="Music Engine Settings">
        </AppHeaderComponent>
        <div class="container">
        <div class="md-layout header-engine">
            <h2 style="text-decoration: underline;">Music Engine Settings Per Playlist </h2>
        </div>
        <div class="md-layout div-box" style="margin-bottom:20px">
            <div class="md-layout-item verticle-center" style="text-align: left;">
				<p class="auth-form__field__share sound-effect-label">
					Reflex Radio Picks Checked
				</p>
			</div>
			<div class="md-layout-item" >
				<div class="share-with-trainers md-layout">
					<div  class="md-layout-item verticle-center align-right" >
						<p class="auth-form__field__share sound-effect-label">Reflex Radio Picks Percentage</p>
					</div>                
					<md-menu md-size="small" md-align-trigger class="menu-to-style ">
					<md-button
						md-menu-trigger
						id="options-mix-indicators-beeps"
						class="menu-to-style"
					>
						<div class="input-field-menu">{{ choosenRRPerc +'%' }}</div>
						<!-- <div v-else class="input-field-menu">RR %</div> -->
					</md-button>
					<md-menu-content class="menu-container  ">
						<md-menu-item
							v-for="(percentage, index) in percentages"
							:key="index + '_for_indicators'"
							:id="'reflex-picks-percentage' + index"
							@click="changePercRRPicks(percentage,choosenRRPerc)"
							:disabled="(percentage+parseInt(popularWithReflexRadioPicksPercentage) > 100)"
						>
							{{ percentage + '%' }}
						</md-menu-item>
					</md-menu-content>
				    </md-menu>
				</div>
			</div>
			
			<div  class="md-layout-item" >
				<div class="share-with-trainers md-layout">
					<div  class="md-layout-item verticle-center align-right" >
							<p class="auth-form__field__share sound-effect-label">
								Popularity Percentage
							</p>
					</div>
				    <md-menu
					md-size="small"
					md-align-trigger
					class="menu-to-style "
				    >
					    <md-button
					    	md-menu-trigger
					    	id="options-mix-indicators-beeps"
					    	class="menu-to-style"
					    >
					    	<div  class="input-field-menu">{{ popularWithReflexRadioPicksPercentage +'%' }}</div>
					    </md-button>
					    <md-menu-content class="menu-container  ">
					    	<md-menu-item
					    		v-for="(percentage, index) in percentages"
					    		:key="index + '_for_indicators'"
					    		:id="'popularity-percentage' + index"
					    		@click="changeChosenPopularityWithReflexRadioPicksPerc(percentage,popularWithReflexRadioPicksPercentage)"
					    		:disabled="(percentage+parseInt(choosenRRPerc) > 100)"
					    	>
					    		{{ percentage + '%' }}
					    	</md-menu-item>
					    </md-menu-content>
				    </md-menu>
			    </div>
			</div>
			
		</div>
		<div class="md-layout div-box" style="margin-bottom: 20px;">
			<div class="md-layout-item verticle-center" style="text-align: left;">
				<p class="auth-form__field__share sound-effect-label">
					Reflex Radio Picks Not Checked
				</p>
			</div>
			<div class="md-layout-item" >
				<div class="share-with-trainers md-layout">
					<div class="md-layout-item verticle-center align-right">
						<p class="auth-form__field__share sound-effect-label">
							Popularity Percentage
						</p>
					</div>

				    <md-menu
					md-size="small"
					md-align-trigger
					class="menu-to-style "
				    >
					    <md-button
					    	md-menu-trigger
					    	id="options-mix-indicators-beeps"
					    	class="menu-to-style"
					    >
					    	<div  class="input-field-menu">{{ chosenPopularityPerc +'%' }}</div>
					    </md-button>
					    <md-menu-content class="menu-container  ">
					    	<md-menu-item
					    		v-for="(percentage, index) in percentages"
					    		:key="index + '_for_indicators'"
					    		:id="'popularity-percentage' + index"
					    		@click="changeChosenPopularityPerc(percentage,chosenPopularityPerc)"
					    	>
					    		{{ percentage + '%' }}
					    	</md-menu-item>
					    </md-menu-content>
				    </md-menu>
			    </div>
			</div>
			<div class="share-with-trainers md-layout"></div>

		</div>
        <div class="md-layout div-box">
			<div class="share-with-trainers md-layout"></div>

           <div class="share-with-trainers md-layout">
				<div class="md-layout-item verticle-center align-right">
	
					<p class="auth-form__field__share sound-effect-label" style="font-size: large;">
						Repeated Songs Percentage
					</p>
				</div>
					<md-menu md-size="small" md-align-trigger class="menu-to-style">
						<md-button md-menu-trigger id="options-repeated-songs-across-playlists" class="menu-to-style" >
						<div v-if="repeatedSongsPerc" class="input-field-menu">{{ repeatedSongsPerc +'%' }}</div>
						</md-button>
						<md-menu-content class="menu-container">
							<md-menu-item
								v-for="(percentage, index) in percentages"
								:key="index + '_for_indicators'"
								:id="'popularity-percentage' + index"
								@click="changeChosenRepeatedSongsPerc(percentage,repeatedSongsPerc)"
							>
								{{ percentage + '%' }}
							</md-menu-item>
						</md-menu-content>
					</md-menu>

			</div>
			<div class="share-with-trainers md-layout"></div>
		</div>
		<div class="md-layout div-box" style="margin-bottom: 20px;">
			<div class="md-layout-item verticle-center" style="text-align: left;">
				<p class="auth-form__field__share sound-effect-label">
					Transitional Effects Control
				</p>
			</div>
			<div class="md-layout-item" >
				<div class="share-with-trainers md-layout">
					<div class="md-layout-item verticle-center align-right">
						<p class="auth-form__field__share sound-effect-label">
							Transitional Effects (riser/downlifter)
						</p>
					</div>

				    <toggle-button @change="changeTransitionalEffectsStatus" v-model="transitionalEffectsOn" color="#a156a0" :sync="true" :width=85 :font-size=17 :height=35
                                   :labels="{checked: 'Yes', unchecked: 'No'}"/>
			    </div>
			</div>
			<div class="md-layout-item" >
				<div class="share-with-trainers md-layout">
					<div class="md-layout-item verticle-center align-right">
						<p class="auth-form__field__share sound-effect-label">
							Volume
						</p>
					</div>

				    <md-menu
					md-size="small"
					md-align-trigger
					class="menu-to-style "
				    >
					    <md-button
					    	md-menu-trigger
					    	id="options-mix-indicators-beeps"
					    	class="menu-to-style"
					    >
					    	<div  class="input-field-menu">{{ transitionalEffectsVolume +'%' }}</div>
					    </md-button>
					    <md-menu-content class="menu-container  ">
					    	<md-menu-item
					    		v-for="(percentage, index) in percentages"
					    		:key="index + '_for_indicators'"
					    		:id="'popularity-percentage' + index"
					    		@click="changeTransitionalEffectsVolume(percentage,chosenPopularityPerc)"
					    	>
					    		{{ percentage + '%' }}
					    	</md-menu-item>
					    </md-menu-content>
				    </md-menu>
			    </div>
			</div>
		</div>
    </div>
	


    </div>

</template>

<script>
    import {required, email} from 'vuelidate/lib/validators';
	import $axios from "@/plugins/axios";
    
    export default {
        name: 'MusicEnginSettings',
      
        data: () => ({
			percentages: [0,10,15,20,30,40,50,60,70,80,90,100],
			settingsData:null,
            choosenRRPerc : null,
			chosenPopularityPerc: null,
            repeatedSongsPerc:null,
            popularWithReflexRadioPicksPercentage:null,
			transitionalEffectsOn:null,
			transitionalEffectsVolume:null
        }),
      

        methods: {
            setPageName() {

                this.$store.dispatch('changePageName', {pageNameString: 'Engine Settings'});
            },
			changeChosenPopularityPerc(newVal,oldVal) {
				if (newVal != oldVal) {
					this.chosenPopularityPerc = newVal;
					this.updateSettingsPercentagePerPlaylist()
				}
			},
			changeChosenPopularityWithReflexRadioPicksPerc(newVal,oldVal) {
				if (newVal != oldVal) {
					this.popularWithReflexRadioPicksPercentage = newVal;
					this.updateSettingsPercentagePerPlaylist()
				}
			},
            changePercRRPicks(newVal,oldVal) {
				if (newVal != oldVal) {
					this.choosenRRPerc = newVal;
					this.updateSettingsPercentagePerPlaylist()
				}
			},
			changeChosenRepeatedSongsPerc(newVal,oldVal) {
				if (newVal != oldVal) {
					this.repeatedSongsPerc = newVal;
					this.updateSettingsPercentagePerPlaylist()
				}
			},
			changeTransitionalEffectsVolume(newVal,oldVal) {
				if (newVal != oldVal) {
					this.transitionalEffectsVolume = newVal;
					this.updateSettingsPercentagePerPlaylist()
				}
			},
			changeTransitionalEffectsStatus() {
					this.transitionalEffectsOn = this.transitionalEffectsOn==true ? 1 : 0;
					this.updateSettingsPercentagePerPlaylist()
			},
			async getSettings(){
				await $axios.get('/settings')
                    .then((res) => {
						this.settingsData = res.data;
						this.choosenRRPerc = this.settingsData.filter(sett => sett.key =="reflex_radio_picks_percentage")[0].value;
						this.popularWithReflexRadioPicksPercentage = this.settingsData.filter(sett => sett.key =="popular_with_reflex_radio_picks_percentage")[0].value;
						this.chosenPopularityPerc = this.settingsData.filter(sett => sett.key =="popular_percentage")[0].value;
						this.repeatedSongsPerc = this.settingsData.filter(sett => sett.key =="repeated_songs_per_playlist_percentage")[0].value;
						this.transitionalEffectsOn = this.settingsData.filter(sett => sett.key =="transitional_effects_on")[0].value;
						this.transitionalEffectsOn = this.transitionalEffectsOn == 1 ? true : false;
						this.transitionalEffectsVolume = this.settingsData.filter(sett => sett.key =="transitional_effects_volume")[0].value;

                    })
                    .catch((error) => {
                        let errMsg = error.response.data;
                        this.$store.dispatch('showSnackbarMessage',
                            {message: errMsg, duration: 6000,  mode: 'fail'})
                    });
			},
            updateSettingsPercentagePerPlaylist() {

                let musicEnginSettingsObj =  {
										reflex_radio_picks_percentage						: this.choosenRRPerc,
										popular_percentage					: this.chosenPopularityPerc,
										popular_with_reflex_radio_picks_percentage	: this.popularWithReflexRadioPicksPercentage,
										repeated_songs_per_playlist_percentage: this.repeatedSongsPerc,
										transitional_effects_volume:this.transitionalEffectsVolume,
										transitional_effects_on:this.transitionalEffectsOn
                                        };


                this.$axios.post('/settings', musicEnginSettingsObj)
                    .then(() => {
						this.getSettings();
                        this.$store.dispatch('showSnackbarMessage',{ message: 'Settings were updated successfully!',
                            duration: 1000,
                            mode: 'success'
                        });
                    })
                    .catch((error) => {

                        let errMsg = error.response.data;

                        this.$store.dispatch('showSnackbarMessage',
                            {message: errMsg, duration: 1000,  mode: 'fail'})
                    });
            }
        },

        created() {
            this.setPageName();
			this.getSettings();
        },

    }
</script>

<style lang="scss" scoped>
.content-header.about-page {
        margin-bottom: 40px;
    }
    .menu-to-style {
		::v-deep .md-ripple {
			border: 2px solid #A156A0 !important;
			border-radius: 6px !important;
		}
	}	
	.menu-container {
		z-index: 100 !important;
	}

    .container{
		border: 1px solid #606060;
		border-radius: 5px;
		background-color: #2A2A2A;
		padding: 20px;
		margin-bottom: 20px;
	}
    p{
        align-self: center;
        font-size: large;
    }
    .header-engine{
        text-align: center;
        justify-content: center;
        margin-bottom: 50px;
    }

	.verticle-center{
		align-self: center;
	}
	.div-box{
		margin-bottom: 20px;
    	box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    	padding: 20px;
		background: #3A3A3A;
    	border-radius: 5px;
	}
	.align-right{
		text-align: right;
		margin-right: 10px;
	}
</style>