const state = () => ({
    users: []
});

const getters = {
    getUsers(state) {
        return state.users;
    }
};

const mutations = {
    setUsers(state, users) {
        state.users = users;
    }
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}