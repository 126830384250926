const state = () => ({
    genres: []
});

const getters = {
    getGenres(state) {
        return state.genres;
    }
};

const mutations = {
    setGenres(state, genres) {
        state.genres = genres;
    }
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}