<template>

	<div>
		<div  style="display:flex;flex-direction: column;align-items: start;height: 100%;">
				<div class="figtree-normal-white-32px" style="flex:5;margin: 20px 0 40px 0;">My Account</div>

				<div style="flex:40;display: flex;width: 100%;text-align: left;flex-wrap: wrap;gap: 20px;">
					<div style="flex:50">
						<div style="flex:5;display: flex;width: 100%;text-align: left;margin-bottom: 10px;" class="figtree-normal-white-26px headers-font">
							Account Details
						</div>	
						<div class="account-details-card figtree-normal-white-18px">
							<div  class="round-avatar-image">
								<img v-if="previewUrl" :src="previewUrl" alt="account-image">
								<img v-if="!previewUrl" src="@/assets/images/avatar-empty.png" alt="account-image">
								<input v-show="false" type="file" @change="showPreview($event)" ref="hiddenInput" class="input-file">
								<md-icon style="position: relative;opacity: 0.8;right: 30px;top: 5px;" @click.native="$refs.hiddenInput.click()" class="md-theme-default md-primary">camera_alt</md-icon>
							</div>
							<div class="figtree-normal-white-18px account-details-font">
								<p class="hidden-at-mobile">Name</p>
								<p> {{ currentUser.first_name }} {{ currentUser.last_name }} </p>
							</div>
							<div class="figtree-normal-white-18px account-details-font">
								<p class="hidden-at-mobile">EMAIL</p>
								<p> {{ currentUser.email }}</p>
							</div>
							<div class="figtree-normal-white-18px account-details-font">
								<p class="hidden-at-mobile">Password</p> 
								<p> **************
								<span
									class="reset-pass"
									id="reset-password-button"
									@click="openResetPasswordDialog"
									style="color: red"
								>
								 RESET
								</span>
							</p>
							</div>
							<div class="figtree-normal-white-18px account-details-font">
								<p class="hidden-at-mobile">Created Date</p>
								<p> {{ currentUser.created_at }}</p>
							</div>
							<div style="border-bottom: 1px solid white;width: 100%;"></div>
								<div class="account-actions">
									<div style="flex:45">
										<button class="account-action-buttons figtree-normal-white-18px account-details-font" @click="openEditAccountModal">Update account</button>
									</div>
									<div style="flex:45" v-if="currentUser.role_id  === 1">
										<router-link 
											v-if="currentUser.role_id  === 1"
											id="accounts-info"
											to="/accounts"
											>
											<button class="account-action-buttons figtree-normal-white-18px account-details-font">View all accounts</button>
										</router-link>				
									</div>

								</div>
							</div>
					</div>
					<div style="flex:50" >
						<div style="flex:5;display: flex;width: 100%;text-align: left;margin-bottom: 10px;" class="figtree-normal-white-26px headers-font">
							Music Account
						</div>	
						<div style="flex:20;" class="music-details-card figtree-normal-white-16px">
							<div style="flex:10;">

								<img class="music-icon" src="@/assets/images/musicIcon.png" alt="fullscreen"/>
							</div>
							<div style="flex:40;margin-left: 20px;">Add/Edit Music Account</div>
							<div style="flex:40;display: flex;justify-content: space-between;align-items: center;">
								<div style="flex:10">
									<div v-if="currentMusicUserToken" class="linked-music"><md-icon style="font-size: 20px!important;">check</md-icon>Linked</div>
								</div>
								<div style="flex:10">
										<md-button style="height: 30px!important;"	class="md-raised md-primary md-raised md-theme-light"
													@click="changeAppleAccount">
													  Change Account
										</md-button>
								</div>
							</div>

						</div>
						<div v-if="userRole <= 2 || userRole === 4" style="flex:60;display: flex;flex-direction:column;width: 100%;text-align: left;margin: 30px 0 0px 0;align-items: center;gap: 17px;">

							<div style="flex:10;display: flex;width: 100%;text-align: left;align-items: center;" class="figtree-normal-white-26px headers-font">
								<div style="flex:60;line-height: 22px;">Payment Methods</div>
								<div class="figtree-medium-white-14px" style="flex:40; display: flex;justify-content: end;">
									<div class="add-payment-method-btn" @click="openModalCard">
										Add payment Methods
									</div>	
								</div>
							</div>
							<div style="display: flex;flex-direction: column;flex:90;width: 100%;">
							
								<div class="cards-div">
									<div  class="first-card" >
											<div class="" style="flex:10">
												<img v-if="cards[0] && cards[0].brand.toLocaleLowerCase() == 'visa'" 
													 src="@/assets/images/visa-card-image.png" alt="" class="card-img">
												<img v-else-if="cards[0] && cards[0].brand.toLocaleLowerCase() == 'mastercard'" 
													 src="@/assets/images/master-card-image.png" alt="" class="card-img">
											</div>
										
											<div class="figtree-medium-white-16px card-font" style="flex:80">
												<p>{{ cards[0] ? cards[0].brand :''}} **** {{ cards[0] ? cards[0].last4 :''}}</p>
												<p> {{ cards[0] ? cards[0].exp_month : ''}} / {{ cards[0] ?  cards[0].exp_year : ''}}</p>
											</div>
									</div>
									<div class="other-cards"> <!-- v-if="cards.length > 1"  -->
										<div  	class="card-number"
												v-for="(card, index) in cards"
												:key="index + 'card'"
												 v-if="index > 0 && index < 3">
											<div class="" style="flex:10">
												<img v-if="card.brand.toLocaleLowerCase() == 'visa'" 
													 src="@/assets/images/visa-card-image.png" alt="" class="card-img">
												<img v-if="card.brand.toLocaleLowerCase() == 'mastercard'" 
													 src="@/assets/images/master-card-image.png" alt="" class="card-img">
											</div>
										
											<div class="figtree-medium-white-16px card-font" style="flex:80">
												<p>{{ card.brand }} **** {{ card.last4 }}</p>
												<p> {{ card.exp_month }} / {{ card.exp_year }}</p>
											</div>
	
										</div>
									</div>
								</div>
								<div class="view-cards-btn">
									<router-link style="text-decoration: none !important;"
										id="payment-method"
										to="/cards"
									>
									<p class="figtree-medium-white-14px">View all Cards</p>
									</router-link>
								</div>
							</div>	
						</div>
					</div>
				</div>
				<div  v-if="userRole === 2 || userRole === 4" style="flex:25;display:flex;flex-direction: column;width: 100%; margin-top: 30px;gap:15px;">
					<div style="flex:5;text-align: left;">
						<router-link style="text-decoration: none;color: var(--material-themerefsecondarysecondary100);" class="figtree-normal-white-26px headers-font"
						id="subscription"
						to="/subscriptions"
						>
							Subscriptions
						</router-link>
					</div>
					<div style="flex:90">
						<div class="row subscriptions-header figtree-semi-bold-pink-swan-18px">
							<div class="flex-box-28 text-el" >Facility</div>
							<div class="flex-box-16 text-el">Subscription cost</div>
							<div class="flex-box-16 text-el">Status</div>
							<div class="flex-box-20 text-el">Start date</div>
							<div class="flex-box-20 text-el">Renewal date</div>
	
						</div>
						<div class="row figtree-semi-bold-pink-swan-18px"
								 v-for="(subscription, index) in subscriptions"
								 :key="index + '_singleClass'">
	
								<p class="flex-box-28">
									{{ subscription.store.name }}
								</p>
	
								<p class="flex-box-16">
									${{ subscription.price }}
								</p>
	
								<p class="flex-box-16">
									{{ subscription.status }}
								</p>
	
								<p class="flex-box-20">
									<span v-if="subscription.current_period_start">{{
											subscription.current_period_start
										}}</span>
								</p>
	
								<p class="flex-box-20">
									<span v-if="subscription.active && subscription.current_period_end && !subscription.cancel_at_period_end">{{
											subscription.current_period_end
										}}</span>
									<span v-else-if="subscription.active && subscription.cancel_at_period_end && !subscription.canceled_at" class="clickable-span"
										@click.stop="extendSubscription(subscription.id)">Renew</span>									
									<span v-else class="clickable-span"
									@click=showSubscription(subscription)>
									Resubscribe
									</span>
								</p>
						</div>
					</div>
				</div>
				
				<div v-if="userRole <= 3" style="flex:25;display:flex;flex-direction: column;width: 100%; margin-top: 30px;gap:15px;">
					<div style="flex:5;display: flex;align-items: center;text-align: left;">
						<div style="flex:70"  class="figtree-normal-white-26px headers-font">
								Facility
						</div>
						
						<div   v-if="userRole < 3 && (isIndividualTrainer == false)" class="figtree-medium-white-14px" style="flex:30; display: flex;justify-content: end;">
							<div class="add-payment-method-btn" @click="openCreateStoreModal">
								Add facility
							</div>	
						</div>
					</div>
					<div style="flex:90">
						<StoresDashboardStores />
					</div>
				</div>

			</div>

		<AuthResetPasswordDialog
			:key="dialogKeyPassword + 'pass'"
			:showDialog.sync="showResetPassRequestDialog"/>

		<CardModal
        v-if="userRole <= 2 || userRole === 4"
			:showDialog.sync="showDialogCardModal"
			:key="dialogKeyCard + 'card'"
			@getCards="getCards"
		/>

		<CreateStoreDialog
			v-if="userRole == 1 || isFacilityOwner"
			:showDialog.sync="showDialogStoreModal"
			:key="dialogKeyStore"
			:store-prop="store"
			@addNewStore="getAllStores"
		/>
		<AccountDialog
                :showDialog.sync="editAccountShowDialog"
                :key="currentUser.account_id+'_'+editAccountDialogKey"
                @addNewAccount="getCurrentAccount"
                :account-prop.sync="account"/>

    <md-dialog :md-active.sync="showContactUsDialog" :md-click-outside-to-close="true">
      <md-dialog-title>
        Info
      </md-dialog-title>
      <md-dialog-content>
        <md-dialog-prompt>You can add up to 9 Facilities to your account, to add more than 9 facilities please contact
          us email : <a href="mailto:contactus@reflex-radio.com">contactus@reflex-radio.com</a>
        </md-dialog-prompt>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="dialog__btn"
                   @click=close>
          Ok
        </md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog :md-active.sync="showSubscriptionDialog" :md-click-outside-to-close="true">
      <md-dialog-title>
        Resubscribe
      </md-dialog-title>
      <md-dialog-content>
        <AppInputComponent
            class="create-form__field"
            controlType="select"
            required
            placeholder="Choose a card"
            v-model="selectedCardId"
            :error="!selectedCardId"
            errorText="Card is required"
        >
          <option
              v-for="(card, index) in cards" :key="index" :value="card.id">
            {{ card.brand }} ***{{ card.last4 }}
          </option>
        </AppInputComponent>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="dialog__btn dialog__btn--danger"
                   @click="showSubscriptionDialog = false">
          Cancel
        </md-button>
        <md-button :disabled="!selectedCardId" class="md-primary md-raised dialog__btn md-theme-light"
                   @click=resubscribe>
          Resubscribe
        </md-button>
      </md-dialog-actions>
    </md-dialog>

	</div>
</template>

<script>
import {mapGetters} from "vuex";
import CardService from '@/services/card.service'
import CardModal from '@/components/Cards/CardModal'
import StoreService from "@/services/store.service";
import CreateStoreDialog from "@/components/Store/CreateStoreDialog";
import SubscriptionService from  "../../services/subscription.service"
import AuthResetPasswordDialog from "@/components/Auth/AuthResetPasswordDialog";
import AccountService from "../../services/account.service"
import UserStreamingService from "../../services/user-streaming.service"
import AccountDialog from "../../components/Accounts/AccountDialog.vue"
import StoresDashboardStores from "../../pages/StoresDashboard/StoresDashboardStores.vue"
	export default {
		name: "AccountInfoDashboard",

		data() {

			return {
				previewUrl: '',
				showPhotoError: false,
				account: {
					id: '',
					name: '',
					email: '',
					street: '',
					suite: '',
					city: '',
					state: '',
					zip: '',
					image_url: null,
					stripe_customer_id: '',
					active: null,
					primary_contact_id: null,
					logo: null
				},

				//Card data
				cards: [],
				showDialogCardModal: false,
				dialogKeyCard: 0,

				//Store data
				stores: [],
				store: {
					name: '',
					street: '',
					suite: '',
					city: '',
					state: '',
					zip: '',
					phone: '',
					img: '',
					email: '',
					account_id: null,
					card_id: null
				},
				showDialogStoreModal: false,
        showContactUsDialog: false,
				dialogKeyStore: 0,

				//Subscription data
				subscriptions: [],

				//Password
				dialogKeyPassword: 0,
				showResetPassRequestDialog: false,
        showSubscriptionDialog: false,
        selectedSubscription: null,
        selectedCardId: null,
		editAccountShowDialog:false,
		editAccountDialogKey:0,
		currentMusicUserToken:null
			}
		},

		components: {
			CardModal,
			CreateStoreDialog,
			AuthResetPasswordDialog,
			AccountDialog,
			StoresDashboardStores
		},

		methods: {
		extendSubscription(id) {
			return SubscriptionService.extend(id)
				.then((res) => {
					this.$store.dispatch('showSnackbarMessage', {
                	message: 'You subscription is updated successfully.',
                	duration: 4000,
                	mode: 'success'
              	});
					this.getSubscriptions();
				}).catch((error) => {
					console.log(error)
					this.$store.dispatch('showSnackbarMessage', {
						message: error.message,
						mode: 'fail'
					});
				});
        },
		openEditAccountModal() {
			this.editAccountDialogKey++;
			this.editAccountShowDialog = true;
		},
			setPageName() {
				this.$store.dispatch('changePageName', {pageNameString: 'Account Information'});
			},

			showPreview(event) {
				const input = event.target;

				console.log(this.currentUser);

				console.log(input.files);

				if (input.files && input.files[0]) {

					let splitFile = input.files[0].name.split('.');
					let splitFileLength = splitFile.length;
					let fileExtension = input.files[0].name.split('.')[splitFileLength - 1];
					let lowerCaseExtension = fileExtension.toLowerCase();

					if (lowerCaseExtension === 'png' || lowerCaseExtension === 'jpeg' || lowerCaseExtension === 'jpg' ||
                        lowerCaseExtension === 'bmp') {

						this.account.logo = input.files[0];

                        AccountService.updateAccount(this.account);

                        const reader = new FileReader();

						reader.onload = e => {
							this.previewUrl = e.target.result;
							input.value = '';
						};

						reader.readAsDataURL(input.files[0]);

						this.showPhotoError = false;

						// console.log(this.showPhotoError);
					} else {
                        this.$store.dispatch('showSnackbarMessage',
                            {message: "Sorry, you are allowed to upload only files with extensions: jpg, png, jpeg, bmp!", duration: 8000})
						this.showPhotoError = true;
					}
				}
			},


			async fetchData() {

				const music = await MusicKit.getInstance()
        		this.currentMusicUserToken = music.musicUserToken;
				if (this.userRole > 2 && this.userRole < 5) {

          await this.getCards();
					await this.getOneStore();
					await this.getSubscriptions();
				}
				else if (this.userRole == 5) {

					await this.getOneStore();
				}
				else {

					await this.getCards();
					await this.getAllStores();
					await this.getSubscriptions();
				}

			},

			// Cards methods
			openModalCard() {

				this.dialogKeyCard++;
				this.showDialogCardModal = true;
			},

			// getOneCard() {
			//
			// 	return CardService.getOneCard(this.currentUser.id)
			// 		.then(data => {
			//
			// 			// console.log('Get cards: ' + data);
			// 			this.cards = data;
			// 		})
			// },

			getOneStore() {

				StoreService.getSingleStore(this.currentUser.store_id)
					.then(res => {

						this.loadingSpinner = false;
						res.moreInfo = res.city + ' ' + res.street;

						this.stores.push(res);
					})
			},

			getCards() {
				return CardService.getAllCards()
					.then(data => {

						// console.log('Get cards: ' + data);
						this.cards = data;
					})
					.catch(error => {

						this.$store.dispatch('showSnackbarMessage', {
							message: error.message,
							mode: 'fail'
						});
					})
			},

			// Facility methods
      openCreateStoreModal() {
        if (this.stores.length >= 9) {
          this.showContactUsDialog = true;
        } else {
          this.dialogKeyStore++;
          this.store = null;
          this.showDialogStoreModal = true;
        }
      },
      close(){
        this.showContactUsDialog = false;
      },

			getAllStores() {

				StoreService.getStores()
					.then(res => {

						this.loadingSpinner = false;
						this.stores = res.map(x => {
							return {
								...x,
								moreInfo: x.city + ' ' + x.street
							}
						});
					})
					.catch(() => {

						this.$store.dispatch('showSnackbarMessage', {
							message: 'Failed. Please, try again.',
							duration: 8000,
							mode: 'fail'
						});
					});
			},

            getCurrentAccount(id){
				id = (isNaN(id)) ? id.id : id ;

                return AccountService.getOne(id)
                    .then((res) => {

                        this.account = res;
                        this.previewUrl = this.account.image_url;
                    })
                    .catch(() => {
                        this.$store.dispatch('showSnackbarMessage', {message: 'Failed Please, try again.', duration: 8000, mode: 'fail'});
                    });
            },

			//Subscription methods
			getSubscriptions() {

				return SubscriptionService.get()
					.then((data) => {

						this.loadingSpinner = false
						this.subscriptions = data;
					})
					.catch((error) => {

						console.log(error)
						this.$store.dispatch('showSnackbarMessage', {
							message: error.message,
							mode: 'fail'
						});
					});
			},

			//Password reset
			openResetPasswordDialog() {
				this.dialogKeyPassword++;
				this.showResetPassRequestDialog = true;
			},
      async changeAppleAccount() {
        const music = await MusicKit.getInstance()
        const temp = music.musicUserToken
        music.musicUserToken = ''
        try {
          await music.authorize();
          const data = {
            "user_id": this.$store.getters['auth/getCurrentUser'].id,
            "service_id": 1,
            "token":music.musicUserToken,
            "store_front":music.storefrontId
          }

          await UserStreamingService.authorize(data).then(() => {
            this.$store.dispatch('showSnackbarMessage', {
              message: 'Apple account is changed successfully.',
              duration: 8000,
              mode: 'success'
            });

          }).catch(() => {
            music.musicUserToken = temp
            this.$store.dispatch('showSnackbarMessage', {
              message: 'Failed. Please, try again.',
              duration: 8000,
              mode: 'fail'
            });
          });

        } catch (e) {
          music.musicUserToken = temp
          console.log('error', e)
          this.$store.dispatch('showSnackbarMessage', {
            message: `${e.message}. Please, try again.`,
            duration: 4000,
            mode: 'fail'
          });
        }


      },
      showSubscription(subscription){
			  this.selectedSubscription = subscription;
			  this.showSubscriptionDialog = true;
      },
      resubscribe(){
        SubscriptionService.resubscribe(this.selectedSubscription.id,this.selectedCardId)
            .then(() => {
              this.$store.dispatch('showSnackbarMessage', {
                message: 'You subscription is updated successfully.',
                duration: 4000,
                mode: 'success'
              });
              this.getSubscriptions();
            })
            .catch((error) => {

              console.log(error)
              this.$store.dispatch('showSnackbarMessage', {
                message: error.message,
                mode: 'fail'
              });
            });
        this.showSubscriptionDialog = false;
      }
		},

		created() {
			this.getCurrentAccount(this.currentUser.account_id);
			this.setPageName();
			this.fetchData();
		},

		computed: {

			...mapGetters(
				{
					currentUser: 'auth/getCurrentUser',
					userRole: 'auth/currentUserRole'
				},
			),

			isIndividualTrainer() {

				return this.userRole == 2 && this.currentUser.account.is_trainer;
			},

			isFacilityOwner() {

				return this.userRole == 2 && this.currentUser.account.is_trainer == false;
			},
		},
	}
</script>

<style lang="scss" scoped>
@include dialogSettings();
	.avatar-image {
		margin-bottom: 5rem;
	}

	.round-avatar-image img {
		border-radius: 50%;
		width: 37px;
		height: 37px;
	}

	.file-upload-label {
		z-index: 3;
		position: relative;
		cursor: pointer;
	}

	.file-upload-label::after {
		content: "";
		opacity: 0.5;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
	}

	.welcome-headline {
		line-height: 30px;
	}

	.role-info h3 {
		margin-top: 2rem;
	}

	.component-wrap {
		border: 1px solid hsla(0, 0%, 38%); 
		background-color: #2A2A2A;
		padding: 4rem;
		
		border-radius: 20px;
		text-align: center;
		margin-top: 5rem;
		min-height: 250px;

		h2 {
			margin-bottom: 3rem;
			font-family: Roboto;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			text-decoration-line: underline;
		}

		p, .subscriptions h3 {
			text-align: left;
		}

		.reset-pass {
			cursor: pointer;
		}

		.card-info, .facility-info, .subscription-info {
			margin-bottom: 3rem;
		}

		.subscription-header {
			margin-bottom: 1rem;
		}
	}

	.md-body-1 {
		color: $color-white;
		font-size: 1.6rem;
	}

  .clickable-span:hover{
    color: #56638a;
    cursor: pointer;
  }
  .account-details-card{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	padding: 30px;
	gap: 20px;
	background: #2A2A2A;
	border: 1px solid #A862C2;
	border-radius: 5px;
	line-height: 20px;
	.account-details-font{
		@media only screen and (max-width: 520px) {
    		font-size: var(--font-size-s)!important;
   	 	};
	}
  }
  .music-details-card{
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 30px;
	// gap: 10px;
	background: #2A2A2A;
	border-radius: 5px;
	flex-wrap: wrap;
  }
.account-actions{
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 8px 15px;
	gap: 10px;
}
.account-action-buttons{
	background: rgba(255, 255, 255, 0.12);
	border: 1px solid #FFFFFF;
	border-radius: 5px;
	width: 100%;
	height: 48px;
	cursor: pointer;
}
.linked-music{
	padding: 3px;
	width: 90px;
    height: 30px;
	text-align: center;
	background: var(--persian-green);
	border-radius: 41px;
	@media only screen and (max-width: 520px) {
    	display: none;
    };
}
.add-payment-method-btn{
	width: 175px;
	height: 40px;
	background: rgba(255, 255, 255, 0.12);
	border-radius: 5px;
	text-align: center;
	padding: 10px;
	cursor: pointer;
}

.cards-div{
	flex: 90;
	align-items: flex-start;
    background-color: var(--tuatara);
    border-radius: 5px 5px 0px 0px;
    display: flex;
    flex-direction: column;
    gap: 17px;
    padding: 15px 15px 15px 19px;
	width: 100%;
	.card-font{
		@media only screen and (max-width: 834px) {
  		font-size: var(--font-size-s)!important;
  };
  @media only screen and (max-width: 520px) {
    font-size: var(--font-size-xs)!important;
    };
	}
}
.first-card{
	align-items: center;
	flex:40;
    background-color: var(--white-52);
    border: 1px solid;
    border-color: var(--amethyst-22);
    border-radius: 5px;
    display: flex;
    gap: 12px;
    padding: 15px;
    width: 100%;
}
.other-cards{
	align-items: center;
	flex:40;
    display: flex;
    gap: 10px;
    width: 100%;
}
.card-number{
	flex:45;
	display: flex;
	gap: 12px;
    background-color: var(--white-52);
    border-radius: 5px;
    padding: 15px;
}
.card-img{
	height: 41px;
	width: 44px;
	min-width: 44px;
	border-radius: 5px;
	@media only screen and (max-width: 520px) {
		min-width	: 34px;
		width		: 34px;
		height		: 32px;
   	 	};
}
.view-cards-btn {
	flex: 10;
    align-items: center;
    background-color: var(--tuatara);
    border-color: var(--chicago);
    border-radius: 0px 0px 5px 5px;
    border-top-style: solid;
    border-top-width: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
	text-align: center;
    width: 100%;
	min-height: 58px;
	cursor: pointer;
  }
  .row{
	display: flex;
	justify-content: flex-start;
	text-align: left;
	background: #262626;
	border-radius: 4px 4px 0px 0px;
	min-height: 56px;
    align-items: center;
	padding-left: 10px;
	gap: 10px;
  }
  .subscriptions-header{
	background: #3A3A3A;
	.text-el{
		overflow: hidden;
		text-overflow: ellipsis;
	}
  }
  .flex-box-16{
	flex:16;
  }
  .flex-box-20{
	flex:20;
  }
  .flex-box-28{
	flex:28;
  }
 
  .headers-font{
	@media only screen and (max-width: 520px) {
    		font-size: var(--font-size-xl)!important;
   	 	};
  }
  .music-icon{
	min-width: 60px;
	width: 60px;
	height: 60px;
	background-color: #f0f0f0;border-radius: 20%;
	position:relative;
	top: 10%;left: 10%;
	@media only screen and (max-width: 520px) {
		min-width	: 40px;
		width		: 40px;
		height		: 40px;
   	 	};
  }
  .hidden-at-mobile{
	@media only screen and (max-width: 481px) {
		display: none;
	};
  }
</style>
