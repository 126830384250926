import $axios from '../plugins/axios'

class ArtistService {

    getAllArtist() {

        return $axios.get('/artists')
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }
}

export default new ArtistService();