const state = () => ({
    rooms: []
});

const getters = {
    getRooms(state) {
        return state.rooms;
    }
};

const mutations = {
    setRooms(state, rooms) {
        state.rooms = rooms;
    }
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}