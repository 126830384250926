export default function auth ({ to, next, store, nextMiddleware }) {

    let isAuthenticated = store.getters['auth/isAuthenticated']();
    
    if (!isAuthenticated){

        return next({
            path: '/login'
        });
    }

    return nextMiddleware();
}