import $axios from '../plugins/axios'

class AuthService {

    getMe() {

        return $axios.get('me')
            .then((res) => {

                return res.data;
            }).catch((error) => {

                console.log(error)
                return new Promise((resolve, reject) => {
                    return reject(error)
                })
            })
    }

    login(user) {

        return $axios.post('auth/login', {
            email: user.email,
            password: user.password
        }).then((res) => {

            return res.data;
        }).catch((error) => {

            console.log(error)
            return new Promise((resolve, reject) => {
                return reject(error)
            })
        })
    }


    loginAfterRegistration(user) {

        return $axios.post('auth/login', {
            email: user.email,
            password: user.password,
            after_registration:true
        }).then((res) => {

            return res.data;
        }).catch((error) => {

            console.log(error)
            return new Promise((resolve, reject) => {
                return reject(error)
            })
        })
    }

    signUp(user) {

        return $axios.post('users', user)
            .then(res => {

                return res
            });
    }

    impersonate(userID) {
        
        return $axios.post(`auth/impersonate`, {user_id: userID})
        .then(res => {
        
            return res.data;
        });
    }

}

export default new AuthService();