<template>
    <div class="component-wrap">

        <AppSpinnerComponent v-if="loadingSpinner" :loading="loadingSpinner"/>

        <AppHeaderComponent headline='Class'></AppHeaderComponent>

        <div class="classes">
            <div class="singleClass__header md-layout  md-raised">
				<h2 class="md-layout-item md-size-30 md-small-size-100">Start</h2>
                <h2 class="md-layout-item md-size-30 md-small-size-100">End</h2>
                <h2 class="md-layout-item md-size-30 md-small-size-100">Trainer</h2>
            </div>

            <div class="singleClass md-raised md-layout"
                 v-for="(singleClass, index) in classes"
                 :key="index + '_singleClass'"
                 @click.stop="playSong(singleClass.id, singleClass.name)">

<!--                <p class="singleClass__data">-->
<!--                    {{ singleClass.room.name }}-->
<!--                </p>-->

                <p class="singleClass__data md-layout-item md-size-30 md-small-size-100">
                    {{ singleClass.start_datetime }}
                </p>

                <p class="singleClass__data md-layout-item md-size-30 md-small-size-100">
                    {{ singleClass.end_datetime }}
                </p>

                <p class="singleClass__data md-layout-item md-size-30 md-small-size-100">
                    {{ singleClass.trainer | fullName  }}
                </p>

				<div class="md-layout-item md-size-10 md-small-size-100">
					<div class="singleClass__play-button">
						<md-tooltip  class="md-theme-light" md-direction="top">Play</md-tooltip>
						<md-icon style="font-size: 48px!important;" class="md-theme-light">play_circle_filled</md-icon>
					</div>
				</div>

<!--                <div class="singleClass__remove-btn edit-btn"-->
<!--                     @click.stop="openEditSingleClassesModal(singleClass)">-->
<!--                    <md-icon>edit</md-icon>-->
<!--                </div>-->

<!--                <div class="singleClass__remove-btn"-->
<!--                     @click.stop="removeSingleClass(singleClass.id)">-->
<!--                    <md-icon>delete_forever</md-icon>-->
<!--                </div>-->

            </div>

        </div>

<!--        <EditSingleClassDialog :showDialogEditClass.sync="showDialogEditClass" :isReccuringClassProp="openReccuringClassEdit" @refreshAllClasses="getAllClasses" :key="dialogKeyEditClass" :rooms="rooms" :singleClass="singleClassForEdit"/>-->
<!--        <CreateSingleClassDialog @refreshAllClasses="getAllClasses" :isReccuringClass="openReccuringClass" :showDialogRecurringClass.sync="showDialogRecurringClass" :key="dialogKeyRecurringClass"/>-->

    </div>
</template>

<script>
    import SingleStoreUserBlock from "@/components/Store/SingleStoreUserBlock.vue"
    import SingleStoreAccessRequestBlock from "@/components/Store/SingleStoreAccessRequestBlock.vue"
    import UserService from '../../services/user.service';
    import ClassService from '../../services/class.service';

    export default {
        name: 'ClassesDashboardSingleClassDefinition',

        data: () => ({
            classes: [],
            loadingSpinner: false,
            users: []
        }),

        components: {
            SingleStoreUserBlock,
            SingleStoreAccessRequestBlock
        },
        methods: {

            getClassesDetails(id) {

                ClassService.getSingleClassDefinition(id)
                    .then((res) => {

                        this.classes = res;
                    })
                    .catch(() => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: 'Failed. Please, try again.',
                            duration: 8000,
                            mode: 'fail'
                        });
                    });
            },

            getAllUsers(){

                return UserService.getAll()
                    .then(data => {

                        this.users = data;
                    })
                    .catch((error) => {

                         this.$store.dispatch('showSnackbarMessage', {
                            message: 'Failed. Please, try again.',
                            duration: 8000,
                            mode: 'fail'
                        });
                    });
            },

            playSong(classId, className) {

                this.$store.dispatch('audioPlayer/updateSongToPlayFromClass', { id: classId, name: className } );
            },
        },

        created() {

            const classId = this.$route.params.id;
            this.getClassesDetails(classId)
            this.getAllUsers();
        },

        filters: {

             fullName(value) {

                 return value.first_name.charAt(0).toUpperCase() + value.first_name.slice(1) + ' '
                        + value.last_name.charAt(0).toUpperCase() + value.last_name.slice(1);
             }
        }
    }
</script>

<style lang="scss" scoped>
    @include tableStyle(classes, singleClass);

    .singleClass {

        &__header {
            text-align: left;
	        padding: 0 32px 0 0;
	        margin: 0 0 3px;

            @include respond(small) {
                flex-direction: column;
                padding: 16px 24px;
				line-height: normal;
            }
        }

		&__play-button {

			.md-icon.md-theme-light.md-icon-font {
				color: #56638a;

				@include respond(small) {
					color: #8996a6;
				}
			}

			@include respond(small) {
				padding-top: 10px;
				margin-right: auto;
				text-align: center;
			}
		}

    }
</style>