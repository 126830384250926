<template id="color-picker-template">
	<div class="color-picker">
		<p class="auth-form__field__share"> Choose color</p>

		<div class="swatch" :style="{'background': color}" ></div>

		<div class="color-picker__overlay" v-if="isVisible"></div>
		<transition name="pop">
			<div class="color-picker__flyout" v-if="isVisible">
				<div class="color-chip" :style="{'background': color}">
					<div class="color-chip__inner">

					</div>
				</div>
				<div class="color-picker__inner">
					<div class="control" :style="gradientH">
						<input type="range" min="0" max="360" v-model="h"/>
					</div>

					<div class="control" :style="gradientS">
						<input type="range" min="0" max="100" v-model="a"/>
					</div>
				</div>
			</div>
		</transition>

	</div>
</template>

<script>
	export default {
		name: "AppColorPicker",
		props: ["change", "initial"],
		data: function () {
			return {
				isVisible: true,
				h: 114,
				s: 100,
				l: 100,
				a: 0
			}
		},
		computed: {

			color: function () {
				let hsl = this.hsb2hsl(parseFloat(this.h) / 360, parseFloat(this.s) / 100)

				let c = hsl.h + ", " + hsl.s + "%, " + hsl.l + "%, " + this.a / 100;

				let s = "hsla(" + c + ")";
				this.change({
					color: s
				});
				return s;
			},

			colorString: function () {
				return this.h + ", " + this.s + "%, " + this.l + "%";
			},

			gradientH: function () {
				let stops = [];
				for (let i = 0; i < 7; i++) {
					let h = i * 60;

					let hsl = this.hsb2hsl(parseFloat(h / 360), parseFloat(this.s) / 100)

					let c = hsl.h + ", " + hsl.s + "%, " + hsl.l + "%"
					stops.push("hsl(" + c + ")")
				}

				return {
					backgroundImage: "linear-gradient(to right, " + stops.join(', ') + ")"
				}
			},
			gradientS: function () {
				let stops = [];
				let c;
				let hsl = this.hsb2hsl(parseFloat(this.h / 360), 0)

				c = hsl.h + ", " + hsl.s + "%, " + hsl.l + "%"
				stops.push("hsl(" + c + ")")

				hsl = this.hsb2hsl(parseFloat(this.h / 360), 1)
				c = hsl.h + ", " + hsl.s + "%, " + hsl.l + "%"
				stops.push("hsl(" + c + ")")

				return {
					backgroundImage: "linear-gradient(to right, " + stops.join(', ') + ")"
				}
			},

			gradientL: function () {
				let stops = [];
				let c;
				let hsl = this.hsb2hsl(parseFloat(this.h / 360), 0)

				c = hsl.h + ", " + hsl.s + "%, " + hsl.l + "%"
				stops.push("hsl(" + c + ")")

				hsl = this.hsb2hsl(parseFloat(this.h / 360), parseFloat(this.s / 100) )

				c = hsl.h + ", " + hsl.s + "%, " + hsl.l + "%"
				stops.push("hsl(" + c + ")")

				return {
					backgroundImage: "linear-gradient(to right, " + stops.join(', ') + ")"
				}
			}
		},
		methods: {

			hsb2hsl(h, s, b = 1) {
				let hsl = {
					h: h
				};

				hsl.l = (2 - s) * b;
				hsl.s = s * b;

				if (hsl.l <= 1 && hsl.l > 0) {
					hsl.s /= hsl.l;
				} else {
					hsl.s /= 2 - hsl.l;
				}

				hsl.l /= 2;

				if (hsl.s > 1) {
					hsl.s = 1;
				}

				if (!hsl.s > 0) hsl.s = 0

				hsl.h *= 360;
				hsl.s *= 100;
				hsl.l *= 100;

				return hsl;
			},

			show: function () {
				this.isVisible = true;
			},
			hide: function () {
				this.isVisible = false;
			},
			toggle: function () {
				this.isVisible = !this.isVisible;
			},

			toHSLArray(hslStr) {
				return hslStr.match(/\d+/g).map(Number);
			},
		},

		mounted: function () {

			if (this.initial) {

				let opacity =  this.initial.split(',').pop().split(')')[0];
				this.h = this.initial.split('(').pop().split(',')[0];
				this.a = opacity * 100;
			}
		}
	}
</script>

<style lang="scss" scoped>

	.color-picker {
		position: relative;
	}

	.color-picker__overlay {
		width: 100%;
		//height: 100vh;
		position: absolute;
		top: 0;
		left: 0;
		background: black;
		z-index: 0;
		opacity: 0;
	}

	.color-picker__flyout {
		width: 240px;
		border: 1px solid #eee;
		border-radius: 4px;
		background: white;
		box-shadow: 0 3px 7px rgba(0, 0, 0, 0.12);
		font-family: "Roboto", "Helvetica Neue", sans-serif;
		position: absolute;
		z-index: 2;
	}

	.color-picker__inner {
		padding: 1.5rem 1rem;
	}

	.color-chip {
		height: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: white;
		border-radius: 4px 4px 0 0;
	}

	.color-chip__inner {
		text-align: center;
	}

	.color-chip__subtitle {
		margin-top: 0.5rem;
		opacity: 0.7;
		font-weight: normal;
		font-size: 15px;
		text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
	}

	.color-chip__title {
		color: white;
		margin: 0;
		font-size: 50px;
		letter-spacing: 4px;
		font-family: Helvetica Neue,serif;
		text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
	}

	.control {
		width: 100%;
		height: 12px;
		border-radius: 12px;
		border: 1px solid #ddd;
	}

	.control + .control {
		margin-top: 1rem;
	}

	.control input {
		width: 100%;
		margin: 0;
	}

	.control input[type=range] {
		-webkit-appearance: none;
		width: 100%;
		background: transparent;
	}

	.control input[type=range]:focus {
		outline: none;
	}

	.control input[type=range]::-ms-track {
		width: 100%;
		cursor: pointer;
		background: transparent;
		border-color: transparent;
		color: transparent;
	}

	.control input[type=range]::-webkit-slider-thumb {
		-webkit-appearance: none;
		border: 1px solid #ddd;
		height: 20px;
		width: 20px;
		border-radius: 50px;
		background: #ffffff;
		cursor: pointer;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
		margin-top: -4px;
	}

	.swatch {
		width: 24px;
		height: 24px;
		border: 4px solid black;
		box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
	}

	.pop-enter-active,
	.pop-leave-active {
		transition: transform .5s, opacity .5s;
		transition-timing-function: cubic-bezier(.8, .3, 0.25, 1.75);
		transform: scale(1);
	}

	.pop-enter,
	.pop-leave-active {
		opacity: 0;
		transform: scale(0);
	}
</style>