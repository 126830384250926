<template>
    <div
        class="custom-input"
        :class="{'valid': bits != 0}"
        tabindex="0"
        ref="bitsInput"
        @click="showBitsOptions = true"
        @mouseleave="showBitsOptions = false"
        @click.stop="$refs.bitsInput.focus()">
        
        <span
            class="custom-input__display">
            {{bits + ' bits'}}
        </span>

        <div class="custom-input__options"
            :class="{
                'active': showBitsOptions,
                'left': offsetSide === 'left',
                'right': offsetSide === 'right'}">

            <ul class="custom-input__options-list">
                <li class="custom-input__option"
                    v-for="(option, index) in options"
                    :key="index + 'key'"
                    @click="bits = option"
                >{{option}}</li>
            </ul>

        </div>
        
        <div class="custom-input__controls">
            <button @click.prevent.stop=""
                    @mousedown.prevent.stop="plusOnHold()"
                    @mouseup.prevent.stop="stopHold()">
                <md-icon>arrow_drop_up</md-icon>
            </button>
            <button @click.prevent.stop=""
                    @mousedown.prevent.stop="minusOnHold()"
                    @mouseup.prevent.stop="stopHold()">
                <md-icon>arrow_drop_down</md-icon>
            </button>
        </div>

    </div>
</template>

<script>
export default {
    name: 'AppTrackBitsInput',
    props: {
        bitsData: {
            type: Number, 
            default: 0
        },
        showBitsOptionsProp: {
            type: Boolean,
            default: false
        },
        offsetSide: {
            type: String,
            default: 'right'
        }
    },
    
    data: () => ({
        options: [
            120, 130, 140, 150, 160, 170, 180, 190, 200, 210, 220, 230, 240
        ]
    }),

    computed: {
        bits: {
            set(newValue) {
                this.$emit('update:bitsData', newValue);
                return newValue;
            },
            get() {
                return this.bitsData;
            }
        },
        showBitsOptions: {
            set(newValue) {
                this.$emit('update:showBitsOptionsProp', newValue);
                return newValue;
            },
            get() {
                return this.showBitsOptionsProp;
            }
        }
    },
    methods: {
        formatTime(number) {
            if (number / 10 < 1) {
                return '0' + number;
            }
            return number;
        },

        plusBits() {
            this.bits++;
            this.$emit('update:bits', this.bits);
        },

        minusBits() {
            if (this.bits - 1 > 0 ) {
                this.bits--;
            }
            this.$emit('update:bits', this.bits);
        },

        plusOnHold() {
            this.intervalId = setInterval(this.plusBits, 150, this.plusBits());
        },

        minusOnHold() {
            this.intervalId = setInterval(this.minusBits, 150, this.minusBits());
        },

        stopHold() {
            clearInterval(this.intervalId);
        }
    }

}
</script>

<style lang="scss" scoped>
@include customSelectInput();
</style>