<template>
    <md-card class="md-theme-light class-preferences">

        <h3>Class preferences</h3>

        <ul class="top-list top-list--artists"
            aria-label="Top Artists">
            <li v-for="(artist, index) in artists" :key="index">
                <span>{{artist.id}}.</span>
                <span>{{artist.name}}</span>
            </li>
        </ul>   

        <ul class="top-list top-list--genres"
            aria-label="Top Genres">
            <li v-for="(genre, index) in genres" :key="index">
                <span>{{genre.id}}.</span>
                <span>{{genre.name}}</span>
            </li>
        </ul>               

    </md-card>
</template>

<script>
export default {
    name: 'TrainerDashboardClassPreferences',
    data: () => ({
        artists: [
            {id: 1, name: 'Artist 1'},
            {id: 2, name: 'Artist 2'},
            {id: 3, name: 'Artist 3'},
            {id: 4, name: 'Artist 4'},
            {id: 5, name: 'Artist 5'},
            {id: 6, name: 'Artist 6'},
            {id: 7, name: 'Artist 7'},
            {id: 8, name: 'Artist 8'},
            {id: 9, name: 'Artist 9'},
            {id: 10, name: 'Artist 10'}
        ],
        genres: [
            {id: 1, name: 'Genre 1'},
            {id: 2, name: 'Genre 2'},
            {id: 3, name: 'Genre 3'},
            {id: 4, name: 'Genre 4'},
            {id: 5, name: 'Genre 5'},
            {id: 6, name: 'Genre 6'},
            {id: 7, name: 'Genre 7'},
            {id: 8, name: 'Genre 8'},
            {id: 9, name: 'Genre 9'},
            {id: 10, name: 'Genre 10'}
        ]
    })
}
</script>

<style lang="scss" scoped>
.class-preferences.md-card  {
    color: $color-grey;
    text-align: left;
    padding: 2.4rem 3.2rem;
    display: flex;
    flex-wrap: wrap;

    h3 {
        width: 100%;
        margin: 0 0 1rem 0;
    }
    .top-list {
        list-style: none;
        width: 50%;

        li {
            padding: 0 1rem;
            border: 1px solid $color-grey;
            font-weight: 500;            
            
            &:nth-child(odd) {
                background: rgba($color-success, .8);
            }
            
            &:not(:first-child) {
                border-top: none;
            }

            span:first-child {
                font-weight: 300;
               margin-right: 1rem;
            }
        }

        &--artists {
            li {
                border-right: none;
            }
        }
    }
}
// overwriting default framework's styles with !important
.md-card {
    margin: 0 !important;

    @include respond(small) {
        margin-bottom: 2.4rem !important;
    }
}
</style>