import { render, staticRenderFns } from "./IntervalTypesDashboard.vue?vue&type=template&id=6f6772d3&scoped=true&"
import script from "./IntervalTypesDashboard.vue?vue&type=script&lang=js&"
export * from "./IntervalTypesDashboard.vue?vue&type=script&lang=js&"
import style0 from "./IntervalTypesDashboard.vue?vue&type=style&index=0&id=6f6772d3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f6772d3",
  null
  
)

export default component.exports