<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-dialog-title>Submit new landing page</md-dialog-title>
            
        <form class="dialog-form">

            <AppInputComponent
                required
                placeholder="Landing page name"
                v-model="$v.landingPage.name.$model"
                :error="$v.landingPage.name.$invalid && $v.landingPage.name.$dirty"
                errorText="Landing page is required"/>

            <AppInputComponent
                required
                placeholder="Description"
                v-model="$v.landingPage.description.$model"
                :error="$v.landingPage.description.$invalid && $v.landingPage.description.$dirty"
                errorText="Description is required"/>

            <AppInputComponent
                required
                placeholder="Goals"
                v-model="$v.landingPage.goals.$model"
                :error="$v.landingPage.goals.$invalid && $v.landingPage.goals.$dirty"
                errorText="Goals is required"/>

            <p class="md-body-1 dialog-text">Upload .zip archive with all landing page files
                (.html/.css/.js) and assets and it will be placed on your server.</p>

            <div class="dialog-form__file-input">
                <input
                    type="file"
                    hidden ref="inputFile"
                    accept=".zip,.rar,.7zip"
                    @change="onFileUpload($event)"
                    >
                
                <md-button
                    class="dialog__btn md-raised md-primary md-theme-light"
                    @click.native="$refs.inputFile.click()">Choose Zip Archive</md-button>
                    <p class="file-name md-body-1">{{fileName}}</p>
            </div>



            <md-dialog-actions>
                <md-button class="dialog__btn dialog__btn--danger" @click="closeDialog()">Cancel</md-button>
                <md-button class="md-primary md-raised dialog__btn md-theme-light"
                            :disabled="$v.landingPage.$invalid"
                            @click="onSubmit()">SUBMIT</md-button>
            </md-dialog-actions>
        </form>
</md-dialog>
</template>

<script>
import {required} from 'vuelidate/lib/validators';

export default {
    name: 'CreateLandingPageDialog',
    props: {
        landingPageToEdit: {
            type: Object,
            required: false,
            default: null
        },
        showDialog: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        landingPage: {
            name: '',
            description: '',
            goals: '',
            zip: null,
        },
        file: null
    }),
    validations: {
        landingPage: {
            name: {required},
            description: {required},
            goals: {required},
        }
    },
    computed: {
        fileName() {
            return this.file ? this.file.name : 'File Name'
        }
    },
    methods: {
        onSubmit() {
            this.$emit('landingPageCreated', this.landingPage);
            this.closeDialog();
        },
        closeDialog() {
            this.$emit('update:showDialog', false);
        },
        onFileUpload(e) {
            const input = event.target;
            this.file = input.files[0];
        }
    }
}
</script>

<style lang="scss" scoped>
@include dialogSettings();
::v-deep .md-field.select-input__field {
    padding-top: 0;
    margin-top: 0;
}
.dialog-form__file-input {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin: 1.6rem 0 2.4rem;

    .md-button {
        margin: 0;
    }

    & > :not(:last-child) {
        margin-right: 2.4rem;
    }

    .file-name {
        min-width: 140px;
        padding: 0 1rem 4px;
        border-bottom: 1px solid $color-white;
    }
}
.dialog-text,
.file-name {
    font-weight: 300;
    font-size: 14px;
}

</style>