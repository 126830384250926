<template>
    <div class="component-wrap md-elevation-1">
        <p class="md-body-1">{{message}}</p>
        <!-- Slot to pass an action btn or Empty state image -->
        <slot/>
    </div>
</template>

<script>
    export default {
        name: 'EmptyStateComponent',
        props: {
            message: {
                type: String,
                default: 'There are no data to display'
            }
        }
    }
</script>

<style lang="scss" scoped>
    .component-wrap {
        padding: 4rem;
        border: 3px solid rgba($color-white, .6);
        border-radius: 20px;
        text-align: center;
    }
    .md-body-1 {
        color: $color-white;
        font-size: 1.6rem;
    }
</style>