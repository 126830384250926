<template>
	<div v-if="isRegisterProp">
		<p class="auth-container__form-wrap__form-description">
			Want Reflex Radio for your workout? Let us know if you want us to contact your gym! Yes
		</p>

		<p class="auth-container__form-wrap__form-description">
			Please provide your gym’s information.
		</p>
		<form
			class="auth-form auth-form--with-subhead  md-layout md-alignment-center-center md-gutter"
			v-on:keyup.enter="register()"
		>
			<div class="md-layout-item md-size-60 md-medium-size-100">
				<AppInputComponent
					class="auth-form__field"
					required
					placeholder="First Name"
					v-model="$v.regForm.first_name.$model"
					:error="$v.regForm.first_name.$error && !$v.regForm.first_name.required"
					errorText="First Name is required"
				/>
			</div>

			<div class="md-layout-item md-size-60 md-medium-size-100">
				<AppInputComponent
					class="auth-form__field"
					required
					placeholder="Last Name"
					v-model="$v.regForm.last_name.$model"
					:error="$v.regForm.last_name.$error && !$v.regForm.last_name.required"
					errorText="Last Name is required"
				/>
			</div>

			<div class="md-layout-item md-size-60 md-medium-size-100">
				<AppInputComponent
					class="create-form__field"
					required
					placeholder="Email"
					v-model="$v.regForm.email.$model"
					:error="$v.regForm.email.$invalid && $v.regForm.email.$dirty"
					errorText="Valid email is required"
				/>
			</div>

			<div class="md-layout-item md-size-60 md-medium-size-100">
				<AppInputComponent
					class="auth-form__field"
					required
					placeholder="Gym Name"
					v-model="$v.regForm.store_name.$model"
					:error="$v.regForm.store_name.$error && !$v.regForm.store_name.required"
					errorText="Gym Name is required"
				/>
			</div>

			<div class="md-layout-item md-size-60 md-medium-size-100">
				<AppInputComponent
					class="auth-form__field"
					required
					placeholder="Gym City"
					v-model="$v.regForm.store_city.$model"
					:error="$v.regForm.store_city.$error && !$v.regForm.store_city.required"
					errorText="Gym City is required"
				/>
			</div>

			<div class="md-layout-item md-size-60 md-medium-size-100">
				<AppInputComponent
					class="create-form__field"
					controlType="select"
					required
					placeholder="Choose a Gym state"
					v-model="$v.regForm.store_state.$model"
					:error="$v.regForm.store_state.$error"
					errorText="Gym state is required">

					<option v-for="(state, index) in states" :key="index"
							:value="state.abbreviation">
						{{ state.name }}
					</option>

				</AppInputComponent>
			</div>

			<div class="md-layout-item md-size-60 md-medium-size-100">
				<AppInputComponent
					class="create-form__field"
					required
					placeholder="Contact Email"
					v-model="$v.regForm.contact_email.$model"
					:error="$v.regForm.contact_email.$invalid && $v.regForm.contact_email.$dirty"
					errorText="Valid contact email is required"
				/>
			</div>

			<div class="md-layout-item md-size-60 md-medium-size-100">
				<AppInputComponent
					class="auth-form__field"
					required
					placeholder="Contact phone number"
					v-model="$v.regForm.contact_phone_number.$model"
					:error="$v.regForm.contact_phone_number.$error && !$v.regForm.contact_phone_number.required"
					errorText="Contact phone number is required"
				/>
			</div>

			<div class="md-layout-item md-size-100"/>

			<md-button
				class="md-primary md-layout-item md-size-30 md-medium-size-100 md-raised back-button"
				@click="goBack()"
			>
				GO BACK
			</md-button>

			<md-button
				class="md-primary md-layout-item md-size-30 md-medium-size-100 md-raised auth-form__submit-btn md-theme-light"
				:disabled="$v.regForm.$invalid"
				@click="register()"
			>
				REGISTER
			</md-button>
		</form>
	</div>
</template>

<script>
	import {email, required} from "vuelidate/lib/validators";
	import {statesConstData} from '@/constants'

	export default {
		name: "AuthRegisterFormMemberWithoutStore",

		props: {
			isRegisterProp:{
				type: Boolean,
				default: false
			},
		},

		data: () => ({

			regForm: {
				first_name: null,
				last_name: null,
				email: null,
				store_name: null,
				store_city: null,
				store_state: null,
				contact_email: null,
				contact_phone_number: null
			},
			states: statesConstData,
		}),

		validations: {

			regForm: {
				first_name: {required},
				last_name: {required},
				email: {required, email},
				store_name: {required},
				store_city: {required},
				// store_state: {required},
				contact_email: {required, email},
				contact_phone_number: {required}
			},
		},

		methods: {

			register() {

				this.$emit('register', this.regForm)
			},

			goBack() {

				this.$emit('update:isRegisterProp', false);
			}
		},
	}
</script>

<style lang="scss" scoped>
	@include authPage;

</style>