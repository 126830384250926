<template>
  <div>
    <draggable  delay-on-touch-only='true' v-model="listSections" class="list-group" @start="drag = true" @end="drag = false" group="b" handle=".handle" :scroll="true" :scroll-sensitivity="200">

      <TrainerDashboardBluePrintTrackPlaylist ref="BluePrintTrackPlaylist"
        v-for="(interval, interval_index) in listSectionInterval" :id="'interval_' + interval.order"
        :trackData="interval" 
        :timeLineProp="interval.timeline"
        :genres-prop="genresProp"
        :content-rating="contentRating" 
        :index-prop="interval.order" :key="interval_index + 'track'"
        :intervalWithPlayIconIdProp.sync="intervalWithPlayIconIdProp" 
        :totalListIntervals="totalListIntervals"
        @refreshAllIntervals="refreshAllIntervals" @setIntervalIconToPlaying="setIntervalIconToPlaying"
        @clearAppleSearch="clearAppleSearch" />
    </draggable>
  </div>
</template>

<script>

import draggable from 'vuedraggable'
import TrainerDashboardBluePrintTrackPlaylist
  from "@/components/TrainerDashboard/TrainerDashboardBluePrintTrackPlaylist.vue";
import IntervalService from '../../services/interval.service';
import SongService from '@/services/song.service';
import PlaylistsService from '@/services/playlists.service'
import { MusicKitHelperMixin } from "../../mixins/MusicKitHelperMixin";
import { AppleSearchMixin } from "../../mixins/AppleSearchMixin";


let intervalflag;
export default {
  name: 'DraggableSectionIntervals',
  mixins: [MusicKitHelperMixin, AppleSearchMixin],

  components: {
    draggable,
    TrainerDashboardBluePrintTrackPlaylist
  },

  props: {
    genresProp: {
      type: [Array],
      default: () => []

    },

    timeLineProp: {
      type: String,
      default: null
    },

    intervalWithPlayIconIdProp: {
      type: Number,
      default: null,
    },

    indexProp: {
      type: Number,
      default: null,
    },
    contentRating: {
      type: Boolean,
      default: false
    },
    totalListIntervals: {
      type: [Array],
      default: () => []
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    sectionId: {
      type: Number,
      default: null,
    },
  },

  data: () => ({
    intervals: [],
    listSectionInterval: []
  }),

  computed: {


    listSections: {

      get() {

        return this.listSectionInterval;
      },

      async set(intervalList) {
        this.clearAppleSearch();
        let periodId = this.$route.params.id;
        for (let index = 0; index < intervalList.length; index++) {
          const interval = intervalList[index]
          if (interval.attributes) {
            const intervalSongId = intervalList[index + 1] ? intervalList[index + 1].interval_songs[0].id : intervalList[index - 1].interval_songs[0].id;
            const intervalIndex = intervalList[index + 1]? intervalList[index + 1].order : intervalList[index - 1].order;
            await this.appleSong(interval, intervalSongId, true,intervalIndex)
            return;
          }
        };
        intervalList.forEach((interval,index) => {
          interval.order = index;
        });

        IntervalService.reorderIntervals({ data: intervalList, id: periodId })
          .then(() => {
            this.closeAppleAudioPlayer();
            this.getIntervalsByPlaylist();
            this.$emit('refreshAllIntervals');

            this.$store.dispatch('showSnackbarMessage', {
              message: 'Playlist successfully reordered!',
              duration: 4000,
              mode: 'success'
            })
          })
          .catch(() => {

            this.$store.dispatch('showSnackbarMessage', {
              message: 'Failed. Please, try again.',
              duration: 5000,
              mode: 'fail'
            });
          });

        this.timeLineSeconds = 0;
        this.listSectionInterval = intervalList;
        this.$emit('refreshAllIntervals');

        this.$forceUpdate();


      }
    },
  },

  methods: {
    clearAppleSearch() {
      this.$emit('clearAppleSearch');

    },
    getIntervalsByPlaylist() {
      this.songsNumber = 0;
      let playlistId = this.$route.params.id;

      return PlaylistsService.getIntervalsByPlaylist(playlistId)
        .then(async (res) => {

          this.intervals = res;
          this.intervals.forEach((interval, index) => {
            interval.order = index;
            this.songsNumber += interval.interval_songs.length;
          });
          this.listSectionInterval = this.intervals.filter(interval => (interval.section_id == this.sectionId));
          this.$forceUpdate();

          this.loadingSpinner = false;
        });
    },
    refreshAllIntervals(val) {
      this.getIntervalsByPlaylist();
      this.$emit('refreshAllIntervals', val);
    },
    setIntervalIconToPlaying(val) {
      this.$emit('setIntervalIconToPlaying', val);

    },

  },

  async created() {
    this.intervals = this.totalListIntervals
    this.listSectionInterval = this.intervals.filter(interval => (interval.section_id == this.sectionId))
  },
  beforeDestroy() {
    if (this.isIntervalPlaying) {
      clearInterval(intervalflag);
      this.music.stop();
      this.music.clearQueue();
    }

  },


}
</script>

<style lang="scss" scoped>
.playing {
  background: #2F2F2F !important;
}
.replaced-item {
  background-color:var(--lavender);
}
</style>
