<template>
  <div>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">

      <md-dialog-content>
        <md-dialog-prompt>You need to link your apple account to get the best experience out of reflex radio
        </md-dialog-prompt>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="dialog__btn dialog__btn--danger"
                   @click=logOut>
          Log Out
        </md-button>
        <md-button class='md-primary dialog__btn md-raised md-theme-light'
                   @click='authorize'>
          Add Account
        </md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>

</template>

<script>
import UserStreamingService from "../services/user-streaming.service";

export default {
  name: "AppleMusic",
  props: {
    showDialog: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    showComplete: false,
  }),
  methods: {
    setPageName() {
      this.$store.dispatch('changePageName', {pageNameString: 'Apple Music'});
    },
    logOut() {
      this.$store.dispatch('auth/signOut').then(() => {

        this.$store.dispatch('audioPlayer/updateSongToPlayFromBluePrint', {song: null, name: null});
        this.$router.replace('/login');
      });
    },

    async authorize() {
      const music = await MusicKit.getInstance()

      try {
        await music.authorize();
        const data = {
          "user_id": this.$store.getters['auth/getCurrentUser'].id,
          "service_id": 1,
          "token": music.musicUserToken,
          "store_front": music.storefrontId
        }

        await UserStreamingService.authorize(data).then(() => {
          this.$store.dispatch('showSnackbarMessage', {
            message: 'Apple account is linked successfully.',
            duration: 8000,
            mode: 'success'
          });
          this.$router.push('/dashboard');

        }).catch(() => {
          this.$store.dispatch('showSnackbarMessage', {
            message: 'Failed. Please, try again.',
            duration: 8000,
            mode: 'fail'
          });
        });

      } catch (e) {
        console.log('error', e)
        await this.$store.dispatch('showSnackbarMessage', {
          message: `${e.message}. Please, try again.`,
          duration: 4000,
          mode: 'fail'
        });
      }
    },
  },

  created() {
    this.setPageName();
  }
}
</script>

<style lang="scss" scoped>
@include dialogSettings();
.md-dialog-container {
  transform: translate(0%, 0%) scale(1) !important;
}

.md-dialog {

  @include respond(small) {
    margin-top: 80px;
  }

  @media screen and (max-width: 980px) and (min-width: 700px) {
    margin-top: 80px;
  }

  /*width: auto !important;*/
  padding-left: 1.5em;
  padding-right: 1.5em;
  text-align: center;

  .plan-form {
    text-align: center;
  }

  .md-dialog-actions {
    justify-content: center;
  }

  .list-group {

    .current-subscriptions {

      @include respond(small) {
        margin-top: 30px;
      }

      @media screen and (max-width: 980px) and (min-width: 700px) {
        margin-top: 30px;
      }
    }
  }
}
</style>
