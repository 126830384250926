<template>
	<md-dialog
		:md-active.sync="showDialog"
		:md-click-outside-to-close="false"
		v-on:keyup.enter="submitBluePrint(false)">

		<md-dialog-title class="pick-bp-title"> Blueprint Template</md-dialog-title>
		<p class="emom-subHeaders">Add DJ Features</p>
		<p class="emom-subHeaders subhead-2">Please select the genere(s) you would like to prefil the playlist with
			(this can be changed once your blueprint has been created).</p>

		<div>
			<div class="md-layout">
				<div v-for="(genre, index_dur) in genres"
				     :id="'genre-id-' + genre.id"
				     :key="index_dur + '_genres'" class="md-layout-item md-size-12" style="text-align: center; " v-if="genre.visibility==1">

					<div class="md-layout-item md-size-6"> {{ genre.name }}</div>
					<md-checkbox class="md-layout-item md-size-10" v-model="selectedGenres" :value="genre.id"/>
				</div>
			</div>
			<div class="md-layout" v-bind:class="{'show-more-genres':showMore}">
				<div v-for="(genre, index_dur) in genres"
				     :id="'genre-id-' + genre.id"
				     :key="index_dur + '_genres'" class="md-layout-item md-size-15" style="text-align: center; " v-if="genre.visibility==2 && showMore==true">

					<div class="md-layout-item md-size-6"> {{ genre.name }}</div>
					<md-checkbox class="md-layout-item md-size-10" v-model="selectedGenres" :value="genre.id"/>
				</div>
			</div>
		</div>
		<div class="md-layout">

			<md-button
					class="md-primary dialog__btn md-raised md-theme-light"
					id="showMore-button-emmom"
					v-if="bluePrintProp"
					@click="updategenre()"
				>
					{{showMoreTxt}}
				</md-button>
		</div>
		<p class="emom-subHeaders third-subhead">Select sound effects to add to your mix</p>

		<div>
			<div class="md-layout">
				<div class="md-layout-item">
					<div class="share-with-trainers">
						<p class="auth-form__field__share sound-effect-label"> Do you want end of interval
							indicators/sound effects?
						</p>

						<toggle-button
							class="toggle-share-btn"
							id="end-of-interval-sound"
							v-model="isWithSoundOnIntervalChange"
							color="#a156a0"
							:sync="true"
							:width=85
							:font-size=17
							:height=35
							:labels="{checked: 'Yes', unchecked: 'No'}"
						/>
					</div>
				</div>

				<div class="md-layout-item select-field-indicator">
					<md-menu
						md-size="huge"
						md-align-trigger
						class="menu-to-style md-layout md-layout-item md-size-100 "
					>
						<md-button
							md-menu-trigger
							id="options-countdown-beeps"
							@click="goNextStepIfTourGuideActive"
							class="menu-to-style md-layout md-layout-item md-size-100 "
							:disabled="!isWithSoundOnIntervalChange"
						>

							<div v-if="beep" class="input-field-menu">{{ beepObj.name }}</div>
							<div v-else class="input-field-menu">Countdown beeps</div>

						</md-button>

						<md-menu-content class="menu-container  ">
							<md-menu-item
								v-for="(singleBeep, index) in coundownBeeps"
								:key="index + '_for_beeps'"
								:id="'countdown-beeps-value-' + index"
								@click="changeCountdownBeepSound(singleBeep)"
							>
								{{ singleBeep.name }}
							</md-menu-item>
						</md-menu-content>
					</md-menu>

					<div style="text-align: center;" v-if="isWithSoundOnIntervalChange && beep.length > 0">
						<div style="display: inline-block; margin-top: 10px;">Play the chosen sound</div>
						<md-button class="md-icon-button"
						           @click.stop="playInterval(beepObj)">
							<md-icon>play_arrow</md-icon>
						</md-button>
					</div>
				</div>
			</div>
			<div class="md-layout">
				<div class="md-layout-item">
					<div class="share-with-trainers">
						<p class="auth-form__field__share sound-effect-label">
							Do you want an end of workout sound effect?
						</p>

						<toggle-button
							class="toggle-share-btn"
							id="end-of-workout-sound"
							v-model="isWithSoundOnEndOfWorkout"
							color="#a156a0"
							:sync="true"
							:width=85
							:font-size=17
							:height=35
							:labels="{checked: 'Yes', unchecked: 'No'}"
						/>
					</div>
				</div>
				<div class="md-layout-item select-field-indicator">
					<md-menu
						md-size="huge"
						md-align-trigger
						class="menu-to-style md-layout md-layout-item md-size-100 "
					>
						<md-button
							md-menu-trigger
							id="options-mix-indicators-beeps"
							@click="goNextStepIfTourGuideActive"
							class="menu-to-style md-layout md-layout-item md-size-100 "
							:disabled="!isWithSoundOnEndOfWorkout"
						>

							<div v-if="chosenIndicator" class="input-field-menu">{{ chosenIndicatorObj.name }}</div>
							<div v-else class="input-field-menu">End of mix indicator</div>

						</md-button>

						<md-menu-content class="menu-container  ">
							<md-menu-item
								v-for="(indicator, index) in mixIndicators"
								:key="index + '_for_indicators'"
								:id="'mix-indicators-beeps-value-' + index"
								@click="changeEndOfMixIndicator(indicator)"
							>
								{{ indicator.name }}
							</md-menu-item>
						</md-menu-content>
					</md-menu>

					<div style="text-align: center;" v-if="isWithSoundOnEndOfWorkout && chosenIndicator.length > 0">
						<div style="display: inline-block; margin-top: 10px;">Play the chosen sound</div>
						<md-button class="md-icon-button"
						           @click.stop="playInterval(chosenIndicatorObj)">
							<md-icon>play_arrow</md-icon>
						</md-button>
					</div>
				</div>
			</div>
			<div class="md-layout">
				<div class="md-layout-item">
					<div class="share-with-trainers">
						<p class="auth-form__field__share sound-effect-label">
							Explicit Content ?
						</p>

						<toggle-button
							class="toggle-share-btn"
							id="explicit-content"
							v-model="allowExplicitContent"
							color="#a156a0"
							:sync="true"
							:width=85
							:font-size=17
							:height=35
							:labels="{checked: 'Yes', unchecked: 'No'}"
						/>
					</div>
				</div>
<!--				<div class="md-layout-item">-->
<!--					<div class="share-with-trainers">-->
<!--						<p class="auth-form__field__share sound-effect-label">-->
<!--							Reflex Radio Picks ?-->
<!--						</p>-->

<!--						<toggle-button-->
<!--							class="toggle-share-btn"-->
<!--							id="explicit-content"-->
<!--							v-model="chooseRRPicks"-->
<!--							color="#a156a0"-->
<!--							:sync="true"-->
<!--							:width=85-->
<!--							:font-size=17-->
<!--							:height=35-->
<!--							:labels="{checked: 'Yes', unchecked: 'No'}"-->
<!--						/>-->
<!--					</div>-->
<!--				</div>-->
			</div>


		</div>

		<md-dialog-actions>
			<md-button class="dialog__btn dialog__btn--danger"
			           id="back-button"
			           @click="goBackToPreviousScreen()">Back
			</md-button>

			<md-button class="dialog__btn dialog__btn--danger"
			           id="close-button"
			           @click="closeDialog(true)">Close
			</md-button>

			<md-button
				class="md-primary dialog__btn md-raised md-theme-light"
				id="submit-button-emmom"
				v-if="bluePrintProp"
				:disabled="selectedGenres.length === 0 || (chosenIndicator.length === 0 && isWithSoundOnEndOfWorkout) || (beep.length === 0 && isWithSoundOnIntervalChange)"
				@click="submitBluePrint(false)"
			>
				SUBMIT
			</md-button>

			<div class="audio-player" v-show="false">
				<AudioPlayer v-if="chosenIndicator.length > 0 || beep.length > 0"/>
			</div>
		</md-dialog-actions>
	</md-dialog>
</template>

<script>
	import {mapGetters} from "vuex";
	import GenreService from '@/services/genre.service'
	import PlaylistsService from '@/services/playlists.service'
	import $axios from "@/plugins/axios";
	import AudioPlayer from "@/components/AudioPlayer/AudioPlayer";

	export default {
		name: 'PreSelectGenresDialog',
		props: {
			showDialog: {
				type: Boolean,
				default: false
			},
			bluePrintProp: {
				name: "",
				image_url: '',
				is_shared: false,
				sets: [],
				type_id: 1
			},
      store_id: {
        type: String,
        default: 0
      }
		},

		components: {
			AudioPlayer,
		},

		data: () => ({
			chosenIndicatorObj: {},
			beepObj: {},
			chosenIndicator: '',
			beep: '',
			selectedGenres: [],
			coundownBeeps: [],
			mixIndicators: [],
			isWithSoundOnIntervalChange: false,
			isWithSoundOnEndOfWorkout: false,
			allowExplicitContent:false,
			genres: [],
			showMore:false,
			showMoreTxt:'Show More',
			chooseRRPicks:false,
		}),

		methods: {
			updategenre(){
				if (this.showMore) {
					this.showMore=false
					this.showMoreTxt = 'Show More'

				}
				else{
					this.showMore=true
					this.showMoreTxt = 'Show Less'
				}
			}
,
			goNextStepIfTourGuideActive() {

				setTimeout(() => {

					if (this.showTourGuide) {
						this.$nextTick(() => {

							this.$tours['myTour'].nextStep();
						})
					}
				}, 500);
			},

			changeCountdownBeepSound(sound) {

				this.beep = sound.id.toString();
				this.goNextStepIfTourGuideActive();
			},

			changeEndOfMixIndicator(sound) {

				this.chosenIndicator = sound.id.toString();
				this.goNextStepIfTourGuideActive();
			},
			async playInterval(intervalToPlay) {

				await this.$store.dispatch('audioPlayer/updatePlayerForIntervals',
					{
						url: null,
						name: null,
						artist: null,
						startSeconds: null,
						endSeconds: null,
						playlistId: null,
						playlistDurationInterval: null,
						autoplay: true,
						isIntervalPlaying: true
					});

				await this.$store.dispatch('audioPlayer/updateIntervalToPlayWithStartSeconds',
					{
						// url: 'https://test.reflexradio.app/upload/Siren_-_Trap_-_Ending.mp3',
						url: intervalToPlay.url,
						name: intervalToPlay.name,
						artist: 'Some artist',
						startSeconds: 0,
						endSeconds: 1000
					});
			},

			async fetchData() {

				await this.getAllIndicators();
				await this.getAllBeeps();
				await this.$store.dispatch('audioPlayer/updatePlayerForIntervals',
					{
						url: null,
						name: null,
						artist: null,
						startSeconds: null,
						endSeconds: null,
						playlistId: null,
						playlistDurationInterval: null,
						autoplay: true,
						isIntervalPlaying: true
					});
			},

			async getAllIndicators() {

				await $axios.get('/workout-indicators')
					.then((res) => {

						this.mixIndicators = res.data;
					})
					.catch(() => {

						this.$store.dispatch('showSnackbarMessage', {
							message: 'Failed. Please, try again.',
							duration: 8000,
							mode: 'fail'
						});
					});
			},

			async getAllBeeps() {

				await $axios.get('/countdown-beeps')
					.then((res) => {

						this.coundownBeeps = res.data;
					})
					.catch(() => {

						this.$store.dispatch('showSnackbarMessage', {
							message: 'Failed. Please, try again.',
							duration: 8000,
							mode: 'fail'
						});
					});
			},

			getAllGenres() {

				GenreService.getAllGenres()
					.then((res) => {

						this.genres = res;

						this.$nextTick(() => {

							this.goNextStepIfTourGuideActive();
						});
					})
					.catch(() => {

						this.$store.dispatch('showSnackbarMessage', {
							message: 'Failed. Please, try again.',
							duration: 8000,
							mode: 'fail'
						});
					});
			},

			goBackToPreviousScreen() {

				this.bluePrint.sets = [];

				let bpFromBack = {
					isBack: true,
					bpObj: this.bluePrint
				};

				this.$emit('goBack', bpFromBack);
				this.closeDialog(false);
			},

			closeDialog(deleteBPName) {
				if (deleteBPName) {
					this.bluePrint.name = '';
				}
				this.$emit('update:showDialog', false);
			},

			submitBluePrint(isEdit) {

				// if (this.showTourGuide) {
				//
				// 	this.$router.push('/playlists/256');
				// 	return;
				// }

//                if (this.$v.bluePrint.$invalid) {
//
//                    this.$store.dispatch('showSnackbarMessage', {
//                        message: 'You must fill all the fields to continue',
//                        duration: 4000,
//                        mode: 'fail'
//                    });
//                } else {

				let _this = this;
				let bluePrintObj = this.bluePrint;
				_this.selectedParentschilds();
				let url = '/templates';

				let bodyFormData = new FormData();

				if (bluePrintObj.type_id != 2) {
					console.log("bluePrintObj :",bluePrintObj)


					for (let i = 0; i < bluePrintObj.sets.length; i++) {

						let durationWorkTest = bluePrintObj.sets[i].duration.split(':')[0] + bluePrintObj.sets[i].duration.split(':')[1];

						if (/^0*$/.test(durationWorkTest)) {

							this.$store.dispatch('showSnackbarMessage', {
								message: "You can't send duration of 00:00.",
								duration: 4000,
								mode: 'fail'
							});

							return;
						}
						console.log('flex:', bluePrintObj.sets[i].flex);
						
						console.log('flex Type:', typeof bluePrintObj.sets[i].flex);
						bluePrintObj.sets[i].flex=(bluePrintObj.sets[i].flex).toString()
						let splittedFlex = bluePrintObj.sets[i].flex.split('-')[0];
						console.log('splittedFlex',splittedFlex)

						bluePrintObj.sets[i].flex = Number(splittedFlex);
					}
				} else {
					for (let i = 0; i < bluePrintObj.sets.length; i++) {
						
						bluePrintObj.sets[i].work.flex=(bluePrintObj.sets[i].work.flex).toString();
						bluePrintObj.sets[i].rest.flex = (bluePrintObj.sets[i].rest.flex).toString();
						let splittedFlex = bluePrintObj.sets[i].work.flex.split('-')[0];
						let splittedFlexRest = bluePrintObj.sets[i].rest.flex.split('-')[0];

						bluePrintObj.sets[i].work.flex = Number(splittedFlex);
						bluePrintObj.sets[i].rest.flex = Number(splittedFlexRest);
					}
				}

				if (this.beep.length > 0) {
					bodyFormData.set('countdown_beep_id', _this.beep);
				}

				if (this.chosenIndicator.length > 0) {
					bodyFormData.set('workout_indicator_id', _this.chosenIndicator);
				}

				bodyFormData.set('name', bluePrintObj.name);
				bodyFormData.set('is_shared', bluePrintObj.is_shared);
				bodyFormData.set('sets', JSON.stringify(bluePrintObj.sets));
				bodyFormData.set('countdown_beeps', bluePrintObj.countdown_beeps);
				bodyFormData.set('image_url', bluePrintObj.image_url);
				bodyFormData.set('type_id', bluePrintObj.type_id);
				bodyFormData.set('genres', JSON.stringify(_this.selectedGenres));
				bodyFormData.set('countdown_beeps', _this.isWithSoundOnIntervalChange);
				bodyFormData.set('workout_indicators', _this.isWithSoundOnEndOfWorkout);
				bodyFormData.set('allow_explicit_content', _this.allowExplicitContent);
				bodyFormData.set('chooseRRPicks', _this.chooseRRPicks);
        if (this.store_id) {
          bodyFormData.set('store_id', this.store_id);
        }

				if (this.showTourGuide) {

					bodyFormData.set('is_tour_guide', '1');
				}

				if (isEdit == true) {

					url += '/' + this.bluePrint.id;
				}

				this.$axios({
					method: 'post',
					url: url,
					data: bodyFormData,
					headers: {'Content-Type': 'multipart/form-data'}
				})
					.then(function (response) {
						// console.log(response);
						_this.$emit('refreshAllBluePrints');

						_this.$store.dispatch('showSnackbarMessage', {
							message: 'Saved',
							duration: 4000,
							mode: 'success'
						});

						PlaylistsService.getPlaylistsForBluePrint(response.data.id)
							.then((res) => {

								_this.$router.push('/playlists/' + res[0].id);
							})
							.catch((err) => {
								_this.$store.dispatch('showSnackbarMessage', {
									message: `${err.response.data}.`,
									mode: 'fail',
								});
							});
						_this.selectedGenres=[];

					})
					.catch(function (error) {
						_this.selectedGenres=[];


						_this.$store.dispatch('showSnackbarMessage', {
							message: error.response.data,
							duration: 4000,
							mode: 'fail'
						});
					});
//                }
			},
			selectedParentschilds(){
				this.selectedGenres.forEach(selectedGenre => {
					this.genres.filter(genre=>{
						if(genre.parent_id==selectedGenre){
							this.selectedGenres.push(genre.id)
						}
					})
				});
				console.log("this.selectedGenres ==>",this.selectedGenres)
			}
		},

		computed: {

			...mapGetters(
				{
					userRole: 'auth/currentUserRole',
					showTourGuide: 'getIsTourGuideActive',
				},
			),
		},

		watch: {

			beep(value) {

				if (value.length > 0) {
					this.beepObj = this.coundownBeeps.find(x => x.id == value);
				}
			},

			chosenIndicator(value) {

				if (value.length > 0) {
					this.chosenIndicatorObj = this.mixIndicators.find(x => x.id == value);
				}
			},
			chooseRRPicks(val){
				if(val){
					this.choosenRRPerc = 70;
					this.chosenPopularityPerc = 15;
				}
				else{
					this.choosenRRPerc = 0;
					this.chosenPopularityPerc = 60;
				}
				console.log("chooseRRPicks ==>",this.choosenRRPerc,this.chosenPopularityPerc)
			},
			isWithSoundOnIntervalChange(value) {

				if (!value) {
					this.beep = '';
				}

				this.goNextStepIfTourGuideActive();
			},

			isWithSoundOnEndOfWorkout(value) {

				if (!value) {
					this.chosenIndicator = '';
				}

				this.goNextStepIfTourGuideActive();
			},

			selectedGenres(value) {

				this.goNextStepIfTourGuideActive();
			},

		},

		created() {

			this.fetchData();

			this.getAllGenres();
			this.bluePrint = this.bluePrintProp;

		},
	}
</script>

<style lang="scss" scoped>
	@include dialogSettings();
	.dialog__hint {
		margin-bottom: 16px;
	}

	.choose-file-btn {
		width: 30%;
		margin-bottom: 30px;
	}

	.upload-cont {
		text-align: center;
	}

	.file-upload-label {
		font-size: 2rem;
	}

	.visible-label {
		margin-bottom: 10px;
		font-size: 1.8rem;
	}

	.input-file {
		display: none;
	}

	.toggle-btn {
		margin-top: 50px;
	}

	.auth-form {
		&__field {
			&__share, &__sample {
				padding-top: 20px;
				padding-bottom: 5px;
				font-size: 20px;
			}
		}
	}

	.pick-bp-title {
		text-align: center;
		font-size: 3rem;
	}

	::v-deep .pick-bp-name input::placeholder {
		color: #BEBEC8 !important;
		font-weight: 500;
	}

	::v-deep .pick-bp-name input {
		padding-left: 5px;
		background-color: transparent !important;
		border: none !important;
		border-bottom: 1px solid white !important;
		font-size: 1.7rem;
		color: white !important;

		&:active {
			border: none;
			background-color: transparent;
			border-bottom: 1px solid white;
		}

		&:focus {
			background-color: transparent;
			border: none;
			border-bottom: 1px solid white;
		}
	}

	.pick-bp-name {
		padding-bottom: 10px !important;
	}

	::v-deep .pick-bp-tags input::placeholder {
		font-size: 1.3rem !important;
	}

	.share-with-trainers {
		margin-top: 10px;
		text-align: center;
		margin-bottom: 40px;
	}

	.toggle-share-btn {
		margin-top: 10px;
	}

	::v-deep .toggle-share-btn .v-switch-core {
		width: 85px !important;
	}

	.emom-subHeaders {
		text-align: center;
		font-size: 2rem;
		margin-bottom: 30px;
	}

	.bp-inputs-container {
		text-align: center;
		margin-bottom: 20px;

		.left-labels {
			display: inline-block;
			width: 5em;
			margin-left: 1rem;
			font-size: 1.6rem;
			vertical-align: bottom;
		}

		.mask-field {
			color: white;
			text-align: center;
			font-size: 2rem;
			width: 12%;
			height: 45px;
			background-color: transparent;
			border: 2px solid #A156A0;
			border-radius: 7px;
		}

		.second-masks {
			width: 70%;
		}
	}

	::v-deep #add-set-btn {
		position: relative !important;
		width: 30px;
		bottom: 0 !important;
		right: 0 !important;
		height: 30px;
	}

	::v-deep #remove-set-btn {
		position: relative !important;
		width: 30px;
		bottom: 0 !important;
		right: 0 !important;
		height: 30px;
	}

	#back-button {
		margin-right: auto;
	}

	.mask-field {

		@include respond(medium) {
			width: 10em;
			margin-top: 10px;
			margin-bottom: 10px;
		}
	}

	.menu-container {
		z-index: 100 !important;
	}

	.menu-to-style {

		::v-deep .md-ripple {
			border: 2px solid #A156A0 !important;
			border-radius: 6px !important;
		}
	}

	::v-deep .v-tour__target--highlighted {
		box-shadow: 0 0 0 99999px rgba(0, 0, 0, .7) !important;
	}

	.input-text-field-free {

		padding-bottom: 0 !important;

		::v-deep input {
			background-color: transparent !important;
			width: 90px;
			height: 40px;
			color: white !important;
		}
	}

	.subhead-2 {
		font-size: 1.3rem;
	}

	.third-subhead {
		margin-top: 40px;
	}

	.sound-effect-label {
		font-size: 1.4rem;
	}

	.select-field-indicator {
		margin-top: 30px;
	}

	.invite-form__field {

		margin-left: 20px;

		::v-deep select {
			background-color: transparent !important;
			border-color: #A156A0 !important;
			color: white !important;
		}

		::v-deep option {
			background-color: #3B3B58 !important;
			margin-top: 5px !important;
		}
	}
	.show-more-genres{
		border-top: 3px solid #A156A0;
		margin-top: 20px;
		padding-top:20px ;
		border-radius: 20px;
	}

	::v-deep .md-list-item-container {
		font-size: 13px !important;
	}

</style>
