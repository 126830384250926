const state = () => ({
    songs: []
});

const getters = {
    getSongs(state) {
        return state.songs;
    }
};

const mutations = {
    setSongs(state, songs) {
        state.v = songs;
    }
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}