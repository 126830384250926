<template>
    <div class="component-wrap">

        <AppHeaderComponent headline="Rooms" class="content-header__classes">
            <div class="content-header__actions">

                <md-button
                        v-if="userRole < 5"
                        class="md-raised md-primary md-raised md-theme-light"
                        id="add-button"
                        @click="openCreateRoomModal()">New Room</md-button>
            </div>
        </AppHeaderComponent>

		<AppSpinnerComponent v-if="loadingSpinner" :loading="loadingSpinner"/>

		<AppEmptyStateComponent message="There are no rooms added yet."
								v-if="!rooms.length && !loadingSpinner"/>

        <div class="rooms">

            <div class="room md-raised md-layout"
                 v-for="(room, index) in rooms"
                 :key="index + '_singleClass'">

                <p class="room__data md-layout-item">
                    {{room.name}}
                </p>

                <p class="room__data md-layout-item">
                    <span class="room__data__room-label">Open at: </span>{{room.open_at}}
                </p>

                <p class="room__data md-layout-item">
                    <span class="room__data__room-label">Close at: </span>{{room.close_at}}
                </p>

                <p class="room__data md-layout-item">
                    <span class="room__data__room-label ">In Store: </span>{{room.store.name}}
                </p>

                <div class="md-layout-item  md-medium-size-100 md-xsmall-size-100 ">
                    <div class="md-layout md-layout-item">
                        <div class="room__remove-btn"
                             id="edit-button"
                             v-if="userRole < 5"
                             @click.stop="openEditRoomModal(room.id)">
                            <md-icon>edit</md-icon>
                        </div>

                        <div class="room__remove-btn"
                             id="remove-button"
                             v-if="userRole < 5"
                             @click.stop="removeRoom(room.id)">
                            <md-icon>delete_forever</md-icon>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <EditRoomDialog :showDialogEditRoom.sync="showDialogEditRoom" @addTheNewRoom="getAllCurrentRooms" :key="dialogKeyEditRoom" :room="room" :stores="stores"/>
        <CreateRoomDialog @addTheNewRoom="getAllCurrentRooms" :showDialogRoom.sync="showDialogRoom" :key="dialogKeyRoom"/>

    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import EditRoomDialog from "../../components/Rooms/EditRoomDialog.vue"
    import CreateRoomDialog from "../../components/Rooms/CreateRoomDialog.vue"
    import {DeepFilterMixin} from '@/mixins/DeepFilterMixin';
    import StoreService from '../../services/store.service';
    import RoomService from '../../services/room.service';

    export default {
        name: 'RoomsDashboard',
        mixins: [DeepFilterMixin],

        data: () => ({
            rooms: [],
            loadingSpinner: true,
            showDialogRoom: false,
            dialogKeyRoom: 0,
            showDialogEditRoom: false,
            dialogKeyEditRoom: 100,
            room: {},
            stores: []
        }),

        components: {
            CreateRoomDialog,
            EditRoomDialog,
        },

        methods: {

            goNextStepIfTourGuideActive() {

                this.$nextTick(() => {

                    this.$tours['myTour'].nextStep();
                })
            },

            getAllStores(){

                return StoreService.getStores()
                    .then((res) => {

                        this.stores = res;
                    })
                    .catch(() => {
                        this.$store.dispatch('showSnackbarMessage', {message: 'Failed. Please, try again.', duration: 8000, mode: 'fail'});
                    });
            },

            getCurrentRoom(editId){

                RoomService.getSingleRoom(editId)
                    .then((res) => {

                        let roomObj = res;

                        roomObj.open_at = this.convertTimeFormatEdit(roomObj.open_at);
                        roomObj.close_at = this.convertTimeFormatEdit(roomObj.close_at);

                        this.room = roomObj;

                    })
                    .catch(() => {

                        this.$store.dispatch('showSnackbarMessage', {message: 'Failed Please, try again.', duration: 8000, mode: 'fail'});
                    });
            },

            setPageName() {

                this.$store.dispatch('changePageName', { pageNameString: 'Rooms'});
            },

            goToSeeDetails(id) {

                this.$router.push('/rooms/'  + id);
            },

            openCreateRoomModal() {

                this.dialogKeyRoom++;
                this.showDialogRoom = true;

                if (this.showTourGuide) {

                    this.goNextStepIfTourGuideActive();
                }
            },

            openEditRoomModal(roomId) {

                this.getCurrentRoom(roomId);
                this.getAllStores();
                this.dialogKeyEditRoom++;
                this.showDialogEditRoom = true;
            },

            removeRoom(roomId) {

                RoomService.deleteRoom(roomId)
                    .then(() => {

                        this.getAllCurrentRooms();

                        this.$store.dispatch('showSnackbarMessage', {
                            message: 'The room was successfully deleted.',
                            duration: 4000,
                            mode: 'success'
                        });
                    })
                    .catch((err) => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: `${err.message}. Please, try again.`,
                            duration: 4000,
                            mode: 'fail'
                        });
                    });
            },

            getAllCurrentRooms() {

                RoomService.getRooms()
                    .then(res => {

                        this.rooms = res;

                        for (let i = 0; i < this.rooms.length; i++) {

                            this.rooms[i].open_at = this.convertTimeFormat(this.rooms[i].open_at);
                            this.rooms[i].close_at = this.convertTimeFormat(this.rooms[i].close_at);
                        }
                        this.loadingSpinner = false;
                    })
                    .catch(() => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: 'Failed. Please, try again.',
                            duration: 8000,
                            mode: 'fail'
                        });
                    });
            },
        },

         computed: {
            ...mapGetters(
                {
                    userRole: 'auth/currentUserRole',
                    showTourGuide: 'getIsTourGuideActive'
                },
            ),
        },
      
        created() {

            this.setPageName();
            this.getAllCurrentRooms();
        },

        mounted() {

            if (this.showTourGuide) {

                this.goNextStepIfTourGuideActive();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .content-header.content-header__stores {
        margin-bottom: 40px;
    }

    .input-field.input-field__stores-filter {
        padding-bottom: 0;
    }

    @include tableStyle(rooms, room);

    .rooms {

        .room {

            &__data {
                min-width: 20%;
                padding-left: 3.2rem;
                line-height: 57px;

                &__room-label {
                    color: #939BB4;
                }

            }

        }
    }
</style>