<template>
  <div>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
      <md-dialog-title class="figtree-medium-white-20px">{{ doWeEdit ? 'Edit' : 'New'}} Playlist</md-dialog-title>

      <md-tabs md-dynamic-height>
        <md-tab md-label="General">

          <md-field class="input">
            <label class="label figtree-normal-white-12px">Playlist Name</label>
            <md-input class="figtree-normal-white-16px-2" v-model="playlist.name"></md-input>
          </md-field>

          <textarea v-model="playlist.description" class="text-area" placeholder="Add an optional description">
                  </textarea>

          <div class="blueprint-color-container">
            <div class="color-label">
              <div class="blueprint-color figtree-medium-white-16px">Playlist Color</div>
            </div>
            <div class="colors">
              <div class="colors-item-1">
                <div class="rounded-div frame-11" @click="parent($event)"></div>
                <div class="rounded-div frame-12" @click="parent($event)"></div>
                <div class="rounded-div frame-13" @click="parent($event)"></div>
                <div class="rounded-div frame-14" @click="parent($event)"></div>
                <div class="rounded-div frame-15" @click="parent($event)"></div>
                <div class="rounded-div frame-16" @click="parent($event)"></div>
              </div>
              <div class="colors-item-2">
                <div class="rounded-div frame-21" @click="parent($event)"></div>
                <div class="rounded-div frame-22" @click="parent($event)"></div>
                <div class="rounded-div frame-23" @click="parent($event)"></div>
                <div class="rounded-div frame-24" @click="parent($event)"></div>
                <div class="rounded-div frame-25" @click="parent($event)"></div>
                <div class="rounded-div frame-26" @click="parent($event)"></div>
              </div>
            </div>

          </div>

          <md-dialog-actions>
            <md-button class="dialog__btn dialog__btn--danger"
                       @click="closeDialog(true)">Cancel
            </md-button>
            <md-button class="md-primary dialog__btn md-raised md-theme-light"
                       id="submit-button"
                       :disabled="playlist.name.trim().length === 0"
                       @click=" doWeEdit ? submitPlaylist() : goToNextTab(1)">{{ doWeEdit ? 'Save' : 'Next' }}
            </md-button>
          </md-dialog-actions>

        </md-tab>

        <md-tab v-if="!doWeEdit" md-label="Genre">
          <div class="container">

            <div class="genre-box" v-for="(genre, index_dur) in genres"
                 v-if="genre.visibility == 1" :id="'genre-id-' + genre.id"
                 @click="updateSelectedGenres(genre.id,'genre-id-' + genre.id)">
              <p class="figtree-medium-white-16px">{{ genre.name }}</p>
            </div>

            <div class="genre-box" v-for="(genre, index_dur) in genres"
                 v-if="genre.visibility == 2 && showMore == true" :id="'genre-id-' + genre.id"
                 @click="updateSelectedGenres(genre.id,'genre-id-' + genre.id)">
              <p class="figtree-medium-white-16px">{{ genre.name }}</p>
            </div>

            <div class="show-more-div figtree-normal-white-16px" @click="updateGenre"
                 v-bind:class="{ 'show-more-genres': showMore }">
              {{ showMoreTxt }}
            </div>

          </div>
          <div
              style="padding: 8px 0 8px 24px;display: flex;align-items: end;justify-content: flex-end;margin-right: -7px">
            <md-button class="dialog__btn dialog__btn--danger"
                       @click="closeDialog(true)">Cancel
            </md-button>
            <md-button class="md-primary dialog__btn md-raised md-theme-light"
                       :disabled="playlist.name.trim().length === 0 || selectedGenres.length === 0"
                       @click="goToNextTab(2)">Next
            </md-button>
          </div>

        </md-tab>

        <md-tab v-if="!doWeEdit" md-label="DJ Features">

          <p class="figtree-normal-white-16px">Select sound effects to add to your mix</p>

          <md-checkbox v-model="isWithSoundOnIntervalChange"/>
          <span class="figtree-normal-white-16px" style="vertical-align: super">
                  Include interval indicators/sound effects</span>


          <div class="dj-select">
            <div style="flex: 50">
              <md-field>
                <label>Countdown beeps</label>
                <md-select v-model="selectedCountdown" :disabled="!isWithSoundOnIntervalChange">
                  <md-option v-for="(singleBeep, index) in coundownBeeps"
                             :key="index" :value="singleBeep.id">
                    {{ singleBeep.name }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
            <div style="flex:50;text-align: center">
              <div v-if="selectedCountdown && isWithSoundOnIntervalChange">
                <div style="display: inline-block; margin-top: 10px;">Play</div>
                <md-button class="md-icon-button" @click.stop="playInterval(beepObj)">
                  <md-icon>play_arrow</md-icon>
                </md-button>
              </div>
            </div>
          </div>


          <md-checkbox v-model="isWithSoundOnEndOfWorkout"/>
          <span class="figtree-normal-white-16px" style="vertical-align: super">
                  Include end of workout sound effect</span>

          <div class="dj-select">
            <div style="flex: 50">
              <md-field>
                <label>End of Mix Indicator</label>
                <md-select v-model="selectedEndIndicator" :disabled="!isWithSoundOnEndOfWorkout">
                  <md-option v-for="(indicator, index) in mixIndicators"
                             :key="index" :value="indicator.id">
                    {{ indicator.name }}
                  </md-option>
                </md-select>
              </md-field>
            </div>

            <div style="flex:50;text-align: center;">
              <div v-if="selectedEndIndicator && isWithSoundOnEndOfWorkout">
                <div style="display: inline-block; margin-top: 10px;">Play</div>
                <md-button class="md-icon-button" @click.stop="playInterval(chosenIndicatorObj)">
                  <md-icon>play_arrow</md-icon>
                </md-button>
              </div>
            </div>
          </div>

          <div style="display: flex;align-items: center">
            <div style="flex: 50">
              <md-checkbox v-model="allowExplicitContent"/>
              <span class="figtree-normal-white-16px" style="vertical-align: super">Explicit Content</span>
            </div>
            <div style="flex: 50">
              <md-checkbox v-model="chooseRRPicks"/>
              <span class="figtree-normal-white-16px" style="vertical-align: super">Reflex Radio Picks</span>
            </div>
          </div>

          <div
              style="padding: 8px 0 8px 24px;display: flex;align-items: end;justify-content: flex-end;margin-right: -7px">
            <md-button class="dialog__btn dialog__btn--danger"
                       @click="closeDialog(true)">Cancel
            </md-button>

            <AppSpinnerComponentButton
                @click="submitPlaylist()"
                :is-disabled-prop = "playlist.name.trim().length === 0 || selectedGenres.length === 0 || buttonLoadingSpinner"
                :is-button-loading-spinner-prop = "buttonLoadingSpinner">
              Create Playlist
            </AppSpinnerComponentButton>

          </div>

        </md-tab>
        <div class="audio-player" v-show="false">
				<AudioPlayer v-if="selectedEndIndicator > 0 || selectedCountdown > 0"/>
			</div>
      </md-tabs>
    </md-dialog>

  </div>
</template>

<script>
import {required} from 'vuelidate/lib/validators';
import AppColorPicker from "@/components/Base/AppColorPicker";
import AppSpinnerComponentButton from "@/components/Base/AppSpinnerComponentButton";
import GenreService from '@/services/genre.service'
import $axios from "@/plugins/axios";
import PlaylistService from '@/services/playlists.service';
import AudioPlayer from "@/components/AudioPlayer/AudioPlayer";

export default {
  name: 'PlaylistDialog',
  components: {
    AppColorPicker,
    AppSpinnerComponentButton,
		AudioPlayer,
  },
  props: {
    doWeEdit: {
      type: Boolean,
      default: false
    },
    showDialog: {
      type: Boolean,
      default: false
    },
    playListProp: {
      name: '',
      is_shared: false,
    },

    modalTitleProp: {
      type: String,
      default: 'EDIT PLAYLIST TITLE OR SHARING'
    },

    isGeneratingMixProp: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    selectedCountdown: null,
    selectedEndIndicator: null,
    playlist: {
      name: '',
      is_shared: false,
      color: '',
      description: ""
    },
    chosenIndicatorObj: {},
    beepObj: {},
    chosenIndicator: '',
    beep: '',
    selectedGenres: [],
    coundownBeeps: [],
    mixIndicators: [],
    isWithSoundOnIntervalChange: false,
    isWithSoundOnEndOfWorkout: false,
    allowExplicitContent: false,
    chooseRRPicks: false,
    showMore: false,
    showMoreTxt: 'Show More',
    buttonLoadingSpinner: false,
    genres: [],

  }),
  validations: {
    playlist: {
      name: {required}
    }
  },
  methods: {

    async playInterval(intervalToPlay) {
      await this.$store.dispatch('audioPlayer/updatePlayerForIntervals',
          {
            url: null,
            name: null,
            artist: null,
            startSeconds: null,
            endSeconds: null,
            playlistId: null,
            playlistDurationInterval: null,
            autoplay: true,
            isIntervalPlaying: true
          });

      const music = MusicKit.getInstance();
      await music.stop();
      await music.clearQueue();

      await this.$store.dispatch('audioPlayer/updateIntervalToPlayWithStartSeconds',
          {
            url: intervalToPlay.url,
            name: intervalToPlay.name,
            artist: 'Some artist',
            startSeconds: 0,
            endSeconds: 1000
          });
    },

    goToNextTab(tab) {
      document.querySelectorAll(".md-tabs > div > button")[tab].click()
    },

    updateSelectedGenres(genreId, divId) {
      if (this.selectedGenres.indexOf(genreId) === -1) {
        this.selectedGenres.push(genreId);
        document.querySelector('#' + divId).classList.add("genre-chosen");
      } else {
        this.selectedGenres.splice(this.selectedGenres.indexOf(genreId), 1);
        document.querySelector('#' + divId).classList.remove("genre-chosen");
      }
    },

    parent(event) {

      document.querySelector('.colors-item-1').getElementsByTagName('div').forEach((element) => {
        const className = element.getAttribute('class').split(' ')[1];
        document.querySelector('.' + className).classList.remove("add-opacity");
      });

      document.querySelector('.colors-item-2').getElementsByTagName('div').forEach((element) => {
        const className = element.getAttribute('class').split(' ')[1];
        document.querySelector('.' + className).classList.remove("add-opacity");
      });

      const className = event.target.getAttribute('class').split(' ')[1];
      const element = document.querySelector('.' + className);
      element.classList.add("add-opacity");
      this.playlist.color = getComputedStyle(element).backgroundColor;

    },

    closeDialog() {

      this.$emit('update:showDialog', false);
    },
    getAllGenres() {

      GenreService.getAllGenres()
          .then((res) => {

            this.genres = res;

          })
          .catch(() => {

            this.$store.dispatch('showSnackbarMessage', {
              message: 'Failed. Please, try again.',
              duration: 8000,
              mode: 'fail'
            });
          });
    },
    async getAllIndicators() {

      await $axios.get('/workout-indicators')
          .then((res) => {

            this.mixIndicators = res.data;
          })
          .catch(() => {

            this.$store.dispatch('showSnackbarMessage', {
              message: 'Failed. Please, try again.',
              duration: 8000,
              mode: 'fail'
            });
          });
    },

    async getAllBeeps() {

      await $axios.get('/countdown-beeps')
          .then((res) => {

            this.coundownBeeps = res.data;
          })
          .catch(() => {

            this.$store.dispatch('showSnackbarMessage', {
              message: 'Failed. Please, try again.',
              duration: 8000,
              mode: 'fail'
            });
          });
    },
    updateGenre() {
      if (this.showMore) {
        this.showMore = false
        this.showMoreTxt = 'Show More'

      } else {
        this.showMore = true
        this.showMoreTxt = 'Show Less'
      }
    },
    changeCountdownBeepSound(sound) {
      this.beep = sound.id.toString();

    },

    changeEndOfMixIndicator(sound) {

      this.chosenIndicator = sound.id.toString();
    },
    addChildGenresToSelectedGenres() {
      this.selectedGenres.forEach(selectedGenre => {
        this.genres.filter(genre => {
          if (genre.parent_id == selectedGenre) {
            this.selectedGenres.push(genre.id)
          }
        })
      })
    },
    submitPlaylist() {
      this.addChildGenresToSelectedGenres();
      let playListObj = this.playlist;
      playListObj['template_id'] = this.$route.params.id;

      if (this.doWeEdit) {

        PlaylistService.updatePlaylist(playListObj)
            .then((res) => {

              if (this.isGeneratingMixProp) {

                this.$emit('generateMixFromPlaylistDialog', true)
              } else {

                this.$emit('refreshAllPlaylists');
              }

              this.closeDialog();

              this.$store.dispatch('showSnackbarMessage', {
                message: 'The playlist was successfully edited.',
                duration: 3000,
                mode: 'success'
              });
            })
            .catch((err) => {

              this.$store.dispatch('showSnackbarMessage', {
                message: `${err.message}. Please, try again.`,
                mode: 'fail'
              });
            });
      } else {
        this.buttonLoadingSpinner = true;
        if (this.selectedCountdown > 0) {
          playListObj['countdown_beep_id'] = this.selectedCountdown;
        }

        if (this.selectedEndIndicator > 0) {
          playListObj['workout_indicator_id'] = this.selectedEndIndicator;
        }

        playListObj['genres'] = JSON.stringify(this.selectedGenres)
        playListObj['countdown_beeps'] = this.isWithSoundOnIntervalChange
        playListObj['workout_indicators'] = this.isWithSoundOnEndOfWorkout
        playListObj['allow_explicit_content'] = this.allowExplicitContent
        playListObj['chooseRRPicks'] = this.chooseRRPicks
        PlaylistService.createPlaylist(playListObj)
            .then((res) => {

              this.buttonLoadingSpinner = false;
              this.$router.push('/playlists/' + res.id);

              this.closeDialog();

              this.$store.dispatch('showSnackbarMessage', {
                message: 'The playlist was successfully created.',
                duration: 3000,
                mode: 'success'
              });
            })
            .catch((err) => {

              this.$store.dispatch('showSnackbarMessage', {
                message: `${err.message}. Please, try again.`,
                mode: 'fail'
              });
              this.buttonLoadingSpinner = false;
            });
      }
    },

  },
  mounted() {
    if(this.doWeEdit) {
      document.querySelector('.colors-item-1').getElementsByTagName('div').forEach((element) => {
        const className = element.getAttribute('class').split(' ')[1];
        if (getComputedStyle(document.querySelector('.' + className)).backgroundColor === this.playlist.color) {
          document.querySelector('.' + className).classList.add("add-opacity");
        }
      });
      document.querySelector('.colors-item-2').getElementsByTagName('div').forEach((element) => {
        const className = element.getAttribute('class').split(' ')[1];
        if (getComputedStyle(document.querySelector('.' + className)).backgroundColor === this.playlist.color) {
          document.querySelector('.' + className).classList.add("add-opacity");
        }
      });
    }else{
      const element = document.querySelector('.frame-11');
      if(element) element.classList.add("add-opacity");
      this.playlist.color = 'rgb(95, 129, 9)';
    }
  },
  async created() {
    if (this.doWeEdit) {
      this.playlist = this.playListProp;
    }
    this.getAllGenres();
    await this.getAllIndicators();
    await this.getAllBeeps();
  },
  watch: {

    selectedCountdown(value) {
      if (value > 0) {
        this.beepObj = this.coundownBeeps.find(x => x.id == value);
      }
    },

    selectedEndIndicator(value) {

      if (value > 0) {
        this.chosenIndicatorObj = this.mixIndicators.find(x => x.id == value);
      }
    },
    chooseRRPicks(val) {
      if (val) {
        this.choosenRRPerc = 70;
        this.chosenPopularityPerc = 15;
      } else {
        this.choosenRRPerc = 0;
        this.chosenPopularityPerc = 60;
      }
    },
    isWithSoundOnIntervalChange(value) {

      if (!value) {
        this.beep = '';
      }

    },

    isWithSoundOnEndOfWorkout(value) {

      if (!value) {
        this.chosenIndicator = '';
      }

    },

  }
}
</script>

<style lang="scss" scoped>
@include dialogSettings();

.md-dialog .md-dialog-container {
  max-width: 768px;
}

.dialog__hint {
  margin-bottom: 16px;
}

.choose-file-btn {
  width: 30%;
  margin-bottom: 30px;
}

.upload-cont {
  text-align: center;
}

.file-upload-label {
  font-size: 2rem;
}

.visible-label {
  margin-bottom: 10px;
  font-size: 1.8rem;
}

.input-file {
  display: none;
}

.toggle-btn {
  margin-top: 50px;
}

.auth-form {
  &__field {

    &__share,
    &__sample,
    &__color {
      padding-top: 20px;
      padding-bottom: 5px;
      font-size: 20px;
    }

    &__color {
      height: 25vh;

      @include respond(small) {
        height: 40vh;
      }
    }
  }
}

.pick-bp-title {
  text-align: center;
}

::v-deep .pick-bp-name input::placeholder {
  color: #BEBEC8 !important;
  font-weight: 500;
}

::v-deep .pick-bp-name input {
  padding-left: 5px;
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px solid white !important;
  font-size: 1.7rem;
  color: white !important;

  &:active {
    border: none;
    background-color: transparent;
    border-bottom: 1px solid white;
  }

  &:focus {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid white;
  }
}

.pick-bp-name {
  padding-bottom: 10px !important;
}

::v-deep .pick-bp-tags input::placeholder {
  font-size: 1.3rem !important;
}

.share-with-trainers {
  margin-bottom: 30px;
}

.toggle-share-btn {
  margin-top: 10px;
}

::v-deep .toggle-share-btn .v-switch-core {
  width: 85px !important;
}

.bp-type-selected {
  background-color: white !important;
  color: #A156A0 !important;
}

::v-deep .v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, .7) !important;
}

.menu-to-style {

  ::v-deep .md-ripple {
    border: 2px solid #A156A0 !important;
    border-radius: 6px !important;
  }
}

.menu-container {
  z-index: 100 !important;
}

::v-deep .md-dialog-container {
  background-color: var(--heavy-metal) !important;
}

::v-deep .md-tabs .md-tabs-content {
  background-color: var(--heavy-metal) !important;
}

::v-deep .md-tabs.md-theme-default .md-tabs-navigation {
  background-color: var(--heavy-metal) !important;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--gray);
}

.text-area {
  align-items: flex-start;
  background-color: var(--onyx);
  border: 1px solid;
  border-color: var(--sonic-silver-22);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 130px;
  padding: 4px 7px;
  color: var(--white);
  font-family: var(--font-family-figtree);
  font-size: var(--font-size-s);
  font-weight: 500;
  font-style: normal;
  width: 100%;
  resize: none;
}

textarea::placeholder {
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  white-space: nowrap;
  color: var(--sonic-silver);
  font-family: var(--font-family-figtree);
  font-size: var(--font-size-s);
  font-weight: 500;
  font-style: normal;
}

.input {
  align-items: flex-start;
  background-color: var(--tuatara);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--silver-chalice);
  border-radius: 4px 4px 0 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 10px;
  position: relative;
}

.label {
  padding-top: 2px;
  padding-left: 10px;
  letter-spacing: 0.4px;
  line-height: 16px;
  margin-top: -1px;
  white-space: nowrap;
  width: fit-content;
}

.blueprint-color-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 11px;
  width: fit-content;
  margin-bottom: 10px;
}

.color-label {
  align-items: flex-start;
  display: flex;
  padding: 10px 0;
  width: fit-content;
}

.blueprint-color {
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  white-space: nowrap;
  width: fit-content;
}

.colors {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: fit-content;
}

.colors-item-1 {
  align-items: flex-start;
  display: flex;
  gap: 6px;
  width: fit-content;
}

.colors-item-2 {
  align-items: flex-start;
  display: flex;
  gap: 6px;
  width: fit-content;
}

.rounded-div {
  border-radius: 50px;
  height: 43px;
  min-width: 43px;
}

.frame-11 {
  background-color: var(--polished-pine);
}

.frame-12 {
  background-color: var(--eucalyptus);
}

.frame-13 {
  background-color: var(--vida-loca);
}

.frame-14 {
  background-color: var(--frostbite);
}

.frame-15 {
  background-color: var(--medium-red-violet);
}

.frame-16 {
  background-color: var(--medium-red-violet);
}

.frame-21 {
  background-color: var(--wedgewood);
}

.frame-22 {
  background-color: var(--blueberry);
}

.frame-23 {
  background-color: var(--sapphire);
}

.frame-24 {
  background-color: var(--razzmatazz);
}

.frame-25 {
  background-color: var(--grenadier);
}

.frame-26 {
  background-color: var(--pumpkin-skin);
}

.add-opacity {
  opacity: 0.5;
  border: 4px double #3a3a3a;
}

::v-deep .md-tabs-navigation .md-button {
  color: var(--mountain-mist);
  font-family: var(--font-family-figtree);
  font-size: var(--font-size-l);
  font-weight: 500;
  font-style: normal;
}

.md-tabs .md-tab {
  margin-bottom: 100px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
}

.genre-box {
  cursor: pointer;
  align-items: center;
  background-color: var(--tuatara);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 139px;
  justify-content: center;
  padding: 10px;
  position: relative;
  width: 140px;

}

.show-more-div {
  cursor: pointer;
  align-items: center;
  background-color: var(--tuatara);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 39px;
  justify-content: center;
  padding: 10px;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

::v-deep .md-tabs.md-theme-default .md-tabs-navigation .md-button.md-active {
  border-bottom: 1px solid var(--lavender);;
}

.genre-chosen {
  background-color: var(--lavender);
}

.dj-select {
  display: flex;
  align-items: center;
  // flex-direction: column;
  ::v-deep .md-field .md-input, .md-field .md-textarea {
    height: 48px!important
  }
}
</style>
