<template>
    <div class="control-field" @mouseleave="showOptions = false">
      
      <label v-if="hasLabelSlot()" class="control-field__label">
          <slot name="label"/>
      </label>

      
      <div
        tabindex="0"
        ref="multiSelectInput"
        class="control-field__multi-select"
        v-bind="$attrs"
        :class="selectClasses"
        @click.stop="showOptions = !showOptions">

        <p class="placeholder" v-if="!chosenOptions.length">
          {{$attrs.placeholder}}
        </p>
      
        <p v-if="chosenOptions.length" class="control-field__multi-select-value"> 
          <span v-for="(option, index) in chosenOptions" :key="index + 'chosenOption'">
            <slot name="chosenOption" :chosenOption="option"/>
          </span>
        </p>
      </div>


      <transition name="slide-fade">
        <ul class="control-field__options-list" v-if="showOptions">
          
          <template v-if="options.length">
            <li
              v-for="(option, index) in options"
              :key="index + 'option_to_choose'"
              class="control-field__option"
              :class="{'control-field__option--chosen': isChosen(option)}"
              @click.stop="toggleOption(option)">
                <slot name="option" v-bind:option="option"></slot>
            </li>
          </template>

          <li v-if="!options.length">No more options left</li>
        
        </ul>
      </transition>

      
      <transition name="slide">
          <p v-if="error" class="control-field__error">{{errorText ? errorText : 'Field is required'}}</p>
      </transition>


    </div>
</template>

<script>
export default {
  name: 'AppMultiSelectInput',
  props: {
    error: {
        type: [String, Boolean],
        default: ''
    },
    errorText: {
        type: String,
        default: ''
    },
    optionsData: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    valuePropertyName: {
      type: String,
      required: true
    }
  },
  data: () => ({
    showOptions: false,
  }),
  computed: {
    options: {
      get() {
        return this.optionsData;
      }
    },

    chosenOptions: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('update:value', newValue);
        return newValue;
      }
    },
    selectClasses() {
      return {
              'control-field__multi-select--invalid': this.error,
              'control-field__multi-select--active': this.showOptions,
              'control-field__multi-select--valid': this.chosenOptions.length
      }
    }

  },
  methods: {
      hasLabelSlot() {
          return !!this.$slots.label;
      },
      isChosen(option) {
        return this.chosenOptions.findIndex(x => x[this.valuePropertyName] === option[this.valuePropertyName]) !== -1;
      },
      toggleOption(option) {        
        const index = this.chosenOptions.findIndex(x => x[this.valuePropertyName] === option[this.valuePropertyName]);
        if (index === -1) {
          this.chosenOptions.push(option);
        } else {
          this.chosenOptions.splice(index, 1);
        }
      }
  }
}
</script>

<style scoped lang="scss">
.control-field {
  position: relative;
  display: flex;
  padding-bottom: 25px;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
.control-field__multi-select {
  background-color: #d8d8d8;
  border: solid 2px #f0f1f2;
  color: #8996a6;
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 1.85;
  padding-left: 24px;
  transition: all .4s ease-out, border 1ms;
  width: 100%;
  display: flex;
  align-items: center;
}
.control-field__multi-select-value {
  width: 100%;
  font-size: 1.6rem;

  span{
    padding: 2px 5px 2px 3px;
  }
}
.control-field__options-list {
  background-color: $color-white;
  list-style: none;
  width: auto;
  max-height: 140px;
  overflow-y: scroll;
  padding: 10px 15px;
  z-index: 30;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
  color: $color-black;
}
.control-field__option {
  font-size: 1.6rem;
  font-weight: 500;
  padding: 8px;
  margin-bottom: 8px;
  cursor: pointer;

  &:hover {
    background-color: $color-grey-light;
  }
  &--chosen {
    color: rgba($color-grey, .7);
    &:hover {
      background-color: rgba($color-danger, .2);
    }
  }
}
.control-field__multi-select--active,
.control-field__multi-select:active,
.control-field__multi-select:focus {
  background-color: #fff;
  color: #44566c;
  border-radius: 5px;
  outline: none;
}
.control-field__multi-select--valid {
  background-color: #fff;
  border: 3px solid $color-accent-purple;
  border-radius: 5px;
  color: #44566c;
}
.control-field__multi-select.control-field__multi-select--invalid {
  background-color: #fff;
  border: 2px solid $color-danger;
}
.control-field__multi-select--active,
.control-field__multi-select:focus {
  border: 3px solid $color-blue;
}
.control-field__multi-select {
  height: 48px;
}
.control-field__textarea {
  padding-top: 8px;
}
.control-field__multi-select {
  appearance: none;
  border-radius: 0;
}
.control-field__multi-select.placeholder {
  color: #8996a6;
}
.control-field__label {
  display: block;
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 16px;
  color: #4d5762;
}
.control-field__error {
  position: absolute;
  bottom: 7px;
  width: 100%;
  padding: 2px 0 2px 24px;
  background-color: $color-white;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.4rem;
  vertical-align: middle;
  color: $color-danger;
  text-align: left;
}
option {
  padding: 10px;
}
</style>