<template>
    <TheLayoutWrap>

        <div class="component-wrap">
            <h1 class="error-title">404</h1>

            <p class="error-text">Ooops! The requested page does not exist. We will redirect you in 10 seconds.</p>

            <p class="error-text">Please, press the button below to go to the home page.</p>

            <router-link tag="md-button" to="/" class="md-raised md-primary">Home dashboard</router-link>
        </div>

    </TheLayoutWrap>
</template>

<script>
    import TheLayoutWrap from "@/components/Layout/TheLayoutWrap.vue"

    export default {
        name: 'PageNotFound',
        components: {
            TheLayoutWrap
        },
        mounted() {
            setTimeout(() => this.$router.push('/'), 10000);
        }
    }
</script>

<style lang="scss" scoped>

    .component-wrap {
        margin: 70px auto;
        text-align: center;
    }
    .error-title {
        font-size: 24.4rem;
        line-height: 32rem;
        color: $color-grey-light;
        position: relative;
        text-shadow: -2px 0 rgba($color-primary, .7),
        0 2px rgba($color-primary, .7),
        2px 0 rgba($color-primary, .7),
        0 -2px rgba($color-primary, .7),
        20px -20px 0 rgba($color-grey-light, .9),
        40px -40px 0 rgba($color-grey-light, .7),
        60px -60px 0 rgba($color-grey-light, .5),
        80px -80px 0 rgba($color-grey-light, .3),
        100px -100px 0 rgba($color-grey-light, .1);
    }
    .error-text {
        font-size: 1.6rem;
        margin-bottom: 1.6rem;
    }
</style>