import $axios from '../plugins/axios'

class IntervalSongService {

    update(data, id) {

        return $axios.put(`/interval-songs/${id}`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }
}

export default new IntervalSongService();