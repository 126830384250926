<template>
	<md-dialog :md-active.sync="showCompleteProp" :md-click-outside-to-close="false">
		<AnimatedLogoPreloader></AnimatedLogoPreloader>

		<md-dialog-title class="md-layout md-alignment-center-center">
			Thank you for registering! <br>
			Check your email for <br>
			additional details. <br>
		</md-dialog-title>

		<div class=" md-layout md-alignment-center-center">
			<md-button
				class="md-primary md-raised md-layout-item md-size-60 md-xlarge-size-30 md-theme-light"
				id="close-complete-reg"
				@click="closeDialog"
			>
				CLOSE
			</md-button>
		</div>

	</md-dialog>
</template>

<script>
	import AnimatedLogoPreloader from '@/components/Common/AnimatedLogoPreloader';

	export default {
		name: "AuthRegistrationCompleteModal",
		components: {
			AnimatedLogoPreloader
		},

		props: {
			showCompleteProp: {
				type: Boolean,
				default: false
			},
		},

		methods: {

			closeDialog() {

				this.$router.push('/dashboard');
			},
		}
	}
</script>

<style lang="scss" scoped>

	.md-dialog-title {
		text-align: center;
		line-height: 80px;
		font-size: 4rem;
		font-weight: 700;

		@include respond(small) {
			font-size: 2rem;
			line-height: 30px;
		}
	}
</style>