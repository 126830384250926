<template>
     <md-button
        v-bind="$attrs"
        class="md-fab md-primary md-theme-light float-btn">
            <md-tooltip class="md-theme-light" md-direction="left">{{hintText}}</md-tooltip>
            <md-icon>{{icon}}</md-icon>
      </md-button>
</template>

<script>
export default {
    name: 'AppFloatFabButton',
    props: {
        icon: {
            type: String,
            default: 'add'
        },
        hintText: {
            type: String,
            default: ''
        }
    },
    data: () => ({

    })
}
</script>

<style lang="scss" scoped>
.float-btn {
    position: fixed;
    bottom: 4rem;
    right: 4rem;
    transition: all .2s ease-in-out;

    &:hover,
    &:focus {
        transform: translateY(-10px);
        box-shadow: 0 0 2px 4px rgba(255, 255, 255, .4);
    }
}
</style>