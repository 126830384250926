<template>
    <div class="component-wrap">
        <AppHeaderComponent headline="Genres" class="content-header__classes">
            <div class="md-layout content-header__actions">
                <md-button
                    class="md-layout-item md-raised md-primary md-raised md-theme-light addBtn md-size-100"
                    @click="openAddGenreModal()"
                >
                    Add New Genre
                </md-button>
                <div class="md-layout-item md-size-100">
                <AppInputComponent
                    class="create-form__field"
                    placeholder="Genre Name"
                    v-model="search"
                />
                </div>
            </div>
        </AppHeaderComponent>

        <AppSpinnerComponent
            v-if="loadingSpinner"
            :loading="loadingSpinner"
        />

        <md-tabs class="md-transparent" md-alignment="fixed" >
            <md-tab id="tab-unmatched" md-label="Unmatched">
                <AppEmptyStateComponent message="There are no genres added yet." v-if="showNoResults"/>
                <div class="genres">
                    <div
                        class="genre md-raised md-layout "
                        v-for="(genre, index) in unmatchedFilteredGenresSearch"
                        :key="index + '_singleClass'"
                        @click="openEditGenreModal(genre.id)"
                    >
                
                        <p class="genre__data md-layout-item md-size-80 md-xsmall-size-100">
                            {{ genre.name }}
                        </p>
                    
                        <div class="md-layout-item md-size-20 md-xsmall-size-100">
                            <div class="md-layout md-layout-item buttons">
                                <div class="genre__remove-btn"
                                     id="edit-button"
                                     @click.stop="openEditGenreModal(genre.id)">
                                    <md-icon>edit</md-icon>
                                </div>
                            
                                <div class="genre__remove-btn"
                                     @click.stop="removeGenre(genre.id)">
                                    <md-icon>delete_forever</md-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
               
                </md-tab>
            <md-tab id="tab-genres" md-label="Genres" >
                <AppEmptyStateComponent message="There are no genres added yet." v-if="filteredGenreSearch.length==0"/>
                <div class="genres">
                    <div
                        class="genre md-raised md-layout "
                        v-for="(genre, index) in filteredGenreSearch"
                        :key="index + '_singleClass'"
                        @click="openEditGenreModal(genre.id)"
                    >
                        <p class="genre__data md-layout-item md-size-80 md-xsmall-size-100">
                            {{ genre.name }}
                        </p>
                    
                        <div class="md-layout-item md-size-20 md-xsmall-size-100">
                            <div class="md-layout md-layout-item buttons">
                                <div class="genre__remove-btn"
                                     id="edit-button"
                                     @click.stop="openEditGenreModal(genre.id)">
                                    <md-icon>edit</md-icon>
                                </div>
                            
                                <div class="genre__remove-btn"
                                     @click.stop="removeGenre(genre.id)">
                                    <md-icon>delete_forever</md-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                
            </md-tab>
            <md-tab id="tab-pages" md-label="Common Genres" >
                <AppEmptyStateComponent message="There are no genres added yet." v-if="commonGenres.length==0"/>

                <div class="genres">
            <div
                class="genre md-raised md-layout "
                v-for="(genre, index) in commonGenres"
                :key="index + '_singleClass'"
                @click="openEditGenreModal(genre.id)"
            >
            <p v-if="genre.visibility==1" style="color:#0606a5" class="genre__data md-layout-item md-size-75 md-xsmall-size-100">
                {{ genre.name }}
            </p>
            <p v-else style="color:#88c988" class="genre__data md-layout-item md-size-75 md-xsmall-size-100">
                {{ genre.name }}
            </p>
           
                <div class="md-layout-item md-size-20 md-xsmall-size-100" style="padding-top: 10px;">
                    <div class="md-layout md-layout-item buttons">
                        <div class="genre__remove-btn"
                             id="edit-button"
                             @click.stop="openEditGenreModal(genre.id)">
                            <md-icon>edit</md-icon>
                        </div>

                        <div class="genre__remove-btn"
                             @click.stop="removeGenre(genre.id)">
                            <md-icon>delete_forever</md-icon>
                        </div>
                        <div class="genre__remove-btn"
                             @click.stop="openChildGenreModal(genre.id)">
                            <md-icon>list</md-icon>
                             
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>

        <GenresChildsModal
            @refreshAllGenres="getAllGenres"
            :show-modal.sync="showGenreChildModal"
            :genre-prop="genre"
            :childs-genre="childs"
            :key="dialogKeyCh"

        />
            </md-tab>
            <GenresFormModal
                        @refreshAllGenres="getAllGenres"
                        :show-modal.sync="showGenreFormModal"
                        :genre-prop="genre"
                        :all-genre="genres"
                        :key="dialogKey"
                    />
        </md-tabs>
    </div>
</template>

<script>
    import GenresFormModal from "../../components/Genres/GenresFormModal";
    import GenresChildsModal from "../../components/Genres/GenresChildsModal";
    import GenreService from "../../services/genre.service";

    export default {
        name: "GenresDashboard",

        components: {
            GenresFormModal,GenresChildsModal
        },

        data: () => ({
            genres: [],
            genre: {},
            commonGenres: [],
            childs: [],
            loadingSpinner: true,
            showNoResults: false,
            showGenreFormModal: false,

            showGenreChildModal: false,
            dialogKey: 0,
            dialogKeyCh: 0,
            search:'',
            intervalIdTimeout : null
        }),

        computed:{

            unmatchedFilteredGenres(){
                return this.genres.filter(genre=>genre.visibility==0&&!genre.parent_id);
            },
            unmatchedFilteredGenresSearch() {
                return this.unmatchedFilteredGenres.filter(genre => {
                    return genre.name.toLowerCase().includes(this.search.toLowerCase())
              })
            },
            filteredGenre(){
                return this.genres.filter(genre=>genre.visibility==0&&genre.parent_id);
            },
            filteredGenreSearch() {
                return this.filteredGenre.filter(genre => {
                    return genre.name.toLowerCase().includes(this.search.toLowerCase())
              })
            },
            
        },

        methods: {

            getCurrentGenre(id) {

                let genre = this.genres.find(x => x.id === id)
                this.genre = Object.assign({}, genre);
            },

            getAllGenres() {

                GenreService.getAllGenres()
                    .then((res) => {
                        this.commonGenres=res.filter(genre=>genre.visibility==1 || genre.visibility==2)
                        console.log('this.genres',this.genres)
                        
                        this.childs = res;
                        this.genres = res;
                        this.loadingSpinner = false;

                        if (this.genres.length === 0) {

                            this.showNoResults = true;
                        }
                    })
                    .catch(() => {

                        this.loadingSpinner = false;
                        this.$store.dispatch('showSnackbarMessage', {
                            message: 'Failed. Please, try again.',
                            duration: 8000,
                            mode: 'fail'
                        });
                    });
            },

            setPageName() {

                this.$store.dispatch('changePageName', {pageNameString: 'Genres'});
            },

            openAddGenreModal() {

                this.dialogKey++;
                this.genre = null;
                this.showGenreFormModal = true;
            },

            openEditGenreModal(id,num) {
                this.getCurrentGenre(id);
                this.dialogKey++;
                this.showGenreFormModal = true;
                
            },
            openChildGenreModal(id) {

                this.getCurrentGenre(id);
                this.dialogKeyCh++;
                this.showGenreChildModal = true;
            },

            removeGenre(genreId) {

                GenreService.deleteGenre(genreId)
                    .then(() => {

                        this.getAllGenres();

                        this.$store.dispatch('showSnackbarMessage', {
                            message: 'The genre was successfully deleted.',
                            duration: 4000,
                            mode: 'success'
                        });
                    })
                    .catch((err) => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: `${err.message}. Please, try again.`,
                            duration: 4000,
                            mode: 'fail'
                        });
                    });
            },
        },

        created() {

            this.setPageName();
            this.getAllGenres();

            //Refresh Genres every 2 min
            this.intervalIdTimeout = window.setInterval(() => {

                this.getAllGenres();
            }, 120000);
        },
        beforeDestroy() {
	        window.clearInterval(this.intervalIdTimeout);
    	}


    }
</script>

<style lang="scss" scoped>

    @media screen and (min-width: 640px) {
        .input-field.input-field--filter {
            margin-right: 2.4rem;
        }
    }

    .content-header.content-header__stores {
        margin-bottom: 40px;
    }

    .input-field.input-field__stores-filter {
        padding-bottom: 0;
    }

    @include tableStyle(genres, genre);

    .genres {
        .genre {

            @include respond(small) {
                flex-direction: row;
            }

            &__data {
                min-width: 15%;

                @include respond(medium) {
                    line-height: 55px;
                }

                @include respond(small) {
                    line-height: normal;
                }
            }

            @include respond(small) {
                &__remove-btn {
                    margin-left: 0;
                }
            }
        }
    }
</style>