<template>
    <div class="report-wrap">
        <AppHeaderComponent headline="Store Retention" class="content-header--mb" />
        
        <!-- Trial Mode Conversion -->
        <RepportLineGraph
            name="Store Retention"
            class="half-width"
            yAxisProperty="value"
            xAxisProperty="time"
            graphIndex="5"
            :graphData="graphData"/>
       
        <div class="stores" v-if="stores.length">
            
            <div
                class="store md-raised"
                v-for="(store, index) in stores"
                :key="index + '_store'"
                @click="goToSeeDetails(store.id)">

                <div class="store__image">
                    <img :src="store.image_url" alt="store-image"/>
                </div>
                
                <p class="store__data">
                    {{store.name}}
                </p>
                
                <p class="store__data store__data--grey">
                    {{store.email ? store.email : '---------'}}
                </p>
                
                <p class="store__data store__data--grey">
                    {{store.moreInfo}}
                </p>

            </div>

        </div>
  
    </div>
</template>

<script>
import RepportLineGraph from "@/components/Reports/ReportLineGraph"
import StoreService from '../../../services/store.service';

export default {
    name: 'AverageUsageByUser',
    components: {
        RepportLineGraph
    },
    data: () => ({
        stores: [],
        graphData: [
            {
                name: "Turnover",
                color: "#53a8e2",
                data: [
                    {
                        value: 40,
                        time: '01/01/2019'
                    },
                    {
                        value: 30,
                        time: '02/01/2019'
                    },
                    {
                        value: 45,
                        time: '03/01/2019'
                    },
                    {
                        value: 42,
                        time: '04/01/2019'
                    },
                    {
                        value: 37,
                        time: '05/01/2019'
                    },
                    {
                        value: 40,
                        time: '06/01/2019'
                    },
                    {
                        value: 45,
                        time: '07/01/2019'
                    },
                    {
                        value: 48,
                        time: '08/01/2019'
                    },
                    {
                        value: 46,
                        time: '09/01/2019'
                    },
                    {
                        value: 40,
                        time: '10/01/2019'
                    },
                    {
                        value: 48,
                        time: '11/01/2019'
                    },
                    {
                        value: 46,
                        time: '12/01/2019'
                    }
                ]
            },
            {
                name: "New",
                color: "#76ddfb",
                data: [
                    {
                        value: 20,
                        time: '01/01/2019'
                    },
                    {
                        value: 24,
                        time: '02/01/2019'
                    },
                    {
                        value: 22,
                        time: '03/01/2019'
                    },
                    {
                        value: 28,
                        time: '04/01/2019'
                    },
                    {
                        value: 35,
                        time: '05/01/2019'
                    },
                    {
                        value: 32,
                        time: '06/01/2019'
                    },
                    {
                        value: 35,
                        time: '07/01/2019'
                    },
                    {
                        value: 42,
                        time: '08/01/2019'
                    },
                    {
                        value: 45,
                        time: '09/01/2019'
                    },
                    {
                        value: 39,
                        time: '10/01/2019'
                    },
                    {
                        value: 42,
                        time: '11/01/2019'
                    },
                    {
                        value: 36,
                        time: '12/01/2019'
                    }
                ]
            },
            {
                name: "Existing",
                color: "#2c82be",
                data: [
                    {
                        value: 15,
                        time: '01/01/2019'
                    },
                    {
                        value: 10,
                        time: '02/01/2019'
                    },
                    {
                        value: 8,
                        time: '03/01/2019'
                    },
                    {
                        value: 9,
                        time: '04/01/2019'
                    },
                    {
                        value: 16,
                        time: '05/01/2019'
                    },
                    {
                        value: 10,
                        time: '06/01/2019'
                    },
                    {
                        value: 12,
                        time: '07/01/2019'
                    },
                    {
                        value: 16,
                        time: '08/01/2019'
                    },
                    {
                        value: 5,
                        time: '09/01/2019'
                    },
                    {
                        value: 10,
                        time: '10/01/2019'
                    },
                    {
                        value: 15,
                        time: '11/01/2019'
                    },
                    {
                        value: 16,
                        time: '12/01/2019'
                    }
                ]
            },
        ]
    }),
    methods: {

        getAllStores() {

            StoreService.getStores()
                .then(res => {

                    this.stores = res.map(x => {
                        return {
                            ...x,
                            moreInfo: x.city + ' ' + x.street
                        }
                    });
                })
                .catch(() => {

                    this.$store.dispatch('showSnackbarMessage', {
                        message: 'Failed. Please, try again.',
                        duration: 8000,
                        mode: 'fail'
                    });
                });
        },
    },
    created() {
        this.getAllStores();
    }
}
</script>

<style lang="scss" scoped>
    .report-wrap {
        padding-bottom: 300px;
    }

    .content-header.content-header--mb {
        margin-bottom: 40px;
    }

    @include appTableStyles;

    @include tableStyle(stores, store);

</style>