import $axios from '../plugins/axios'

class AccountService {

    getOne(accountId) {

        return $axios.get(`/accounts/${accountId}`)
            .then(res => {
                return res.data;
            })
            .catch((error) => {
                console.log(error)
                throw error;
            })
    }

    getOwner(accountId) {

        return $axios.get('/accounts/' + accountId)
            .then(res => {
                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getAccounts() {

        return $axios.get('/accounts')
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getAccountStores(accountId) {

        return $axios.get(`/accounts/${accountId}/stores`)
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                console.log(error)
            });
    }

    updateAccount(account){

        let bodyFormData = new FormData();

        let accountObj = account;

        let url = '/accounts/' + accountObj.id;

        bodyFormData.set('name', accountObj.name);
        bodyFormData.set('email', accountObj.email);
        bodyFormData.set('street', accountObj.street);
        bodyFormData.set('suite', accountObj.suite);
        bodyFormData.set('city', accountObj.city);
        bodyFormData.set('state', accountObj.state);
        bodyFormData.set('zip', accountObj.zip);
        bodyFormData.set('active', accountObj.active);
        bodyFormData.set('logo', accountObj.logo);
        bodyFormData.set('is_wtf_member', accountObj.is_wtf_member);

        return $axios({
            method: 'post',
            url: url,
            data: bodyFormData,
            headers: {'Content-Type': 'multipart/form-data'}
        })
            .then(function (res) {

                return res.data;

            })
            .catch(function (error) {
                console.log(error);
            });


    }

    deleteAccount(id) {

        return $axios.delete(`/accounts/${id}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }
}

export default new AccountService();