<template>
    <div class="component-wrap">
        <AppHeaderComponent headline="Account"/>

        <AccountForm v-if="account"
                  :account-prop.sync="account"
                  @createNewAccount="createNewAccount"
        />

    </div>
</template>

<script>
    import {DeepFilterMixin} from "@/mixins/DeepFilterMixin"
    import AccountForm from "../../components/Accounts/AccountForm";
    import AccountService from "../../services/account.service";

    export default {
        name: 'AccountPage',
        mixins: [DeepFilterMixin],
        data: () => ({
            account: null
        }),

        components: {
            AccountForm
        },

        methods: {

            setPageName(){

                this.$store.dispatch('changePageName', { pageNameString: 'Account'});
            },

            createNewAccount(account) {

                let bodyFormData = new FormData();

                let accountObj = account;

                let url = '/accounts/' + accountObj.id;

                bodyFormData.set('name', accountObj.name);
                bodyFormData.set('email', accountObj.email);
                bodyFormData.set('street', accountObj.street);
                bodyFormData.set('suite', accountObj.suite);
                bodyFormData.set('city', accountObj.city);
                bodyFormData.set('state', accountObj.state);
                bodyFormData.set('zip', accountObj.zip);
                bodyFormData.set('active', accountObj.active);
                bodyFormData.set('logo', accountObj.logo);

                let _this = this;

                this.$axios({
                    method: 'post',
                    url: url,
                    data: bodyFormData,
                    headers: {'Content-Type': 'multipart/form-data'}
                })
                    .then(function (response) {
                        console.log(response);

                        _this.$store.dispatch('showSnackbarMessage', {
                            message: 'You have successfully created a store.',
                            duration: 4000,
                            mode: 'success'
                        });

                    })
                    .catch(function (error) {
                        console.log(error);

                        _this.$store.dispatch('showSnackbarMessage', {message: "Something went wrong. Try again.", duration: 4000, mode: 'fail'});
                    });

            },

            getAccount() {

                if(this.$route.params.id) {

                    return AccountService.getOne(this.$route.params.id)
                        .then((data) => {

                            this.account = data;
                        })
                        .catch(() => {

                            this.$store.dispatch('showSnackbarMessage', {message: 'Failed. Please, try again.', duration: 8000, mode: 'fail'});
                        });
                } else {

                    let accountId = this.$store.getters['auth/getCurrentUser'].account_id;

                    return AccountService.getOwner(accountId)
                        .then((data) => {

                            this.account = data;
                        })
                        .catch(() => {

                            this.$store.dispatch('showSnackbarMessage', {message: 'Failed. Please, try again.', duration: 8000, mode: 'fail'});
                        });
                }
            },
        },

        created() {

            this.setPageName();
            this.getAccount();
        },
    }
</script>

<style lang="scss" scoped>
    .input-field.input-field--filter {
        padding-bottom: 0;
    }

</style>