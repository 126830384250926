<template>
    <div class="grid-wrapper">
        <slot/>
    </div>  
</template>

<script>
export default {
    name: 'AppContentGrid'
}
</script>

<style lang="scss" scoped>
.grid-wrapper .list-group{
    display: grid;
    grid-template-columns: repeat(auto-fit, 290px);
    grid-gap: 4rem;

    @include respond(small) {
        grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
        grid-gap: 2.4rem;
    }

}
</style>