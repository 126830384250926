import $axios from '../plugins/axios'

class AlbumService {

    getAllAlbums() {
        return $axios.get('/albums')
            .then(res => {
                return res.data;
            })
            .catch((error) => {
                console.log(error)
                throw error;
            })
    }
}

export default new AlbumService();
