
import PlaylistService from "../services/playlists.service"
export const playPlaylistIntervalMixin = {
    methods: {
        // async playPlaylistMix(id, playlistName) {
        //     let songsForMix = [];
		// 	let intervalsWithTimeline = null;
		// 	let appleIntervals = null;
		// 	let total_duration = null;
		// 	await PlaylistService.getIntervalsByPlaylist(id)
        //     .then((res) => {
        //         intervalsWithTimeline = res;
		// 			console.log(intervalsWithTimeline)
		// 		})
		// 	for (const interval of intervalsWithTimeline) {
        //         let timelineString = this.getTimeline(interval.duration);
		// 		interval.timeline = timelineString;
		// 	}
			
		// 	await PlaylistService.getPlaylistMixView(id)
        //     .then((res) => {
        //         appleIntervals=res;
        //         const map1 = new Map();
        //         let user_content_rating  = (res[0].allow_explicit_content==0)?'clean':'explicit';
        //         total_duration = this.convertHHMMSStoSeconds(res[0].total_duration)
        //         console.log(total_duration)
        //         res.forEach(value => {
        //             if(map1.has(value.song_id + '_' + value.sections_order + '_' + value.intervals_order + '_' + value.song_inInterval_Order)) {
        //                 if(map1.get(value.song_id + '_' + value.sections_order + '_' + value.intervals_order + '_' + value.song_inInterval_Order).content_rating === user_content_rating){
        //                 }
        //                 else{
        //                     map1.set(value.song_id + '_' + value.sections_order + '_' + value.intervals_order + '_' + value.song_inInterval_Order,value)
        //                 }
        //             }
        //             else{
        //                 map1.set(value.song_id + '_' + value.sections_order + '_' + value.intervals_order + '_' + value.song_inInterval_Order,value)
        //             }
		// 			})
		// 			appleIntervals= Array.from(map1, ([key, value]) => {
		// 			//   console.log(value);
		// 			return value;
        //         });
        //         // console.log('arrrr ==> ',appleIntervals)
        //         appleIntervals.map(el=>songsForMix.push(el.streaming_id.toString()));
        //         // console.log(songsForMix)
        //     })
        //     .catch((err) => {
        //         console.log(err)
        //         this.$store.dispatch('showSnackbarMessage', {
        //             message: `Mix was not found for this Blueprint`,
        //             mode: 'fail'
        //         });
        //     });
		// 	const music = MusicKit.getInstance();
		// 	await music.stop()
		// 	await music.clearQueue();
        //     await this.set(songsForMix)
		// 	// console.log("appleIntervals =>",appleIntervals)
		// 	await PlaylistService.getPlaylistMix(id)
        //     .then((res) => {
        //         this.$store.dispatch('audioPlayer/updatePlayerForIntervals', {
        //             url: res.url,
		// 				name: playlistName,
		// 				playlistId: id,
		// 				playlistDurationInterval: null,
		// 				startSeconds: null,
		// 				endSeconds: total_duration,
		// 				autoplay: false,
		// 				isIntervalPlaying: false,
		// 				intervals: intervalsWithTimeline,
		// 				appleIntervals :appleIntervals
		// 			});
		// 		})
		// 		.catch((err) => {
        //             this.$store.dispatch('showSnackbarMessage', {
        //                 message: `Mix was not found for this Blueprint`,
		// 				mode: 'fail'
		// 			});
		// 		});
        //         this.showDialog = false;
		// },
    	// async set(songsForMix) {
        //     const music = MusicKit.getInstance();
		//   	await music.mute()
    	//   	await music.setQueue({
        //           songs: songsForMix,
        //           startPlaying:true
        //         }
        //         );
        //         await music.pause();
        //         return;
        //     },
        async loadStreamingIdsForMix(id){
			this.songsForMix = [];
            let musicIntervals = null;
            await PlaylistService.getPlaylistMixView(id)
					.then((res) => {

						musicIntervals=res;
						const map1 = new Map();

						let user_content_rating  = (res[0].allow_explicit_content==0)?'clean':'explicit';
						// total_duration = this.convertHHMMSStoSeconds(res[0].total_duration)
						res.forEach(value => {
						    if(map1.has(value.song_id + '_' + value.sections_order + '_' + value.intervals_order + '_' + value.song_inInterval_Order)) {
						        if(map1.get(value.song_id + '_' + value.sections_order + '_' + value.intervals_order + '_' + value.song_inInterval_Order).content_rating === user_content_rating ){
						        }
						        else{
						            map1.set(value.song_id + '_' + value.sections_order + '_' + value.intervals_order + '_' + value.song_inInterval_Order,value)
						        }
						    }
						    else{
						        map1.set(value.song_id + '_' + value.sections_order + '_' + value.intervals_order + '_' + value.song_inInterval_Order,value)
						    }
						})

						musicIntervals= Array.from(map1, ([key, value]) => {
						return value;
						});
						musicIntervals.map(el=>this.songsForMix.push(el.streaming_id.toString()));
					})
					.catch((err) => {
						console.log(err)
						this.$store.dispatch('showSnackbarMessage', {
							message: `Mix was not found for this Blueprint`,
							mode: 'fail'
						});
					});
        }
        }
}
