<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-dialog-title v-if="!interval.hasOwnProperty('id')">Create Interval</md-dialog-title>
        <md-dialog-title v-if="interval.hasOwnProperty('id')">Edit Interval</md-dialog-title>

        <form class="dialog-form">
            <div class="md-layout">

<!--                <AppInputComponent-->
<!--                    class="auth-form__field intervalTypesSelect md-layout-item md-size-100"-->
<!--                    required-->
<!--                    v-if="addNewIntervalType"-->
<!--                    placeholder="Enter the new movement type"-->
<!--                    v-model="$v.interval.typeName.$model"-->
<!--                    :error="$v.interval.typeName.$invalid && $v.interval.typeName.$dirty"-->
<!--                    errorText="Movement type is required">-->

<!--                </AppInputComponent>-->

				<!--                        v-if="!addNewIntervalType"-->
                <AppInputComponent
                        class="auth-form__field intervalTypesSelect md-layout-item md-size-100 control-field"
                        controlType="select"
						id="movement-type-select"
                        required
                        placeholder="Choose movement type"
                        v-model="$v.interval.type_id.$model"
                        :error="$v.interval.type_id.$invalid && $v.interval.type_id.$dirty"
                        errorText="Movement type is required">
                    <option v-for="(type, index) in types" :key="index" :value="type.id">{{type.name}}</option>

                </AppInputComponent>

<!--                <md-button v-if="!addNewIntervalType" class="md-primary md-raised dialog__btn md-theme-light newTypeBtn" @click="addNewIntervalType = true;">Add new movement type</md-button>-->
<!--                <md-button v-if="addNewIntervalType" class="md-primary md-raised dialog__btn md-theme-light newTypeBtn" @click="addNewIntervalType = false;">Choose from existing movement types</md-button>-->

                <AppInputComponent
                        class="auth-form__field md-layout-item md-size-100"
                        controlType="select"
                        required
                        placeholder="Choose genre"
                        v-model="$v.interval.genre_id.$model"
                        :error="$v.interval.genre_id.$invalid && $v.interval.genre_id.$dirty"
                        errorText="Genre is required"
                >
                    <option v-for="(genre, index) in genres" :key="index" :value="genre.id">{{genre.name}}</option>

                </AppInputComponent>

                <AppTrackDurationInput
                    v-if="!isCustomTimeSelect"
                    :timeData.sync="interval.durationObj"
                    :showDurationOptionsProp.sync="showInputsChild.durationObj"
                    @showCustomSelectTime="showCustomSelectTime"
                    class="custom-input__large md-layout-item md-size-100"/>

                <div class="md-layout md-gutter"
                     v-if="isCustomTimeSelect">
                    <span class="md-layout-item md-size-50">    Minutes    </span>
                    <span class="md-layout-item md-size-50">    Seconds    </span>

                    <AppInputComponent
                            class="auth-form__field md-layout-item md-size-50"
                            required
                            type="number"
                            placeholder="Min"
                            v-model="$v.interval.durationObj.min.$model"
                            :error="$v.interval.durationObj.min.$invalid && $v.interval.durationObj.min.$dirty"
                            errorText="Minutes should be between [0 - 60]"
                    />

                    <AppInputComponent
                            class="auth-form__field md-layout-item md-size-50"
                            required
                            type="number"
                            placeholder="Sec"
                            v-model="$v.interval.durationObj.sec.$model"
                            :error="$v.interval.durationObj.sec.$invalid && $v.interval.durationObj.sec.$dirty"
                            errorText="Seconds should be between [0 - 60]"
                    />

                    <div class="md-layout-item md-size-100">
                        <md-button class="md-primary md-raised dialog__btn md-theme-light newTypeBtn"
                                   @click="isCustomTimeSelect = false;">
                            Choose time from dropdown
                        </md-button>
                    </div>
                </div>

                <AppSelectNumberEnergyInput
                        v-if="!isThisRest"
                        :energyData.sync="interval.energy"
                        :showDurationOptionsProp.sync="showInputsChild.energy"
                        class="custom-input__large md-layout-item md-size-100"/>

                <AppInputComponent
                        v-if="isThisRest"
                        class="auth-form__field md-layout-item md-size-100"
                        required
                        type="number"
                        min="1" max="5"
                        placeholder="Flex(Energy ( 1-5 )"
                        v-model="$v.interval.energy.$model"
                        :error="$v.interval.energy.$invalid && $v.interval.energy.$dirty"
                        errorText="Flex(Energy) is required"
                />


				<AppInputComponent
					class="auth-form__field md-layout-item md-size-100"
					controlType="select"
					required
					placeholder="Set loudness"
					v-model="$v.interval.loudness.$model"
					:error="$v.interval.loudness.$invalid && $v.interval.loudness.$dirty"
					errorText="Genre is required"
				>
					<option v-for="(loud, index) in [1,2,3,4,5,6,7,8,9,10]" :key="index" :value="loud">{{loud}}</option>

				</AppInputComponent>

				<AppInputComponent
					class="auth-form__field md-layout-item md-size-100"
					controlType="select"
					required
					placeholder="Choose mood"
					v-model="$v.interval.mood_id.$model"
					:error="$v.interval.mood_id.$invalid && $v.interval.mood_id.$dirty"
					errorText="Mood is required"
				>
					<option v-for="(mood, index) in moods" :key="index" :value="mood.id">{{mood.name}}</option>

				</AppInputComponent>

				<AppInputComponent
					class="auth-form__field md-layout-item md-size-100"
					controlType="select"
					required
					placeholder="Choose template"
					v-model="$v.interval.template_id.$model"
					:error="$v.interval.template_id.$invalid && $v.interval.template_id.$dirty"
					errorText="Template is required"
				>
					<option v-for="(template, index) in templates" :key="index" :value="template.id">{{template.name}}</option>

				</AppInputComponent>

<!--                <div class="error md-layout-item md-size-100" v-if="!$v.interval.energy.between">Energy value MUST be between 1 and <span v-if="isThisRest">5</span><span v-if="!isThisRest">10</span></div>-->

                <md-dialog-actions class="md-layout-item md-size-100">
                    <md-button class="dialog__btn dialog__btn--danger"
                               id="close-button"
                               @click="closeDialog()">Cancel</md-button>
                    <md-button class="md-primary md-raised dialog__btn md-theme-light"
                               id="submit-button"
                                :disabled="$v.interval.$invalid"
                                @click="onSubmit()">SUBMIT</md-button>
                </md-dialog-actions>
            </div>
        </form>
</md-dialog>
</template>

<script>
import {required, between, requiredIf} from 'vuelidate/lib/validators';
import IntervalService from "../../services/interval.service";
import GenreService from  "../../services/genre.service";
import $axios from "@/plugins/axios";
import BluePrintService from "@/services/bluePrint.service";

export default {
    name: 'TrainerDashboardTrackDialog',

    props: {
        showDialog: {
            type: Boolean,
            default: false
        },
        isThisRest: {
            type: Boolean,
            default: false
        },
        allIntervals: {
            type: Array,
            default: []
        },
        intervalProp: {
            type: Object,
            default: {
                type_id: '',
                // typeName: '',
                durationObj: {min: 0, sec: 0},
                energy: 0,
                genre_id: '',
                // period_id: '',
                order: '',
            }
        },
    },

    data: () => ({
        interval: {
            // typeName: '',
            type_id: '',
            durationObj: {min:0, sec: 0},
            energy: 0,
            genre_id: '',
            // mood_id: '',
            // period_id: '',
            order: '',
			loudness: '',
			mood_id: '',
			template_id: ''
        },
        addNewIntervalType: false,
        types: [],
        genres: [],
		templates: [],
		moods: [],
        // moods: [],
        showInputsChild: {
            bits: false,
            duration: false
        },
        isCustomTimeSelect: false,
        isCustomTEnergySelect: false
    }),

    validations: {
        interval: {
            type_id: {
                required: requiredIf(function(){
                    return !this.addNewIntervalType
                })},
            // typeName: {
            //     required: requiredIf(function(){
            //         return this.addNewIntervalType
            //     })},
            durationObj: {
                min: {
                    required,
                    between: between(0, 60)
                },
                sec: {
                    required,
                    between: between(0, 60)
                }
            },
			loudness: {required},
            genre_id: {required},
			template_id: {required},
			mood_id: {required},
            // mood_id: {required},
            energy: {
                required,
                between(value) {
                    return between(1, this.energyMaxLength)(value)
                }
            }
        }
    },

    computed: {

      energyMaxLength() {

          let valueToReturn = 0;

          if (this.isThisRest) {

              valueToReturn = 5;
          } else {

              valueToReturn = 10;
          }

          return valueToReturn;
      }
    },

    methods: {

        // allMoods(){
        //     this.$store.dispatch('moods/getAllMoods')
        //         .then((res) => {
        //             this.moods = res;
        //         })
        //         .catch(() => {
        //             this.$store.dispatch('showSnackbarMessage', {message: 'Failed. Please, try again.', duration: 8000, mode: 'fail'});
        //         });
        // },

        formatTime(number) {
            if (number / 10 < 1) {
                return '0' + number;
            }
            return number;
        },

        async allIntervalGenres() {

            await GenreService.getAllGenres()
                .then((res) => {

                    this.genres = res;
                })
                .catch(() => {

                    this.$store.dispatch('showSnackbarMessage', {message: 'Failed. Please, try again.', duration: 8000, mode: 'fail'});
                });
        },

		async getAllBlueprints(){

			// return this.$store.dispatch('bluePrints/getBluePrints')
			return BluePrintService.getBluePrints()
				.then((res) => {

					this.templates = res;
					// this.setLoadingSpinner(false);
					// this.bluePrints.push({});

				})
				.catch((err) => {

					this.$store.dispatch('showSnackbarMessage', {
						message: `${err.message}. Please, try again.`,
						mode: 'fail'
					});
				});

		},

		async allIntervalMoods() {

			await $axios.get('/moods')
				.then((res) => {

					this.moods = res.data;
				})
				.catch(() => {

					this.$store.dispatch('showSnackbarMessage', {message: 'Failed. Please, try again.', duration: 8000, mode: 'fail'});
				});
		},

        async allIntervalTypes() {

            await IntervalService.getAllIntervalTypes()
                .then((res) => {

                    this.types = res;
                })
                .catch(() => {

                    this.$store.dispatch('showSnackbarMessage', {message: 'Failed. Please, try again.', duration: 8000, mode: 'fail'});
                });
        },

        async onSubmit() {

            let intervalObj = this.interval;

            // intervalObj.period_id = this.$route.params.id;
            let durationMin = intervalObj.durationObj.min;
            let durationSec = intervalObj.durationObj.sec;

            if (durationSec.length === 1) {

                durationSec = durationSec + '0';
            }

            intervalObj['duration'] = durationMin + ':' + durationSec;

            if (this.interval.hasOwnProperty('id')) {

                if (this.addNewIntervalType) {

                   // await this.createIntervalType();
                }

                IntervalService.editInterval(intervalObj)
                    .then((res) => {

                        this.$store.dispatch('showSnackbarMessage', {message: 'The interval was successfully edited.'});
                        this.$emit('periodCreated');
                        this.closeDialog();
                    })
                    .catch(() => {

                        this.$store.dispatch('showSnackbarMessage', {message: 'Failed. Please, try again.', duration: 8000, mode: 'fail'});
                    });
            } else {

                intervalObj.order = this.allIntervals.length;

                // if (this.addNewIntervalType) {
				//
                //     await this.createIntervalType();
                // }

                IntervalService.createInterval(intervalObj)
                    .then((res) => {

                        this.$store.dispatch('showSnackbarMessage', {message: 'The interval was successfully created.'});
                        this.$emit('periodCreated');
                        this.closeDialog();
                    })
                    .catch(() => {

                        this.$store.dispatch('showSnackbarMessage', {message: 'Failed. Please, try again.', duration: 8000, mode: 'fail'});
                    });
            }
        },

        // createIntervalType() {
		//
        //     return IntervalService.createIntervalType({ name: this.interval.typeName })
        //         .then((res) => {
		//
        //             this.interval.type_id = res.id;
        //             this.addNewIntervalType = false;
        //         })
        //         .catch((err) => {
		//
        //             this.$store.dispatch('showSnackbarMessage', {
        //                 message: `${err.message}. Please, try again.`,
        //                 mode: 'fail'
        //             });
        //         });
        // },

        closeDialog() {

            this.interval.type_id = '';
            this.interval.durationObj = {min:0, sec: 0};
            this.interval.genre_id = '';
            this.interval.energy = '';
            this.interval.typeName = '';
            this.interval.mood_id = '';
            // this.interval.period_id = '';

            this.$emit('update:showDialog', false);
        },

        async fetchData(){

            await this.allIntervalGenres();
            await this.allIntervalTypes();
            await this.allIntervalMoods();
            await this.getAllBlueprints();
        },

        showCustomSelectTime() {

            this.isCustomTimeSelect = true;
        }
    },

    created() {

        let _this = this;

        _this.fetchData().then(() => {

            if (_this.intervalProp &&_this.intervalProp.hasOwnProperty('id')) {

                _this.interval = _this.intervalProp;
                _this.interval.typeName = _this.intervalProp.type.name;
            }

            // open custom select if time is more then 5 min
            if (((_this.interval.durationObj.min * 60) + _this.interval.durationObj.sec) > 300) {

                _this.isCustomTimeSelect = true;
            }
        });
    },
}
</script>

<style lang="scss" scoped>
    @include dialogSettings();

    ::v-deep .md-field.select-input__field {
        padding-top: 0;
        margin-top: 10px;
    }

    ::v-deep .select-input {
        display: flex;
        align-items: center;
        background: $color-white;
        color: $color-grey;
        height: 48px;

        &.valid {
            border: 3px solid $color-accent-purple;
        }

        &:focus {
            background-color: $color-white;
        }

        input {
            padding: 1rem 1.6rem;
        }
    }

    .newTypeBtn {
        margin-bottom: 30px;
        text-align: center;
    }

    .intervalTypesSelect{
       padding-bottom: 5px!important;
    }

	#movement-type-select {
		padding-bottom: 25px!important;
	}
</style>
