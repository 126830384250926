<template>
	<div>

		<md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
			<md-dialog-title  class="figtree-medium-white-20px" style="text-align:left;margin-bottom: 0 0 40px 0;padding-top:0;">Add new card</md-dialog-title>

			<md-dialog-content>
				<Card class='stripe-card'
					  :class='{ complete }'
					  :stripe='config.stripe_api_key'
					  :options='stripeOptions'
					  @change='complete = $event.complete'
				/>
			</md-dialog-content>
			<md-dialog-actions>
				<md-button v-if="disableCloseProp" class="dialog__btn dialog__btn--danger"
                   @click="logOut">Log Out
				</md-button>
        <md-button v-else class="dialog__btn dialog__btn--danger"
                   @click="closeDialog">Close
        </md-button>
				<md-button class='md-primary dialog__btn md-raised md-theme-light pay-with-stripe'
						   @click='stripeSubmit'
						   :disabled='!complete'>
					Save card
				</md-button>
			</md-dialog-actions>
		</md-dialog>

		<CreateStoreDialog
			:key="dialogKey"
			:showDialog.sync="showStoreDialog"
			:disableCloseProp="isCloseDisable"
			:store-prop="store"
		/>
	</div>
</template>

<script>
	import CardService from '@/services/card.service'
	import CreateStoreDialog from "@/components/Store/CreateStoreDialog.vue"
	import {Card, createToken} from 'vue-stripe-elements-plus'
	import config from '@/config.json'
	import {mapGetters} from "vuex";

	export default {
		name: 'CardModal',
		components: {
			Card,
			CreateStoreDialog
		},
		props: {
			showDialog: {
				type: Boolean,
				default: false
			},

			disableCloseProp: {
				type: Boolean,
				default: false
			},

			isAddingFirstCardProp: {
				type: Boolean,
				default: false
			}
		},
		data: function () {

			return {
				config,
				store: {
					name: '',
					street: '',
					suite: '',
					city: '',
					state: '',
					zip: '',
					phone: '',
					img: '',
					email: '',
					account_id: null,
					card_id: null
				},
				complete: false,
				isCloseDisable: true,
				showStoreDialog: false,
				dialogKey: 0,
				stripeOptions: {
					style: {
						base: {
							iconColor: '#c4f0ff',
							color: '#fff',
							fontWeight: 500,
							fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
							fontSize: '16px',
							fontSmoothing: 'antialiased',
							':-webkit-autofill': {
								color: '#fce883',
							},
							'::placeholder': {
								color: '#87BBFD',
							},
						},
						invalid: {
							iconColor: '#FFC7EE',
							color: '#FFC7EE',
						},
					}
				},
			}
		},
		methods: {
			stripeSubmit() {

				createToken().then(data => {

					this.creteCard(data.token)
				})
			},
			creteCard(token) {

				return CardService.create(token).then(data => {

					this.closeDialog();

					if (this.currentUser.account.is_trainer) {

						this.store.account_id = this.currentUser.account.id;
						this.store.card_id = data.id;
						this.dialogKey++;
						this.showStoreDialog = true;
					} else {

                        this.$store.dispatch('auth/getMe').then(() => {

                            if (this.currentUser.account.has_store === false){

                                this.$router.push('/stores');
                            }
                        });
					}

					// if (this.isAddingFirstCardProp) {
                    //
					// 	this.$router.push('/cards');
					// }

				}).catch(error => {

					console.log(error)
					this.$store.dispatch('showSnackbarMessage', {
						message: error.message,
						mode: 'fail'
					});
				})
			},
			closeDialog() {

				this.$emit('getCards');
				this.$emit('update:showDialog', false);
			},
      logOut() {
        this.$store.dispatch('auth/signOut').then((res) => {

          this.$store.dispatch('audioPlayer/updateSongToPlayFromBluePrint', {song: null, name: null});
          this.$router.replace('/login');
        });
      },
		},

		computed: {
			...mapGetters(
				{
					currentUser: 'auth/getCurrentUser',
				},
			)
		},

		created() {

		}
	}
</script>

<style lang="scss" scoped>

	.md-dialog {

		@include respond(small) {
			margin-top: 80px;
		}

		@media screen and (max-width: 980px) and (min-width: 700px) {
			margin-top: 80px;
		}

		/*width: auto !important;*/
		padding-left: 1.5em;
		padding-right: 1.5em;
		text-align: center;

		.plan-form {
			text-align: center;
		}

		.md-dialog-actions {
			justify-content: center;
		}

		.list-group {

			.current-subscriptions {

				@include respond(small) {
					margin-top: 30px;
				}

				@media screen and (max-width: 980px) and (min-width: 700px) {
					margin-top: 30px;
				}
			}
		}
	}
</style>
