const state = () => ({
    favoriteArtists: []
});

const getters = {
    getFavoriteArtists(state) {
        return state.favoriteArtists;
    }
};

const mutations = {
    setFavoriteArtists(state, favoriteArtists) {
        state.favoriteArtists = favoriteArtists;
    }
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}