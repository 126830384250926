<template>

	<div>

		<md-dialog :md-active.sync="showPlayerlist" :md-click-outside-to-close="true">

			<div class="playlist">
				<div class="playlist__header">
					<div
						class="close-button"
						id="close-media-player"
						@click="closePlayerPlaylist"
					>
						<md-icon id="close-icon  " class="md-theme-default " > close</md-icon>
					</div>
				</div>
				<!--				<md-dialog-content>-->
				<div
					class="playlist__played"
					:style="{ 	 marginTop: percentCompleteProp*5 + 'px' ,
                           		 boxShadow: '0px 0px 0px 9999px #46464655'
                            }"
				>

				</div>

				<div
					class="playlist__pointer"
					:style="{ marginTop: percentCompleteProp*5 + 'px'}"
				>
					<div class="left-arrow ">
						<!--                        <md-icon style="font-size: 64px!important;">arrow_right</md-icon>-->
						<svg height="20" width="20">
							<polygon points="0,0 20,10 0,20" style="fill:rgb(239,71,111);"/>
						</svg>
					</div>

					<div class="main-pointer ">
						<svg width="100%" height="8px">
							<rect width="100%" height="100" style="fill:rgb(239,71,111);"/>
							Sorry, your browser does not support inline SVG.
						</svg>
					</div>

					<div class="right-arrow ">
						<!--                        <md-icon style="font-size: 64px!important;">arrow_left</md-icon>-->
						<svg height="20" width="20">
							<polygon points="20,0 0,10 20,20" style="fill:rgb(239,71,111);"/>
						</svg>
					</div>
				</div>

				<div
					class="playlist__song md-layout md-alignment-left"
					v-for="(interval, index) in intervalsProp"
					:key="index + 'playlist_interval'"
					:style="{ height: initHeightInterval(interval.duration) + 'px' }"
				>

					<p class="playlist__song__length md-layout-item md-small-size-50 md-large-size-20 ">
						{{ interval.duration }}
					</p>

					<p class="playlist__song__name md-layout-item md-small-size-50 md-large-size-20 ">
						{{ getGenreName(interval.genre_id) }}
					</p>
				</div>
				<!--				</md-dialog-content>-->
			</div>
		</md-dialog>

	</div>
</template>

<script>
	import GenreService from "@/services/genre.service";

	export default {
		name: "AudioPlayerPlaylist",
		props: {

			showPlayerlistProp: {
				type: Boolean,
				default: false
			},

			intervalsProp: {
				type: Array,
				default: null
			},

			percentCompleteProp: {
				type: Number,
				default: 0
			},

			durationSecondsProp: {
				type: Number,
				default: null
			}
		},

		data: () => ({

			currentPlayPosition: 100,
			genres: [],
			showPlayerlist: false,
		}),


		methods: {

			async allIntervalGenres() {

				await GenreService.getAllGenres()
					.then((res) => {

						this.genres = res;
					})
					.catch(() => {

						this.$store.dispatch('showSnackbarMessage', {
							message: 'Failed. Please, try again.',
							duration: 8000,
							mode: 'fail'
						});
					});
			},

			getGenreName(genre_id) {

				for (const genre of this.genres) {

					if (genre.id == genre_id) {
						return genre.name;
					}
				}
			},

			initHeightInterval(intervalSeconds) {

				let timeObj = intervalSeconds.split(':');
				let secondsInterval = (+timeObj[0]) * 60 * 60 + (+timeObj[1]) * 60 + (+timeObj[2]);

				let percentHeight = secondsInterval / this.durationSecondsProp * 100;

				//500px = 100% height
				return percentHeight * 5;
			},

			closePlayerPlaylist() {

				this.showPlayerlist = false;
			}
		},

		created() {

			this.allIntervalGenres();
			this.showPlayerlist = this.showPlayerlistProp;
		}
	}
</script>

<style lang="scss" scoped>

	::v-deep .md-dialog-container {
		padding: 0;
		overflow: hidden !important;


	}

	.md-dialog-content {
		padding: 0 !important;
	}

	.md-dialog {
		//height: 540px !important;
	}

	.playlist {

		height: 555px;
		background-color: #FFFFFF;

		&__header {
			height: 40px;
			background: linear-gradient(135deg, #A156A0 32.49%, #EF476F 100%);
		}

		&__played {
			position: absolute;
			width: 100%;
			height: 100%;
		}

		&__pointer {

			display: inline;
			width: 100%;
			position: absolute;
			background-color: grey;

			.left-arrow {
				position: absolute;
				left: 0;
				z-index: 2;
				color: #EF476F;
			}

			.main-pointer {
				position: absolute;
				width: 100%;
				z-index: 1;
				background: #46464655;
			}

			.right-arrow {
				position: absolute;
				alignment: right;
				right: 0;
				z-index: 2;

			}
		}

		&__song {
			color: #000000;
			border: 1px solid #A156A0;
			font-family: Roboto;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
		}
	}

	#close-media-player {
		position: absolute;
		background: #ef476f;
		color: white!important;
		top: -1px;
		right: -1px;
		cursor: pointer;
		z-index: 5;
		display: none;

		@include respond(small) {
			display: block;
		}
		.md-icon {
			color: white !important;
		}
	}

</style>