<template>
 <md-card
        v-bind="$attrs" 
        class="md-theme-light graph-wrap"
        :ref="'graphWrap' + graphIndex">
        
        <h3 class="graph__name">{{name}}
            <md-button class="md-icon-button graph__menu md-theme-light">
                <md-icon class="md-theme-light">more_horiz</md-icon>
            </md-button>
        </h3>

        <div class="graph__legend" v-if="graphData.length > 1">
            <div class="graph__color"
                v-for="(data, index) in finalData" :key="index + 'data'">
                <span class="graph__color-sample" :style="{'background': data.color}"></span>
                <span class="graph__color-name">{{data.key}}</span>    
            </div>
        </div>

        <div :id="'graph' + graphIndex" ref="graph" width="100%"></div>
    </md-card>
</template>

<script>
import * as d3 from 'd3'

export default {
    name: 'ReportPieChart',
    props: {
        graphData: {
            type: Array,
            required: true
        },
        graphIndex: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        }
    },
    data: () => ({
        finalData: []
    }),
    computed: {
        graphWidth() {
            return this.$refs[`graphWrap${this.graphIndex}`].$el.clientWidth;
        }
    },
    methods: {
        prepareData() {
            const sortedData = this.graphData.sort((a, b) => {
                return b.value - a.value
            });

            if (sortedData.length > 4) {
                this.finalData = [
                    ...sortedData.slice(0, 4),
                    {
                        key: 'other',
                        value: sortedData.slice(4).reduce((acc, cur) => acc + cur.value, 0)
                    }
                ];
            } else {
                this.finalData = sortedData;
            } 
        },

        initDimensions() {
            this.dimensions = {
                width: 244,
                height: 244,
                margin: {
                    top: 10,
                    right: 10,
                    bottom: 10,
                    left: 10,
                },
            };
            this.dimensions.boundedWidth = this.dimensions.width
                - this.dimensions.margin.left
                - this.dimensions.margin.right;
            this.dimensions.boundedHeight = this.dimensions.height
                - this.dimensions.margin.top
                - this.dimensions.margin.bottom;
        },

        initStaticElements() {
            this.wrapper = d3.select(`#graph${this.graphIndex}`)
                .append("svg")
                    .attr("width", this.dimensions.width)
                    .attr("height", this.dimensions.height);

            this.bounds = this.wrapper.append("g")
                .style("transform", `translate(${
                    this.dimensions.margin.left}px, ${
                    this.dimensions.margin.top
                }px)`);
        },

        startDrawProcess() {
            this.arcGenerator = d3.pie()
                .padAngle(0.005)
                .value(d => d.value)

            this.arcs = this.arcGenerator(this.finalData)

            this.interpolateWithSteps = numberOfSteps => new Array(numberOfSteps)
                .fill(null)
                .map((d, i) => i / (numberOfSteps - 1));

            this.radius = this.dimensions.boundedWidth / 2;

            this.arc = d3.arc()
                .innerRadius(this.radius * 0.7) // set to 0 for a pie chart
                .outerRadius(this.radius)
        },

        drawData() {
            const centeredGroup = this.bounds.append("g")
                .attr("transform",
                    `translate(${this.dimensions.boundedHeight / 2}, ${this.dimensions.boundedWidth / 2})`)

            centeredGroup.selectAll("path")
                .data(this.arcs)
                .enter().append("path")
                .attr("fill", d => d.data.key == "other" ? "#dadadd" : d.data.color)
                .attr("d", this.arc)
                .append("title")
                .text(d => d.data.key)

            this.iconGroups = centeredGroup.selectAll("g")
                .data(this.arcs)
                .enter().append("g")
                .attr("transform", d => `translate(${this.arc.centroid(d)})`)
        },

        drawAxes() {
            this.bounds.append("text")
                .attr("class", "title")
                .text(this.name)
                .attr("transform", `translate(${this.dimensions.boundedWidth / 2 - 120}, ${this.dimensions.boundedHeight / 2 + 10})`)

            this.iconGroups.append("text")
                .attr("class", "label")
                .text(d => d.data.value + '%')
                .attr("transform", d => `translate(-8, 5)`);
        }

    },
    mounted() {
        this.prepareData();
        this.initDimensions();
        this.initStaticElements();
        this.startDrawProcess();
        this.drawData();
        this.drawAxes();

    }

}
</script>

<style lang="scss" scoped>
.md-card.graph-wrap {
    margin: 0 0 2.4rem;
    background-color: $color-white !important;
    font-family: 'Roboto';
    cursor: pointer;
    padding: 0 0 40px 0;

    @include respond(small) {
        margin-bottom: 2.4rem !important;
    }
}

.graph__name {
    color: #485465;
    padding: 16px 35px 0 40px;
    font-family: Helvetica, 'Roboto';
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: -0.09px;
    position: relative;
    text-align: left;
    text-transform: uppercase;
    width: 100%;
}
.graph__menu {
    position: absolute;
    top: 0;
    right: 20px;
}
.graph__legend {
    display: flex;
    justify-content: flex-end;
    padding: 0 35px 0 40px;

}
.graph__color {
    display: flex;
    align-items: center;
    
    &:not(:first-child) {
        margin-left: 14px;
    }
}
.graph__color-name {
    font-size: 10px;
    color: #485465;
    margin-left: 8px;
}
.graph__color-sample {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

::v-deep text.label {
    font-size: 12px;
    font-weight: bold;
    fill: #fff;
}
::v-deep text.title {
    font-size: 24px;
    font-weight: bold;
    fill: #000;
}
</style>