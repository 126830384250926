<template>
  <div class="component-wrap">

    <md-app-toolbar class="md-primary tour-guide-ribbon" v-if="store && (!store.subscription.active || store.subscription.status === 'incomplete')">
      <div id="tour-ribbon">
        <span> The subscription for this store is canceled! </span>
      </div>
    </md-app-toolbar>

    <div v-if="userRole > 1" style="display: flex">

      <div class="div-border">
        <span v-if="stores.length > 1" class="span-bold-label"> Dashboard </span>
        <md-field v-if="stores.length > 1" style="margin-right: 60px">
          <md-select v-model="selectedStore">
            <md-option
                v-for="(store, index) in stores" :key="index" :value="store.id">
              {{ store.name }}
            </md-option>
          </md-select>
        </md-field>

        <span class="span-bold-label"> Blueprints </span>

        <span class="span-label"> All </span>

        <toggle-button class="toggle-share-btn toggle-center"
                       v-model="showPrivateBlueprints"
                       color="#a156a0"
                       :sync="true"
                       :width=40
                       :font-size=10
                       :height=18
        />

        <span class="span-label"> Private </span>
      </div>

    </div>
    <div :class="{ 'disabled': store && (!store.subscription.active || store.subscription.status === 'incomplete') }">
      <AppHeaderComponent
          :headline="userRole === 1 ? 'Blueprints' :
           (stores.find(value => value.id === selectedStore) ? stores.find(value => value.id === selectedStore).name : '') + ' Blueprints'">
        <div class="content-header__actions">


          <!-- <md-button
              class="md-raised md-primary md-raised md-theme-light deleteBtn"
              id="delete-blueprints-btn"
              @click="goToDeletedBlueprints()">Deleted Blueprints
          </md-button>

          <md-button
              class="md-raised md-primary md-raised md-theme-light archivedBtn"
              id="archived-blueprints-btn"
              @click="goToArchivedBlueprints()">Archived Blueprints
          </md-button> -->
        </div>
      </AppHeaderComponent>

      <AppContentGrid>
     
        <div class="list-group">
          <div class="add-blueprint-button" @click="openBuePrintDialog()">
            <div class="add-blueprint-text">

              <md-icon>add_circle_outline</md-icon>
              <div class="text">
                <div class="add-blueprint figtree-medium-white-14px">Add Blueprint</div>
              </div>
            </div>
          </div>

          <BluePrintCard
              v-for="(bluePrint, index) in bluePrints"
              v-show="bluePrint.id"
              :bluePrint="bluePrint"
              :key="index + '_bluePrints'"
              :randomColor="getRandomColor(index)"
              @refreshBluePrints="getAllBlueprints"
              @setLoadingSpinner="setLoadingSpinner"
              @tempRemoveBluePrint="tempRemoveBluePrint"
              @editBluePrint="editBluePrint(bluePrint.id)"
              @restoreBluePrint="restoreBluePrint"
              @archiveBluePrint="archiveCurrentBlueprint(bluePrint.id)"
              @click.native="goToSingleBluePrint(bluePrint.id)"
              @showModalTableBluePrintInfo="openBluePrintModalInfo(bluePrint.id)"
              @showPlaylistModal="openPlaylistModal(bluePrint.id)"
          />
        </div>
      </AppContentGrid>

      <AppSpinnerComponent v-if="loadingSpinner" :loading="loadingSpinner"/>

      <TrainerDashboardBluePrintDialog
          @refreshAllBluePrints="getAllBlueprints"
          @openSelectedTypeDialog="openSelectedTypeDialog"
          :showDialog.sync="showDialog"
          :doWeEdit="doWeEdit"
          :store_id="selectedStore"
          :blue-print-prop="bluePrintToEdit"
          :key="dialogKey"
          ref="blueprintDialog"/>

      <EmomBlueprintDialog
          @goBack="openBuePrintDialogBack"
          @refreshAllBluePrints="getAllBlueprints"
          @openPreSelectGenresDialog="openPreSelectGenresDialog"
          :showDialog.sync="showDialogEmom"
          :blue-print-prop="newBlueprint"
          :key="dialogKeyEmom"
      />

      <TabataBlueprintDialog
          @refreshAllBluePrints="getAllBlueprints"
          @goBack="openBuePrintDialogBack"
          @openPreSelectGenresDialog="openPreSelectGenresDialog"
          :showDialog.sync="showDialogTabata"
          :blue-print-prop="newBlueprint"
          :key="dialogKeyTabata"
      />

      <CustomBlueprintDialog
          @refreshAllBluePrints="getAllBlueprints"
          @goBack="openBuePrintDialogBack"
          @openPreSelectGenresDialog="openPreSelectGenresDialog"
          :showDialog.sync="showDialogCustom"
          :blue-print-prop="newBlueprint"
          :key="dialogKeyCustom"
      />

      <PreSelectGenresDialog
          v-if="showDialogPreSelect"
          @refreshAllBluePrints="getAllBlueprints"
          @goBack="openBuePrintDialogBack"
          @openPreSelectGenresDialog="openPreSelectGenresDialog"
          :showDialog.sync="showDialogPreSelect"
          :blue-print-prop="newBlueprint"
          :key="dialogKeyPreSelect"
          :store_id="selectedStore"
      />


      <BluePrintCardModalTableInfo
          :showDialog.sync="showBluePrintCardModalTableInfo"
          :blue-print-infos-prop="bluePrintJobs"/>

      <BluePrintsPlaylistModal
          v-if="bluePrintPlaylist"
          :show-dialog.sync="showPlaylistModal"
          :playlists-prop="bluePrintPlaylist"
      />

      <div id='ZN_7NyKMXz56SQQJyB'><!--DO NOT REMOVE-CONTENTS PLACED HERE--></div>
    </div>
  </div>
</template>

<script>

import BluePrintCard from '@/components/BluePrints/BluePrintCard';
import TrainerDashboardBluePrintDialog from '../../components/TrainerDashboard/TrainerDashboardBluePrintDialog';
import EmomBlueprintDialog from '../../components/TrainerDashboard/EmomBlueprintDialog';
import TabataBlueprintDialog from '../../components/TrainerDashboard/TabataBlueprintDialog';
import CustomBlueprintDialog from '../../components/TrainerDashboard/CustomBlueprintDialog';
import PreSelectGenresDialog from '../../components/TrainerDashboard/PreSelectGenresDialog.vue';
import FavoritesFormModal from '../../components/Favorites/FavoritesFormModal';
import BluePrintService from '../../services/bluePrint.service';
import BluePrintCardModalTableInfo from '../../components/Common/BluePrintCardModalTableInfo';
import {mapGetters} from 'vuex';
import BluePrintsPlaylistModal from '@/components/BluePrints/BluePrintsPlaylistsModal';
import UserService from '@/services/user.service';
import StoreService from "../../services/store.service";
import {RandomColorsMixin} from "../../mixins/RandomColorsMixin";


export default {
  name: 'TemplateDashboard',
  mixins: [RandomColorsMixin],
  data: function() {
    return {
      doWeEdit: false,
      bluePrints: [],
      newBlueprint: {},
      tempBluePrints: null,
      bluePrintToEdit: {},
      bluePrintJobs: null,
      loadingSpinner: true,
      showDialog: false,
      showDialogEmom: false,
      showDialogTabata: false,
      showDialogPreSelect: false,
      showDialogCustom: false,
      showFavoriteFormModal: false,
      showBluePrintCardModalTableInfo: false,
      showPlaylistModal: false,
      bluePrintPlaylist: null,
      dialogKey: 0,
      dialogKeyEmom: 100,
      dialogKeyTabata: 200,
      dialogKeyCustom: 300,
      dialogKeyPreSelect: 400,
      stores: [],
      store: null,
      selectedStore: null,
      intervalIdTimeout:null,
      showPrivateBlueprints: false
    };
  },
  components: {
    BluePrintsPlaylistModal,
    BluePrintCard,
    TrainerDashboardBluePrintDialog,
    FavoritesFormModal,
    BluePrintCardModalTableInfo,
    EmomBlueprintDialog,
    TabataBlueprintDialog,
    CustomBlueprintDialog,
    PreSelectGenresDialog
  },

  async created() {
    await StoreService.getMyStores().then((res) => {
      this.stores = res;
    }).catch((err) => {
      this.$store.dispatch('showSnackbarMessage', {
        message: `${err.message}. Please, try again.`,
        mode: 'fail'
      });
    });

    const user = this.$store.getters['auth/getCurrentUser'];
    if(this.userSelectedStore!=null) this.selectedStore =this.userSelectedStore;
    else if(this.stores.length > 0)
      this.selectedStore = this.stores[0].id;
    //if user login for the first time
    if (user.is_setup == false) {

      // this.showFavoriteFormModal = true;
    }

    this.setPageName();
    this.setLoadingSpinner(false);

    this.triggerTourGuide();

    this.getAllBlueprints();

    this.$root.$on('undoDelete', this.restoreBluePrint);

    //Refresh templates every 5 min
    this.intervalIdTimeout = window.setInterval(() => {

      this.getAllBlueprints();
    }, 300000);
  },
  beforeDestroy() {
    window.clearInterval(this.intervalIdTimeout);
  },
  destroyed() {

    this.$store.dispatch('changeShowTourGuideRibbon', false);
    this.$root.$off('undoDelete', this.restoreBluePrint);
  },
  methods: {

    openPreSelectGenresDialog(){

      this.dialogKeyPreSelect++;
      this.showDialogPreSelect = true;

    },

    openSelectedTypeDialog(selectedTypeObj) {
      this.newBlueprint = selectedTypeObj;

      switch (selectedTypeObj.type_id) {
        case 1:
          this.dialogKeyEmom++;
          this.showDialogEmom = true;
          break;
        case 2:
          this.dialogKeyTabata++;
          this.showDialogTabata = true;
          break;
        case 3:
          this.dialogKeyCustom++;
          this.showDialogCustom = true;
          break;
      }

    },

    goNextStepIfTourGuideActive() {

      this.$nextTick(() => {

        this.$tours['myTour'].nextStep();
      });
    },

    archiveCurrentBlueprint(id) {

      let userId = this.$store.getters['auth/getCurrentUser'].id;

      BluePrintService.archiveBluePrint({template_id: id, user_id: userId}).then((res) => {

        this.getAllBlueprints();

        this.$store.dispatch('showSnackbarMessage', {
          message: 'The Blueprint was successfully archived.',
          duration: 3000,
          mode: 'success',
        });
      }).catch((err) => {
        this.$store.dispatch('showSnackbarMessage', {
          message: `${err.message}. Please, try again.`,
          mode: 'fail',
        });
      });
    },

    setPageName() {

      this.$store.dispatch('changePageName', {pageNameString: 'Blueprints'});
    },

    openBuePrintDialogBack(selectedTypeObj) {

      this.bluePrintToEdit = selectedTypeObj.bpObj;


//            this.dialogKey++;
      this.doWeEdit = false;

      if (selectedTypeObj.bpObj.image_url) {
        this.$refs.blueprintDialog.showPreviewWhenGoBack(selectedTypeObj.bpObj.preview);
      }
      if (!selectedTypeObj.isBack) {
        this.bluePrintToEdit = '';
      }
      this.showDialog = true;
    },

    openBuePrintDialog() {
      this.dialogKey++;
      this.bluePrintToEdit = '';
      this.showDialog = true;
      this.doWeEdit = false;

      if (this.showTourGuide) {

        this.goNextStepIfTourGuideActive();
      }
    },

    editBluePrint(bluePrintId) {

      BluePrintService.getSingleBluePrint(bluePrintId).then((res) => {

        this.dialogKey++;
        this.bluePrintToEdit = res;
        this.doWeEdit = true;
        this.showDialog = true;
      }).catch((err) => {
        this.$store.dispatch('showSnackbarMessage', {
          message: `${err.message}. Please, try again.`,
          mode: 'fail',
        });
      });
    },

    tempRemoveBluePrint(id) {

      this.setLoadingSpinner(true);
      this.bluePrints = this.bluePrints.filter(bluePrint => bluePrint.id != id);
      this.$store.commit('bluePrints/setTempBluePrints', this.bluePrints);
    },

    restoreBluePrint(id) {

      this.$store.commit('bluePrints/setIsUndoActive', true);
      this.getAllBlueprints();
      this.$store.commit('bluePrints/setTempBluePrints', '');
      this.setLoadingSpinner(false);

      this.$store.dispatch('showSnackbarMessage', {
        message: 'The blueprint was successfully restored.',
        duration: 3000,
        mode: 'success',
      });
    },

    setLoadingSpinner(isLoading) {

      this.loadingSpinner = isLoading;
    },

    getAllBlueprints() {
      const storeId = this.selectedStore ? this.selectedStore : this.stores[0].id;
      BluePrintService.getBluePrints(storeId,this.showPrivateBlueprints).then((res) => {

        this.bluePrints = res;
        this.setLoadingSpinner(false);

        for (let i = 0; i < this.bluePrints.length; i++) {
          this.bluePrints[i]['sets'] = [];
        }

        this.bluePrints.push({});

      }).catch((err) => {

        this.$store.dispatch('showSnackbarMessage', {
          message: `${err.message}. Please, try again.`,
          mode: 'fail',
        });
      });

    },

    goToArchivedBlueprints() {

      this.$router.push('/archived-blueprints/' + this.selectedStore);
    },

    goToDeletedBlueprints() {

      this.$router.push('/deleted-blueprints/' + this.selectedStore);
    },

    goToSingleBluePrint(id) {

      this.$router.push('/blueprints/'+ id+'/playlists');
    },

    turnOffLoadingSpinner() {

    },

    getBluePrintJobs(id) {

      return BluePrintService.getSingleBluePrintJobs(id).then((res) => {

        this.bluePrintJobs = res;
      }).catch((err) => {

        this.$store.dispatch('showSnackbarMessage', {
          message: `${err.message}. Please, try again.`,
          mode: 'fail',
        });
      });
    },

    getPlaylistsForBlueprint(id) {

      return BluePrintService.getBluePrintPlaylists(id).then((res) => {

        this.bluePrintPlaylist = res;
      }).catch((err) => {

        this.$store.dispatch('showSnackbarMessage', {
          message: `${err.message}. Please, try again.`,
          mode: 'fail',
        });
      });
    },

    async openBluePrintModalInfo(id) {

      await this.getBluePrintJobs(id);
      this.showBluePrintCardModalTableInfo = true;
    },

    async openPlaylistModal(id) {

      await this.getPlaylistsForBlueprint(id);
      this.showPlaylistModal = true;
    },

    triggerTourGuide() {

      // trigger tour guide
      UserService.getUserTourGuide().then(res => {

        if (res == false) {

          this.$store.dispatch('changeIsTourGuideActive', true);
          // this.$store.dispatch('changeOpenMenuForTourGuide', true);

          this.$tours['myTour'].start();
        } else if (res.template_created == false) {

          this.$store.dispatch('changeIsTourGuideActive', true);
          // this.$store.dispatch('changeOpenMenuForTourGuide', true);

          this.$tours['myTour'].start();
        } else {

          this.$store.dispatch('changeShowTourGuideRibbon', true);
        }
      });
      // trigger tour guide
    }
  },
  watch: {
    selectedStore(){
      this.getAllBlueprints();
      this.store = this.stores.find(value => value.id === this.selectedStore);
      this.$store.dispatch('auth/setSelectedStore', this.selectedStore);
    },
    showPrivateBlueprints(){
      this.getAllBlueprints();
      this.store = this.stores.find(value => value.id === this.selectedStore);
    }
  },
  computed: {

    ...mapGetters(
        {
          userRole: 'auth/currentUserRole',
          showTourGuide: 'getIsTourGuideActive',
          userSelectedStore: 'auth/getSelectedStore',

        },
    ),
  },
};
</script>

<style lang="scss" scoped>
::v-deep .content-header {
  margin-bottom: 60px !important;
}

.md-toolbar.tour-guide-ribbon {
  margin-bottom: 10px;
  background: #F7F9AC 90% !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  min-height: 38px;
}

.tour-guide-ribbon {
  width: 100%;
  display: flex;
  min-height: 38px;
  height: 38px;
  align-items: center;
  align-content: center;
  padding: 20px;

  span {
    color: #EB4871;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.select-centered {
  margin: 0 auto;
  width: 250px;
  height: 35px;
}

.content__subtitle {
  text-align: left;
  margin: 5.6rem 0 2.4rem;
}

#archived-blueprints-btn {
  margin-left: 2.4rem;

  @include respond(xsmall) {
    margin-left: 0;
  }
}

#create-blueprint-button {
  border-radius: 50%;

  @include respond(xsmall) {
    border-radius: 0;
  }
}

.content-header {

  &__actions {
  align-items: center !important;

  @include respond(small) {

    ::v-deep .md-button {
      margin-bottom: 2rem !important;

    }
  }
}
}

.div-border {
border: 2px solid #75758d;
border-radius: 20px;
padding: 8px;
display: flex;
@media only screen and (max-width: 900px) {
  display: block;
};
}

.span-label {
align-self: center;
font-size: 17px;
}

.span-bold-label {
  margin-right: 25px;
  align-self: center;
  font-size: 17px;
  font-weight: bold;
}

.toggle-center {
  padding-left: 5px;
  padding-right: 5px;
  align-self: center
}
.add-blueprint-text {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 9px;
    justify-content: center;
    width: fit-content;
  }

  .add_circle_fill0_wght400_grad0_opsz24-3 {
    height: 39.33px;
    min-width: 39.33px;
  }

  .text {
    align-items: flex-start;
    display: flex;
    gap: 10px;
    padding: 10px;
    width: fit-content;
  }

  .add-blueprint {
    letter-spacing: 0;
    line-height: 16px;
    margin-top: -1px;
    white-space: nowrap;
    width: fit-content;
  }

  .add-blueprint-button {
    align-items: center;
    background-color:rgba(255, 255, 255, 0.102);
    border: 1px solid;
    border-color:rgba(168, 98, 194, 1);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: center;
    padding: 10px;
    height: 143px;
  }
  .blueprints-1 {
    letter-spacing: 0;
    line-height: 16px;
    min-width: 64px;
    white-space: nowrap;
  }
</style>
