export const repeatClassName = {
    methods: {
        getRepeatClassName(repeatId) {
            let repeatClassName = '';
            switch (repeatId) {
                case null:
                    repeatClassName = 'One-off'
                    break;
                case 0:
                    repeatClassName = 'One-off'
                    break;
                case 1:
                    repeatClassName = 'Daily'
                    break;
                case 2:
                    repeatClassName = 'Weekly'
                    break;
                case 3:
                    repeatClassName = 'Monthly'
                    break;

                default:
                    repeatClassName = 'Not defined'
            }
            return repeatClassName;
        }
    }
}