import $axios from '../plugins/axios'

class PublisherService {

    getAllPublishers() {

        return $axios.get('/publishers')
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }
}

export default new PublisherService();