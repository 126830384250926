<template>
  <div class="component-wrap">

    <div class="div-top" v-if="blueprint" :style="`background: linear-gradient(180deg, rgba(91.37, 91.37, 91.37, 0) 0%,${blueprint.color} 100%)`">
      <div style="text-align: left">
        <div class="figtree-normal-white-12px-2">{{ blueprint.is_shared ? 'Shared' : 'Private' }}</div>
        <h1 class="blueprint-name figtree-bold-white-46px">{{ blueprint.name }}</h1>
        <div v-if="blueprint.playlists" class="figtree-medium-white-20px">{{ blueprint.playlists.length }} Playlists</div>
      </div>

      <div  class="button">
        <div v-if="blueprint.sections && blueprint.sections.length > 0"
             class="button-1 figtree-normal-white-16px" @click="createPlaylistDialog()">Create Playlist</div>
        <div v-else class="button-1 figtree-normal-white-16px" style="opacity: 0.5">
          <md-tooltip class="md-theme-light" md-direction="bottom">You should create sections first!</md-tooltip>
          Create Playlist
        </div>
      </div>
    </div>

    <div v-if="canEdit" class="div-mid">
    <div class="group-312">
      <h1 class="lets-create-a-bluep figtree-medium-white-32px">Let's create a Blueprint for your workout!</h1>
      <p class="please-select-a-template-to-get-started figtree-medium-white-14px">
        Please select a template to get started
      </p>
    </div>

    <div class="template-options">

      <div class="template-btn" @click="openSelectedTypeDialog(1)">
        <img class="timer_fill0_wght400_grad0_opsz24-2"
             src="@/assets/images/timer-fill0-wght400-grad0-opsz24-2-9@2x.png"/>
        <div class="frame-326">
          <div class="emom">EMOM</div>
        </div>
      </div>

      <div class="template-btn" @click="openSelectedTypeDialog(2)">
        <img class="timer_fill0_wght400_grad0_opsz24-2"
             src="@/assets/images/timer-fill0-wght400-grad0-opsz24-2-9@2x.png"/>
        <div class="frame-326">
          <div class="emom">Work/Rest</div>
        </div>
      </div>

      <div class="template-btn" @click="openSelectedTypeDialog(3)">
        <img class="timer_fill0_wght400_grad0_opsz24-2"
             src="@/assets/images/timer-fill0-wght400-grad0-opsz24-2-9@2x.png"/>
        <div class="frame-326">
          <div class="emom">Custom</div>
        </div>
      </div>

    </div>
    </div>
    <div class="div-bottom">
      <div v-if="blueprint.sections && blueprint.sections.length > 0" style="text-align: left;margin-bottom: 30px" class="figtree-medium-white-20px">Program Workout</div>


      <draggable v-model="sectionsList"  @start="drag=true" @end="drag=false" :options="{disabled : !canEdit}">
      <div v-for="(section , index) in blueprint.sections" >
        <div :class="['workout-card',{'section-margin': !showMoreDetails[index]}]" @click="showDetails(index)"> 
          
          <div class="box-1">
            <div class="row">
              <div class="number figtree-medium-white-18px">{{ Number(index) + 1 }}</div>
            </div>
          </div>
          <div class="box-2">
            <div class="row-1">
              <div class="section-1 figtree-medium-white-18px">{{ section.title }}</div>
            </div>
          </div>
          <div class="box-3">
            <div class="row">
              <div class="emom2 figtree-medium-white-18px">{{ getSectionType(section.type_id) }}</div>
            </div>
          </div>
          <div class="box-4">
            <div class="row">
              <div class="address figtree-medium-white-18px">{{ section.duration }}</div>
            </div>
          </div>
          <div v-if="canEdit" class="box-5">
            <!-- <div class="row-2"> -->
              <md-menu  @click.stop >
                <md-button md-menu-trigger class="md-icon-button tag-input__btn">
                  <md-icon style="color: white">more_vert</md-icon>
                </md-button>
              
                <md-menu-content style="background-color: var(--onyx)!important;z-index: 12;">
                  <md-menu-item @click.stop="editSection(section)" >
                    <md-button class="figtree-medium-white-12px">Edit Section
                    </md-button>
                  </md-menu-item>
                  <md-menu-item   @click.stop="deleteSection(section.id)" >
                    <md-button class="figtree-medium-white-12px">Delete Section</md-button>
                  </md-menu-item>
                </md-menu-content>
              </md-menu>
            <!-- </div> -->
          </div>
        </div>
        <div class="details-card" v-if="showMoreDetails[index] && section.type_id === 1" v-for="(interval , intervalIndex) in section.details" > 
          <div class="box-6 hide-index">
            <div class="row">
              {{ Number(intervalIndex) + 1 }}
            </div>
          </div>  
          <div class="box-6">
            <div class="row-1">
              <div class="section-1 figtree-medium-white-18px">{{ interval.duration }}</div>
            </div>
          </div>
          <div class="box-6">
            <div class="row">
              <div class="emom2 figtree-medium-white-18px">{{ interval.for_minutes }}</div>
            </div>
          </div>
          <div class="box-6">
            <div class="row">
              <div class="address figtree-medium-white-18px"><flexLevels :className="interval.flex" :key="'flex_' + index" :id="'flex_' + index" /></div>
            </div>
          </div>
          <div class="box-6">
          </div>
          <div class="box-6">
          </div>
        </div>
        <div class="details-card" v-if="showMoreDetails[index] && section.type_id === 2"
          v-for="(interval , intervalIndex) in section.details" >
          <div class="box-6 hide-index">
            <div class="row">
              {{ Number(intervalIndex) + 1 }}
            </div>
          </div>  
          <div class="box-6">
            <div class="row-1">
              <div class="section-1 figtree-medium-white-18px">{{ interval.work.duration }}</div>
            </div>
          </div>
          <div class="box-6">
            <div class="row">
              <div class="emom2 figtree-medium-white-18px"> <flexLevels :className="interval.work.flex" :key="'flex_' + index" :id="'flex_' + index" /></div>
            </div>
          </div>
          <div class="box-6">
            <div class="row-1">
              <div class="section-1 figtree-medium-white-18px">{{ interval.rest.duration }}</div>
            </div>
          </div>
          <div class="box-6">
            <div class="row">
              <div class="emom2 figtree-medium-white-18px"> <flexLevels :className="interval.rest.flex" :key="'flex_' + index" :id="'flex_' + index" /></div>
            </div>
          </div>
          <div class="box-6">
            <div class="row">
              <div class="emom2 figtree-medium-white-18px"> {{ interval.rounds }} <span class="hide-label">Round{{ interval.rounds === 1 ? '':'s'}}</span> </div>
            </div>
          </div>
        </div>
        <div class="details-card" v-if="showMoreDetails[index] && section.type_id === 3"
          v-for="(interval , intervalIndex) in section.details" >
        
          <div class="box-6 hide-index">
            <div class="row">
              {{ Number(intervalIndex) + 1 }}
            </div>
          </div>  
          <div class="box-6">
            <div class="row-1">
              <div class="section-1 figtree-medium-white-18px">{{ interval.duration }}</div>
            </div>
          </div>
         
          <div class="box-6">
            <div class="row">
              <div class="emom2 figtree-medium-white-18px"><flexLevels :className="interval.flex" :key="'flex_' + index" :id="'flex_' + index" /></div>
            </div>
          </div>
          <div class="box-6">
          </div>
          <div class="box-6">
          </div>
          <div class="box-6">
          </div>
        </div>
        </div>
      </draggable>


    </div>
    <EmomBlueprintDialog @refreshAllBluePrints="getBluePrint" :showDialog.sync="showDialogEmom" :typeId="1"
                         :blue-print-prop="blueprint" :section="sectionToBeEdited" :key="dialogKeyEmom"/>

    <TabataBlueprintDialog @refreshAllBluePrints="getBluePrint" :showDialog.sync="showDialogTabata" :typeId="2"
                           :blue-print-prop="blueprint" :section="sectionToBeEdited" :key="dialogKeyTabata"/>

    <CustomBlueprintDialog @refreshAllBluePrints="getBluePrint" :showDialog.sync="showDialogCustom" :typeId="3"
                           :blue-print-prop="blueprint" :section="sectionToBeEdited" :key="dialogKeyCustom"/>
    <PlaylistDialog :showDialog.sync="showDialogPlaylist" :key="dialogKeyPlaylist"/>

  </div>

</template>

<script>
import $axios from "@/plugins/axios";
import EmomBlueprintDialog from '../../components/TrainerDashboard/EmomBlueprintDialog';
import TabataBlueprintDialog from '../../components/TrainerDashboard/TabataBlueprintDialog';
import CustomBlueprintDialog from '../../components/TrainerDashboard/CustomBlueprintDialog';
import PlaylistDialog from '../../components/TrainerDashboard/PlaylistDialog';
import SectionsService from '@/services/sections.service';
import draggable from 'vuedraggable'
import {DurationHelperMixin} from "../../mixins/DurationHelperMixin";
import flexLevels from "@/components/TrainerDashboard/flexLevels";

export default {
  name: 'SectionsDashboard',
  mixins: [DurationHelperMixin],

  data: () => ({
    blueprint: {},
    dialogKeyEmom: 100,
    dialogKeyTabata: 200,
    dialogKeyCustom: 300,
    dialogKeyPlaylist: 400,
    showDialogEmom: false,
    showDialogTabata: false,
    showDialogCustom: false,
    showDialogPlaylist: false,
    sectionToBeEdited :null,
    sectionSets: [],
    TabataSectionSets:[],
    CustomSectionSets:[],
    showMoreDetails:[],
    canEdit:true
  }),

  components: {
    EmomBlueprintDialog,
    TabataBlueprintDialog,
    CustomBlueprintDialog,
    PlaylistDialog,
    draggable,
    flexLevels,
  },
  methods: {
    showSectionDetails() {
      this.blueprint.sections.forEach(section => {
        this.showMoreDetails.push(true)
        switch (section.type_id) {
          case 1:
            this.populateEmomData(section.intervals)
            section.details = this.sectionSets;
            break;
          case 2:
            this.populateTabataData(section.intervals)
            section.details = this.TabataSectionSets;
            break;
          case 3:
            this.populateCustomData(section.intervals)
            section.details = this.CustomSectionSets;
            break;
          default:
            break;
        }
      });
    },

    setFlex(val) {
      switch (val) {
        case 1:
          return 'stretch';
          break;
        case 2:
          return 'warm-up';
          break;
        case 3:
          return 'stretch-training-1';
          break;
        case 4:
          return 'cardio-1';
          break;
        case 5:
          return 'sprint-1';
          break;
        default:
          break;
      }

    },

    showDetails(index) {
      this.showMoreDetails[index] = !this.showMoreDetails[index];
      this.$forceUpdate();
    },

    populateNewSet(oldDuration, for_minutes, oldEnergy) {
      this.sectionSets.push({
        duration: oldDuration,
        for_minutes: for_minutes,
        flex: this.setFlex(oldEnergy)
      });
    },

    populateEmomData(intervals) {
      this.sectionSets = [];
      let oldDuration = intervals[0].duration;
      let oldEnergy = intervals[0].energy;
      let counter = 0;
      intervals.forEach((interval, index) => {
        if (interval.energy === oldEnergy && interval.duration === oldDuration) {
          counter++;
        }
        else {
          let secondsDuration = this.convertHHMMSStoSeconds(oldDuration) * counter;
          let for_minutes = this.convertTimeHHMMSS(secondsDuration).toString();
          counter = 1
          this.populateNewSet(oldDuration, for_minutes, oldEnergy);
        }
        oldDuration = interval.duration;
        oldEnergy = interval.energy;
      });
      let secondsDuration = this.convertHHMMSStoSeconds(oldDuration) * counter;
      let for_minutes = this.convertTimeHHMMSS(secondsDuration).toString();
      this.populateNewSet(oldDuration, for_minutes, oldEnergy);

    },

    populateNewTabataSet(workDuration, WorkEnergy, restDuration, restEnergy, rounds) {
      this.TabataSectionSets.push({
        work: {
          duration: workDuration,
          flex: this.setFlex(WorkEnergy),
        },
        rest: {
          duration: restDuration,
          flex: this.setFlex(restEnergy),
        },
        rounds: rounds
      });

    },

    populateTabataData(intervals) {
      this.TabataSectionSets = [];
      let workDuration = intervals[0].duration, WorkEnergy = intervals[0].energy;
      let restDuration = intervals[1].duration, restEnergy = intervals[1].energy;
      let counter = 1;

      for (let i = 2; i < intervals.length; i += 2) {
        if ((intervals[i].energy === WorkEnergy && intervals[i].duration === workDuration) && (restEnergy == intervals[i + 1].energy && intervals[i + 1].duration === restDuration)) {
          counter++;
        }
        else {
          this.populateNewTabataSet(workDuration, WorkEnergy, restDuration, restEnergy, counter);

          workDuration = intervals[i].duration, WorkEnergy = intervals[i].energy, restDuration = intervals[i + 1].duration, restEnergy = intervals[i + 1].energy;
          counter = 1;
        }

      }
      this.populateNewTabataSet(workDuration, WorkEnergy, restDuration, restEnergy, counter)
    },

    populateCustomData(intervals) {
      this.CustomSectionSets = [];
      intervals.forEach((interval) => {
        this.CustomSectionSets.push({
          duration: interval.duration,
          flex: this.setFlex(interval.energy)
        });
      });
    },

    convertHHMMSStoSeconds(timeHHMMSS) {

      let timeObj = timeHHMMSS.split(':');
      let secondsDuration = (+timeObj[0]) * 60 * 60 + (+timeObj[1]) * 60 + (+timeObj[2]);

      return secondsDuration;
    },

    setPageName() {
      this.$store.dispatch('changePageName', {pageNameString: 'Sections'});
    },
    createPlaylistDialog() {
      this.showDialogPlaylist = true;
      this.dialogKeyPlaylist++;
    },
    async getBluePrint() {
      const id = this.$route.params.id
      await $axios.get(`/templates/${id}`)
          .then((res) => {
            this.blueprint = res.data;
            this.canEdit = this.blueprint.playlists.length === 0;
            this.showSectionDetails();
          })
          .catch((error) => {
            console.log(error)
            let errMsg = error.response.data;
            this.$store.dispatch('showSnackbarMessage',
                {message: errMsg, duration: 6000, mode: 'fail'})
          });
    },

    editSection(section) {
          this.sectionToBeEdited = section;
          this.openSelectedTypeDialog(section.type_id,false);
       
    },
    openSelectedTypeDialog(type_id,flag=true) {
      if (flag)this.sectionToBeEdited =null;
      switch (type_id) {
        case 1:
          this.dialogKeyEmom++;
          this.showDialogEmom = true;
          break;
        case 2:
          this.dialogKeyTabata++;
          this.showDialogTabata = true;
          break;
        case 3:
          this.dialogKeyCustom++;
          this.showDialogCustom = true;
          break;
      }

    },

    getSectionType(typeId) {
      switch (typeId) {
        case 1 :
          return 'EMOM';
        case 2 :
          return 'Work/Rest'
        case 3 :
          return 'Custom';
      }
    },
    async deleteSection(sectionId){
      await SectionsService.deleteSingleSection(sectionId)
        .then((res) => {
          this.$store.dispatch('showSnackbarMessage', {
            message: `Section Deleted successfully.`,
            mode: 'success',
          });
          this.getBluePrint();
        })
        .catch((err) => {
        
          this.$store.dispatch('showSnackbarMessage', {
            message: `${err.message}. Please, try again.`,
            mode: 'fail',
          });
        })
    }
  },

  computed: {
    sectionsList: {

      get() {

        return this.blueprint.sections;
      },

      set(sectionsList) {
        let periodId = this.$route.params.id;
        sectionsList.forEach((section, index) => {
          section.order = index;
        });

        let dataForOrder = sectionsList.map(({id, order}) => ({id, order}));

        SectionsService.reorderSections({data: dataForOrder, id: periodId})
            .then(() => {
              this.getBluePrint();
              this.$store.dispatch('showSnackbarMessage', {
                message: 'Sections successfully reordered!',
                duration: 4000,
                mode: 'success'
              });
            })
            .catch(() => {

              this.$store.dispatch('showSnackbarMessage', {
                message: 'Failed. Please, try again.',
                duration: 5000,
                mode: 'fail'
              });
            });

        this.blueprint.sections = sectionsList;
      }
    },
  },

  created() {
    this.setPageName();
    this.getBluePrint();
  },

}
</script>

<style lang="scss" scoped>
.content-header.about-page {
  margin-bottom: 40px;
}

.menu-to-style {
  ::v-deep .md-ripple {
    border: 2px solid #A156A0 !important;
    border-radius: 6px !important;
  }
}

.menu-container {
  z-index: 100 !important;
}

.container {
  border: 3px solid rgba(255, 255, 255, 0.6);
  border-radius: 3rem;
  padding: 20px;
  margin-bottom: 20px;
}

p {
  align-self: center;
  font-size: large;
}

.header-engine {
  text-align: center;
  justify-content: center;
  margin-bottom: 50px;
}

.verticle-center {
  align-self: center;
}

.div-box {
  margin-bottom: 20px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  padding: 20px;
}

.align-right {
  text-align: right;
  margin-right: 10px;
}

.group-312 {
  text-align: left;
  gap: 6px;
  margin-bottom: 20px;
}

.div-top {
  background-color: unset;
  height: 300px;
  padding: 30px;
}

.div-mid {
  padding: 30px;
}


.div-bottom {
  padding: 30px;
}

.app-content {
  @media only screen and (max-width: 600px) {
			width: 100%;
			// padding: 1.6rem 2.4rem;
		};
}

.blueprint-name {
  margin-bottom: 20px;
  line-height: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;


  @media only screen and (max-width: 620px) {
    color: var(--material-themerefsecondarysecondary100);
    font-family: var(--font-family-figtree);
    font-size: var(--font-size-xxl);
    font-weight: 700;
    font-style: normal;
  };
}
.hide-index{
  @media only screen and (max-width: 620px) {
    display: none;
  }
}
.hide-label{
  @media only screen and (max-width: 420px) {
    display: none;
  }
}
.lets-create-a-bluep {
  letter-spacing: 0;
  line-height: 40px;
  white-space: nowrap;

  @media only screen and (max-width: 620px) {
    font-size: var(--font-size-l);
  };
}

.please-select-a-template-to-get-started {
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 3px;
  white-space: nowrap;
  text-align: left !important;

  @media only screen and (max-width: 620px) {
    font-size: var(--font-size-m);
  };
}

.template-options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.template-btn {
  cursor: pointer;
  align-items: center;
  background-color: var(--mine-shaft);
  border: 1px solid;
  border-color: var(--lavender);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  text-align: -webkit-center;
  padding: 10px;
  width: 32%;

  @media only screen and (max-width: 600px) {
    display: block;
  };
}

.timer_fill0_wght400_grad0_opsz24-2 {
  height: 33px;
  margin-left: -2px;
  min-width: 35px;

  @media only screen and (max-width: 600px) {
    height: 25px;
    min-width: 25px;
  };
}

.frame-326 {
  align-items: flex-start;
  display: flex;
  margin-right: -2px;
  padding: 10px 0 5px;
  width: fit-content;
}

.emom {
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  white-space: nowrap;
  width: fit-content;
  font-family: var(--font-family-figtree);
  font-size: var(--font-size-xl);
  font-weight: 500;
  font-style: normal;

  @media only screen and (max-width: 600px) {
    font-size: var(--font-size-m);
  };

  @media only screen and (max-width: 425px) {
    font-size: var(--font-size-s);
  };
}

.button {
  align-items: center;
  border: 1px solid;
  border-color: var(--material-themerefsecondarysecondary1007);
  border-radius: 4px;
  display: flex;
  height: 44px;
  justify-content: center;
  padding: 8px 24px;
  width: 148px;
  margin-top: 50px;
}

.button-1 {
  letter-spacing: 0;
  line-height: 20px;
  margin-left: -1.5px;
  margin-right: -1.5px;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
}

.workout-card {
  align-items: flex-start;
  background-color: var(--onyx);
  border-radius: 4px;
  display: flex;
}
.section-margin{
  margin-bottom: 5px;
}
.details-card {
  align-items: flex-start;
  background-color: #2F2F2F !important;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  border-color: rgb(64, 64, 64);
  // border-radius: 4px;
  display: flex;
  text-align: left;
  width: 99.5%;
  margin-left: .5%;
  padding-left: 50px;
  @media only screen and (max-width: 620px) {
    padding-left: 0;

  }
}
.row {
  height: 56px;
  padding: 16px 10px 16px 10px;
}

.section-1 {
  @media only screen and (max-width: 490px) {
    font-size: var(--font-size-xs)
  };
}

.row-1 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 16px;
  height: 56px;
  padding: 16px 10px 16px 10px;
}

.number {
  align-self: stretch;
  flex: 1;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  white-space: nowrap;

  @media only screen and (max-width: 490px) {
    font-size: var(--font-size-xs);
  };
}

.row-2 {
  height: 56px;
  padding: 16px 3px 16px 3px;
}

.emom2 {
  align-self: stretch;
  flex: 1;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  white-space: nowrap;

  @media only screen and (max-width: 490px) {
    font-size: var(--font-size-xs)!important;
  };
}

.row-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 16px;
  height: 56px;
  padding: 16px;
}

.frame-372 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 1;
  gap: 1px;
  justify-content: center;
}

.frame-371 {
  background-color: var(--malibu);
  border-radius: 1px;
  height: 7px;
  min-width: 4px;
}

.frame-370 {
  background-color: var(--dull-lavender);
  border-radius: 1px;
  height: 12px;
  min-width: 4px;
}

.frame-369 {
  background-color: var(--chicago);
  border-radius: 1px;
  height: 17px;
  min-width: 4px;
}

.frame-368 {
  background-color: var(--chicago);
  border-radius: 1px;
  height: 23px;
  min-width: 4px;
}

.frame-367 {
  background-color: var(--chicago);
  border-radius: 1px;
  height: 29px;
  margin-top: -5px;
  min-width: 4px;
}

.row-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 16px;
  height: 56px;
  padding: 16px;
}

.address {
  align-self: stretch;
  flex: 1;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  white-space: nowrap;

  @media only screen and (max-width: 490px) {
    font-size: var(--font-size-xs);
  };
}



.box-1 {
  flex: 6;
}

.box-2 {
  flex: 40;
}

.box-3 {
  flex: 24;
}


.box-4 {
  flex: 24;
}

.box-5 {
  align-self: center;
  flex: 6;
}
.box-6 {
  flex: 16;
}
.box-7 {
  flex: 33;
}
::v-deep .md-list.md-theme-default{
  background-color: var(--onyx)!important;
  padding: 0;
}

::v-deep .md-list-item-content{
  padding-left: 5px;
}
.md-list-item-content .md-ripple{
	max-width: 95px;
}
</style>
