const state = () => ({
    subscriptions: []
});

const getters = {
    getSubscriptions(state) {
        return state.subscriptions;
    }
};

const mutations = {
    setSubscriptions(state, subscriptions) {
        state.subscriptions = subscriptions;
    }
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}