<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">

        <md-dialog-title>Create A Workout Segment</md-dialog-title>

        <form class="reset-form">
            <div
                class="use-the-same-period md-layout-item md-raised md-xlarge-size-25 md-large-size-25 md-medium-size-100 md-small-size-100 md-xsmall-size-100 ">
                <div class="status-label">Use already created period:</div>
                <toggle-button v-model="isUseAlreadyCreatedPeriods" color="#a156a0" :sync="true" :width=110
                               :font-size=17 :height=35 :labels="{checked: 'Yes', unchecked: 'No'}"/>
            </div>

            <div v-if="isUseAlreadyCreatedPeriods">
                <AppSpinnerComponent v-if="loadingSpinner" :loading="loadingSpinner"/>

                <AppInputComponent
                    v-if="!loadingSpinner"
                    class="auth-form__field"
                    controlType="select"
                    required
                    placeholder="Choose a period from the dropdown"
                    v-model="selectedPeriodId"

                >
                    <option v-for="(period, index) in usedPeriods" :key="index" :value="period.id">
                        {{ period.name }}
                    </option>
                </AppInputComponent>
            </div>

            <AppInputComponent
                v-if="!isUseAlreadyCreatedPeriods"
                class="auth-form__field"
                required
                placeholder="Workout Segment Name (e.g. Warm Up)"
                v-model="$v.period.name.$model"
                :error="$v.period.name.$invalid && $v.period.name.$dirty"
                errorText="Name is required"
            />

            <!--            <AppInputComponent-->
            <!--                    class="auth-form__field"-->
            <!--                    required-->
            <!--                    type="number"-->
            <!--                    placeholder="Period Order"-->
            <!--                    v-model="$v.period.order.$model"-->
            <!--                    :error="$v.period.order.$invalid && $v.period.order.$dirty"-->
            <!--                    errorText="Period Order is required"-->
            <!--            />-->
            <md-dialog-actions>
                <md-button class="dialog__btn dialog__btn--danger" @click="closeDialog()">Cancel</md-button>

                <md-button
                    class="md-primary dialog__btn md-raised md-theme-light"
                    v-if="isUseAlreadyCreatedPeriods == false && !periodProp"
                    :disabled="$v.period.name.$invalid"
                    @click="createPeriod()"
                >
                    SUBMIT
                </md-button>

                <md-button
                    class="md-primary dialog__btn md-raised md-theme-light"
                    v-if="periodProp"
                    :disabled="$v.period.name.$invalid"
                    @click="editPeriod()"
                >
                    EDIT
                </md-button>

                <md-button
                    class="md-primary dialog__btn md-raised md-theme-light"
                    v-else
                    :disabled="selectedPeriodId == null"
                    @click="copyPeriod()"
                >
                    COPY
                </md-button>
            </md-dialog-actions>
        </form>
    </md-dialog>
</template>

<script>

    import {required} from 'vuelidate/lib/validators';
    import IntervalService from "../../services/interval.service";
    import PeriodService from '../../services/period.service';

    export default {
        name: 'CreatePeriodDialog',

        props: {
            showDialog: {
                type: Boolean,
                default: false
            },

            periodProp: {
                id: '',
                period_id: '',
                intervals: '',
                name: '',
                order: '',
                template_id: '',
                periodsLength: 0
            }
        },

        data: () => ({
            period: {
                id: '',
                period_id: '',
                intervals: '',
                name: '',
                order: '',
                template_id: '',
                periodsLength: 0
            },
            tempPeriod: {},
            intervals: [],
            selectedPeriodId: null,
            usedPeriods: [],
            isUseAlreadyCreatedPeriods: false,
            loadingSpinner: false,
        }),

        validations: {
            period: {
                name: {required},
            }
        },

        methods: {

            closeDialog() {

                this.$emit('update:showDialog', false);
            },

            setupCurrentPeriodCopy() {

                let periodData = {};

                let period = this.usedPeriods.find(x => x.id == this.selectedPeriodId);
                periodData= Object.assign({}, period);

                periodData.template_id = this.$route.params.id;
                periodData.order = this.$parent.periods.length + 1;

                return periodData;
            },

            setupCurrentPeriodCreate() {

                let periodData = {};

                periodData.name = this.period.name;
                periodData.template_id = this.$route.params.id;
                periodData.order = this.$parent.periods.length + 1;

                return periodData;
            },

            savePeriod(period) {

                PeriodService.createPeriod(period)
                    .then((res) => {

                        this.$emit('refreshAllPeriods');
                        this.$store.dispatch('showSnackbarMessage', {message: 'The period was successfully created.'});
                        this.closeDialog();
                    })
                    .catch((err) => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: `${err.message}. Please, try again.`,
                            mode: 'fail'
                        });
                    });
            },

            editPeriod() {

                PeriodService.editPeriod(this.period)
                    .then((res) => {

                        this.$emit('refreshAllPeriods');
                        this.$store.dispatch('showSnackbarMessage', {message: 'The period was successfully created.'});
                        this.closeDialog();
                    })
                    .catch((err) => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: `${err.message}. Please, try again.`,
                            mode: 'fail'
                        });
                    });
            },

           async createPeriod() {

                this.tempPeriod = await this.setupCurrentPeriodCreate();
                this.savePeriod(this.tempPeriod);
            },

            async copyPeriod() {

                this.tempPeriod = await this.setupCurrentPeriodCopy();
                this.savePeriod(this.tempPeriod);
            },

            getAllPeriods() {

                return this.$store.dispatch('periods/getPeriods')
                    .then((res) => {

                        this.usedPeriods = res;
                    })
                    .catch((err) => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: `${err.message}. Please, try again.`,
                            mode: 'fail'
                        });
                    });
            },

            async fetchPeriods() {

                await this.getAllPeriods();
            },

            saveInterval(intervalObj, newCreatedPeriodId) {

                intervalObj.period_id = newCreatedPeriodId;

                IntervalService.createInterval(intervalObj)
                    .then((res) => {

                    })
                    .catch(() => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: 'Failed. Please, try again.',
                            duration: 8000,
                            mode: 'fail'
                        });
                    })
            },
        },
        watch: {

            'isUseAlreadyCreatedPeriods': function (value) {

                this.loadingSpinner = true;
                let _this = this;

                if (value) {

                    _this.fetchPeriods().then(() => {

                        _this.loadingSpinner = false;
                    });
                }
            }
        },

        created() {

            if (this.periodProp) {

                this.period = this.periodProp;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @include dialogSettings();
    .dialog__hint, .use-the-same-period {
        margin-bottom: 16px;
    }
</style>
