<template>
  <div class="container feed-back" :md-active.sync="showDialog">
    <form>
      <h3>Report error or suggestion</h3>
      <input disabled type="text" required placeholder="Name" v-model="feedbackForm.name">

      <input disabled type="text" required placeholder="Mail" v-model="feedbackForm.email">
      <span v-if="$v.feedbackForm.email.$invalid && feedbackForm.email"><small class="email-text-error">Valid email is required</small></span>
      <textarea placeholder="Write something.." required style="height:200px" v-model="feedbackForm.message"></textarea>

      <div class="md-dialog-actions">
        <md-button class="close-button-color" @click.native="$emit('update:showDialog', false);">
          Close
        </md-button>
        <md-button class="md-theme-light md-raised md-primary" @click.native="sendFeedBackEmail()"
                   :disabled="$v.feedbackForm.$invalid || sendClicked">
          Send
        </md-button>
      </div>
    </form>
  </div>
</template>

<script>
import {required, email} from 'vuelidate/lib/validators';
import ContactService from "../../services/ContactService";

export default {
  name: "FeedbackDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    sendClicked: false,
    FeedbackDialogKey: 0,
    showFeedbackDialog: false,
    feedbackForm: {
      name: '',
      message: '',
      email: '',

    },
  }),
  validations: {
    feedbackForm: {
      name: {required},
      message: {required},
      email: {required, email},
    }
  },
  methods: {
    sendFeedBackEmail() {
      this.sendClicked = true;
      const feedbackObject = {
        name: this.feedbackForm.name,
        message: 'The user sent the feedback from the '+ window.location.href.substring(window.location.href.lastIndexOf('/') + 1) + ' page.And the message is ' +  this.feedbackForm.message,
        email: this.feedbackForm.email
      };

      ContactService.sendFeedback(feedbackObject)
          .then(() => {
            this.$store.dispatch('showSnackbarMessage', {
              message: 'Message was send successfully!',
              duration: 6000,
              mode: 'success'
            });
            this.$emit('update:showDialog', false);
            this.sendClicked = false;
          })
          .catch(() => {
            this.$store.dispatch('showSnackbarMessage', {
              message: 'Failed. Please, try again.',
              duration: 8000,
              mode: 'fail'
            });
            this.sendClicked = false;
          });
    },
  },
  created() {
    this.feedbackForm.name = this.$store.getters['auth/getCurrentUser'].first_name + ' ' +
        this.$store.getters['auth/getCurrentUser'].last_name;
    this.feedbackForm.email = this.$store.getters['auth/getCurrentUser'].email;
  }
}

</script>

<style scoped>

.close-button-color {
  background-color: #474747
}

.feed-back {
  position: absolute;
  bottom: 0;
  width: 340px;
  right: 0;
  z-index: 0;
}

.email-text-error {
  color: red;
  float: left;
  margin-left: 5px;
}

input[type=text], select, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #828282;
  background-color: #363636;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 10px;
  resize: vertical;
  color: white;
}

.container {
  border-radius: 5px;
  padding: 20px;
  background-color: #2C2C2C;
}
</style>
