<template>
	<div id="app">
		<transition name="fade" mode="out-in">
			<router-view></router-view>
		</transition>

		<md-snackbar
			v-if="snackbar"
			md-position="left"
			:md-active.sync="showSnackbar"
			:md-duration="snackbar.duration"
			md-persistent>
        <span
	        v-if="snackbar.mode != 'success'"
	        class="snackbar-text--fail">Oooops!</span>
			<span>{{ snackbar.message }}</span>
			<md-button class="md-primary md-theme-light" @click="clickSnackBarButton(snackbar.buttonText)">
				{{ snackbar.buttonText }}
			</md-button>
		</md-snackbar>

<!--		<div class="vld-parent">-->

<!--			<loading-overlay :active.sync="loadingOverlay"-->
<!--			         :can-cancel="true"-->
<!--			         :is-full-page="true"-->
<!--			         :color="'#A156A0'"-->
<!--			         :backgroundColor="'#3b3b58'"-->
<!--			         :loader="'dots'"-->
<!--			         :opacity="1"-->
<!--			>-->
<!--				<AnimatedLogoPreloader></AnimatedLogoPreloader>-->
<!--				<AppSpinnerComponent-->
<!--					:color="'#A156A0'"-->
<!--					:loading="true"/>-->
<!--			</loading-overlay>-->
<!--		</div>-->
	</div>
</template>

<script>
import AnimatedLogoPreloader from "@/components/Common/AnimatedLogoPreloader";
import LoadingOverlay from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'app',
	components: {
		LoadingOverlay,
		AnimatedLogoPreloader,
	},
	data: () => {
		return {
			showSnackbar: false,
		};
	},

	methods: {
		clickSnackBarButton(value) {

			if (value == 'Undo') {

				this.$root.$emit('undoDelete');
			}

			this.showSnackbar = false;
		},
	},

	computed: {
		snackbar() {

			return this.$store.getters['getSnackbarMessage'];
		},
		loadingOverlay() {

			return this.$store.getters['getLoadingOverlay'];
		},
	},

	watch: {
		snackbar() {
			this.showSnackbar = true;
		},
	},

	created() {

		// this.$store.dispatch('changeLoadingOverlay', {loading: true});
		//
		// setTimeout(() => {
		// 	this.$store.dispatch('changeLoadingOverlay', {loading: false});
		// }, 2500);

		// this.$store.dispatch('auth/getMe');
	},
};
</script>

<style lang="scss">
#app {
	// font-family: 'Avenir', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #fff;
}

#nav {
	padding: 30px;

	a {
		font-weight: bold;
		color: #2c3e50;

		&.router-link-exact-active {
			color: #42b983;
		}
	}
}

.snackbar-text--fail {
	color: $color-danger;
	margin-right: 3px;
}

.md-button {
  text-transform: none!important;
	overflow: visible !important;
}

.md-app.md-fixed .md-app-scroller{
    overflow: unset!important;
}

</style>
