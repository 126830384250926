<template>
    <div :class="[`frame-372`, className || ``]">
      <div class="frame-371"></div>
      <div class="frame-370-1"></div>
      <div class="frame-369"></div>
      <div class="frame-368"></div>
      <div class="frame-367"></div>
    </div>
  </template>
  
  <script>
    export default {
      name: "flexLevels",
      props: {
			className: {
				type: String,
				default: ''
			}}
    };
  </script>
  
  <style>
    .frame-372 {
      align-items: flex-end;
      display: flex;
      gap: 1px;
      width: 24px;
    }
  
    .frame-371 {
      background-color: var(--malibu);
      border-radius: 1px;
      height: 7px;
      min-width: 4px;
    }
  
    .frame-370-1 {
      background-color: var(--chicago);
      border-radius: 1px;
      height: 12px;
      min-width: 4px;
    }
  
    .frame-369 {
      background-color: var(--chicago);
      border-radius: 1px;
      height: 17px;
      min-width: 4px;
    }
  
    .frame-368 {
      background-color: var(--chicago);
      border-radius: 1px;
      height: 23px;
      min-width: 4px;
    }
  
    .frame-367 {
      background-color: var(--chicago);
      border-radius: 1px;
      height: 29px;
      min-width: 4px;
    }
  
    .frame-372.warm-up .frame-370-1,
    .frame-372.stretch-training-1 .frame-370-1,
    .frame-372.cardio-1 .frame-370-1,
    .frame-372.sprint-1 .frame-370-1 {
      background-color: var(--dull-lavender);
    }
  
    .frame-372.stretch-training-1 .frame-369,
    .frame-372.cardio-1 .frame-369,
    .frame-372.sprint-1 .frame-369 {
      background-color: var(--electric-violet);
    }
  
    .frame-372.cardio-1 .frame-368,
    .frame-372.sprint-1 .frame-368 {
      background-color: var(--red-violet);
    }
  
    .frame-372.sprint-1 .frame-367 {
      background-color: var(--thunderbird);
    }
  </style>
  