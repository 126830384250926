<template>
    <form class="md-layout create-form">
        <div  class="round-avatar-image">
			<img v-if="account.image_url" :src="account.image_url" alt="account-image">
			<img v-else src="@/assets/images/avatar-empty.png" alt="account-image">
            <input type="file" @change="showPreview($event)" ref="hiddenInput" class="input-file">
            <md-button class="upload-btn" v-if="userRole < 4"
                       @click.native="$refs.hiddenInput.click()">Upload
            </md-button>
            <div class="photo-error" v-if="showPhotoError">You must upload a photo file (png, jpg, jpeg or bmp) to add a logo !</div>
		</div>
        <md-field class="input">
            <label class="label figtree-normal-white-12px">Name</label>
            <md-input  style="width:100%;" class="figtree-normal-white-16px-2"
                    v-model="account.name" 
                    required
                    :disabled="userRole > 3"
                    :error="$v.account.name.$invalid && $v.account.name.$dirty"
                    errorText="Name is required">
            </md-input>
        </md-field>
        
        <md-field class="input">
            <label class="label figtree-normal-white-12px">EMAIL</label>
            <md-input  style="width:100%;" class="figtree-normal-white-16px-2"
                    v-model="account.email" 
                    :disabled="userRole > 3"
                    required
                    :error="$v.account.email.$invalid && $v.account.email.$dirty"
                    errorText="Valid email">
            </md-input>
        </md-field>
        <md-field class="input">
            <label class="label figtree-normal-white-12px">Street</label>
            <md-input  style="width:100%;" class="figtree-normal-white-16px-2"
                    v-model="account.street" 
                    :disabled="userRole > 3"
                    >
            </md-input>
        </md-field>
        <md-field class="input">
            <label class="label figtree-normal-white-12px">Suite</label>
            <md-input  style="width:100%;" class="figtree-normal-white-16px-2"
                    v-model="account.suite" 
                    :disabled="userRole > 3"
                    >
            </md-input>
        </md-field>
        <md-field class="input">
            <label class="label figtree-normal-white-12px">City</label>
            <md-input  style="width:100%;" class="figtree-normal-white-16px-2"
                    v-model="account.city" 
                    :disabled="userRole > 3"
                    >
            </md-input>
        </md-field>
        <md-field class="input">
            <label class="label figtree-normal-white-12px">State</label>
            <md-input  style="width:100%;" class="figtree-normal-white-16px-2"
                    v-model="account.state" 
                    :disabled="userRole > 3"
                    >
            </md-input>
        </md-field>
        <md-field class="input">
            <label class="label figtree-normal-white-12px">Zip</label>
            <md-input  style="width:100%;" class="figtree-normal-white-16px-2"
                    v-model="account.zip" 
                    :disabled="userRole > 3"
                    >
            </md-input>
        </md-field>
       
        <div v-if="account.hasOwnProperty('id')">
            <md-checkbox    :disabled="userRole > 3"
                            v-model="account.active"/>
            <span class="figtree-normal-white-16px" style="vertical-align: super">
                Active</span>
        </div>
        <div style=" display: flex;width: 100%;">
            <div style="flex:50"></div>
            <div style="flex:50;">
                <md-dialog-actions v-if="isDialog" class="form-buttons">

                    <md-button class="dialog__btn dialog__btn--danger"
                               v-if="userRole < 4"
                               id="closeBtn"
                               @click="closeDialog()">Cancel</md-button>
                    <md-button class="md-primary dialog__btn md-raised md-theme-light"
                               v-if="userRole < 4"
                               id="createBtn"
                               :disabled="$v.account.$invalid"
                               @click="createNewAccount()">Save Changes
                    </md-button>
                </md-dialog-actions>
                <md-dialog-actions v-if="!isDialog" class="form-buttons dialalog-form-buttons">
    
                    <md-button class="md-transparent dialog__btn md-raised md-theme-light"
                               v-if="userRole < 4"
                               @click="$router.go(-1)">GO BACK
                    </md-button>
                    <md-button class="md-primary dialog__btn md-raised md-theme-light"
                               v-if="userRole < 4"
                               :disabled="$v.account.$invalid"
                               id="updateBtn"
                               @click="createNewAccount()">UPDATE
                    </md-button>
                </md-dialog-actions>
            </div>

        </div>
    </form>
</template>


<script>

    import {email, required, requiredIf} from 'vuelidate/lib/validators';
    import {mapGetters} from "vuex";

    export default {
        name: 'CreateAccountModal',

        props: {
            showDialog: {
                type: Boolean,
                default: false
            },
            accountProp: {
                type: Object,
                default: function () {

                    return {
                        id: '',
                        name: '',
                        email: '',
                        street: '',
                        suite: '',
                        city: '',
                        state: '',
                        zip: '',
                        image_url:null,
                        stripe_customer_id: '',
                        active: null,
                        primary_contact_id: null
                    }
                }
            },
            isDialog: {
                type: Boolean,
                default: false
            },
        },

        data() {

            return {
                previewUrl: '',
                showPhotoError: false,
                account: null,
            }
        },

        validations: {
            account: {
                email: {required, email},
                name: {required},
                logo: {
                    required: requiredIf(function(){
                        return !this.account.hasOwnProperty('id')
                    })},
            }
        },

        methods: {

            showPreview(event) {
                const input = event.target;

                console.log(input.files);

                if (input.files && input.files[0]) {

                    let splitFile = input.files[0].name.split('.');
                    let splitFileLength = splitFile.length;
                    let fileExtension = (input.files[0].name.split('.')[splitFileLength - 1]).toLowerCase();

                    if (fileExtension === 'png' || fileExtension === 'jpeg' || fileExtension === 'jpg' ||
                        fileExtension === 'bmp') {

                        this.account.logo = input.files[0];
                        const reader = new FileReader();

                        reader.onload = e => {
                            this.previewUrl = e.target.result;
                            input.value = '';
                        };

                        reader.readAsDataURL(input.files[0]);

                        this.showPhotoError = false;

                        console.log(this.showPhotoError);
                    } else {
                        this.showPhotoError = true;
                    }
                }
            },

            closeDialog() {

                this.$emit('closeDialog');
            },

            createNewAccount() {

                this.$emit('createNewAccount', this.account);
                this.closeDialog();
            },
        },

        computed: {
            ...mapGetters(
                {
                  userRole: 'auth/currentUserRole',
                  currentUser:'auth/getCurrentUser'
                },
            ),
        },

        created() {

            this.account = this.accountProp;
        }
    }
</script>

<style lang="scss" scoped>
    @include dialogSettings();
    .dialog__hint {
        margin-bottom: 16px;
    }

    .md-dialog {

        .choose-file-btn {
            width: 30%;
        }

        .upload-cont {
            text-align: center;
        }

        .file-upload-label {
            font-size: 2rem;
        }
    }

    .form-buttons {
        margin-top: 20px;
        // justify-content: space-around;
    }

    .input-file {
        display: none;
    }

    .create-form {

        .md-layout-item {
            margin: 0 auto;
        }
    }

    .md-dialog-title {
        text-align: center;
        font-size: 2.3rem;
    }

    .upload-dialog__preview {
        /*max-width: 100px;*/
	    margin: 20px auto;
    }

    .photo-error {
        color: #FF0900;
	    font-size: 1.7rem;
	    margin: 7px auto 0;
    }

    .active-container {
        text-align: center;

        .status-label {
            margin-top: 25px;
            margin-bottom: 20px;
            font-size: 2.4rem;
        }
    }
    .label {
        padding-top: 2px;
        padding-left: 10px;
        letter-spacing: 0.4px;
        line-height: 16px;
        margin-top: -1px;
        white-space: nowrap;
        width: fit-content;
    }
    .input {
      align-items: flex-start;
      background-color: var(--tuatara);
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-color: var(--silver-chalice);
      border-radius: 4px 4px 0 0;
      padding-left: 10px;
    }
	.round-avatar-image img {
		border-radius: 50%;
		width: 46px;
		height: 46px;
	}
    .round-avatar-image{
      padding-left: 10px;

        margin-bottom: 40px;
    }
    .upload-btn{
        width: 92px;
        height: 30px;
        background: rgba(255, 255, 255, 0.12);
        border-radius: 5px;
    }
    .action-btns{
        display: flex;
        justify-content: end;
    }
</style>