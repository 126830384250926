<template>
  <div v-if="isRegisterProp" class="owner-form">
    <div style="min-height: 63px;display: flex;align-items: center;" class=" figtree-medium-white-20px">
      Facility Owner
    </div>
    <form style="width:100%;padding: 0px 25px 0px 25px;"
        class="auth-form auth-form--with-subhead  md-layout md-alignment-center-center md-gutter"
        v-on:keyup.enter="register()">
				<div style="display: flex;flex-direction: column;width: 100%;gap: 0px;">
					<div style="flex:10">
            <md-field  :class="[{ 'md-invalid': $v.regForm.first_name.$error && !$v.regForm.first_name.required},'input']">
							<label class="label figtree-normal-white-12px">First Name</label>
							<md-input   style="width:100%;" class="figtree-normal-white-16px-2"
									v-model="$v.regForm.first_name.$model"
									required
									>
								</md-input>
								<span class="md-error" v-if="$v.regForm.first_name.$error && !$v.regForm.first_name.required">First Name is required</span>
						</md-field>
          </div>

					<div style="flex:10">
            <md-field  :class="[{ 'md-invalid': $v.regForm.last_name.$error && !$v.regForm.last_name.required},'input']">
							<label class="label figtree-normal-white-12px">Last Name</label>
							<md-input   style="width:100%;" class="figtree-normal-white-16px-2"
									v-model="$v.regForm.last_name.$model"
									required
									>
								</md-input>
								<span class="md-error" v-if="$v.regForm.last_name.$error && !$v.regForm.last_name.required">Last Name is required</span>
						</md-field>
          </div>
          <div style="flex:10">
            <md-field  :class="[{ 'md-invalid': $v.regForm.email.$invalid && $v.regForm.email.$dirty},'input']">
							<label class="label figtree-normal-white-12px">Email</label>
							<md-input   style="width:100%;" class="figtree-normal-white-16px-2"
									v-model="$v.regForm.email.$model"
									required
									>
								</md-input>
								<span class="md-error" v-if="$v.regForm.email.$invalid && $v.regForm.email.$dirty">Valid email is required</span>
						</md-field>
          </div>
          <div  style="flex:10">
            <md-field  :class="[{ 'md-invalid': $v.regForm.password.$error && !$v.regForm.password.required},'input']">
							<label class="label figtree-normal-white-12px">Password</label>
              <md-input   style="width:100%;" class="figtree-normal-white-16px-2"
									v-model="$v.regForm.password.$model"
									required
                  type="password"
									>
								</md-input>
								<span class="md-error" v-if="$v.regForm.password.$error && !$v.regForm.password.required">Password is required</span>
						</md-field>
          </div> 

          <div  style="flex:10">
            <md-field  :class="[{ 'md-invalid': $v.regForm.account_name.$error && $v.regForm.account_name.required},'input']">
							<label class="label figtree-normal-white-12px">Account Name</label>
              <md-input   style="width:100%;" class="figtree-normal-white-16px-2"
									v-model="$v.regForm.account_name.$model"
									required
									>
								</md-input>
								<span class="md-error" v-if="$v.regForm.account_name.$error && $v.regForm.account_name.required">Name is required</span>
						</md-field>
          </div> 

          <div  style="flex:10">
            <md-field  :class="[{ 'md-invalid': $v.regForm.account_email.$invalid && $v.regForm.account_email.$dirty},'input']">
							<label class="label figtree-normal-white-12px">Account Email</label>
              <md-input   style="width:100%;" class="figtree-normal-white-16px-2"
									v-model="$v.regForm.account_email.$model"
									required
									>
								</md-input>
								<span class="md-error" v-if="$v.regForm.account_email.$invalid && $v.regForm.account_email.$dirty">Valid email is required</span>
						</md-field>
          </div> 
          <div  style="flex:10;text-align: left;">
            <md-checkbox v-model="termsAndConditionsApproved"/>
            <span style="vertical-align: super"> I agree to the <a style="color: #fca5fb;cursor: pointer;" @click="openTermsAndConditions">
              Terms of Use and Privacy Policy</a></span>
          </div>
          <div  style="flex:10;text-align: left;">
            <md-checkbox  v-model="regForm.is_wtf_member"/>
            <span style="vertical-align: super" class="figtree-normal-white-16px-white-16px">I am a WTF Gym Talk Member</span>
          </div>
          <div  style="flex:20;text-align: right;">

          <md-button
              class="dialog__btn dialog__btn--danger  back-button"
              @click="goBack()"
          >
           Cancel
          </md-button>
    
          <md-button
              class="md-primary  md-raised auth-form__submit-btn md-theme-light figtree-normal-white-16px" style=" height: 44px;min-width: 60px;padding: 12px 40px;"
              :disabled="$v.regForm.$invalid || !termsAndConditionsApproved"
              @click="register()"
          >
            Register
          </md-button>
          </div>
        </div>
     

    </form>
  </div>
</template>

<script>
import {email, required} from "vuelidate/lib/validators";

export default {
  name: "AuthRegisterFormOwner",

  props: {
    isRegisterProp: {
      type: Boolean,
      default: false
    },
  },

  data: () => ({
    regForm: {
      first_name: null,
      last_name: null,
      email: null,
      password: null,
      account_email: null,
      account_name: null,
      is_wtf_member: false,
      account: true,
      is_trainer: false
    },
    termsAndConditionsApproved: false
  }),

  validations: {

    regForm: {
      first_name: {required},
      last_name: {required},
      email: {required, email},
      password: {required},
      account_email: {required, email},
      account_name: {required},
      is_wtf_member: {required}
    },
  },

  methods: {

    register() {

      this.$emit('register', this.regForm)
    },

    goBack() {

      this.$emit('update:isRegisterProp', false);
    },

    openTermsAndConditions() {
      let routeData = this.$router.resolve({name: 'terms-and-conditions'});
      window.open(routeData.href, '_blank');
    }
  }

}
</script>

<style lang="scss" scoped>

@include authPage;
.owner-form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;
  background: #2C2C2C;
  border: 1px solid #3C3C3C;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 0px 0px 41px;
  height: 750px;
  width: 513px;
		@media only screen and (max-width: 481px) {
      width:98%;
		};
}
</style>
