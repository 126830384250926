<template>
  <div class="component-wrap">
       <AppHeaderComponent
            class="marketing-page"
            headline="Awesome landing page">
            
            <md-button
                class="md-raised md-primary md-raised md-theme-light">
                Open in new window</md-button>
        </AppHeaderComponent>

        <div class="landing-page__copy half-width">
            
            <p class="md-body-1">
                <strong>Description:</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris 
                nisi ut aliquip ex ea commodo consequat. 
                Duis aute irure dolor in reprehenderit in voluptate velit esse 
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat 
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
            </p>

            <p class="md-body-1">
                <strong>Goals:</strong> Lorem ipsum dolor sit amet, 
                consectetur adipiscing elit, sed do eiusmod tempo, 
                em ipsum dolor sit amet, consectetur adipiscing 
                elit, sed do eiusmod tempo.
            </p>

        </div>

        <ReportPieChart
            name="Trial mode conversion"
            class="half-width"
            graphIndex="6"
            :graphData="pieChartData"
        />

        <!-- Average Lifitime Customer Value -->
        <RepportLineGraph
            name="AVERAGE LIFETIME CUSTOMER VALUE"
            class="full-width"
            yAxisProperty="value"
            xAxisProperty="time"
            graphIndex="2"
            :graphData="[mainGraph[0]]"
        />


  </div>
</template>

<script>
import RepportLineGraph from "@/components/Reports/ReportLineGraph"
import ReportPieChart from "@/components/Reports/ReportPieChart"

export default {
    name: "MarketingLandingPage",
    components: {
        RepportLineGraph,
        ReportPieChart
    },
    data: () => ({ pieChartData: [
            {key: 'yes', value: '40', color: '#53a8e2'},
            {key: 'no', value: '60', color: '#76ddfb'}
        ],
        mainGraph: [
            {
                name: "Turnover",
                color: "#53a8e2",
                data: [
                    {
                        value: 40,
                        time: '01/01/2019'
                    },
                    {
                        value: 30,
                        time: '02/01/2019'
                    },
                    {
                        value: 45,
                        time: '03/01/2019'
                    },
                    {
                        value: 42,
                        time: '04/01/2019'
                    },
                    {
                        value: 37,
                        time: '05/01/2019'
                    },
                    {
                        value: 40,
                        time: '06/01/2019'
                    },
                    {
                        value: 45,
                        time: '07/01/2019'
                    },
                    {
                        value: 48,
                        time: '08/01/2019'
                    },
                    {
                        value: 46,
                        time: '09/01/2019'
                    },
                    {
                        value: 40,
                        time: '10/01/2019'
                    },
                    {
                        value: 48,
                        time: '11/01/2019'
                    },
                    {
                        value: 46,
                        time: '12/01/2019'
                    }
                ]
            },
            {
                name: "New",
                color: "#76ddfb",
                data: [
                    {
                        value: 20,
                        time: '01/01/2019'
                    },
                    {
                        value: 24,
                        time: '02/01/2019'
                    },
                    {
                        value: 22,
                        time: '03/01/2019'
                    },
                    {
                        value: 28,
                        time: '04/01/2019'
                    },
                    {
                        value: 35,
                        time: '05/01/2019'
                    },
                    {
                        value: 32,
                        time: '06/01/2019'
                    },
                    {
                        value: 35,
                        time: '07/01/2019'
                    },
                    {
                        value: 42,
                        time: '08/01/2019'
                    },
                    {
                        value: 45,
                        time: '09/01/2019'
                    },
                    {
                        value: 39,
                        time: '10/01/2019'
                    },
                    {
                        value: 42,
                        time: '11/01/2019'
                    },
                    {
                        value: 36,
                        time: '12/01/2019'
                    }
                ]
            },
            {
                name: "Existing",
                color: "#2c82be",
                data: [
                    {
                        value: 15,
                        time: '01/01/2019'
                    },
                    {
                        value: 10,
                        time: '02/01/2019'
                    },
                    {
                        value: 8,
                        time: '03/01/2019'
                    },
                    {
                        value: 9,
                        time: '04/01/2019'
                    },
                    {
                        value: 16,
                        time: '05/01/2019'
                    },
                    {
                        value: 10,
                        time: '06/01/2019'
                    },
                    {
                        value: 12,
                        time: '07/01/2019'
                    },
                    {
                        value: 16,
                        time: '08/01/2019'
                    },
                    {
                        value: 5,
                        time: '09/01/2019'
                    },
                    {
                        value: 10,
                        time: '10/01/2019'
                    },
                    {
                        value: 15,
                        time: '11/01/2019'
                    },
                    {
                        value: 16,
                        time: '12/01/2019'
                    }
                ]
            },
        ]
    }),
    methods: {

    }
}
</script>

<style lang="scss" scoped>
.component-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.content-header.marketing-page {
    width: 100%;
    margin-bottom: 40px;
}
.full-width {
    width: 100%;
}
.half-width {
    width: 49%;

    @include respond(small) {
        width: 100%;
    }
}
.landing-page__copy p{
    font-size: 16px;
    font-weight: 300;
    line-height: 1.6;
    text-align: left;
    margin-bottom: 4rem;
}
::v-deep .md-card.graph-wrap {
    margin: 0 0 3.2rem;
}
</style>