<template>
    <div class="component-wrap">
        <div v-if="store" class="deleted-header">
            <div style="flex:30" >
                <div class="figtree-medium-white-32px">{{userRole === 1 ? 'Deleted Blueprints' : store.name + ' Deleted Blueprints'}}</div>
            </div>
            <div style="flex:30">
                <p class="if-you-deleted-a-blufigtree-medium-white-16px">
                    If you delete a Blueprint or Playlist, you can bring it back within 90 days of deleting it. <br />Find the Blueprint or Playlist below and click the Restore button to bring it back to your Dashboard.
                 </p>
            </div>
        </div>
        
        <AppEmptyStateComponent message="There are no deleted Blueprints at this time." v-if="bluePrints.length == 1 && !loadingSpinner"/>
        <div v-if="store && bluePrints.length > 1"  class="blueprint-container">
            <div class="blueprint-playlist-table">
                <div style="flex: 5;" class="figtree-medium-white-22px">Blueprints</div>
                <div style="flex: 90;">
                    <div class="list-group-header figtree-semi-bold-pink-swan-18px deleted-blueprints">
                        <div style="flex:25">Deleted After</div>
                        <div style="flex:40">Title</div>
                        <div style="flex:10">Playlists</div>
                        <div style="flex:15"></div>
                    </div>

                    <div class="list-group figtree-semi-bold-pink-swan-18px deleted-blueprints"  v-for="(bluePrint, index) in bluePrints" :key="index + '_bluePrints'" v-if="bluePrint.id" :style="`border-left-color: ${bluePrint.color ? bluePrint.color : getRandomColor(index)};`">
                        <div :style="{flex:isMobile ? 30 : 25}">{{ bluePrint.delete_after }}</div>
                        <div :style="{flex:isMobile ? 45 : 40}">{{ bluePrint.name }}</div>
                        <div style="flex:10;">{{ bluePrint.playlists.length }}</div>
                        <div :style="[{flex:isMobile ? 10 : 15}]">
                            <div v-if="isMobile"
                                class="figtree-normal-white-18px"
                                @click="restoreBlueprint(bluePrint.id)"> <md-icon>restore</md-icon>
                            </div>
                            <md-button v-else
                                class="restore-btn figtree-normal-white-18px"
                                id="delete-blueprints-btn"
                                @click="restoreBlueprint(bluePrint.id)">  Restore
                            </md-button>

                        </div>



                    </div>
                </div>

            </div>
        </div>
        <AppEmptyStateComponent message="There are no deleted bluePrints to display. as you are from different store" v-if="!store && !loadingSpinner"/>

        <AppSpinnerComponent v-if="loadingSpinner" :loading="loadingSpinner"/>

    </div>
</template>

<script>

    import BluePrintCard from "@/components/BluePrints/BluePrintCard"
    import BluePrintService from '../../services/bluePrint.service';
    import StoreService from '../../services/store.service';
    import {mapGetters} from "vuex";
    import {RandomColorsMixin} from "../../mixins/RandomColorsMixin";
    import { CheckWindowWidthMixin } from "../../mixins/CheckWindowWidthMixin";
import { text } from "body-parser";

    export default {
        name: 'TemplatesDeletedDashboard',
        mixins: [RandomColorsMixin, CheckWindowWidthMixin],
        data: function() {
          return {
            bluePrints: [],
            store: null,
            loadingSpinner: true,
            intervalIdTimeout : null
          }
        },

        components: {
            BluePrintCard
        },

         async created() {

           this.setPageName();

           await this.getStore();
           this.getAllBlueprints();
           this.setLoadingSpinner(false);

           this.intervalIdTimeout = window.setInterval(() => {

             this.getAllBlueprints();
           }, 300000);
         },

        methods: {

            restoreBlueprint(id){

                BluePrintService.restoreBlueprint({ id: id })
                    .then((res) => {

                        this.getAllBlueprints();

                        this.$store.dispatch('showSnackbarMessage', {
                            message: 'The Blueprint was successfully restored to the Dashboard.',
                            duration: 3000,
                            mode: 'success'
                        });
                    })
                    .catch((err) => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: `${err.message}. Please, try again.`,
                            mode: 'fail'
                        });
                    });
            },

            setPageName(){

                this.$store.dispatch('changePageName', { pageNameString: 'Archived Blueprints'});
            },

            setLoadingSpinner(isLoading) {

                this.loadingSpinner = isLoading;
            },

            getAllBlueprints(){
             const storeId =  this.$route.params.id;
              BluePrintService.getAllDeletedBlueprints(storeId)
                    .then((res) => {
                        this.bluePrints = res;
                        this.setLoadingSpinner(false);
                        this.bluePrints.push({});

                    })
                    .catch((err) => {
                        this.$store.dispatch('showSnackbarMessage', {
                            message: `${err.message}. Please, try again.`,
                            mode: 'fail'
                        });
                    });

            },
          getStore(){
            const storeId =  this.$route.params.id;
            StoreService.getSingleStore(storeId)
                .then((res) => {
                  this.store = res;
                })
                .catch((err) => {
                  this.$store.dispatch('showSnackbarMessage', {
                    message: `${err.message}. Please, try again.`,
                    mode: 'fail'
                  });
                });

          },

            goToSingleBluePrint(id){

                this.$router.push('/blueprints/' + id + '/periods');
            },

            goBackToDashboard(){

                this.$router.push('/dashboard');
            },

            turnOffLoadingSpinner() {

            }
        },
      computed: {

        ...mapGetters(
            {
              userRole: 'auth/currentUserRole',
              showTourGuide: 'getIsTourGuideActive',
            },
        ),
      },
        beforeDestroy() {
	        window.clearInterval(this.intervalIdTimeout);
    	}
    }
</script>

<style lang="scss" scoped>
    ::v-deep .content-header{
        margin-bottom: 60px!important;
    }

    .content__subtitle {
        text-align: left;
        margin: 5.6rem 0 2.4rem;
    }
    .list-group{
        display: flex;
        align-items: center;
        padding: 0px 0px 0px 10px;
        height: 60px;
        background: #212121;
        margin-bottom: 1px;
        border-left: 2px solid;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;


    }
    .list-group-header{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        height: 56px;
        background: #3A3A3A;
        border-radius: 4px 4px 0px 0px;
        padding: 0px 0px 0px 10px;

    }
    .restore-btn{
        border: 1px solid;
        border-color: var(--cultured-pearl);
        border-radius: 5px;
        width: 140px;
        height: 50px;
        @media only screen and (max-width: 834px) {
            font-size: var(--font-size-s);
            width: fit-content;
            height: 35px;
        };
    }
    .blueprint-playlist-table{
        flex: 50;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 15px;
        text-align: left;
    }
    .deleted-header{
        display: flex;
        flex-direction: column;
        gap:20px;
        text-align: left;
        width: 100%;
        margin: 40px 0px 30px 0px;
    }
    .blueprint-container{
        display:flex;
        flex-direction: column;
        width: 85%;
        gap: 25px;
        @media only screen and (max-width: 834px) {
            width: 100%;
        };
    }
    .deleted-blueprints{
      @media only screen and (max-width: 834px) {
        font-size: var(--font-size-m);
        };
        @media only screen and (max-width: 520px) {
          font-size: var(--font-size-s);
          };
    }
</style>
