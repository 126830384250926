<template>
	<div class="component-wrap" style="width:100%">
		<div  class="deleted-header">
            <div style="flex:30" >
                <div class="figtree-medium-white-32px">Archived Playlists</div>
            </div>
            <div style="flex:30">
                <p class="figtree-medium-white-16px">
					If you archive a Blueprint or Playlist, it will remain in the Archived folder forever. <br />Find the Blueprint or Playlist below and click the Restore button to bring it back to your Dashboard.
                 </p>
            </div>
        </div>

		<AppEmptyStateComponent message="There are no archived Playlists at this time."
								v-if="playlists.length == 1 && !loadingSpinner"/>
		<div v-if="playlists.length > 1 && !loadingSpinner" class="blueprint-container">
            <div class="blueprint-playlist-table">
                <div style="flex: 5;" class="figtree-medium-white-22px">Playlists</div>
                <div style="flex: 90;">
		    		<div class="list-group-header figtree-semi-bold-pink-swan-18px deleted-blueprints">
						<div style="flex:3;"></div>
						<div style="flex:37">Title</div>
            		    <div style="flex:15">Songs</div>
            		    <div style="flex:15">Duration</div>
            		    <div class="actions-btn" style="opacity: 0;" >re</div>
					
            		</div>
    				<div class="container">
    				  <ArchivedDeletedPlaylistCard
    				      :id="'playlists-archived-' + index"
    				      v-for="(playlist, index) in playlists"
    				      v-show="playlist.id"
    				      :isArchived="true"
    				      :period="playlist"
    				      :key="index + '_playlists'"
    				      :randomColor="getRandomColor(index)"
    				      @click.native="goToSinglePlaylist(playlist.id)"
    				      @refreshPlaylists="getAllPlaylists"
    				      @setLoadingSpinner="setLoadingSpinner"
    				      @tempRemovePlaylist="tempRemovePlaylist"
    				      @editPlaylist="editPlaylist(playlist.id)"
    				      @restorePlaylist="restorePlaylist"
    				      @unArchivePlaylist="unArchiveCurrentPlaylist(playlist.id)"
    				  />
    				</div>
				</div>
			</div>
		</div>
		<AppSpinnerComponent v-if="loadingSpinner" :loading="loadingSpinner"/>

	</div>
</template>

<script>

	import ArchivedDeletedPlaylistCard from "@/components/Playlists/ArchivedDeletedPlaylistCard"

	import PlaylistService from '@/services/playlists.service';
import { CheckWindowWidthMixin } from "../../mixins/CheckWindowWidthMixin";
  import {RandomColorsMixin} from "../../mixins/RandomColorsMixin";

	export default {
		name: 'PlaylistsArchivedDashboard',
    mixins: [RandomColorsMixin,CheckWindowWidthMixin],
		data: function () {
			return {
				playlists: [],
				tempPlaylists: null,
				playlistToEdit: {},
				loadingSpinner: true,
				showDialog: false,
				dialogKey: 0,
				intervalIdTimeout:null
			}
		},

		components: {
			ArchivedDeletedPlaylistCard,
		},

		created() {

			this.setPageName();

			this.setLoadingSpinner(false);
			this.getAllPlaylists();

			this.$root.$on('undoDelete', this.restorePlaylist);
			//Refresh templates every 5 min
			this.intervalIdTimeout = window.setInterval(() => {

				this.getAllPlaylists();
			}, 300000);
		},

		methods: {
			unArchiveCurrentPlaylist(id) {

				PlaylistService.unArchivePlaylist(id)
					.then((res) => {

						this.getAllPlaylists();

						this.$store.dispatch('showSnackbarMessage', {
							message: 'The Playlist was successfully unarchived.',
							duration: 3000,
							mode: 'success'
						});
					})
					.catch((err) => {
						this.$store.dispatch('showSnackbarMessage', {
							message: `${err.message}. Please, try again.`,
							mode: 'fail'
						});
					});
			},

			setPageName() {

				this.$store.dispatch('changePageName', {pageNameString: 'Archived Playlists'});
			},

			openBuePrintDialog() {

				this.dialogKey++;
				this.playlistToEdit = "";
				this.showDialog = true;
			},

			editPlaylist(playlistId) {

				PlaylistService.getSinglePlaylist(playlistId)
					.then((res) => {

						this.dialogKey++;
						this.playlistToEdit = res;
						this.showDialog = true;
					})
					.catch((err) => {

						this.$store.dispatch('showSnackbarMessage', {
							message: `${err.message}. Please, try again.`,
							mode: 'fail'
						});
					});
			},

			tempRemovePlaylist(id) {

				this.setLoadingSpinner(true);
				this.playlists = this.playlists.filter(playlist => playlist.id != id);
				this.$store.commit('playlists/setTempPlaylists', this.playlists);
			},

			restorePlaylist(id) {

				this.$store.commit('playlists/setIsUndoActive', true);
				this.getAllPlaylists();
				this.$store.commit('playlists/setTempPlaylists', '');
				this.setLoadingSpinner(false);

				this.$store.dispatch('showSnackbarMessage', {
					message: 'The playlist was successfully restored.',
					duration: 3000,
					mode: 'success'
				});
			},

			setLoadingSpinner(isLoading) {

				this.loadingSpinner = isLoading;
			},

			getAllPlaylists() {

				PlaylistService.getAllArchivedPlaylists(this.$route.params.id)
					.then((res) => {

						this.playlists = res;
						this.setLoadingSpinner(false);
						this.playlists.push({});

					})
					.catch((err) => {

						this.$store.dispatch('showSnackbarMessage', {
							message: `${err.message}. Please, try again.`,
							mode: 'fail'
						});
					});

			},

			goToSinglePlaylist(id) {

				this.$router.push('/playlists/' + id );
			},

			goBackToDashboard(id) {

				this.$router.push('/dashboard');
			},

			turnOffLoadingSpinner() {

			}
		},
		beforeDestroy() {
        	window.clearInterval(this.intervalIdTimeout);
    	},
	}
</script>

<style lang="scss" scoped>
	::v-deep .content-header {
		margin-bottom: 60px !important;
	}

	.content__subtitle {
		text-align: left;
		margin: 5.6rem 0 2.4rem;
	}

  .list-group-header{
		gap: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        height: 56px;
        background: #3A3A3A;
        border-radius: 4px 4px 0px 0px;
        padding: 0px 0px 0px 10px;

    }
	.blueprint-playlist-table{
        flex: 50;
        display: flex;
        flex-direction: column;
        width: 85%;
        gap: 15px;
        text-align: left;
		@media only screen and (max-width: 834px) {
            width: 100%;
        };
    }
	.blueprint-container{
        display:flex;
        flex-direction: column;
		width: 100%;

        gap: 25px;
        @media only screen and (max-width: 834px) {
            width: 100%;
        };
    }
	.actions-btn{
		flex:15;
		text-align: right;
		@media only screen and (max-width: 481px) {
         flex: 8;
        };
	}
	.deleted-header{
        display: flex;
        flex-direction: column;
        gap:20px;
        text-align: left;
        width: 100%;
        margin: 40px 0px 30px 0px;
    }
</style>
