<template>
  <div class="component-wrap">
    <div class="song-row song-border">
      <div class="song-column">
        <AppInputComponent placeholder="Filter Songs By Name" v-model="filterUserString"/>
      </div>

<!--      TODO: song processing status types should be fetched from the DB-->
      <div class="song-column">
        <AppInputComponent controlType="select" v-model="filterStatusString" placeholder="Filter Songs By Status">
          <option value="1">Missing MetaData</option>
          <option value="2">MetaData Review</option>
          <option value="3">Waiting Review</option>
          <option value="4">Reviewed</option>
          <option value="5">Missing Song File</option>
          <option value="true">Processing</option>
        </AppInputComponent>
      </div>

      <div class="song-column">
        <md-field>
          <label>Choose Genres</label>
          <md-select v-model="selectedGenre" multiple>
            <md-option v-for="(genre, index) in genres" :key="index" :value="genre.id">{{ genre.name }}
            </md-option>
          </md-select>
        </md-field>
      </div>

      <div class="song-column">
        <md-field>
          <label>Choose Energy levels</label>
          <md-select v-model="selectedEnergy" multiple @change="selectedEnergy">
            <md-option v-for="n in 5" :value="n">{{ n }}
            </md-option>
          </md-select>
        </md-field>
      </div>
    </div>

    <div>
      <AppHeaderComponent style="margin-top: 25px;margin-bottom: 25px" headline="Songs"></AppHeaderComponent>
    </div>

<!--    <div class="song-border" style="margin-bottom: 25px">-->
      <div style="height: 60px">
<!--        <div class="md-layout md-layout-item md-size-30 md-small-size-100" style="float: left">-->
<!--          <md-button class="md-layout-item md-raised md-primary md-theme-light" id="set-active-status-button"-->
<!--                     @click="setAllSelectedWithStatus('1')">-->
<!--            set active-->
<!--          </md-button>-->

<!--          <md-button class="md-layout-item md-raised md-primary md-theme-light" id="set-inactive-status-button"-->
<!--                     @click="setAllSelectedWithStatus('0')">-->
<!--            set inactive-->
<!--          </md-button>-->
<!--        </div>-->

        <div class="md-layout md-layout-item md-size-30 md-small-size-100 song-border" style="float: right;padding: 3px">
          <md-button
              class="md-layout-item md-raised md-primary md-theme-light"
              id="add-song-button" @click.native="openAddSongModal()">
            Add New Song
          </md-button>
          <md-button
              class="md-layout-item md-raised md-primary md-theme-light"
              id="export-song-button" @click.native="exportSongs()">
            Export excel
          </md-button>
        </div>
      </div>

<!--      <div class="md-alignment-bottom-left" style="text-align: left">-->
<!--        <md-checkbox-->
<!--            :id="`select-all-checkbox`" style="padding-left: 1.4rem" v-model="isSelectAllChecked">-->
<!--          Select all-->
<!--        </md-checkbox>-->
<!--      </div>-->
<!--    </div>-->

    <AppSpinnerComponent v-if="loadingSpinner" :loading="loadingSpinner"/>

    <AppEmptyStateComponent message="There are no songs added yet." v-if="showNoResults"/>


    <div v-if="!showNoResults" class="song-row song-border" style="margin-bottom: 10px;margin-top: 20px">
      <div class="pagination-column">
       Total: {{ this.total }}
      </div>

      <div class="pagination-column">
        <span class="pagination">
				<a href="#" style=" color:white;" :class="{ active: isActive == i }" v-for="i in shownPages" :key="i"
           @click="addClass(i); getPage(i - 1);">{{ i }}</a>
			  </span>
      </div>

      <div class="pagination-column">
        <span style="padding-right: 10px">Page Size:</span>
        <select @change="numOfPages($event)" v-model="pageSize">
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
    </div>

    <div class="songs">
      <!-- paginated -->
      <div class="song md-raised md-layout" v-for="(song, index) in songs" :key="index + '_singleClass'"
           @click="openEditSongModal(song.id)">
<!--        <md-checkbox class="song__data md-secondary md-size-5 md-layout-item md-medium-size-100"-->
<!--                     :id="`selected-songs-${index}`" :key="index" :value="song.id" v-model="selectedSongs"/>-->

        <p class="song__data md-layout-item md-size-8 md-medium-size-100 song-genre">
          <md-tooltip class="md-theme-light" md-direction="top">{{ song.title }}</md-tooltip>

          {{ song.title }}
        </p>

        <p class="song__data md-layout-item md-size-8 md-medium-size-100">
          {{ song.duration }}
        </p>

        <p class="song__data md-layout-item md-size-8 md-medium-size-100 song-genre">
          <md-tooltip class="md-theme-light" md-direction="top">{{ (song.artist != null) ? song.artist.name : 'waiting update' }}</md-tooltip>

          {{ (song.artist != null) ? song.artist.name : 'waiting update' }}
        </p>
        
        <p class="song__data md-layout-item md-size-8 md-medium-size-100 song-genre" >
          <md-tooltip class="md-theme-light" md-direction="top">{{ song.genres.map((genre=>genre.name)).join(' , ') }}</md-tooltip>
          {{ song.genres.map((genre=>genre.name)).join(' , ')}}
        </p>

        <p class="song__data md-layout-item md-size-6 md-medium-size-100">
          {{ (song.publisher != null) ? song.publisher.name : 'waiting update' }}
        </p>

        <p class="song__data md-layout-item md-size-2 md-medium-size-100">
          <span v-if="song.energy"> Energy: {{ song.energy }}</span>
        </p>

        <!--
          this code will be used later


           <p class="song__data md-layout-item md-size-8 md-medium-size-100">
          Like: <span v-if="song.likes"> {{ song.likes }}</span>
        </p>

        <p class="song__data md-layout-item md-size-8 md-medium-size-100">
          Dislike: <span v-if="song.likes"> - {{ song.dislikes }}</span>
        </p>

        <p class="song__data md-layout-item md-size-5 md-medium-size-100">
          <span style="color:red" v-show="song.active == false">Inactive</span>
          <span style="color:rgba(32, 158, 75, 1)" v-show="song.active == true">Active</span>
        </p> -->

        <p class="song__data md-size-10 md-medium-size-100">
          <span style="color:orange" v-if="song.processing == true">Processing</span>

          <span style="color:#CF6679;" v-if="song.processing == false && song.ProcessingStatusType.id == 1">{{
              song.ProcessingStatusType.processing_status
            }}</span>
          <span style="color:#c9c958" v-if="song.processing == false && song.ProcessingStatusType.id == 2">{{
              song.ProcessingStatusType.processing_status
            }}</span>
          <span style="color:rgb(98 185 98);" v-if="song.processing == false && song.ProcessingStatusType.id == 3">{{
              song.ProcessingStatusType.processing_status
            }}</span>
          <span style="color:rgb(105 105 229);" v-if="song.processing == false && song.ProcessingStatusType.id == 4">{{
              song.ProcessingStatusType.processing_status
            }}</span>
            <span style="color:#CF6679;" v-if="song.processing == false && song.ProcessingStatusType.id == 5">{{
              song.ProcessingStatusType.processing_status
            }}</span>
        </p>


        <div class="md-layout-item md-size-10 md-medium-size-100">
          <div class="md-layout md-layout-item">
            <div class="song__remove-btn" id="edit-button" @click.stop="openEditSongModal(song.id)">
              <md-icon>edit</md-icon>
            </div>

            <div class="song__remove-btn" id="remove-button" @click.stop="removeSong(song.id)">
              <md-icon>delete_forever</md-icon>
            </div>
          </div>
        </div>
      </div>
    </div>

    <SongFormModal @search="search" :show-modal.sync="showSongFormModal" :song-prop="song"
                   :key="dialogKey"/>
  </div>
</template>
<script>
import SongFormModal from '../../components/Songs/SongFormModal.vue'
import GenreService from "../../services/genre.service";
import {DeepFilterMixin} from '@/mixins/DeepFilterMixin'
import SongService from '../../services/song.service'

export default {
  name: 'SongsDashboard',
  mixins: [DeepFilterMixin],
  data: () => ({
    numShown: 5,
    songs: [],
    genres: [],
    loadingSpinner: true,
    showDialogSong: false,
    showSongFormModal: false,
    showNoResults: false,
    filterUserString: '',
    selectedGenre: null,
    selectedEnergy: null,
    song: {},
    // currentSongGenres: [],
    dialogKey: 0,
    isSelectAllChecked: false,
    selectedSongs: [],
    current: 0,
    pageSize: 25,
    filterStatusString: 1,
    limit: 0,
    isActive: 0,
    row: 0,
    pages: 0,
    total: 0
  }),
  components: {
    SongFormModal
  },
  computed: {
    shownPages() {
      const numShown = Math.min(this.numShown, Math.ceil(this.pages));
      let first = this.current - Math.floor(numShown / 2);
      first = Math.max(first, 1);
      first = Math.min(first, Math.ceil(this.pages) - numShown + 1);
      return [...Array(numShown)].map((k, i) => i + first);
    },
    filteredStatusSongs() {
      return this.songs.filter(y =>
          this.filterFunction(y, this.filterStatusString)
      )
    }
  },

  watch: {
    isSelectAllChecked(value) {
      if (value) {
        this.filteredSongs.forEach(oneSong => {
          this.selectedSongs.push(oneSong.id)
        })
        this.filteredStatusSongs.forEach(oneSong => {
          this.selectedSongs.push(oneSong.id)
        })
        return
      }

      this.selectedSongs = []
    },
    filterUserString() {
      this.search()
    },
    filterStatusString() {
      this.search()
    },
    selectedEnergy() {
      this.search()
    },
    selectedGenre() {
      this.search()
    },
    pageSize() {
      this.search()
    },
  },

  methods: {
    search() {
      this.getAllSongs(0, this.filterUserString, this.filterStatusString, this.selectedEnergy, this.selectedGenre)
      this.numOfPages()
      this.addClass(1)
      this.current = 0;
      this.shownPages;
    },
    addClass: function (el) {
      this.isActive = el
    },
    onChange(event) {
      console.log(event.target.value)
      this.filterStatusString = event.target.value
    },
    prev(current) {
      if (current <= 0) {
        current = 0
      } else {
        current--
      }
      console.log(current)
      this.limit = current
      this.getAllSongs(Math.ceil(this.limit), this.filterUserString, this.filterStatusString, this.selectedEnergy, this.selectedGenre)
    },
    next(current) {
      if (current < 0) {
        current = 0
      } else if (current > this.pages - 1) {
        current = this.pages - 1
      } else {
        current++
      }
      this.current = current
      if (this.limit >= this.pages - 1) {
        this.limit = this.pages - 1
      } else {
        this.limit = current
      }
      this.getAllSongs(Math.ceil(this.limit), this.filterUserString, this.filterStatusString, this.selectedEnergy, this.selectedGenre)
    },
    getPage(current) {
      this.current = current
      this.limit = current
      this.getAllSongs(Math.ceil(this.limit), this.filterUserString, this.filterStatusString, this.selectedEnergy, this.selectedGenre)
    },
    getCurrentSong(id) {
      let song = this.songs.find(x => x.id === id)
      this.song = Object.assign({}, song)
    },
    getAllSongs(limit = 0, word = null, search_status = null, energy = null, genre = null) {
      SongService.getSongs(this.pageSize, limit, word, search_status, energy, genre)
          .then(res => {
            this.songs = res
            this.loadingSpinner = false
            this.total = this.songs.length === 0 ? 0 : this.songs[0].total
            if (limit == 0) {
              this.addClass(1)
            }
            this.numOfPages()
            this.showNoResults = this.songs.length === 0
          })
          .catch(() => {
            this.$store.dispatch('showSnackbarMessage', {
              message: 'Failed. Please, try again.',
              duration: 8000,
              mode: 'fail'
            })
            this.loadingSpinner = false
          })
    },
    setPageName() {
      this.$store.dispatch('changePageName', {pageNameString: 'Songs'})
    },
    goToSeeDetails(id) {
      this.$router.push('/songs/' + id)
    },
    openAddSongModal() {
      this.dialogKey++
      this.song = null
      // this.genres = [];
      this.showSongFormModal = true
    },
    openEditSongModal(id) {
      this.getCurrentSong(id)
      this.dialogKey++
      this.showSongFormModal = true
    },
    removeSong(songId) {
      SongService.deleteSong(songId)
          .then(() => {
            this.getAllSongs()

            this.$store.dispatch('showSnackbarMessage', {
              message: 'The song was successfully deleted.',
              duration: 4000,
              mode: 'success'
            })
          })
          .catch(err => {
            this.$store.dispatch('showSnackbarMessage', {
              message: `${err.message}. Please, try again.`,
              duration: 4000,
              mode: 'fail'
            })
          })
    },
    exportSongs() {
      SongService.exportSongs()
          .then(response => {
            window.open(response)
          })
          .catch(err => {
          })
    },
    setAllSelectedWithStatus(isActiveStatus) {
      const promises = []

      Object.values(this.selectedSongs).forEach(selectedSong => {

        let bodyFormData = new FormData();
        bodyFormData.set('active', isActiveStatus);

        promises.push(SongService.updateSongStatus(bodyFormData, selectedSong));
      })

      Promise.all(promises)
          .then(() => {
            this.getAllSongs()

            this.$store.dispatch('showSnackbarMessage', {
              message: 'Status changed successfully.',
              duration: 4000,
              mode: 'success'
            })
          })
          .catch(err => {
            this.$store.dispatch('showSnackbarMessage', {
              message: `${err.message}. Please, try again.`,
              duration: 4000,
              mode: 'fail'
            })
          })
    },
    numOfPages() {
      this.pages = this.total / this.pageSize
      console.log(Math.ceil(this.pages))
      // this.next(1)
      // this.addClass(1);
      return Math.ceil(this.pages)
    },
    getAllGenres() {
      GenreService.getAllGenres()
          .then((res) => {
            this.genres = res;
          })
          .catch(() => {
            this.$store.dispatch('showSnackbarMessage', {
              message: 'Failed. Please, try again.',
              duration: 8000,
              mode: 'fail'
            });
          });
    },
  },

  created() {
    this.numOfPages()
    this.setPageName()
    this.getAllSongs(0, this.filterUserString, this.filterStatusString, this.selectedEnergy, this.selectedGenre)
    this.getAllGenres()
  }
}
</script>

<style lang="scss" scoped>

.song-column {
  float: left;
  width: 25%;
  padding: 10px;
}

.pagination-column {
  width: 33.33%;
  float: left;
  height: 60px;
  padding: 20px 0;
}

.song-border {
  border: 2px solid #75758d;
  border-radius: 20px;
  background-color: #2A2A2A;
}

.song-row:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 600px) {
  .song-column {
    width: 100%;
  }
}

.content-header__actions {
  width: 38%;
}

@media screen and (min-width: 640px) {
  .input-field.input-field--filter {
    margin-right: 2.4rem;
  }
}

.content-header.content-header__stores {
  margin-bottom: 40px;
}

.input-field.input-field__stores-filter {
  padding-bottom: 0;
}

@include tableStyle(songs, song);

.songs {
  .song {
    @include respond(medium) {
      flex-direction: column;
      padding: 16px 24px;
      margin-bottom: 3px;
    }

    &__data {
      min-width: 1%;
    }

    &__remove-btn {
      @include respond(medium) {
        margin-left: 25%;
      }
    }
  }
}

.pagination {
  display: inline-block;
  color: white;
  text-align: center;
}

.pagination a {
  color: white;
  float: left;
  padding: 0 16px;
  text-decoration: none;
  border-radius: 5px;
}

.active {
  background-color: #a156a0;
}

.custom-page-size {
  width: 92px;
  height: 62px;
  margin-left: 20%;
  padding-left: 20px;
}
.song-genre {
    	width: 100px;
    	overflow: hidden;
    	white-space: nowrap;
    	text-overflow: ellipsis;
    }

    // #song-genre:hover{
		
		// margin-top: 10px;
    // 	overflow: visible; 
    // 	white-space: normal; 

    // }
</style>
