<template>
  <md-card ref="graphWrap">
    <div :class="classProp" id="graph" width="100%"
         :style="{ backgroundColor: color }"></div>
  </md-card>
</template>

<script>
import * as d3 from 'd3'
import {CheckWindowWidthMixin} from '../../mixins/CheckWindowWidthMixin';

export default {
  name: 'TrainerDashboardBluePrintFlexRate',
  mixins: [CheckWindowWidthMixin],

  props: {
    graphDataProp: {
      type: Array,
      required: true
    },
    classProp: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'transparent'
    }
  },

  data: () => ({
    maxBits: 5,
    maxTime:null,
    factorRatio : null
  }),

  computed: {
    graphData() {

      return this.graphDataProp;
    }
  },

  watch: {
    graphDataProp() {
      this.draw();
    }
  },

  methods: {
    convertTimeHHMMSS(val) {

      let hhmmss = new Date(val).toISOString().substr(11, 8);
      return hhmmss.indexOf("00:") === 0 ? hhmmss.substr(3) : hhmmss;
    },
    initDataAccessors() {
      this.yAccessor = d => d.bits;
      this.xAccessor = d => new Date(d.time - 1000 * 60 * 60 * 2);
      
    },
    reset() {
      this.initDataAccessors();
      this.initGraphCanvas();
      this.draw();
    },
    getColor(val) {
      switch (val) {
        case 1:
          return "rgb(126, 201, 253)";
        case 2:
          return "rgb(161, 139, 250)";
        case 3:
          return "rgb(172, 40, 248)";
        case 4:
          return "rgb(195, 37, 165)";
        case 5:
          return "rgb(194, 25, 25)";
      }
    },
    draw() {
      this.factorRatio = this.isMobile ? 15 : 25;
      const data = [];
      let max = 0;
      for (let i = 0; i < this.graphDataProp.length; i += 2) {
        let obj = {};
        max = Math.max(max, this.graphDataProp[i].time, this.graphDataProp[i + 1].time);
        obj["start"] = this.graphDataProp[i].time;
        obj["end"] = this.graphDataProp[i + 1].time;
        obj["flex"] = this.graphDataProp[i].bits;
        data.push(obj);
      }


      
      d3.select("." + this.classProp).selectAll("*").remove();
      var svg = d3.select("." + this.classProp).append("svg")
          .attr("width", this.classProp === 'graph1' ? parseInt(document.documentElement.clientWidth * 0.85, 10) : document.documentElement.clientWidth)
          .attr("height", this.classProp === 'graph1' ? 40 : 18)

      var xScale = d3.scaleLinear()
          .domain([0, max])
          .range([10, this.dimensions.boundedWidth]);
        

      this.maxTime =(this.graphDataProp[this.graphDataProp.length-1].time);
      let data1 = [0,7200000, 10800000];
      let data3 = [];
      this.graphDataProp.map(d=>{
        data3.push(d.time)
      })
      data3 = data3.filter((item,
      index) => data3.indexOf(item) === index);

      let extend = d3.extent(data3)
      const xScaleXAxis = d3.scaleLinear()
          .domain(extend)
          .range([0, this.dimensions.boundedWidth])
          .nice();

      const yScale = d3.scaleLinear()
          .domain([0, this.maxBits])
          .range([this.dimensions.height, 0]);

      const maxTime =(this.graphDataProp[this.graphDataProp.length-1].time)/(60*1000);
      if (this.classProp === 'graph1') {
        const x_axis = d3.axisBottom()
            .scale(xScaleXAxis)
            .tickFormat(d => this.convertTimeHHMMSS(d));

        svg.append("g")
            .attr("transform", "translate(0, 20)")
            .attr("font-size", "9px")
            // .call(x_axis)

        d3.select('g')
          .selectAll('text')
          .data(data3)
          .join('text')
          .attr('x', function (d) {
            return xScale(d);
          })
          
          .attr('y', 12)
          .style('fill', 'darkOrange')
          .text(d => this.convertTimeHHMMSS(d))
          ;
          let mm =d3.selectAll('text')
          .style('fill', 'white');
          
          mm = Array.prototype.slice.call(mm._groups[0]);
          mm.map((node,i) => {
            if(i < mm.length-1){
              let diff = mm[i+1].attributes.x.nodeValue - mm[i].attributes.x.nodeValue;
              if (diff < this.factorRatio && mm[i].attributes.x.nodeValue != 0 && mm[i].attributes.x.nodeValue != 10) {
                mm[i].classList.add('addOpacity');

              }
            }
          });
          d3.selectAll(".addOpacity").attr('opacity', '0')
          d3.selectAll('.addOpacity')
          .on('mouseover', function (d, i) {
          d3.select(this).transition()
               .duration('100')
               .attr('opacity', '1')
                .attr('y',-13)
                .attr("font-size", "9px")
              })
               .on('mouseout', function (d, i) {
          d3.select(this).transition()
               .duration('100')
               .attr('opacity', '0')
               .attr('y',0)
               .attr("font-size", "9px")

                
              });
        if (this.isMobile) {
          this.factorRatio = 15
          svg.selectAll('g')
          .attr("style", (this.maxTime/(1000*60)) >= 60 ? "font-size:6px;font-weight:bold" : "font-size:6.5px;font-weight:bold")
        }
      }

      var rects = svg.selectAll('rect')
          .data(data);

      rects.enter()
          .append('rect')
          .style('stroke', 'black')
          .merge(rects)
          .attr('x', function (d) {
            return xScale(d.start);
          })
          .attr('width', function (d) {
            return (xScale(d.end) - xScale(d.start)) * 0.98;
          })
          .attr('y', function (d) {
            return yScale(d.flex);
          })
          .attr('height', function (d) {
            return yScale(0) - yScale(d.flex);
          });

      var bars = svg.selectAll("rect").data(data);
      bars.enter().append("rect")
          .attr('width', function (d) {
            return (xScale(d.end) - xScale(d.start)) * 0.98;
          })
      bars
          .attr('x', function (d) {
            return xScale(d.start);
          })
          .attr('y', function (d) {
            return yScale(d.flex);
          })
          .attr('height', function (d) {
            return yScale(0) - yScale(d.flex);
          })
          .attr("fill", (d) => {
            return this.getColor(d.flex)
          });
      bars.exit().remove();
    },
    initGraphCanvas() {
      this.dimensions = {
        width: this.classProp === 'graph1' ? parseInt(document.documentElement.clientWidth * 0.8, 10) : document.documentElement.clientWidth,
        height: 18,
        margin: {
          top: 0,
          right: 15,
          bottom: 0,
          left: 0,
        },
      };

      this.dimensions.boundedWidth = this.dimensions.width
          - this.dimensions.margin.left
          - this.dimensions.margin.right;
      this.dimensions.boundedHeight = this.dimensions.height
          - this.dimensions.margin.top
          - this.dimensions.margin.bottom;

    }
  },

  mounted() {
    window.addEventListener("resize", this.reset)

    this.initDataAccessors();
    this.initGraphCanvas();
    this.draw();
  }
}
</script>

<style lang="scss" scoped>
// overwriting default framework's styles with !important
.md-card.graph-wrap {
  margin: 0 !important;
  margin-bottom: -5px;
  width: 100%;

}

::v-deep .md-card {
  box-shadow: unset !important;
}

.legend {
  display: flex;
  justify-content: space-around;
  padding-bottom: 15px;
  font-size: 2rem;
  font-weight: 600;
}

#graph {
  box-shadow: unset;
}

</style>
